<template>
	<div style="height: 100%">
		<m-list
			:key="componentKeyList"
			ref="offerslist"
			:items="offers"
			:dynamic="false"
			item-value="of_id"
			:item-height="55"
			:limit="limit"
			:skip="skip"
			:total="total"
			:current="current"
			:bgstrip="true"
			@changerange="changerange"
			@itemclick="itemclick"
		>
			<template v-slot:ths="{}">
				<th></th>
				<th></th>
				<th @click="changeSort('of_ref')">Réf.</th>
				<th @click="changeSort('of_type')">Type</th>
				<th v-if="!short" @click="changeSort('of_surface')">Surface</th>
				<th @click="changeSort('t2.bu_name')">Immeuble</th>
				<th @click="changeSort('price')">Prix</th>
				<th @click="changeSort('of_web')" v-if="!short">Web</th>
			</template>
			<template v-slot:tds="{ item }">
				<td style="width: 45px" class="formlittle" @click="clickStopPropagation">
					<!-- <v-checkbox :id="`cbofferlist_${item.of_id}`" v-model="item.selected" @click.capture.stop="offersSelectedAction"></v-checkbox> -->
					<input :id="`cbofferlist_${item.of_id}`" type="checkbox" @click.capture.stop="offersSelectedAction" v-model="item.selected" />
				</td>
				<td style="width: 90px">
					<div style="position: relative" v-if="item.of_immotools">
						<div style="position: absolute; z-index: 10; width: 100%; top: 10px">
							<img style="width: 100%" src="/img/immotoolbox.png" alt="" />
						</div>
					</div>

					<img
						v-if="item.images.length"
						:src="
							`${$config.server_url}/api/1.0/images/${item.images[0].fi_id}/70/45?token=${$store.state.accesstoken}&d=${item.images[0].updatedAt}`
						"
						height="45px"
						width="70px"
						class="my-1"
						@mouseover="enterimage(item)"
						@mouseleave="exitimage(item)"
					/>
					<img
						v-else
						:src="`/img/principaute-de-monaco-2016.jpg`"
						height="45px"
						width="70px"
						class="my-1"
						@mouseover="enterimage(item)"
						@mouseleave="exitimage(item)"
					/>
				</td>
				<td style="width: auto">
					{{ item.of_name_fr }}
					<br />
					<small>
						Ref : {{ item.of_ref }}
						<b v-if="!filter.hideagency || item.showAgency"> - {{ item.of_agency_txt }}</b>
					</small>
				</td>
				<td style="width: 200px">
					<div style="line-height: 1rem">
						<div v-if="short">
							<span :inner-html.prop="item.of_surface | formatSurface('<br>')"></span>
						</div>
						<span :inner-html.prop="item.of_type | formatFromArray($store.state.items_of_type)"></span>
						<span v-if="item.of_newprogram" style="color:#1976d2;"> / P. neuf</span>
						<span v-if="item.of_nbpieces > 0"> / </span>
						<span :inner-html.prop="item | formatPieces"></span>
						<br />
						<small v-if="!short"
							>Maj : {{ item.updatedAt | formatDateAgo }} / le {{ $moment(item.updatedAt).format("DD/MM/YYYY") }}</small
						>
					</div>
				</td>
				<td style="width: 120px" v-if="!short">
					<div v-if="item.of_surface_livable">
						Hab. :
						<span :inner-html.prop="item.of_surface_livable | formatSurface('<br>')"></span>
					</div>
					<div v-if="item.of_surface">
						Total :
						<span :inner-html.prop="item.of_surface | formatSurface('<br>')"></span>
					</div>
				</td>
				<td style="width: 200px">
					<span :inner-html.prop="item.bu_id | formatBuildingAddress('<br>', false)"></span>
				</td>
				<td style="width: 190px; white-space:initial; text-overflow:unset;">
					<span :inner-html.prop="item | formatOfferPrices('<br>')"></span>
				</td>
				<td style="width: 100px" v-if="!short">
					<span :inner-html.prop="item.of_web === 1 ? 'Oui' : item.of_web === 2 ? 'Oui, accueil' : 'Non'"></span><br />
					<span :inner-html.prop="item.of_ci === 1 ? 'Immotools' : ''"></span>
				</td>
			</template>
		</m-list>
		<!-- <visit-choose-day ref="winVisit" :offersselected="offersSelected"></visit-choose-day> -->
		<!-- <present-win-edit ref="winPresent" :offersselected="offersSelected"></present-win-edit> -->

		<div ref="menuOffersAction" v-if="showMenu" class="menuOffersAction" :close-on-click="false">
			<v-list>
				<div v-for="(item, index) in itemsOffersAction" :key="index">
					<v-list-item @click="toVisit(item)" v-if="item.title">
						<v-list-item-icon>
							<icon class="icon-menu" :name="item.icon" small></icon>
						</v-list-item-icon>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>

					<v-divider v-if="!item.title"></v-divider>
				</div>
				<!-- 				<v-list-group>
					<template v-slot:activator>
						
						<v-list-item v-if="itemOfferActionPrint.title" @click="setMenuPosition()">
							<v-list-item-icon>
								<icon class="icon-menu" :name="itemOfferActionPrint.icon"></icon>
							</v-list-item-icon>
							<v-list-item-title>{{ itemOfferActionPrint.title }}</v-list-item-title>
						</v-list-item>
						<v-divider v-if="!itemOfferActionPrint.title"></v-divider>
					</template>
					<v-list-group sub-group>
						<template v-slot:activator>
							<v-list-item-title @click="setMenuPosition()">Fiche simple</v-list-item-title>
						</template>
						<v-list-item v-for="(langue, i) in $store.state.items_languages" :key="i" class="print-langue">
							<v-list-item-title v-text="langue.text" @click="toPrint('simple', langue.value)"></v-list-item-title>
						</v-list-item>
					</v-list-group>

					<v-list-group sub-group>
						<template v-slot:activator>
							<v-list-item-title @click="setMenuPosition()">Fiche complète</v-list-item-title>
						</template>
						<v-list-item v-for="(langue, i) in $store.state.items_languages" :key="i" class="print-langue">
							<v-list-item-title v-text="langue.text" @click="toPrint('complete', langue.value)"></v-list-item-title>
						</v-list-item>
					</v-list-group>
				</v-list-group> -->
			</v-list>
		</div>

		<emailing-win-edit ref="winEmailing" :offersselected="offersSelected"></emailing-win-edit>
		<action-win-edit
			ref="actionwinedit"
			:offers-selected="offersSelected"
			:contact-selected="contactSelected"
			@actionWinEditSaved="actionWinEditSaved"
		></action-win-edit>
		<offers-print-dialog @print="toPrint" v-model="offersPrintDialog"></offers-print-dialog>
	</div>
</template>

<script>
import OffersEmailingWinEdit from "../views/OffersEmailingWinEdit";

export default {
	name: "offerslist",
	props: {
		offers: {
			default: function() {
				return [];
			},
			type: Array
		},
		contactSelected: {
			default: function() {
				return null;
			},
			type: Object
		},
		filter: {
			default: function() {
				return {};
			},
			type: Object
		},
		skip: {
			default: 0,
			type: Number
		},
		total: {
			default: 0,
			type: Number
		},
		limit: {
			default: 0,
			type: Number
		},
		current: {
			default: "",
			type: String
		},
		short: {
			default: false,
			type: Boolean
		}
		// hideAgency: {
		//   default: true,
		//   type: Boolean,
		// },
	},

	data() {
		return {
			componentKeyList: 0,
			offersSelected: [],
			showMenu: false,
			x: 0,
			y: 0,
			xSaved: 0,
			ySaved: 0,
			offersPrintDialog: false
		};
	},
	// filters: {},
	watch: {
		offers(v) {
			// console.log("v", v, this.offersSelected);
			this.updateOffersSelected();
		}
	},
	computed: {},
	components: {
		"emailing-win-edit": OffersEmailingWinEdit
	},
	mounted() {
		window.addEventListener("mousemove", this.savePosition);
	},
	destroyed() {
		window.removeEventListener("mousemove", this.savePosition);
	},

	methods: {
		clickStopPropagation(event) {
			event.stopPropagation();
		},
		enterimage(item) {
			item.showAgency = true;
			this.$forceUpdate();
		},
		exitimage(item) {
			item.showAgency = false;
			this.$forceUpdate();
		},
		savePosition(evt) {
			this.xSaved = evt.clientX * 1;
			this.ySaved = evt.clientY * 1;
			// console.log("this.x", this.x, this.y);
		},
		updateOffersSelected() {
			for (let iV = 0; iV < this.offers.length; iV++) {
				const row_of = this.offers[iV];
				if (this.$_.find(this.offersSelected, { of_id: row_of.of_id })) row_of.selected = true;
				else row_of.selected = false;
			}
		},
		changeSort(sort) {
			this.$emit("changesort", sort);
		},
		actionWinEditSaved(ac_id) {
			this.offersSelected = [];
			// this.$forceUpdate();
			// this.$refs.offerslist.$forceUpdate();
			this.updateOffersSelected();
			this.updateListView();
			this.$emit("actionWinEditSaved", ac_id);
		},
		async toVisit(val) {
			this.showMenu = false;
			if (val.action == "present") {
				// this.$refs.winPresent.open();
				this.$refs.actionwinedit.open("-5");
			}
			if (val.action == "notinterested") {
				if (!this.contactSelected) {
					alert("Vous devez d'abord choisir un contact");
					return;
				}
				for (let iOf = 0; iOf < this.offersSelected.length; iOf++) {
					const row_of = this.offersSelected[iOf];
					await this.$axios.post(this.$config.server_url + "/api/1.0/actions", {
						ac_type: "notinterested",
						co_id_contact: this.contactSelected.co_id,
						co_id_user: this.$store.getters.get_user.co_id,
						co_id_user_todo: this.$store.getters.get_user.co_id,
						ac_date: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
						of_id: row_of.of_id,
						// ac_offers: [this.row_of.of_id],
						ac_done: true,
						ac_comment: "Non intéressé via une correlation"
					});
				}
				this.offersSelected = [];
				this.updateOffersSelected();
				this.updateListView();
				// this.$forceUpdate();
				this.$emit("actionWinEditSaved");
			}
			if (val.action == "visit") {
				// this.$refs.winVisit.open();
				this.$refs.actionwinedit.open("-6");
			}
			if (val.action == "emailing") {
				this.$refs.actionwinedit.open("-7");
				// this.$refs.winEmailing.open();
			}
			if (val.action == "deleteselection") {
				this.offersSelected = [];
				this.updateOffersSelected();
				// this.offers.map(row_of => {
				//   row_of.selected = false;
				// });
				this.updateListView();
			}
			if (val.action == "print") {
				this.offersPrintDialog = true;
			}
		},
		updateListView() {
			this.componentKeyList++;
			this.$nextTick(() => {
				// this.$forceUpdate();
				// this.$refs.offerslist.$forceUpdate();
				// console.log("this.componentKeyList, this.$store.state.offersscrolltop", this.componentKeyList, this.$store.state.offersscrolltop);
				this.$refs.offerslist.update(this.$store.state.offersscrolltop);
			});
		},
		toPrint(options) {
			this.offersPrintDialog = false;
			// let selected = [];
			// for (
			//   let iSelected = 0;
			//   iSelected < this.offersSelected.length;
			//   iSelected++
			// ) {
			//   const sel = this.offersSelected[iSelected];
			//   selected.push(sel.of_id);
			//   // window.setTimeout(() => {
			//   // }, 1000 * iSelected);
			// }
			let selected = this.offersSelected.map(row_of => {
				return row_of.of_id;
			});
			window.open(
				this.$config.server_url +
					"/api/2.0/offers/prints?selected=" +
					JSON.stringify(selected) +
					"&type=" +
					options.typeFiche +
					"&duplicate1page=" +
					options.duplicate1page +
					"&lang=" +
					options.langue +
					"&wlogo=" +
					options.withLogo +
					"&wprice=" +
					options.withPrice +
					"&d=" +
					new Date().getTime(),
				"_blank"
			);
			this.offersSelected = [];
			this.updateOffersSelected();
			this.updateListView();
		},
		setMenuPosition() {
			let me = this;
			function setPosition() {
				if (!me.$refs.menuOffersAction) return;
				let p = me._findRelativeParent(me.$refs.offerslist.$el);
				let top = me.y * 1 - p.top;

				let h = parseInt(me.$refs.menuOffersAction.clientHeight);
				let t = me.y;
				if (h + t > window.innerHeight - 30) {
					top -= h + t - window.innerHeight + 30;
				}
				me.$refs.menuOffersAction.style.top = top + "px";
			}
			setPosition();
			setTimeout(() => {
				setPosition();
			}, 300);
			setTimeout(() => {
				setPosition();
			}, 800);
		},
		changerange(skip, scrollTop) {
			this.$emit("changerange", skip, scrollTop);
		},
		itemclick(item) {
			this.$emit("itemclick", item);
		},
		update(scrollTop) {
			this.$refs.offerslist.update(scrollTop);
		},
		_findRelativeParent(node, res) {
			if (!res) res = { top: 0, left: 0 };
			let p = node.parentElement;
			if (p) {
				let s = getComputedStyle(p);
				if (s.position == "relative" || s.position == "absolute") {
					res.top += p.getBoundingClientRect().top - p.scrollTop;
					res.left += p.getBoundingClientRect().left;
					return res;
				}
				//   console.log("p", p.className, p.getBoundingClientRect().top, p.style);
				return this._findRelativeParent(p, res);
			}
			return res;
		},
		offersSelectedAction(evt, evt2) {
			// console.log("evt", evt, evt2);
			// return;
			// console.log("evt", evt);
			// console.log("val", val);
			/* this.lastX = evt.clientX;
      this.lastY = evt.clientY; */
			//this.showMenu = true;
			// console.log("evt.clientX,evt.clientY", evt.clientX, evt.clientY);
			// this.offersSelected = this.getOffersSelected();
			// console.log("evt", evt.target);
			window.setTimeout(() => {
				let toUnSelect = [];
				for (let iOf = 0; iOf < this.offers.length; iOf++) {
					const row_of = this.offers[iOf];
					let toAdd = row_of.selected;
					for (let iOfSel = 0; iOfSel < this.offersSelected.length; iOfSel++) {
						const row_of_selected = this.offersSelected[iOfSel];
						if (row_of.of_id == row_of_selected.of_id) {
							toAdd = false;
							if (!row_of.selected) toUnSelect.push(row_of.of_id);
						}
					}
					if (toAdd) this.offersSelected.push(row_of);
				}
				// this.offersSelected = this.$_.dropWhile(this.offersSelected, row_of => {
				//   if (toUnSelect.indexOf(row_of.of_id) >= 0) return true;
				//   return false;
				// });
				this.offersSelected = this.offersSelected.filter(row_of => {
					if (toUnSelect.indexOf(row_of.of_id) >= 0) return false;
					return true;
				});
				// console.log("this.offersSelected", this.offersSelected);

				// return;
				// this.x = evt.clientX * 1;
				// this.y = evt.clientY * 1;
				// console.log("this.y", this.y);
				this.x = this.xSaved;
				this.y = this.ySaved;
				if (this.offersSelected.length) {
					/* this.itemOfferActionPrint = {
						title: this.$options.filters.plural(this.offersSelected.length, "%n bien à imprimer", "%n biens à imprimer"),
						action: "print",
						icon: "print"
					}; */
					this.itemsOffersAction = [
						{
							title: "Effacer la sélection",
							action: "deleteselection",
							icon: "trash"
						},
						{
							title: ""
						},
						{
							title: this.$options.filters.plural(this.offersSelected.length, "%n bien non intéressé", "%n biens non intéressés"),
							action: "notinterested",
							icon: "eye-slash"
						},
						{
							title: ""
						},
						{
							title: this.$options.filters.plural(this.offersSelected.length, "%n bien présenté", "%n biens présentés"),
							action: "present",
							icon: "eye"
						},
						{
							title: ""
						},
						{
							title: this.$options.filters.plural(this.offersSelected.length, "%n bien à visiter", "%n biens à visiter"),
							action: "visit",
							icon: "key"
						},
						{
							title: ""
						},
						{
							title: this.$options.filters.plural(this.offersSelected.length, "%n bien à emailer", "%n biens à emailer"),
							action: "emailing",
							icon: "at"
						},
						{
							title: ""
						},
						{
							title: this.$options.filters.plural(this.offersSelected.length, "%n bien à imprimer", "%n biens à imprimer"),
							action: "print",
							icon: "print"
						}
					];
					this.showMenu = true;
					this.$nextTick(() => {
						// console.log("this.lastY", this.lastY);
						// this.x = this.lastX;
						// this.y = this.lastY - 40;
						this.setMenuPosition();
					});
				} else {
					this.showMenu = false;
				}
			}, 100);
		}
	}
};
</script>
<style scoped>
.menuOffersAction {
	position: absolute;
	z-index: 10;
	left: 80px;
	border-radius: 5px;
	border: solid 1px #cacaca;
	box-shadow: #cacaca 2px 2px 6px;
	width: 300px;
}
</style>
