<template>
	<v-layout align-center justify-center class="mainbglogin">
		<v-flex xs12 sm8 md4 style="max-width: 580px">
			<v-card class="elevation-12">
				<v-card-text>
					<div class="d-flex">
						<div style="width: 190px" class="mr-5">
							<v-img :src="logoUrl" height="100px" width="190px" contain></v-img>
						</div>
						<div class="d-flex">
							<h1 class="align-self-center">
								Bienvenue chez
								<br />
								<b>{{ societyName }}</b>
							</h1>
						</div>
					</div>
					<div ref="part1" v-show="currentPart == 'part1'">
						<v-form class="mt-3">
							<m-form-text label="Identifiant" label-position="top" v-model="co_login" name="login" autocomplete></m-form-text>
							<m-form-text
								type="password"
								label="Mot de passe"
								label-position="top"
								v-model="co_password"
								autocomplete
								name="password"
							></m-form-text>
						</v-form>
						<v-alert :type="alert_type" ref="alert" v-if="alert_txt">
							{{ alert_txt }}
						</v-alert>
						<div>
							<a @click="currentPart = 'part2'">Mot de passe oublié</a>
						</div>
						<div>
							<v-btn color="black" @click="login()" class="ml-auto">Login</v-btn>
						</div>
					</div>

					<div ref="part2" v-show="currentPart == 'part2'">
						<v-form class="mt-3">
							<m-form-text label="Identifiant ou Email" label-position="top" v-model="co_login" name="login" autocomplete></m-form-text>
						</v-form>
						<v-alert :type="alert_type_part2" ref="alert" v-if="alert_txt_part2">{{ alert_txt_part2 }}</v-alert>
						<div>
							<v-btn color="secondary" @click="currentPart = 'part1'" class="mr-2">Annuler</v-btn>
							<v-btn color="black" @click="sendPassword" class="ml-auto">Envoyer le mot de passe</v-btn>
						</div>
					</div>

					<div ref="part3" v-show="currentPart == 'part3'">
						Vous allez recevoir un mail le plus rapidement possible, vous permettant de mettre à jour votre mot de passe.
						<div>
							<v-btn color="black" @click="currentPart = 'part1'" class="ml-auto">Se connecter maintenant</v-btn>
						</div>
					</div>

					<div ref="part4" v-show="currentPart == 'part4'">
						<div v-if="!alert_txt_part4">
							<m-form-text
								type="password"
								label="Nouveau mot de passe"
								label-position="top"
								v-model="co_password1"
								autocomplete
								name="password"
							></m-form-text>
							<m-form-text
								type="password"
								label="Retaper votre mot de passe"
								label-position="top"
								v-model="co_password2"
								autocomplete
								name="password"
							></m-form-text>
							<v-btn color="black" @click="newpassword" class="ml-auto">Redéfinir le nouveau mot de passe</v-btn>
						</div>
						<v-alert :type="alert_type_part4" ref="alert" v-if="alert_txt_part4">{{ alert_txt_part4 }}</v-alert>
					</div>

					<div ref="part5" v-show="currentPart == 'part5'">
						C'est bon !
						<br />Votre nouveau mot de passe a été définit. <br />Vous pouvez maintenant vous connecter.
						<div>
							<v-btn color="black" @click="currentPart = 'part1'" class="ml-auto">Se connecter maintenant</v-btn>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-flex>
	</v-layout>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
	name: "login",
	components: {},
	data() {
		return {
			currentPart: "part1",
			societyName: "",
			logoUrl: "",
			co_login: "",
			co_password: "",
			co_password1: "",
			co_password2: "",
			alert_txt: "",
			alert_type: "warning",
			alert_txt_part2: "",
			alert_type_part2: "warning",
			alert_txt_part4: "",
			alert_type_part4: "warning",
			challenge_newpassword: "",
			co_id: ""
		};
	},
	mounted() {
		this.$root.$children[0].hideMenus();
		this.$root.$children[0].resizeMainContainer();
		this.loadAppData();
		if (this.$route.name == "resetpass") {
			this.currentPart = "part4";
			this.checkchallenge(this.$route.params.co_id, this.$route.params.challenge);
		}
	},
	methods: {
		async newpassword() {
			if (!this.challenge_newpassword) return;
			let response = await this.$axios.post(this.$config.server_url + "/api/1.0/signin/forgetpassword/newpassword", {
				co_password: this.co_password1,
				co_id: this.co_id,
				challenge: this.challenge_newpassword
			});
			if (response.data.err) {
				this.alert_txt_part4 = "Erreur de challenge";
			} else {
				this.currentPart = "part5";
			}
		},
		async checkchallenge(co_id, challenge) {
			this.co_id = co_id;
			let response = await this.$axios.post(this.$config.server_url + "/api/1.0/signin/forgetpassword/checkchallenge", { co_id, challenge });
			if (response.data.err) {
				this.alert_txt_part4 = "Erreur de challenge";
			} else {
				// this.currentPart = "part3";
				this.challenge_newpassword = response.data.data.challenge;
			}
		},
		async sendPassword() {
			let response = await this.$axios.post(this.$config.server_url + "/api/1.0/signin/forgetpassword", { co_login: this.co_login });
			if (response.data.err) {
				this.alert_txt_part2 = "Nous n'avons pas pu retrouver votre identifiant";
			} else {
				this.currentPart = "part3";
			}
		},
		async loadAppData() {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/app-datas", {});
			this.societyName = response.data.data.societyName;
			this.logoUrl = response.data.data.logoUrl;
		},
		async login() {
			try {
				let response = await this.$axios.post(this.$config.server_url + "/api-web/1.0/signin", {
					co_login: this.co_login,
					co_password: this.co_password
				});
				if (response.data.err) {
					//console.log("response.data.err", response.data.err);
					this.alert_txt = "Erreur de connexion";
					this.alert_type = "warning";
					return;
				}
				this.alert_txt = "Vous êtes connecté";
				this.alert_type = "success";
				// this.$store.commit("set_token", response.data.accesstoken);
				// this.$store.commit("set_refreshtoken", response.data.refreshtoken);
				// this.$store.commit("set_user", response.data.data);
				this.$store.commit("set_connexion", {
					accesstoken: response.data.accesstoken,
					refreshtoken: response.data.refreshtoken,
					user: response.data.data,
					preferences: response.data.preferences
				});
				this.$root.$children[0].showMenus();
				this.$router.push("/home");
			} catch (error) {
				console.error("error", error);
				this.alert_txt = "Erreur!!!";
				this.alert_type = "warning";
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.mainbglogin {
	background-image: url(/img/Larvotto_Monaco-1.jpg);
	background-size: cover;
	background-position: bottom;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 12px;
	left: 12px;
	height: 100vh;
	width: 100vw;
}
</style>
