import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";
Vue.use(Vuetify, {
	directives: {
		Ripple
	}
});

export default new Vuetify({
	icons: {
		iconfont: "mdi"
	},
	theme: {
		themes: {
			light: {
				primary2: "#2096f3",
				error2: "#ff4b56",
				warning2: "#fab943",
				secondary2: "#484a4f"
			}
		}
	}
});
