var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"tablestriped dense"},[_c('tbody',_vm._l((_vm.items_frame_goods),function(item){return _c('tr',{key:item.value},[(_vm.hasGoodValue(item, _vm.row_of))?_c('td',[_vm._v(_vm._s(_vm._f("trad")(item.label ? item.label : item.label_fr,_vm.lang)))]):_vm._e(),(false)?_c('td',{domProps:{"innerHTML":_vm._s(_vm.Nada)}}):(item.value == 'co_id_owner' && _vm.hasGoodValue(item, _vm.row_of))?_c('td',[_c('a',{on:{"click":_vm.showprivate}},[_vm._v(_vm._s(_vm.showprivatebt))]),(_vm.showprivateok)?_c('div',[_c('a',{domProps:{"innerHTML":_vm._s(
							_vm.$options.filters[item.filter](
								_vm.row_of[item.value],
								item.filtermore && _vm.$store.state[item.filtermore] ? _vm.$store.state[item.filtermore] : item.filtermore
							)
						)},on:{"click":function($event){return _vm.$router.push('/contacts/' + _vm.row_of.co_id_owner.co_id + '/details')}}})]):_vm._e()]):(item.value == 'of_mandat' && _vm.hasGoodValue(item, _vm.row_of))?_c('td',[_c('a',{on:{"click":_vm.showprivate}},[_vm._v(_vm._s(_vm.showprivatebt))]),(_vm.showprivateok)?_c('div',[_vm._v(_vm._s(_vm.row_of[item.value]))]):_vm._e()]):(item.value == 'of_notes_customer' && _vm.hasGoodValue(item, _vm.row_of))?_c('td',[_c('a',{on:{"click":_vm.showprivate}},[_vm._v(_vm._s(_vm.showprivatebt))]),(_vm.showprivateok)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.nl2br(_vm.row_of[item.value]))}}):_vm._e()]):(item.value == 'of_notes_private' && _vm.hasGoodValue(item, _vm.row_of))?_c('td',[_c('a',{on:{"click":_vm.showprivate}},[_vm._v(_vm._s(_vm.showprivatebt))]),(_vm.showprivateok)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.nl2br(_vm.row_of[item.value]))}}):_vm._e()]):(item.value == 'of_notes_private2' && _vm.hasGoodValue(item, _vm.row_of))?_c('td',[_c('a',{on:{"click":_vm.showprivate}},[_vm._v(_vm._s(_vm.showprivatebt))]),(_vm.showprivateok)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.nl2br(_vm.row_of[item.value]))}}):_vm._e()]):(item.filter && _vm.hasGoodValue(item, _vm.row_of))?_c('td',{domProps:{"innerHTML":_vm._s(
					_vm.$options.filters[item.filter](
						_vm.row_of[item.value],
						item.filtermore && _vm.$store.state[item.filtermore] ? _vm.$store.state[item.filtermore] : item.filtermore
					)
				)}}):(_vm.hasGoodValue(item, _vm.row_of))?_c('td',{domProps:{"innerHTML":_vm._s(_vm.row_of[item.value])}}):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }