<template>
	<v-layout column fill-height formlittle>
		<v-flex style="flex-grow: 0" class="py-0">
			<v-card class="overflow-auto">
				<v-card-text class="pt-7 pb-7">
					<v-layout wrap no-padding-inputs ma-0>
						<v-flex md4 pa-0>
							<v-layout align-center justify-start ma-0>
								<div style="width: 300px; border: 1px solid #bbb; padding: 0 20px">
									<v-autocomplete
										:name="$Utils.randomstring('types')"
										label="Types de bien"
										:items="$store.state.items_of_type"
										v-model="filter.types"
										multiple
										autocomplete
									></v-autocomplete>
								</div>
							</v-layout>
							<v-layout align-center justify-start ma-0>
								<m-form-checkbox
									:name="$Utils.randomstring('room_1')"
									v-model="filter.room_1"
									label="Studio"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('room_2')"
									v-model="filter.room_2"
									label="2P"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('room_3')"
									v-model="filter.room_3"
									label="3P"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('room_4')"
									v-model="filter.room_4"
									label="4P"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('room_5')"
									v-model="filter.room_5"
									label="5P+"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('confidential')"
									v-model="filter.confidential"
									label="Confidentiel"
									class=""
								></m-form-checkbox>
							</v-layout>
							<v-layout align-center justify-start ma-0>
								<m-form-checkbox
									:name="$Utils.randomstring('monaco')"
									v-model="filter.monaco"
									label="Monaco"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('france')"
									v-model="filter.france"
									label="France"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('archives')"
									v-model="filter.archives"
									label="Archives"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('immotools')"
									v-model="filter.immotools"
									label="Immotools"
									class="mr-4"
								></m-form-checkbox>
							</v-layout>
							<v-layout align-center justify-start ma-0>
								<m-form-checkbox
									:name="$Utils.randomstring('sell')"
									v-model="filter.sell"
									label="Vente"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('rent')"
									v-model="filter.rent"
									label="Location"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('newprogram')"
									v-model="filter.newprogram"
									label="P. neuf"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('follows')"
									v-model="filter.follows"
									label="Suivis"
									class="mr-4"
								></m-form-checkbox>
								<m-form-checkbox
									:name="$Utils.randomstring('notfollows')"
									v-model="filter.notfollows"
									label="Non suivis"
									class="mr-4"
								></m-form-checkbox>
							</v-layout>
							<!-- <div>
                <a @click="showMoreFilters = !showMoreFilters"
                  >Plus d'options</a
                >
              </div> -->
						</v-flex>
						<v-flex md2 py-0>
							<v-layout row ma-0>
								<div style="width: 50%">
									<!-- <m-form-text
                    :name="$Utils.randomstring('budget')"
                    v-model="filter.budget"
                    label="Budget"
                    label-position="top"
                    placeholder="Min"
                    tooltip="Exemple d'utilisation :<br>“2“ : 2 millions € à plus ou moins 30%<br>“1 2“ : entre 1 et 2 millions €<br>“300 800“ : entre 300.000 € et 800.000 €<br>“&lt;2“ : inférieur à 2 millions €<br>“5+-50“ : 5 millions € à plus ou moins 50%"
                  ></m-form-text>-->
									<m-form-text
										:name="$Utils.randomstring('budget')"
										v-model="filter.budgetmintxt"
										label="Budget"
										label-position="top"
										placeholder="Min"
									></m-form-text>
								</div>
								<div style="width: 50%">
									<m-form-text
										:name="$Utils.randomstring('budget')"
										v-model="filter.budgetmaxtxt"
										label="."
										label-position="top"
										placeholder="Max"
									></m-form-text>
								</div>
							</v-layout>

							<v-layout row ma-0>
								<div style="width: 50%">
									<!-- <m-form-text
                    :name="$Utils.randomstring('surface')"
                    v-model="filter.surface"
                    label="Surface"
                    label-position="top"
                    placeholder="Min"
                    tooltip="Exemple d'utilisation :<br>“40“ : 40 m<sup>2</sup> à plus ou moins 30%<br>“60 80“ : entre 60 et 80 m<sup>2</sup><br>“&lt;30“ : inférieur à 30 m<sup>2</sup><br>“50+-50“ : 5 m<sup>2</sup> à plus ou moins 50%"
                  ></m-form-text>-->
									<m-form-text
										:name="$Utils.randomstring('surface')"
										v-model="filter.surfacemintxt"
										label="Surface"
										label-position="top"
										placeholder="Min"
									></m-form-text>
								</div>
								<div style="width: 50%">
									<m-form-text
										:name="$Utils.randomstring('surface')"
										v-model="filter.surfacemaxtxt"
										label="."
										label-position="top"
										placeholder="Max"
									></m-form-text>
								</div>
							</v-layout>
						</v-flex>
						<v-flex md2 py-0>
							<m-form-combobox
								v-model="filter.agency"
								label="Agence"
								label-position="top"
								prepend-icon="mdi-account"
								:name="$Utils.randomstring('agency')"
								:store-url="$config.server_url + '/api/1.0/contacts'"
								:store-params="{ gr_id: 'agencies' }"
								item-value="co_id"
								:item-text="$options.filters.formatContactName"
							></m-form-combobox>
							<m-form-combobox
								v-model="filter.negociator"
								label="Négociateur"
								label-position="top"
								prepend-icon="mdi-account"
								:name="$Utils.randomstring('negociator')"
								:store-url="$config.server_url + '/api/1.0/contacts'"
								:store-params="{ gr_id: 'users' }"
								item-value="co_id"
								:item-text="$options.filters.formatContactName"
							></m-form-combobox>
							<!-- <v-switch
                class="mt-5"
                v-model="filter.immotools"
                label="Voir les biens Immotools"
                :name="$Utils.randomstring('immotools')"
                color="#53bd7b"
                :inset="true"
                :light="true"
                dense
              ></v-switch>-->
						</v-flex>
						<v-flex md2 py-0>
							<m-form-combobox
								v-model="filter.building"
								label="Immeuble"
								label-position="top"
								:name="$Utils.randomstring('building')"
								:store-url="$config.server_url + '/api/1.0/buildings'"
								:store-params="{ addnone: true }"
								item-value="bu_id"
								item-text="bu_name"
							></m-form-combobox>
							<m-form-select
								:name="$Utils.randomstring('district')"
								:items="$store.state.items_bu_district"
								v-model="filter.district"
								label="Quartier"
								label-position="top"
								:label-width="5"
							></m-form-select>
						</v-flex>
						<v-flex md2 py-0>
							<m-form-text
								:name="$Utils.randomstring('text')"
								v-model="filter.text"
								label="Texte libre"
								label-position="top"
								tooltip="Recherche sur : nom de l'offre, description, référence, notes privées, nom de l'immeuble, adresse, ville, code postal"
							></m-form-text>
							<!-- <div class="mt-2">
                <v-btn color="warning2" small @click="resetSearch()">Remise à zéro</v-btn>
              </div>-->
						</v-flex>
					</v-layout>

					<div v-if="showMoreFilters" style="display: flex; flex-wrap: wrap">
						<m-form-checkbox
							:name="$Utils.randomstring('cellar')"
							v-model="filter.cellar"
							label="Avec cave"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('parking')"
							v-model="filter.parking"
							label="Avec parking"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('withgarden')"
							v-model="filter.withgarden"
							label="Avec jardin"
							class="mr-4"
						></m-form-checkbox>

						<div v-for="(service, index) in $store.state.preferences.items.items_of_services" :key="index">
							<m-form-checkbox
								:label="service.label_fr"
								:name="$Utils.randomstring('of_services')"
								v-model="filter[service.valuetxt]"
								class="mr-2"
							></m-form-checkbox>
						</div>

						<!-- <m-form-checkbox
							v-if="!$store.state.preferences.shared.forMonaco"
							:name="$Utils.randomstring('new')"
							v-model="filter.new"
							label="Développement neuf"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('modernbuilding')"
							v-model="filter.modernbuilding"
							label="Immeuble moderne"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('bourgeoisbuilding')"
							v-model="filter.bourgeoisbuilding"
							label="Immeuble bourgeois"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('concierge')"
							v-model="filter.concierge"
							label="Concierge"
							class="mr-4"
						></m-form-checkbox>

						<m-form-checkbox
							:name="$Utils.randomstring('law1235')"
							v-model="filter.law1235"
							label="Sous loi 1235"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('law887')"
							v-model="filter.law887"
							label="Sous loi 887"
							class="mr-4"
						></m-form-checkbox> -->
						<!-- <m-form-checkbox
							:name="$Utils.randomstring('freeoflaw')"
							v-model="filter.freeoflaw"
							label="Libre de loi"
							class="mr-4"
						></m-form-checkbox> -->
						<!-- <m-form-checkbox
							:name="$Utils.randomstring('interestingview')"
							v-model="filter.interestingview"
							label="Vue interessante"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('seaview')"
							v-model="filter.seaview"
							label="Vue mer"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('mixeduse')"
							v-model="filter.mixeduse"
							label="Usage mixte"
							class="mr-4"
						></m-form-checkbox> -->
						<!-- <m-form-checkbox
							:name="$Utils.randomstring('roofterrace')"
							v-model="filter.roofterrace"
							label="Toit terrasse"
							class="mr-4"
						></m-form-checkbox> -->
						<m-form-checkbox :name="$Utils.randomstring('sign')" v-model="filter.sign" label="Vitrine" class="mr-4"></m-form-checkbox>

						<m-form-checkbox
							:name="$Utils.randomstring('sign')"
							v-model="filter.hideagency"
							label="Masquer agence"
							class="mr-4"
						></m-form-checkbox>
						<m-form-checkbox
							:name="$Utils.randomstring('sign')"
							v-model="filter.offerUpdatedAt"
							label="Trier par date de modif"
							class="mr-4"
						></m-form-checkbox>
					</div>
				</v-card-text>
			</v-card>
		</v-flex>

		<v-flex overflow-hidden style="flex-grow: 1; height: 100%" class="pb-0" ref="offersdataparent2">
			<v-card class="fill-height d-flex flex-column">
				<div class="flex-grow-0 pa-3 pb-0">
					<div class="d-flex">
						<div class="pr-3">
							<h1>{{ nboffers_txt }} pour</h1>
						</div>
						<div style="width: 200px">
							<m-form-combobox
								v-model="currentcontact"
								placeholder="Choisir un contact"
								:label-width="0"
								prepend-icon="mdi-account"
								:name="$Utils.randomstring('currentcontact')"
								:store-url="$config.server_url + '/api/1.0/contacts'"
								:store-params="{ addnone: false, gr_id: 'contact' }"
								item-value="co_id"
								:item-text="$options.filters.formatContactName"
							></m-form-combobox>
						</div>
						<div class="pl-3">
							<v-btn color="warning2" small @click="$refs.winAssociation.open()">Associer la recherche</v-btn>
						</div>
						<div class="pl-3">
							<v-btn color="secondary2" small @click="$refs.contactwinedit.open(-1)">Nouveau contact</v-btn>
						</div>
						<div style="flex-grow: 1; height: 100%"></div>
						<div>
							<v-btn color="info" @click="newOffer()"> <v-icon small>mdi-plus</v-icon>&nbsp;Nouveau bien </v-btn>
						</div>
					</div>
					<div>
						<span style="display: inline-block; margin-right: 10px" v-if="searchText.length"> Recherche en cours : </span>
						<v-chip v-for="s in searchText" :key="s.se_id" class="mr-1 mt-1" color="primary">{{ s }}</v-chip>
						<span v-if="searchText.length" @click="cancelSearch()">
							<icon class="icon-cross withpointer" name="times"></icon>
						</span>
					</div>
				</div>
				<v-card-text class="flex-grow-1 overflow-hidden pt-0">
					<offers-list
						ref="offerslist"
						:contact-selected="currentcontact"
						:offers="offers"
						:filter="filter"
						:limit="offers_limit"
						:skip="offers_skip"
						:total="offers_total"
						:current="offers_current"
						@itemclick="displayOffer"
						@changerange="changeOffersRange"
						@changesort="changeOffersSort"
					></offers-list>
				</v-card-text>
			</v-card>
		</v-flex>

		<contact-win-edit ref="contactwinedit" @contactWinEditSaved="contactWinEditSaved"></contact-win-edit>

		<search-association-win-edit ref="winAssociation" :filters="filter"></search-association-win-edit>
	</v-layout>
</template>

<script>
// import OffersPresentWinEdit from "../views/OffersPresentWinEdit";
// import OffersVisitChooseDay from "../views/OffersVisitChooseDay";
import OffersList from "../views/OffersList";
import OffersSearchAssociationWinEdit from "../views/OffersSearchAssociationWinEdit";

export default {
	name: "offers",
	data() {
		// console.log(
		//   "this.$store.state.offersfilters",
		//   this.$store.state.offersfilters
		// );
		// const sourceAxiosGetOffer = CancelAxios.source();
		return {
			// currentcontact: this.$_.cloneDeep(this.$store.state.currentcontact),
			showMoreFilters: true,
			cancelAxiosGetOffers: null,
			searchAssociationWinEdit: false,
			autoHeightOffers: 100,
			nboffers_txt: "0 bien",
			offersSelected: [],
			x: 0,
			y: 0,
			// lastX: 0,
			// lastY: 0,
			itemOfferActionPrint: {},
			itemsOffersAction: [],
			// offersscrolltop: this.$store.state.offersscrolltop,
			// offersskip:this.$store.state.offersskip,
			// offersHeaders: [
			//   { text: "Image", value: "of_defaultimage", width: 70 },
			//   {
			//     text: "Nom",
			//     align: "left",
			//     sortable: true,
			//     value: "of_name_fr"
			//   },
			//   { text: "Type", value: "of_type", width: 220 },
			//   { text: "Surface", value: "of_surface", align: "end", width: 100 },

			//   { text: "Ville", value: "bu_id.bu_city" },
			//   { text: "Immeuble", value: "bu_name" },
			//   { text: "Prix", value: "of_price", align: "end", width: 190 },
			//   { text: "Online", value: "of_web", align: "end", width: 100 }
			// ],
			offers: [],
			offers_skip: 0,
			offers_total: 0,
			offers_limit: 3000,
			offers_current: "",
			offer_current: "",
			offers_sort: "price",
			offers_sortorder: false,
			// offersLoading: true,
			filter: this.$_.cloneDeep(this.$store.state.offersfilters)
		};
	},
	// filters: {},

	computed: {
		currentcontact: {
			get() {
				// console.log(
				//   "this.$store.state.currentcontact",
				//   this.$store.state.currentcontact
				// );
				return this.$store.state.currentcontact;
			},
			set(value) {
				this.$store.commit("set_currentcontact", value);
			}
		},
		// currentcontact() {
		//   return this.$store.state.currentcontact;
		// },
		searchText() {
			let s = this.$options.filters.formatSearchName(this.$store.state.offersfilters, "", true);
			// console.log("s", s);
			return s;
		},
		reload_actions() {
			return this.$store.state.reload_actions;
		}
	},
	components: {
		// "present-win-edit": OffersPresentWinEdit,
		// "visit-choose-day": OffersVisitChooseDay,
		// "search-association-win-edit": OffersSearchAssociationWinEdit,
		// "emailing-win-edit": OffersEmailingWinEdit,
		"search-association-win-edit": OffersSearchAssociationWinEdit,
		"offers-list": OffersList
	},
	async mounted() {
		// console.log("this.scrollTop", this.scrollTop);
		// console.log("this.$router", this.$router, this.$route);
		// console.log("this.$route.params", this.$route);
		// this.setHeightOffers();
		// window.addEventListener("resize", this.setHeightOffers);
		// window.addEventListener("mousemove", this.onMouseMove);

		if (this.$store.state.preferences.shared.co_id_society) {
			// this.filter.agency = this.$store.state.preferences.shared.co_id_society;
		}

		if (this.$route.params.se_id) {
			await this.getSearch(this.$route.params.se_id);
		} else if (this.$route.name == "offersfollows") {
			this.filter.follows = true;
			await this.getOffers(this.filter, 0, 0);
			this.$router.push("/offers");
		} else {
			if (this.$store.state.offers_sort) {
				this.offers_sort = this.$store.state.offers_sort;
				this.offers_sortorder = this.$store.state.offers_sortorder;
			}
			await this.getOffers(this.filter, this.$store.state.offersskip, this.$store.state.offersscrolltop);
		}
	},
	sockets: {
		offers_update(of_id) {
			this.getOffers(this.filter, this.$store.state.offersskip);
		}
	},
	destroyed() {
		// window.removeEventListener("resize", this.setHeightOffers);
		// window.removeEventListener("mousemove", this.onMouseMove);
	},
	watch: {
		// currentcontact(val) {
		//   // console.log("val", val);
		//   this.$store.commit("set_currentcontact", val);
		// },
		reload_actions() {
			// this.loadActions();
		},
		$route(to, from) {
			// if (to.meta.edit) this.$refs.CalendarWinEdit.open();
			if (this.$route.name == "offersfollows") {
				this.cancelSearch();
				this.filter.follows = true;
			}
			// console.log("this.$route.name", this.$route.name);
			// else this.filter.follows = false;
			this.$nextTick(() => {
				this.$router.push("/offers");
			});
			// console.log("this.filter", this.filter.follows);
			// this.getOffers();
		},
		filter: {
			handler(newVal, oldVal) {
				if (newVal.text) newVal.text.trim();

				// console.log("filterwatch");
				// console.log("newVal", newVal);
				function convertToMultiple(v) {
					v = (v + "").replace(",", ".");
					// console.log("v", v);
					v = v * 1;
					if (v < 99) v = v * 1000000;
					else if (v < 999) v = v * 1000;
					return v;
				}
				let filter = this.$_.cloneDeep(newVal);

				filter.budgetmin = 0;
				filter.budgetmax = 0;
				if (filter.budgetmintxt) filter.budgetmin = convertToMultiple(filter.budgetmintxt);
				if (filter.budgetmaxtxt) filter.budgetmax = convertToMultiple(filter.budgetmaxtxt);
				if (filter.budgetmin && !filter.budgetmax) filter.budgetmax = 100000000;
				if (!filter.budgetmin && filter.budgetmax) filter.budgetmin = 1;
				// console.log("filter", filter);
				// filter.budgetmin = 0;
				// filter.budgetmax = 0;
				// if (filter.budget) {
				//   filter.budget = filter.budget.trim();
				//   if (filter.budget.indexOf(">") == 0) {
				//     filter.budgetmax = 100000000;
				//     filter.budgetmin = convertToMultiple(filter.budget.substring(1));
				//   } else if (filter.budget.indexOf("<") == 0) {
				//     filter.budgetmin = 1;
				//     filter.budgetmax = convertToMultiple(filter.budget.substring(1));
				//   } else if (filter.budget.indexOf("=") == 0) {
				//     filter.budgetmin = convertToMultiple(filter.budget.substring(1));
				//     filter.budgetmax = convertToMultiple(filter.budget.substring(1));
				//   } else if (filter.budget.indexOf(" ") > 0) {
				//     filter.budgetmin = convertToMultiple(filter.budget.split(" ")[0]);
				//     filter.budgetmax = convertToMultiple(filter.budget.split(" ")[1]);
				//   } else if (filter.budget.indexOf("+-") > 0) {
				//     let budget = convertToMultiple(
				//       filter.budget.substring(0, filter.budget.indexOf("+-"))
				//     );
				//     let purcent = filter.budget.substring(
				//       filter.budget.indexOf("+-") + 2
				//     );
				//     filter.budgetmin = budget - (budget * purcent) / 100;
				//     filter.budgetmax = budget + (budget * purcent) / 100;
				//   } else {
				//     let budget = convertToMultiple(filter.budget);
				//     filter.budgetmin = budget - (budget * 30) / 100;
				//     filter.budgetmax = budget + (budget * 30) / 100;
				//   }
				// }

				filter.surfacemin = 0;
				filter.surfacemax = 0;
				if (filter.surfacemintxt) filter.surfacemin = filter.surfacemintxt * 1;
				if (filter.surfacemaxtxt) filter.surfacemax = filter.surfacemaxtxt * 1;
				if (filter.surfacemin && !filter.surfacemax) filter.surfacemax = 10000;
				if (!filter.surfacemin && filter.surfacemax) filter.surfacemin = 1;

				// filter.surfacemin = 0;
				// filter.surfacemax = 0;
				// if (filter.surface) {
				//   filter.surface = filter.surface.trim();
				//   if (filter.surface.indexOf(">") == 0) {
				//     filter.surfacemax = 10000;
				//     filter.surfacemin = filter.surface.substring(1) * 1;
				//   } else if (filter.surface.indexOf("<") == 0) {
				//     filter.surfacemin = 1;
				//     filter.surfacemax = filter.surface.substring(1) * 1;
				//   } else if (filter.surface.indexOf("=") == 0) {
				//     filter.surfacemin = filter.surface.substring(1) * 1;
				//     filter.surfacemax = filter.surface.substring(1) * 1;
				//   } else if (filter.surface.indexOf(" ") > 0) {
				//     filter.surfacemin = filter.surface.split(" ")[0] * 1;
				//     filter.surfacemax = filter.surface.split(" ")[1] * 1;
				//   } else if (filter.surface.indexOf("+-") > 0) {
				//     let surface =
				//       filter.surface.substring(0, filter.surface.indexOf("+-")) * 1;
				//     let purcent =
				//       filter.surface.substring(filter.surface.indexOf("+-") + 2) * 1;
				//     filter.surfacemin = surface - (surface * purcent) / 100;
				//     filter.surfacemax = surface + (surface * purcent) / 100;
				//   } else {
				//     let surface = filter.surface * 1;
				//     filter.surfacemin = surface - (surface * 30) / 100;
				//     filter.surfacemax = surface + (surface * 30) / 100;
				//   }
				// }
				if (filter.agency && filter.agency.co_id) {
					filter.agency = {
						co_id: filter.agency.co_id,
						co_society: filter.agency.co_society,
						co_name: filter.agency.co_name,
						co_firstname: filter.agency.co_firstname
					};
				}
				if (filter.negociator && filter.negociator.co_id) {
					filter.negociator = {
						co_id: filter.negociator.co_id,
						co_society: filter.negociator.co_society,
						co_name: filter.negociator.co_name,
						co_firstname: filter.negociator.co_firstname
					};
				}
				if (filter.building && filter.building.bu_id) {
					filter.building = {
						bu_id: filter.building.bu_id,
						bu_name: filter.building.bu_name,
						bu_address1: filter.building.bu_address1,
						bu_city: filter.building.bu_city
					};
				}
				//console.log("filter", filter);
				newVal.budgetmin = filter.budgetmin;
				newVal.budgetmax = filter.budgetmax;
				newVal.surfacemin = filter.surfacemin;
				newVal.surfacemax = filter.surfacemax;
				this.$store.commit("set_offersfilters", filter);
				// this.$router.push("/offers");
				// console.log("ici2");
				this.getOffers(filter, 0, 0);
			},
			deep: true
		}
	},
	methods: {
		async contactWinEditSaved(co_id) {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + co_id);
			// console.log("response.data.data", response.data.data);
			this.currentcontact = response.data.data;
		},
		resetSearch() {},
		cancelSearch() {
			this.filter = {};
		},

		async getSearch(se_id) {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/searches/" + se_id);
			if (!response.data.data) return;
			this.filter = response.data.data.se_filters;
			// this.$router.push("/offers");
		},

		// onMouseMove(evt) {
		//   this.lastX = evt.clientX;
		//   this.lastY = evt.clientY;
		// },
		// getOffersSelected() {
		//   let offersSelected = [];
		//   for (let iOf = 0; iOf < this.offers.length; iOf++) {
		//     const row_of = this.offers[iOf];
		//     if (row_of.selected) offersSelected.push(row_of);
		//   }
		//   return offersSelected;
		// },

		changeOffersSort(sort) {
			this.offers_sort = sort;
			this.offers_sortorder = !this.offers_sortorder;
			this.$store.commit("set_offerssort", this.offers_sort, this.offers_sortorder);
			this.getOffers(this.filter, 0, 0);
		},
		changeOffersRange(skip, scrollTop) {
			// console.log("skip, scrollTop", skip, scrollTop);
			// console.log("scrollTop", scrollTop);
			// console.log("ici1");
			// this.getOffers(this.filter, skip);
			this.$store.commit("set_offersscrolltop", scrollTop);
			this.$store.commit("set_offersskip", skip);
			// this.filter.skip = skip;
			// this.filter.limit = this.offers_limit;
		},
		async getOffers(filter, skip = 0, scrollTop = -1) {
			if (!filter) filter = {};
			let f = this.$_.cloneDeep(filter);
			// console.log("filter", filter);
			this.cancelAxiosGetOffers && this.cancelAxiosGetOffers();
			f.skip = skip;
			f.skip = 0;
			f.limit = this.offers_limit;
			f.simple = true;
			let sort = this.offers_sort;
			let order = this.offers_sortorder ? "asc" : "desc";
			sort = sort.replace(",", " " + order + ",");
			f.sort = sort + " " + order;

			if (filter.offerUpdatedAt) {
				f.sort = "t1.updatedAt desc";
			}
			if (f.agency && this.$_.isPlainObject(f.agency)) f.agency = f.agency.co_id;
			// console.log("filter, skip, scrollTop", f, skip, scrollTop);

			// this.offersLoading = true;
			// filter.follows = false;
			// if (this.$route.name == "offersfollows") filter.follows = true;
			// if (this.sourceAxiosGetOffer && this.sourceAxiosGetOffer.cancel)
			//   this.sourceAxiosGetOffer.cancel("Operation canceled by the user.");
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers", {
				params: f,
				cancelToken: new this.$axios.CancelToken(c => {
					this.cancelAxiosGetOffers = c;
				})
			});
			if (!response || !response.data) return;
			this.offers_total = response.data.meta.total;
			this.offers_skip = skip;
			let offers = response.data.data;
			for (let iOf = 0; iOf < offers.length; iOf++) {
				const row_of = offers[iOf];
				row_of.showAgency = false;
				for (let iOfSel = 0; iOfSel < this.offersSelected.length; iOfSel++) {
					const row_of_selected = this.offersSelected[iOfSel];
					if (row_of.of_id == row_of_selected.of_id) row_of.selected = true;
				}
				// console.log(
				//   "row_of.of_agency_txt",
				//   "'" + row_of.of_agency_txt + "'",
				//   "'" + this.$store.state.preferences.shared.societyName + "'"
				// );
				// if (this.$_.trim(row_of.of_agency_txt) !== this.$store.state.preferences.shared.societyName)
				if (row_of.of_immotools) row_of.mListTrClass = "offers-bg-otheragency";
				if (row_of.of_confidential) row_of.mListTrClass = "offers-bg-confidential";
			}

			this.offers = offers;
			this.$refs.offerslist.update(scrollTop);
			// if (this.$refs.offerslist) this.$refs.offerslist.update(scrollTop);

			// this.offersLoading = false;
			this.nboffers_txt = this.$options.filters.plural(response.data.meta.total, "bien", "biens");
		},
		newOffer() {
			this.$router.push("/offers/-1/edit");
		},
		// setHeightOffers() {
		//   if (this.$refs.offersdataparent2) {
		//     this.autoHeightOffers =
		//       this.$refs.offersdataparent2.offsetHeight - 50 - 56;
		//   }
		// },
		displayOffer(item) {
			this.$router.push("/offers/" + item.of_id + "/details"); //item.of_slug_fr
		}
	}
};
</script>
<style scoped>
.v-list-group .v-list-item {
	padding: 0;
}
.icon-menu {
	/* color: #555 !important; */
	color: #bdbdbd;
}
.v-list-group .print-langue {
	padding-left: 70px;
}
.v-list-group__items {
	max-width: 300px;
}
.icon-cross {
	width: 24px;
	height: 24px;
	vertical-align: middle;
}
</style>
