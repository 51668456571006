<template>
	<v-layout align-center justify-center fill-height>
		<v-flex fill-height class="card1">
			<v-card class="fill-height overflow-auto" flat>
				<v-card-title>
					<h1 style="margin-right:20px">
						<span>{{ offers.length }}</span> Biens à trier
					</h1>
					<div>
						<m-form-select
							label=""
							:label-width="2"
							label-position="top"
							:items="items_homeother"
							v-model="what"
							@change="getOffers"
						></m-form-select>
					</div>
				</v-card-title>
				<v-card-text>
					<table class="offerordertab">
						<draggable v-model="offers" group="people" @start="drag = true" @end="drag = false" @change="changeorder">
							<tr v-for="row_of in offers" :key="row_of.of_id">
								<td><icon class="icon-menu" name="sort"></icon></td>
								<td>
									<v-img
										v-if="row_of.images.length"
										:src="
											`${$config.server_url}/api/1.0/images/${row_of.images[0].fi_id}/70/45?token=${$store.state.accesstoken}&d=${row_of.images[0].updatedAt}`
										"
										height="45px"
										width="70px"
										class="my-1"
									></v-img>
									<v-img v-else :src="`/img/principaute-de-monaco-2016.jpg`" height="45px" width="70px" class="my-1"></v-img>
								</td>
								<td>{{ row_of.of_ref }}</td>
								<td>
									{{ row_of.of_name_fr }}
								</td>
								<td>
									{{ row_of | formatOfferPrices }}
								</td>
							</tr>
						</draggable>
					</table>
				</v-card-text>
			</v-card>
		</v-flex>
	</v-layout>
</template>
<script>
import draggable from "vuedraggable";

export default {
	name: "offerorder",
	components: {
		draggable
	},
	data() {
		return {
			offers: [],
			items_homeother: [
				// { text: "Liste des biens", value: 1 },
				{ text: "Homepage", value: 2 }
			],
			what: 2
		};
	},
	watch: {},
	mounted() {
		this.getOffers();
	},
	methods: {
		async changeorder() {
			let sort = [];
			for (let iOrder = 0; iOrder < this.offers.length; iOrder++) {
				const row_of = this.offers[iOrder];
				sort.push(row_of.of_id);
			}
			// console.log("sort", sort);
			await this.$axios.put(this.$config.server_url + "/api/1.0/offerorder", { sort, what: this.what });
		},
		async getOffers() {
			let f = {};
			if (this.what == 1) f.web = true;
			if (this.what == 2) f.of_web = 2;
			// console.log("filter", filter);
			this.cancelAxiosGetOffers && this.cancelAxiosGetOffers();
			f.skip = 0;
			f.limit = 10000;
			let sort = "of_sort";
			if (this.what == 2) sort = "of_sort_homepage";
			let order = "asc";
			f.sort = sort + " " + order;
			// console.log("f", f);

			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers", {
				params: f,
				cancelToken: new this.$axios.CancelToken(c => {
					this.cancelAxiosGetOffers = c;
				})
			});
			if (!response || !response.data) return;
			this.offers = response.data.data;
			// console.log("this.offers", this.offers);

			// if (this.$refs.offerslist) this.$refs.offerslist.update(scrollTop);

			// this.offersLoading = false;
		}
	}
};
</script>
<style lang="scss" scoped>
.backgroud-white {
	background-color: #fff;
}
.container-messages {
	width: 100%;
	height: 100%;
	overflow: auto;
}
.card-title {
	justify-content: space-between;
}
.container-filters {
	line-height: 0.875rem;
	font-size: 0.875rem;
	// float: right;
	// input {
	//   height: 24px;
	// }
}
.offerordertab td {
	border-bottom: 1px solid #eee;
	padding: 3px 15px;
	cursor: move;
}
</style>
