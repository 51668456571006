<template>
	<div class="container-statistiques">
		<v-row class="page-preferences" column fill-height formlittle>
			<v-col class="py-0">
				<v-card class="mb-4 flex-grow-1 card card-personalpreference">
					<v-card-title class="d-flex flex-row justify-space-between">
						<h1>
							<span class="bis">Tableau de bord</span>
						</h1>
					</v-card-title>
					<v-card-text>
						<div class="d-flex flex-row container-filters">
							<!--  <div class="d-flex flex-row sous-container-filter"> -->
							<div class="container-filter-users">
								<v-combobox
									class="mr-3"
									v-model="selectedUsers"
									:items="itemsUsers"
									:item-text="$options.filters.formatContactName"
									label="Négociateur(s)"
									multiple
									filled
									hide-details
									return-object
								></v-combobox>
							</div>
							<div class="d-flex flex-row container-filter-date">
								<m-form-date
									:name="$Utils.randomstring('filterfrom')"
									v-model="filterfrom"
									label="Date de début"
									labelPosition="top"
									class="filter-date mr-3"
								/>
							</div>
							<m-form-date
								:name="$Utils.randomstring('filterto')"
								v-model="filterto"
								label="Date de fin"
								labelPosition="top"
								class="filter-date"
							/>
							<!-- </div> -->
						</div>
						<hr />
						<v-row>
							<v-col cols="2" class="d-flex flex-row container-indicateur">
								<div class="indicateur-de-travail bleu-clair d-flex flex-row">
									<div>{{ nbContactSuivi }}</div>
								</div>
								<div class="d-flex flex-column ml-3">
									<span class="bis line-height">Contacts</span>
									suivis
								</div>
							</v-col>
							<v-col cols="2" class="d-flex flex-row container-indicateur">
								<div class="indicateur-de-travail vert d-flex flex-row">
									<div>{{ nbOfferSuivi }}</div>
								</div>
								<div class="d-flex flex-column ml-3">
									<div class="bis line-height">Biens</div>
									suivis
								</div>
							</v-col>
							<v-col cols="2" class="d-flex flex-row container-indicateur">
								<v-toolbar-items>
									<v-menu :open-on-hover="true" :close-on-click="true" :close-on-content-click="true" :offset-y="true">
										<template v-slot:activator="{ on }">
											<div class="indicateur-de-travail jaune d-flex flex-row" v-on="on">
												<div>{{ nbContactsNotContated }}</div>
											</div>
											<div class="d-flex flex-column ml-3 mt-3">
												<div class="bis line-height">Contacts</div>
												<div>non contactés</div>
											</div>
										</template>
										<div class="dropdown-menu">
											<table class="tablestriped">
												<thead>
													<tr>
														<th>Contact</th>
														<th>Proch. contact</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(contact, index) in contactsNotContated" :key="index" class="withpointer">
														<td>{{ contact.co_id | formatContactName }}</td>
														<td>
															<div
																:class="
																	(contact.ac_id &&
																		contact.ac_id.length &&
																		$moment(contact.ac_id[0].ac_date).isBefore($moment().subtract(1, 'd'))) ||
																	!contact.ac_id.length
																		? 'dateCRred'
																		: 'dateCRgreen'
																"
															>
																{{
																	contact.ac_id && contact.ac_id.length
																		? $moment(contact.ac_id[0].ac_date).format("DD/MM/YYYY")
																		: "pas de contact prévu"
																}}
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</v-menu>
								</v-toolbar-items>
							</v-col>
							<v-col cols="2" class="d-flex flex-row container-indicateur">
								<v-toolbar-items>
									<v-menu :open-on-hover="true" :close-on-click="true" :close-on-content-click="true" :offset-y="true">
										<template v-slot:activator="{ on }">
											<div class="indicateur-de-travail rouge d-flex flex-row" v-on="on">
												<div>{{ nbOffersNotContated }}</div>
											</div>
											<div class="d-flex flex-column ml-3 mt-3">
												<div class="bis line-height">Biens</div>
												non contactés
											</div>
										</template>
										<div class="dropdown-menu">
											<table class="tablestriped">
												<thead>
													<tr>
														<th>Bien</th>
														<th>Proch. contact</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(offer, index) in offersNotContated" :key="index" class="withpointer">
														<td>{{ offer.of_id.of_ref }}</td>
														<td>
															<div
																:class="
																	(offer.ac_id &&
																		offer.ac_id.length &&
																		$moment(offer.ac_id[0].ac_date).isBefore($moment().subtract(1, 'd'))) ||
																	!offer.ac_id.length
																		? 'dateCRred'
																		: 'dateCRgreen'
																"
															>
																{{
																	offer.ac_id && offer.ac_id.length
																		? $moment(offer.ac_id[0].ac_date).format("DD/MM/YYYY")
																		: "pas de contact prévu"
																}}
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</v-menu>
								</v-toolbar-items>
							</v-col>
							<v-col cols="2" class="d-flex flex-column container-indicateur">
								<div class="indicateur-de-resultat biens">
									<div class="pt-1"><span class="bis">Total</span> Commission</div>
									<div class="bis color-white text-Xlarge">{{ totalCom }}</div>
								</div>
							</v-col>
							<v-col cols="2" class="d-flex flex-column container-indicateur">
								<div class="indicateur-de-resultat ca">
									<div class="pt-1"><span class="bis">CA</span> potentiel</div>
									<div class="bis color-white text-Xlarge">
										{{ caPotentiel | formatPrice }}
									</div>
								</div>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="4">
								<div class="bis text-large">{{ nbContactSuivi }} contacts suivis</div>
								<!-- <div class="my-2">
                  <v-text-field
                    placeholder="Rechercher"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    outlined
                  ></v-text-field>
                </div>-->
								<div>
									<table class="tablestriped">
										<thead>
											<tr>
												<th>Contact</th>
												<th>Proch. contact</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(contact, index) in contactSuivi"
												:key="index"
												:class="{
													'd-none': index >= contactsNbMax && !contactsShowMore,
													withpointer: true
												}"
											>
												<td>{{ contact.co_id | formatContactName }}</td>
												<td width="160">
													<span
														:class="
															(contact.actions &&
																contact.actions.length &&
																$moment(contact.actions[0].ac_date).isBefore($moment().subtract(1, 'd'))) ||
															!contact.actions.length
																? 'dateCRred'
																: 'dateCRgreen'
														"
													>
														{{
															contact.actions && contact.actions.length
																? $moment(contact.actions[0].ac_date).format("DD/MM/YYYY")
																: "pas de contact prévu"
														}}
														<icon
															v-if="contact.actions.length"
															class="action_icon"
															:name="
																$_.result(
																	$_.find($store.state.items_ac_type, {
																		value: contact.actions[0].ac_type
																	}),
																	'icon'
																)
															"
														></icon>
													</span>
												</td>
											</tr>
										</tbody>
									</table>
									<div class="text-right mt-2" v-if="offerSuivi.length > contactsNbMax">
										<a @click="contactsShowMore = !contactsShowMore">{{
											contactsShowMore
												? `Masquer ${contactSuivi.length - contactsNbMax} biens`
												: `Voir ${contactSuivi.length - contactsNbMax} biens de plus`
										}}</a>
									</div>
								</div>
							</v-col>
							<v-col cols="4">
								<div class="bis text-large">{{ nbOfferSuivi }} biens suivis</div>
								<!-- <div class="my-2">
                  <v-text-field
                    placeholder="Rechercher"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    outlined
                  ></v-text-field>
                </div>-->
								<div>
									<table class="tablestriped">
										<thead>
											<tr>
												<th>Bien</th>
												<th></th>
												<th>Proch. contact</th>
												<th>Négociateurs</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(offer, index) in offerSuivi"
												:key="index"
												:class="{
													'd-none': index >= offersNbMax && !offersShowMore,
													withpointer: true
												}"
											>
												<td>
													<v-img
														v-if="offer.imageid"
														:src="
															`${$config.server_url}/api/1.0/images/${offer.imageid}/70/45?token=${$store.state.accesstoken}`
														"
														height="45px"
														width="70px"
														class="my-1"
													></v-img>
												</td>
												<td>{{ offer.of_id.of_ref }}</td>
												<td width="155">
													<span
														v-if="offer.actions"
														:class="
															(offer.actions.length &&
																$moment(offer.actions[0].ac_date).isBefore($moment().subtract(1, 'd'))) ||
															!offer.actions.length
																? 'dateCRred'
																: 'dateCRgreen'
														"
													>
														{{
															offer.actions.length
																? $moment(offer.actions[0].ac_date).format("DD/MM/YYYY")
																: "pas de contact prévu"
														}}
														<icon
															v-if="offer.actions.length"
															class="action_icon"
															:name="
																$_.result(
																	$_.find($store.state.items_ac_type, {
																		value: offer.actions[0].ac_type
																	}),
																	'icon'
																)
															"
														></icon>
													</span>
												</td>
												<td>{{ offer.co_id_user | formatContactName }}</td>
											</tr>
										</tbody>
									</table>
									<div class="text-right mt-2" v-if="offerSuivi.length > offersNbMax">
										<a @click="offersShowMore = !offersShowMore">{{
											offersShowMore
												? `Masquer ${offerSuivi.length - offersNbMax} biens`
												: `Voir ${offerSuivi.length - offersNbMax} biens de plus`
										}}</a>
									</div>
								</div>
							</v-col>
							<v-col cols="4">
								<div class="bis text-large">Résumé des actions :</div>
								<hr />
								<div class="d-flex flex-row container-indicateur my-4">
									<div class="indicateur-de-travail jaune d-flex flex-row">
										<div>{{ nbCall }}</div>
									</div>
									<div class="d-flex flex-column ml-3">
										<div class="bis line-height">Appels</div>
										à des clients
									</div>
								</div>
								<hr />
								<div class="d-flex flex-row container-indicateur my-4">
									<div class="indicateur-de-travail bleu-clair d-flex flex-row">
										<div>{{ nbVentesGerees }}</div>
									</div>
									<div class="d-flex flex-column ml-3">
										<div class="bis line-height">Rapports de visites</div>
										remplis
									</div>
								</div>
								<hr />
								<div class="d-flex flex-row container-indicateur my-4">
									<div class="indicateur-de-travail vert d-flex flex-row">
										<div>{{ nbVentesGerees }}</div>
									</div>
									<div class="d-flex flex-column ml-3">
										<div class="bis line-height">Rendez-vous</div>
										pris
									</div>
								</div>
								<hr />
								<div class="d-flex flex-row container-indicateur my-4">
									<div class="indicateur-de-travail rouge d-flex flex-row">
										<div>{{ nbNewOffer }}</div>
									</div>
									<div class="d-flex flex-column ml-3">
										<div class="bis line-height">Nouveaux</div>
										biens rentrés
									</div>
								</div>
								<hr />
								<div class="d-flex flex-row container-indicateur my-4">
									<div class="indicateur-de-travail violet d-flex flex-row">
										<div>{{ nbActionsTodo }}</div>
									</div>
									<div class="d-flex flex-column ml-3">
										<div class="bis line-height">Actions</div>
										à faire
									</div>
								</div>
								<hr />
								<div class="d-flex flex-row container-indicateur my-4">
									<div class="indicateur-de-travail jaune d-flex flex-row">
										<div>{{ nbVentesGerees }}</div>
									</div>
									<div class="d-flex flex-column ml-3">
										<div class="bis line-height">Biens</div>
										vendus
									</div>
								</div>
								<hr />
								<div class="d-flex flex-row container-indicateur my-4">
									<div class="indicateur-de-travail vert d-flex flex-row">
										<div>{{ nbVentesGerees }}</div>
									</div>
									<div class="d-flex flex-column ml-3">
										<div class="bis line-height">Biens</div>
										loués
									</div>
								</div>
								<hr />
							</v-col>
						</v-row>
						<hr />
						<v-row>
							<v-col cols="6">
								<!-- <div class="bis text-large">Evolution des 5 objectifs</div>
                <v-btn class="mt-2 btn-edit-objectif" small>Modifier les ojectifs</v-btn>
                <v-row>
                  <v-col cols="6">
                    <div class="ml-3 bis">Objectif CA</div>
                    <div class="graph-CA"></div>
                    <div class="ml-3 bis">Objectif rapports remplis</div>
                    <div class="graph-RC"></div>
                    <div class="ml-3 bis">Objectif contacts suivis</div>
                    <div class="graph-contactSuivi"></div>
                  </v-col>
                  <v-col cols="6">
                    <div class="ml-3 bis">Objectif nouveaux contact</div>
                    <div class="graph-newContact"></div>
                    <div class="ml-3 bis">Objectif biens suivis</div>
                    <div class="graph-bienSuivi"></div>
                  </v-col>
                </v-row>-->
							</v-col>
							<v-col cols="6">
								<div class="bis text-large">
									<span>{{ actionActionsTodo.length }}</span> actions à faire
								</div>
								<!-- <div class="my-2">
                  <v-text-field
                    placeholder="Rechercher"
                    prepend-inner-icon="mdi-magnify"
                    hide-details
                    outlined
                  ></v-text-field>
                </div>-->
								<div>
									<card-table-actions
										:actions="actionActionsTodo"
										:actions-total="actionActionsTodo.length"
										:actions-nb-max="10"
										:colcomment="false"
										:coldone="false"
									></card-table-actions>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "statistiques",
	components: {},
	data() {
		return {
			offersShowMore: false,
			offersNbMax: 6,
			contactsShowMore: false,
			contactsNbMax: 12,
			itemsUsers: [],
			selectedUsers: [],
			filterfrom: this.$moment()
				.add(-1, "month")
				.format("YYYY-MM-DD"),
			filterto: this.$moment()
				.add(1, "month")
				.format("YYYY-MM-DD"),
			nbContactSuivi: 0,
			contactSuivi: [],
			nbOfferSuivi: 0,
			offerSuivi: [],
			nbContactsNotContated: 0,
			contactsNotContated: [],
			nbOffersNotContated: 0,
			offersNotContated: [],
			nbVentesGerees: 0,
			nbLocationsGerees: 0,
			totalCom: 0,
			caPotentiel: 0,
			nbCall: 0,
			nbRCOk: 0,
			nbRDV: 0,
			nbNewOffer: 0,
			nbActionsTodo: 0,

			// actionsTodos: [],
			actionActionsTodo: []
		};
	},
	created() {},
	async mounted() {
		await this.getAgents();
		let user = this.$store.getters.get_user;
		this.selectedUsers.push(user);
		this.loadChiffres();
		//this.loadTodo();
		this.nbOfferSuivi = this.$store.getters.get_nbbiensuivi;
		this.nbContactSuivi = this.$store.getters.get_nbclientsuivi;
	},
	computed: {
		reload_actions() {
			return this.$store.state.reload_actions;
		}
	},
	sockets: {
		actions_update(ac_id) {
			// console.log("actions_update");
			this.loadChiffres();
		}
	},
	watch: {
		reload_actions() {
			this.loadChiffres();
		},
		selectedUsers(val) {
			for (let ico = 0; ico < val.length; ico++) {
				// const co = val[ico];
			}
			this.loadChiffres();
		},
		filterto(val) {
			// console.log("val", val);
			this.loadChiffres();
		},
		filterfrom(val) {
			// console.log("val", val);
			this.loadChiffres();
		}
		// $route(to, from) {
		//   if (to.meta.edit) this.$refs.CalendarWinEdit.open();
		// }
	},
	methods: {
		async getAgents() {
			let params = { gr_id: "users" };
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts", { params });
			this.itemsUsers = response.data.data;
			this.itemsUsers.sort((a, b) => {
				return a.co_name.localeCompare(b.co_name);
			});
		},
		async loadChiffres() {
			let selectedUsersId = [];
			for (let ius = 0; ius < this.selectedUsers.length; ius++) {
				const user = this.selectedUsers[ius];
				selectedUsersId.push(user.co_id);
			}
			if (this.selectedUsers.length === 0) {
				// users.push("-1");
			}
			let params = {
				user: this.user,
				users: selectedUsersId,
				dateto: this.filterto,
				datefrom: this.filterfrom
			};
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/statistiques/", { params });
			// console.log("response.data.data stats", response.data);
			// this.actionActionsTodo = response.data.todos_todo_user;
			this.actionActionsTodo = this.$Utils.concatActionsUser(response.data).filter(item => {
				return !item.ac_done;
			});
			this.nbContactSuivi = response.data.followscontacts.length;
			this.contactSuivi = response.data.followscontacts;
			this.nbOfferSuivi = response.data.followsoffers.length;
			this.offerSuivi = response.data.followsoffers;
			this.nbContactsNotContated = response.data.contactsnotcontated.length;
			this.contactsNotContated = response.data.contactsnotcontated;
			this.nbOffersNotContated = response.data.offers_not_contated.length;
			this.offersNotContated = response.data.offers_not_contated;
			this.nbVentesGerees = response.data.signatures_sell_user.length;
			this.nbLocationsGerees = response.data.signatures_rent_user.length;
			// console.log("this.offerSuivi", this.offerSuivi);
			for (let iSell = 0; iSell < response.data.signatures_sell_user.length; iSell++) {
				const sell = response.data.signatures_sell_user[iSell];
				this.totalCom += sell.ac_signature_sum2;
			}
			for (let iRent = 0; iRent < response.data.signatures_rent_user.length; iRent++) {
				const rent = response.data.signatures_rent_user[iRent];
				this.totalCom += rent.ac_signature_sum2;
			}
			for (let iOf = 0; iOf < response.data.followsoffers.length; iOf++) {
				const row_of = response.data.followsoffers[iOf];
				this.caPotentiel += row_of.of_id.of_sell_sum + row_of.of_id.of_rent_sum;
			}
			this.nbCall = response.data.calls_user.length;
			this.nbRCOk = response.data.reports_user.filter(row_ac => {
				return row_ac.ac_done == true;
			});
			this.nbRDV = response.data.visits_user.length;
			this.nbNewOffer = response.data.newoffer_user.length;
			this.nbActionsTodo = this.actionActionsTodo.length;
		}
		/*  async loadTodo() {
      // console.log("this.selectedUsers", this.selectedUsers);
      let selectedUsersId = [];
      for (let ius = 0; ius < this.selectedUsers.length; ius++) {
        const user = this.selectedUsers[ius];
        selectedUsersId.push(user.co_id);
      }
      let response = await this.$axios.get(
        this.$config.server_url + "/api/1.0/actions",
        {
          params: {
            sort: "ac_date DESC",
            users: selectedUsersId
          }
        }
      );
      this.actionsTodos = response.data.data;

      for (let iAc = 0; iAc < response.data.data.length; iAc++) {
        const row_ac = response.data.data[iAc];
        this.$moment(row_ac.ac_date).isBefore(this.$moment().subtract(2, "d"))
          ? (row_ac.classDate = "dateCRred")
          : (row_ac.classDate = "dateCRgreen");
      }
      // console.log("response.data.data todo", response.data.data);
    } */
	}
};
</script>
<style lang="scss" scoped>
@import "../sass/variables.scss";
.text-large {
	font-size: large;
}
.text-Xlarge {
	font-size: x-large;
}
.line-height {
	line-height: 0.6rem;
}
.container-statistiques {
	width: 100%;
	height: 100%;
}
.container-filters {
	align-items: flex-end;
}

.indicateur-de-resultat {
	border-radius: 5px;
	width: 100%;
	height: 100%;
	text-align: center;
	&.biens {
		background-color: $myorange;
	}
	&.ca {
		background-color: $myteal;
	}
}
.action_icon {
	padding-top: 5px;
}

.container-indicateur {
	align-items: center;
}

#inspire .btn-edit-objectif {
	background-color: $myrouge-fonce;
}
.container-filters {
	justify-content: flex-end;
	margin-bottom: 10px;
}
.dropdown-menu {
	background-color: #fff;
	padding: 10px;
}
/* .container-filter-date {
  justify-content: flex-end;
  width: 30%;
} */
</style>
