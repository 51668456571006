<template>
	<canvas id="myChart" width="300" height="300"></canvas>
</template>

<script>
import Chart from "chart.js";
export default {
	name: "chart",
	components: {},
	data() {
		return {
			myChart: null
		};
	},
	props: {
		row_of: Object
	},
	async mounted() {
		var labels = [];
		this.$_.each(this.$store.state.items_feedbackstypes, val => {
			labels.push(val.text);
		});
		this.myChart = new Chart(document.getElementById("myChart").getContext("2d"), {
			type: "radar",
			options: {
				legend: {
					display: false
				},
				scale: {
					display: true
				}
			},
			data: {
				labels: labels,
				datasets: [
					{
						label: "",
						backgroundColor: "rgba(151,187,205,0.2)",
						borderColor: "rgba(151,187,205,1)",
						data: [50, 30, 80, 40, 54, 12, 89]
					},
					{
						label: "",
						backgroundColor: "rgba(220,220,220,0.0)",
						borderColor: "rgba(220,220,220,0.0)",
						data: [100, 100, 100, 100, 100, 100, 100]
					},
					{
						label: "",
						backgroundColor: "rgba(220,220,220,0.2)",
						borderColor: "rgba(220,220,220,1)",
						data: [50, 50, 50, 50, 50, 50, 50]
					},
					{
						label: "",
						backgroundColor: "rgba(220,220,220,0)",
						borderColor: "rgba(220,220,220,0)",
						data: [0, 0, 0, 0, 0, 0, 0]
					}
				]
			}
		});
		this.updateChart();
	},
	watch: {
		async row_of() {
			this.updateChart();
		}
	},
	computed: {},
	destroyed() {},
	methods: {
		updateChart() {
			let vals = {};
			this.$_.each(this.row_of.reports_offer, val => {
				if (val.ac_report) {
					this.$_.each(this.$store.state.items_feedbackstypes, fbtype => {
						if (!vals["type_" + fbtype.value]) vals["type_" + fbtype.value] = [0, 0];
						this.$_.each(this.$store.state.items_feedbacks, fb => {
							if (fbtype.value == fb.type) {
								vals["type_" + fbtype.value][0] += val.ac_report["feedback" + fb.value] * 1;
								vals["type_" + fbtype.value][1]++;
							}
						});
					});
				}
			});
			let data = [];
			this.$_.each(this.$store.state.items_feedbackstypes, fbtype => {
				if (!vals["type_" + fbtype.value]) vals["type_" + fbtype.value] = [0, 0];
				data.push(((vals["type_" + fbtype.value][0] + vals["type_" + fbtype.value][1]) / (vals["type_" + fbtype.value][1] * 2)) * 100);
			});
			this.myChart.data.datasets[0].data = data;
			// console.log('data, this.myChart',data, this.myChart);
			this.myChart.update();
		}
	}
};
</script>

<style lang="scss"></style>
