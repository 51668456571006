<template>
	<v-dialog v-model="dialog" persistent :transition="false" max-width="950px" ref="winAssociation">
		<v-card>
			<v-card-title>
				<div>
					<h1>
						Envoyer la sélection par mail
						<span class="bis">à un contact</span>
					</h1>
				</div>
			</v-card-title>
			<v-card-text grid-list-xl :style="stylecontainer">
				<v-container fluid pa-0 grid-list-xl formlittle ref="mycontainer">
					<v-form ref="form" v-model="valid">
						<m-form-select
							label="Langue"
							:label-width="2"
							label-position="top"
							:items="$store.state.items_languages"
							v-model="selectedLanguage"
						></m-form-select>
						<m-form-text
							type="textarea"
							:name="$Utils.randomstring('message')"
							label="Message"
							:label-width="2"
							label-position="top"
							v-model="message"
							tooltip="Modifier les messages par défaut dans les préférences partagées"
						></m-form-text>

						<m-form-combobox
							label="Contact"
							:label-width="2"
							label-position="top"
							v-model="row_co"
							prepend-icon="mdi-account"
							:rules="row_co_rules"
							:store-url="$config.server_url + '/api/1.0/contacts'"
							:store-params="{ gr_id: 'contacts' }"
							item-value="co_id"
							:item-text="$options.filters.formatContactName"
						></m-form-combobox>
					</v-form>
					<v-card v-if="row_co_details">
						<v-card-text>
							<contact-details v-model="row_co_details" @contactDetailsLoaded="setContainerStyle"></contact-details>
						</v-card-text>
					</v-card>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin()" :disabled="!valid">Envoyer</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "offersemailingwinedit",
	props: ["offersselected"],
	data() {
		return {
			selectedLanguage: "fr",
			dialog: false,
			row_co: "",
			row_co_details: null,
			stylecontainer: ``,
			valid: true,
			message: "",
			row_co_rules: [
				v => {
					if (v && v.co_id) return true;
					return "Contact obligatoire";
				}
			]
		};
	},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	computed: {},
	watch: {
		selectedLanguage(v) {
			this.setDefaultMessage(v);
		},
		async row_co(v) {
			if (!v || !v.co_id) {
				this.row_co_details = null;
				this.setContainerStyle();
				return;
			}
			// let response = await this.$axios.get(
			//   this.$config.server_url + "/api/1.0/contacts/" + v.co_id
			// );
			// this.row_co_details = response.data.data;
			this.row_co_details = v.co_id;
			this.setContainerStyle();
		}
	},
	components: {},
	methods: {
		setDefaultMessage(v) {
			if (this.$store.state.preferences.shared.modelesMail && this.$store.state.preferences.shared.modelesMail[v])
				this.message = this.$store.state.preferences.shared.modelesMail[v];
		},
		setContainerStyle() {
			window.setTimeout(() => {
				if (!this.$refs.mycontainer) return;
				if (this.$refs.mycontainer.clientHeight > window.innerHeight - 300)
					this.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
				else this.stylecontainer = "";
			}, 10);
		},
		open() {
			this.setDefaultMessage("fr");
			this.dialog = true;
		},
		cancelWin() {
			this.dialog = false;
			// this.$emit('input');
		},
		async saveWin() {
			await this.$axios.post(this.$config.server_url + "/api/1.0/offers/emails", {
				co_id_contact: this.row_co.co_id,
				offersselected: this.offersselected,
				message: this.message
			});
			this.dialog = false;
		}
	}
};
</script>

<style lang="scss"></style>
