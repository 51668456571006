import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import store from "./store";
// import axios from "axios";
// import config from "../config";
var numeral = require("numeral");

Vue.filter("lowerCase", function(val) {
	return val.toLowerCase();
});
Vue.filter("formatPurcent", function(val) {
	return numeral((val * 1) / 100).format("0,0 %") + "";
});
Vue.filter("trad", function(key, lang) {
	//console.log("lang", key, lang);
	let trad = store.state.preferences.trads[key];
	if (trad) return trad[lang];
	return key;
});
Vue.filter("formatFromTo", function(valFrom, valTo) {
	let mF = moment(new Date(valFrom));
	let mT = moment(new Date(valTo));
	return "De " + mF.format("HH[H]mm") + " à " + mT.format("HH[H]mm");
});
Vue.filter("formatDate", function(val) {
	let v = "";
	if (_.isString(val)) {
		let m = moment(val);
		if (m.isValid()) v = m.format("DD/MM/YYYY");
	}
	if (moment.isMoment(val)) v = val.format("DD/MM/YYYY");
	else if (moment.isDate(val)) v = moment(val).format("DD/MM/YYYY");
	return v;
});
Vue.filter("formatDateTime", function(val) {
	let v = "";
	if (_.isString(val)) {
		let m = moment(val);
		if (m.isValid()) v = m.format("DD/MM/YYYY [à] HH[H]mm");
	}
	if (moment.isMoment(val)) v = val.format("DD/MM/YYYY [à] HH[H]mm");
	if (moment.isDate(val)) v = moment(val).format("DD/MM/YYYY [à] HH[H]mm");
	return v;
});
Vue.filter("formatDateAgo", function(val) {
	let v = "";
	if (_.isString(val)) {
		let m = moment(val);
		if (m.isValid()) v = m.fromNow();
	}
	if (moment.isMoment(val)) v = val.fromNow();
	if (moment.isDate(val)) v = moment(val).fromNow();
	return v;
});
Vue.filter("formatPrice", function(val, suffixe) {
	if (!suffixe) suffixe = " €";
	val = val * 1;
	// if (val >= 1000000) return numeral(val / 1000000).format("0,0.0") + "M" + suffixe;
	// if (val >= 1000) return numeral(val / 1000).format("0,0.0") + "K" + suffixe;
	return numeral(val).format("0,0") + suffixe;
});
Vue.filter("formatSurface", function(val, whithoutSup) {
	let more = " m<sup>2</sup>";
	if (whithoutSup) more = "m2";
	return numeral(val * 1).format("0,0") + more;
});
Vue.filter("formatYesNo", function(val) {
	return val ? "Oui" : "Non";
});
Vue.filter("formatFromArray", function(val, myarray, mydefault, lang = "fr") {
	let f = _.result(_.find(myarray, { value: val }), "text", mydefault);
	return Vue.filter("trad")(f, lang);
});
Vue.filter("formatOfferSurface", function(row_of, whithoutSup) {
	let txt = Vue.filter("formatSurface")(row_of.of_surface, whithoutSup);
	if (row_of.of_surface_approximate) txt += " (approximatif)";
	return txt;
});

Vue.filter("formatOfferNameShort", function(row_of) {
	if (!row_of) return "";
	let txt = [];
	if (row_of.of_ref) txt.push(row_of.of_ref);
	if (row_of.of_name_fr) txt.push(row_of.of_name_fr);
	return txt.join(" / ");
});
Vue.filter("formatOfferNameShort2", function(row_of) {
	if (!row_of) return "";
	let txt = [];
	if (row_of.of_ref) txt.push(row_of.of_ref);
	return txt.join(" / ");
});
Vue.filter("formatOfferName", function(row_of, brBeforePrice = false, bold = false, lang = "fr") {
	if (!row_of) return "";
	let txt = [];
	if (row_of.of_ref) txt.push(row_of.of_ref);
	if (row_of.bu_id && row_of.bu_id.bu_name && row_of.bu_id.bu_name != "Aucun / Nouveau") {
		let bu_name = row_of.bu_id.bu_name;
		if (bold) bu_name = "<b>" + bu_name + "</b>";
		txt.push(bu_name);
	}
	if (row_of.of_type) txt.push(Vue.filter("formatFromArray")(row_of.of_type, store.state.items_of_type, "A définir", lang));
	if (row_of.of_nbpieces) txt.push(Vue.filter("formatPieces")(row_of, lang));
	if (row_of.of_surface) txt.push(Vue.filter("formatOfferSurface")(row_of, true));
	if (row_of.of_name_fr) txt.push(row_of["of_name_" + lang]);
	let ps = Vue.filter("formatOfferPrices")(row_of, " / ", brBeforePrice, lang);
	if (brBeforePrice) {
		return txt.join(" / ") + "<br>" + ps;
	}
	if (ps) txt.push(ps);
	return txt.join(" / ");
});

Vue.filter("contactIcon", function(row_co) {
	let ico = "mdi-account";
	if (row_co.co_type !== "contact") ico = "mdi-account-card-details";
	else if (row_co.co_issyndic) ico = "mdi-crane";
	else if (row_co.co_isrenter) ico = "mdi-owl";
	else if (row_co.co_isowner) ico = "mdi-castle";
	else if (row_co.co_isagency) ico = "mdi-home-thermometer";
	else if (row_co.co_isagent) ico = "mdi-account-key";
	else if (row_co.co_islawyer) ico = "mdi-scale-balance";
	else if (row_co.co_issociety) ico = "mdi-domain";
	else if (row_co.co_iscaretaker) ico = "mdi-account-question";
	else if (row_co.co_isbuilding) ico = "mdi-office-building";
	return ico;
});

Vue.filter("formatPieces", function(row_of, lang = "fr") {
	let txt = "";
	if (row_of.of_nbpieces == 1 && row_of.of_type == 1) txt = Vue.filter("trad")("Studio", lang);
	else if (row_of.of_nbpieces == 1) txt = "1 " + Vue.filter("trad")("pièce", lang);
	else if (row_of.of_nbpieces > 1) txt = row_of.of_nbpieces + " " + Vue.filter("trad")("pièces", lang);
	return txt;
});
Vue.filter("formatContactName", function(row_co, useBold = false, blackSociety = false) {
	if (_.isPlainObject(useBold)) useBold = false;
	if (!row_co) return "";
	let txt = [];
	if (row_co.co_name) txt.push((useBold ? "<b>" : "") + row_co.co_name.toUpperCase() + (useBold ? "</b>" : ""));
	if (row_co.co_firstname) txt.push(row_co.co_firstname);
	row_co.completeName = txt.join(" ");
	let res = row_co.completeName;
	if ((row_co.co_issociety || row_co.co_isagency || row_co.co_issyndic || row_co.co_isbuilding) && blackSociety) {
		res = `<span class='span-society'>${res}</span>`;
	}
	if (row_co.co_type != "contact" && blackSociety) {
		res = `<span class='span-user'>${res}</span>`;
	}
	return res;
});
Vue.filter("formatActionName", function(row_ac) {
	if (!row_ac) return "";
	let txt = [];
	if (row_ac.createdAt) txt.push(moment(row_ac.createdAt).format("DD/MM/YYYY [à] HH[H]mm"));
	//if (row_co) txt.push(Vue.filter("formatContactName")(row_co.data.data));
	if (row_ac.ac_comment) txt.push(row_ac.ac_comment.slice(0, row_ac.ac_comment.length > 40 ? row_ac.ac_comment.length : 40) + "...");
	return txt.join(" / ");
});
Vue.filter("formatOfferPrices", function(row_of, sep = " / ", withBold = false, lang = "fr") {
	let txt = [],
		b1 = withBold ? "<b>" : "",
		b2 = withBold ? "</b>" : "";

	if (row_of.of_rent) {
		if (row_of.of_rent_uponrequest) txt.push(Vue.filter("trad")("A louer", lang) + " : " + Vue.filter("trad")("Prix sur demande", lang));
		else {
			let moisAn = "";
			if (row_of.of_rent_sum)
				moisAn +=
					Vue.filter("trad")("A louer", lang) +
					" : " +
					b1 +
					Vue.filter("formatPrice")(row_of.of_rent_sum, " €/" + Vue.filter("trad")("mois", lang)) +
					b2;
			else if (row_of.of_rent_sum_year)
				moisAn +=
					Vue.filter("trad")("A louer", lang) +
					" : " +
					b1 +
					Vue.filter("formatPrice")(row_of.of_rent_sum_year, " €/" + Vue.filter("trad")("an", lang)) +
					b2;
			if (row_of.of_rent_charge_included) {
				moisAn += " (" + Vue.filter("trad")("Chargesincluses", lang) + ")";
			} else if (row_of.of_rent_charge) {
				moisAn +=
					" (" +
					Vue.filter("trad")("Charges", lang) +
					" : " +
					Vue.filter("formatPrice")(row_of.of_rent_charge, " €/" + Vue.filter("trad")("mois", lang)) +
					")";
			} else if (row_of.of_rent_charge_year) {
				moisAn +=
					" (" +
					Vue.filter("trad")("Charges", lang) +
					" : " +
					Vue.filter("formatPrice")(row_of.of_rent_charge_year, " €/" + Vue.filter("trad")("an", lang)) +
					")";
			}
			txt.push(moisAn);
		}
	}
	if (row_of.of_sell) {
		let viagerTemp = "",
			viagerTemp2 = "";
		if (row_of.of_sell_lifeannuity) {
			viagerTemp = " " + Vue.filter("trad")("en viager", lang);
			viagerTemp2 = " + " + Vue.filter("formatPrice")(row_of.of_sell_lifeannuity) + "/" + Vue.filter("trad")("mois", lang);
		}
		if (row_of.of_sell_uponrequest)
			txt.push(Vue.filter("trad")("A vendre", lang) + viagerTemp + " : " + Vue.filter("trad")("Prix sur demande", lang));
		else if (row_of.of_sell_sum)
			txt.push(
				Vue.filter("trad")("A vendre", lang) + viagerTemp + " : " + b1 + Vue.filter("formatPrice")(row_of.of_sell_sum) + b2 + viagerTemp2
			);
	}

	return txt.join(sep);
});
Vue.filter("formatEmail", function(txt) {
	return `<a href="mailto:${txt}">${txt}</a>`;
});
Vue.filter("formatEventName", function(row_ve) {
	// console.log("row_ve", row_ve);
	let txt = "";
	txt +=
		"Du " +
		moment(row_ve.ve_dtstart).format("DD/MM/YYYY [à] HH[H]mm") +
		" au " +
		moment(row_ve.ve_dtend).format("DD/MM/YYYY [à] HH[H]mm") +
		" : " +
		row_ve.ve_summary;
	return txt;
});

Vue.filter("formatTel", function(txt) {
	return `<a href="tel:${txt}">${txt}</a>`;
});
Vue.filter("formatWeb", function(txt) {
	return `<a href="${txt}" target="_blank">${txt}</a>`;
});
Vue.filter("formatSearchName", function(search, comment, chips = false) {
	// console.log("search", search);
	let res = [];

	if (comment) res.push(comment);
	if (search.types && _.isArray(search.types) && search.types.length) {
		let tabtemp = [];
		for (let iType = 0; iType < search.types.length; iType++) {
			const typ = search.types[iType];
			tabtemp.push(_.result(_.find(store.state.items_of_type, { value: typ }), "text"));
		}
		res.push(tabtemp.join(", "));
	}
	if (search.room_1) res.push("Studio");
	if (search.room_2) res.push("2 pièces");
	if (search.room_3) res.push("3 pièces");
	if (search.room_4) res.push("4 pièces");
	if (search.room_5) res.push("5 pièces et plus");
	if (search.confidential) res.push("Confidentiel");
	if (search.monaco) res.push("Monaco");
	if (search.france) res.push("France");
	if (search.sell) res.push("Vente");
	if (search.rent) res.push("Location");

	if (search.text) res.push("Contient " + search.text);

	// console.log("search", search);
	if (search.budgetmin && search.budgetmax)
		res.push("Budget entre " + Vue.filter("formatPrice")(search.budgetmin) + " et " + Vue.filter("formatPrice")(search.budgetmax));
	if (search.surfacemin && search.surfacemax)
		res.push(
			"Surface entre " + Vue.filter("formatSurface")(search.surfacemin, true) + " et " + Vue.filter("formatSurface")(search.surfacemax, true)
		);

	if (search.agency) res.push("Agence “" + Vue.filter("formatContactName")(search.agency) + "“");
	if (search.building && search.building.bu_id) res.push("Immeuble “" + search.building.bu_name + "“");
	if (search.district) res.push("Quartier “" + search.district + "“");
	if (search.negociator) res.push("Négociateur “" + Vue.filter("formatContactName")(search.negociator) + "“");

	if (search.follows) res.push("Biens suivis");
	if (search.notfollows) res.push("Biens non suivis");
	if (search.archives) res.push("Archives");
	if (search.immotools) res.push("Immotools");
	// console.log("search.types", search.types);
	// console.log('res.join(" / ")', res.join(" / "));

	if (search.cellar) res.push("Avec cave");
	if (search.parking) res.push("Avec parking");
	if (search.withgarden) res.push("Avec jardin");
	if (search.newprogram) res.push("Programme neuf");
	if (search.modernbuilding) res.push("Immeuble moderne");
	if (search.bourgeoisbuilding) res.push("Immeuble bourgeois");
	if (search.concierge) res.push("Concierge");
	if (search.law1235) res.push("Sous loi 1235");
	if (search.law887) res.push("Sous loi 887");
	if (search.freeoflaw) res.push("Libre de loi");
	if (search.interestingview) res.push("Vue interessante");
	if (search.seaview) res.push("Vue mer");
	if (search.mixeduse) res.push("Usage mixte");
	if (search.roofterrace) res.push("Toit terrasse");
	if (search.sign) res.push("Vitrine");

	if (chips) return res;
	return res.join(" / ");
});

Vue.filter("nl2br", function(str) {
	// console.log("str", str);
	let breakTag = "<br />";
	return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
});
Vue.filter("limittext", function(str, length, more = "...") {
	if (!str) return "";
	str = str.split("\n")[0];
	// if (str.length < length) return str;
	let res = str.substring(0, length) + ' <a onclick="javascript:void(0);">' + more + "</a>";
	// if (nl2br) {
	// 	let breakTag = "<br />";
	// 	return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
	// }
	return res;
});

Vue.filter("formatContactAdress", function(row_co, sep) {
	if (!sep) sep = "<br>";
	let txt = [];
	if (row_co.co_address1) txt.push(row_co.co_address1);
	if (row_co.co_address2) txt.push(row_co.co_address2);
	if (row_co.co_address3) txt.push(row_co.co_address3);
	if (row_co.co_zip && row_co.co_city) txt.push(row_co.co_zip + " " + row_co.co_city);
	if (row_co.co_country) txt.push(row_co.co_country);
	return `<a href="https://www.google.fr/maps?q=${encodeURI(txt.join(", "))}" target="_blank">${txt.join(sep)}</a>`;
});

Vue.filter("formatBuildingAddress", function(row_bu, sep, usemore = true) {
	if (!row_bu) return "";
	if (!sep) sep = "<br>";
	let txt = [];
	if (row_bu.bu_name) txt.push(row_bu.bu_name);
	if (row_bu.bu_address1) txt.push(row_bu.bu_address1);
	if (row_bu.bu_address2) txt.push(row_bu.bu_address2);
	if (row_bu.bu_address3) txt.push(row_bu.bu_address3);
	let more = "";
	if (row_bu.bu_zip) more += row_bu.bu_zip;
	if (row_bu.bu_zip && row_bu.bu_city) more += " ";
	if (row_bu.bu_city) more += row_bu.bu_city;
	if (row_bu.bu_country) more += (more ? " - " : "") + row_bu.bu_country;
	if (more && usemore) txt.push(more);
	// return `<a href="https://www.google.fr/maps?q=${encodeURI(txt.join(", "))}" target="_blank">${txt.join(sep)}</a>`;
	return `${txt.join(sep)}`;
});

Vue.filter("plural", function(nb, singular, plural) {
	let res = nb + "";
	if (!singular) return res;
	else if (nb > 1 && plural && plural.indexOf("%n") >= 0) res = plural.replace(/%n/g, nb + "");
	else if (nb > 1 && plural && plural.indexOf("%n") < 0) res = nb + " " + plural;
	else if (nb > 1 && !plural) res = nb + " " + singular + "s";
	else if (nb <= 1 && singular.indexOf("%n") >= 0) res = singular.replace(/%n/g, nb + "");
	else if (nb <= 1 && singular.indexOf("%n") < 0) res = nb + " " + singular;
	return res;
});
