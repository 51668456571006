<template>
	<v-layout align-center justify-center fill-height>
		<v-flex fill-height style="max-width: 250px; min-width: 250px" class="py-0 pr-0">
			<v-card class="fill-height d-flex flex-column">
				<v-card-title class="flex-grow-0">
					<h2>Groupes</h2>
					<v-spacer></v-spacer>
					<v-tooltip bottom v-if="$store.state.preferences.rights.contact_export">
						<template v-slot:activator="{ on }">
							<v-btn color="primary2" x-small>
								<v-icon v-on="$store.state.preferences.personal.helpBubble ? on : false" @click="exportContacts"
									>mdi-folder-download-outline</v-icon
								>
							</v-btn>
						</template>
						<span>Exporter les contacts</span>
					</v-tooltip>
					<v-dialog v-model="dialogexport" max-width="500">
						<v-card class="content-modal">
							<v-card-title> Export des contacts </v-card-title>
							<v-card-text
								>Pour exporter, vous devez indiquer un mot de passe :
								<m-form-text
									:name="$Utils.randomstring('exportpassword')"
									label=""
									label-position="top"
									:label-width="9"
									style="width: 300px"
									suffix="m2"
									v-model="exportpassword"
								></m-form-text>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary2" @click="cancelWinExport">Annuler</v-btn>
								<v-btn color="warning2" @click="saveWinExport">Exporter</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-card-title>
				<v-card-text class="flex-grow-1 pa-0 overflow-auto">
					<table class="table-list">
						<tbody>
							<tr
								v-ripple
								v-for="item in groups"
								:key="item.gr_id"
								:class="{ active: group_current == item.gr_id }"
								:data-gr-id="item.gr_id"
								@click="groupClick"
							>
								<td style="width: 30px">
									<v-icon>{{ item.icon }}</v-icon>
								</td>
								<td>
									<div class="txtoverflow">{{ item.text }}</div>
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
			</v-card>
		</v-flex>
		<v-flex fill-height style="max-width: 250px; min-width: 250px" class="py-0 pr-0">
			<v-card class="fill-height d-flex flex-column">
				<v-card-title class="flex-grow-0">
					<h2>{{ contacts_total }} fiches</h2>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn color="primary2" x-small class="mr-2">
								<v-icon v-on="$store.state.preferences.personal.helpBubble ? on : false" @click="addContactsToMailchimp"
									>mdi-email-multiple-outline</v-icon
								>
							</v-btn>
						</template>
						<span>synchroniser tous les contacts avec Mailchimp</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn color="primary2" x-small>
								<v-icon v-on="$store.state.preferences.personal.helpBubble ? on : false" @click="addNewContact">mdi-plus</v-icon>
							</v-btn>
						</template>
						<span>Créer un nouveau contact</span>
					</v-tooltip>
				</v-card-title>
				<div class="flex-grow-0">
					<m-form-text
						placeholder="Rechercher"
						tooltip="Recherche sur le nom, prénom, adresse, CP,<br>ville, email, téléphone, fax, mobile et ID"
						tooltip-position="top"
						:name="$Utils.randomstring('searchcontact')"
						v-model="searchcontact"
					></m-form-text>
				</div>
				<v-card-text class="flex-grow-1 pa-0 overflow-hidden" style="height: 100%">
					<m-list
						ref="contactslist"
						:dynamic="true"
						:items="contacts"
						item-value="co_id"
						:item-height="30"
						:limit="contacts_limit"
						:skip="contacts_skip"
						:total="contacts_total"
						:current="contact_current"
						@itemclick="contactClick"
						@changerange="changeContactsRange"
					>
						<template v-slot:ths="{}"></template>
						<template v-slot:tds="{ item }">
							<td :style="`width:40px;`">
								<div v-if="item.co_avatar" style="width: 25px; height: 25px; margin-right: 10px">
									<v-img
										:src="
											item.co_avatar
												? $config.server_url +
												  '/api/1.0/avatar/' +
												  item.co_id +
												  '/25/25?d=' +
												  item.updatedAt +
												  '&token=' +
												  $store.state.accesstoken
												: '/img/default-profile.jpg'
										"
										height="25px"
										width="25px"
										class="rounded-image"
									></v-img>
								</div>
								<div v-else-if="item.co_type == 'contact'" style="width: 25px; height: 25px; margin-right: 10px">
									<v-icon>{{ item | contactIcon }}</v-icon>
								</div>
								<div v-else style="width: 25px; height: 25px; margin-right: 10px">
									<v-icon>mdi-account-card-details</v-icon>
								</div>
							</td>
							<td>{{ item | formatContactName }}</td>
							<td style="width: 10px; padding: 0">
								<div v-if="!item.hassearches && item.co_type == 'contact'">
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<div v-on="on" style="background-color: #ff4b56; border-radius: 50%; width: 6px; height: 6px"></div>
										</template>
										<span>Contact suivi, mais sans aucune recherche associée</span>
									</v-tooltip>
								</div>
							</td>
						</template>
					</m-list>
					<!-- <table class="table-list">
            <tbody>
              <tr
                v-ripple
                v-for="row_co in contacts"
                :key="row_co.co_id"
                :class="{active:(contact_current==row_co.co_id)}"
                :data-co-id="row_co.co_id"
                @click="contactClick"
              >
                <td style="width:30px;">
                  <div v-if="row_co.co_avatar">
                    <v-img
                      :src="row_co.co_avatar?$config.server_url+'/api/1.0/avatar/'+row_co.co_id+'/25/25?d='+row_co.updatedAt:'/img/default-profile.jpg'"
                      height="25px"
                      width="25px"
                      class="rounded-image"
                    ></v-img>
                  </div>
                  <div v-else-if="row_co.co_type=='contact'">
                    <v-icon>mdi-account</v-icon>
                  </div>
                  <div v-else>
                    <v-icon>mdi-account-card-details</v-icon>
                  </div>
                </td>
                <td>
                  <div class="txtoverflow">{{ row_co | formatContactName }}</div>
                </td>
              </tr>
            </tbody>
          </table>-->
				</v-card-text>
			</v-card>
		</v-flex>
		<v-flex fill-height class="py-0" style="width: calc(100% - 500px)">
			<v-card class="fill-height overflow-auto" id="container_contactdetail_scroll">
				<v-card-text>
					<contact-details
						ref="contactdetails"
						v-model="contact_current"
						:clientsuivi="clientsuivi"
						@contactWinEditOpen="contactWinEditOpen"
						@contactWinEditSaved="contactWinEditSaved"
						@contactWinEditCanceled="contactWinEditCanceled"
						@contactWinEditDeleted="contactWinEditDeleted"
						@suiviChanged="suiviChanged"
					></contact-details>
				</v-card-text>
			</v-card>
		</v-flex>
	</v-layout>
</template>

<script>
import Vue from "vue";

export default {
	name: "contacts",
	components: {},
	data() {
		return {
			exportpassword: "",
			dialogexport: false,
			cancelAxiosLoadContacts: null,
			searchcontact: "",
			group_current: "all",
			groups: [
				{
					gr_id: "all",
					text: "Toutes les fiches",
					icon: "mdi-account-group"
				},
				{
					gr_id: "mycontacts",
					text: "Mes contacts",
					icon: "mdi-account"
				},
				{
					gr_id: "contacts",
					text: "Tous les contacts",
					icon: "mdi-account"
				},
				{
					gr_id: "societies",
					text: "Toutes les sociétés",
					icon: "mdi-domain"
				},
				{
					gr_id: "users",
					text: "Tous les utilisateurs",
					icon: "mdi-account-card-details"
				},
				{
					gr_id: "agents",
					text: "Tous les négociateurs immo.",
					icon: "mdi-account-key"
				},
				{
					gr_id: "owners",
					text: "Tous les proprétaires",
					icon: "mdi-castle"
				},
				{
					gr_id: "lawyers",
					text: "Tous les avocats",
					icon: "mdi-scale-balance"
				},
				{
					gr_id: "renters",
					text: "Tous les locataires",
					icon: "mdi-owl"
				},
				{
					gr_id: "agencies",
					text: "Toutes les agences immo.",
					icon: "mdi-home-thermometer"
				},
				{
					gr_id: "syndics",
					text: "Tous les syndics",
					icon: "mdi-crane"
				},
				{
					gr_id: "caretakers",
					text: "Tous les concierges",
					icon: "mdi-account-question"
				},
				{
					gr_id: "intermediaire",
					text: "Tous les intermédiaires",
					icon: "mdi-account-question"
				},
				{
					gr_id: "buildings",
					text: "Tous les immeubles",
					icon: "mdi-office-building"
				},
				{
					gr_id: "voeux",
					text: "Les voeux",
					icon: "mdi-office-building"
				},
				{
					gr_id: "follows",
					text: "Mes contacts suivis",
					icon: "mdi-hand-heart"
				}
				// {
				//   gr_id: "caddy",
				//   text: "Mon panier",
				//   icon: "mdi-cart"
				// },
				// {
				//   gr_id: "lastimport",
				//   text: "Dernier import",
				//   icon: "mdi-clock"
				// }
			],
			contacts: [],
			contacts_skip: 0,
			contacts_total: 0,
			contacts_limit: 50,
			contacts_current: "",
			contact_current: "",
			clientsuivi: false,
			// agentfollower: {},
			contact: null
			// loadFirstContact: false
		};
	},

	watch: {
		async searchcontact(val) {
			val.trim();
			await this.loadContacts(0);
			await this.loadFirstContactOfList();
		},
		async $route(to, from) {
			if (to.name == "contactsgroup" && to.params.group == "follows") {
				this.group_current = to.params.group;
				await this.loadContacts(0);
				await this.loadFirstContactOfList();
			}
			if (to.name == "contactedit" && to.params.co_id == this.$store.state.user.co_id) {
				this.setCurrent(to.params.co_id);
				// await this.loadContact();
				Vue.nextTick(() => {
					this.$refs.contactdetails.openWinEdit(this.$route.params.co_id);
				});
			}
		}
	},
	// computed: {
	//   nbcontacts() {
	//     return this.contacts_total;
	//   }
	// },

	async mounted() {
		// console.log("this.$route.name", this.$route.name);
		// if (!this.$route.params.co_id) this.loadFirstContact = true;
		if (this.$route.name == "contactsgroup") {
			this.group_current = this.$route.params.group;
		}
		await this.loadContacts(0);

		if (this.$route.name == "contactsgroup") {
			await this.loadFirstContactOfList();
		} else if (this.$route.name == "contactslist") {
			if (this.$store.state.currentcontact) this.setCurrent(this.$store.state.currentcontact);
			else await this.loadFirstContactOfList();
		} else if (this.$route.name == "contactdetails") {
			// this.contact_current = this.$route.params.co_id;
			this.setCurrent(this.$route.params.co_id);
			// await this.loadContact();
		} else if (this.$route.name == "contactedit") {
			// this.contact_current = this.$route.params.co_id;
			// console.log("this.$route.params.co_id", this.$route.params.co_id);
			this.setCurrent(this.$route.params.co_id);
			// await this.loadContact();
			Vue.nextTick(() => {
				this.$refs.contactdetails.openWinEdit(this.$route.params.co_id);
			});
		}
	},
	methods: {
		async saveWinExport() {
			let response = await this.$axios.post(this.$config.server_url + "/api/1.0/contacts/checkexportpassword", {
				exportpassword: this.exportpassword
			});

			let data = response.data.data;
			// console.log("data", data);
			if (data.ok) {
				window.open(
					`${this.$config.server_url}/api/1.0/contacts/export?token=${this.$store.state.accesstoken}&exportpassword=${this.exportpassword}&gr_id=${this.group_current}&text=${this.searchcontact}`,
					"_self"
				);
				this.dialogexport = false;
			} else {
				alert("Le mot de passe n'est pas valide");
			}
		},
		cancelWinExport() {
			this.dialogexport = false;
		},
		exportContacts() {
			this.exportpassword = "";
			this.dialogexport = true;
		},
		suiviChanged() {
			this.loadContacts(this.contacts_skip);
		},
		changeContactsRange(skip) {
			// console.log("skip", skip);

			this.loadContacts(skip);
		},
		addNewContact() {
			// this.contact_current = "-1";
			// this.contact = null;
			// this.contact_current = "-1";
			this.setCurrent("-1");
			Vue.nextTick(() => {
				this.$refs.contactdetails.openWinEdit();
			});

			// this.contactWinEditOpen(-1);
		},
		async addContactsToMailchimp() {
			// let response =
			await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/mailchimp");
			// console.log('response',response);
		},
		async setCurrent(v) {
			// console.log("v", v);
			let co_id = v;
			if (v === "-1") return (this.contact_current = co_id);
			if (this.$_.isPlainObject(v)) {
				co_id = v.co_id;
				this.$store.commit("set_currentcontact", v);
			} else {
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + v);
				this.$store.commit("set_currentcontact", response.data.data);
			}
			this.contact_current = co_id;
			// console.log("this.contact_current", this.contact_current);

			// this.$store.commit("set_lastcoid", co_id);
			// this.loadContact();
		},
		async loadFirstContactOfList() {
			if (this.contacts.length) {
				// this.contact_current = this.contacts[0].co_id;
				this.setCurrent(this.contacts[0]);
				// await this.loadContact();
			}
		},
		async groupClick(evt) {
			let gr_id = evt.target.closest("tr").getAttribute("data-gr-id");
			if (gr_id) {
				this.group_current = gr_id;
				this.$router.push("/contacts/group/" + this.group_current);
				await this.loadContacts(0);
				await this.loadFirstContactOfList();
			}
		},
		async loadContacts(skip) {
			let params = {
				gr_id: this.group_current,
				skip: skip,
				limit: this.contacts_limit,
				hassearches: true
			};
			// console.log("params", params);
			if (this.searchcontact) params.text = this.searchcontact;

			this.cancelAxiosLoadContacts && this.cancelAxiosLoadContacts();
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts", {
				params,
				cancelToken: new this.$axios.CancelToken(c => {
					this.cancelAxiosLoadContacts = c;
				})
			});
			if (!response || !response.data) return;
			// console.log("response.data", response.data);
			let contacts = response.data.data;
			for (let iContact = 0; iContact < contacts.length; iContact++) {
				const row_co = contacts[iContact];
				row_co.mListTrClass = "contact-bg-white";
				if (row_co.co_type != "contact") row_co.mListTrClass = "contact-bg-grey";
				if (row_co.co_issociety) row_co.mListTrClass = "contact-bg-black";
			}
			this.contacts = contacts;
			this.contacts_total = response.data.meta.total;
			this.contacts_skip = skip;
			if (this.$refs.contactslist) {
				this.$refs.contactslist.update();
				if (skip == 0) this.$refs.contactslist.scrollToTop();
			}
			// if (this.loadFirstContact && this.contacts.length) {
			//   this.contact_current = this.contacts[0].co_id;
			//   this.loadContact();
			// }
		},
		contactClick(row_co, indexCo, evt) {
			// console.log("row_co,indexCo", row_co, indexCo);
			// let co_id = evt.target.closest("tr").getAttribute("data-co-id");
			if (row_co && row_co.co_id) {
				this.$router.push("/contacts/" + row_co.co_id + "/details");
				// this.contact_current = row_co.co_id;
				this.setCurrent(row_co);
				// this.loadContact();
			}
		},
		// async loadContact() {
		//   let params = {};
		//   let response = await this.$axios.get(
		//     this.$config.server_url + "/api/1.0/contacts/" + this.contact_current,
		//     { params }
		//   );
		//   this.contact = response.data.data;
		//   // this.loadSuivi(response.data.data.co_id);
		//   this.$router.push("/contacts/" + this.contact.co_id + "/details");
		// },

		contactWinEditOpen(co_id) {
			this.$router.push("/contacts/" + co_id + "/edit");
		},
		contactWinEditSaved(co_id) {
			// this.contact_current = co_id;
			this.setCurrent(co_id);
			this.$router.push("/contacts/" + co_id + "/details");
			this.loadContacts(this.contacts_skip);
			// this.loadContact();
		},
		contactWinEditCanceled(co_id) {
			if (!co_id) return this.loadFirstContactOfList();
			this.$router.push("/contacts/" + co_id + "/details");
		},
		async contactWinEditDeleted(co_id) {
			await this.loadContacts(this.contacts_skip);
			await this.loadFirstContactOfList();
			if (this.contacts.length) {
				this.$router.push("/contacts/" + this.contacts[0].co_id + "/details");
			}
		}
	}
};
</script>

<style lang="scss">
// @import "../baseStyle.scss";
@import "../sass/variables.scss";

.table-list {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	tr {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		display: block;
		cursor: pointer;
		td {
			padding: 2px 10px;
			font-size: 0.75rem;
			div.txtoverflow {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
		&:hover {
			background-color: $mygris;
		}
		&.active {
			background-color: $mybleu-clair;
			td {
				color: $mybleu-fonce;
				i {
					color: $mybleu-fonce;
				}
			}
		}
	}
}
</style>
