<template>
	<div class="card-table-actions">
		<div style="float: right" v-if="actionsTotal > actionsNbMax && actionsNbMax == 0">
			<a @click="actionsShowMore = !actionsShowMore">
				{{ actionsShowMore ? `Masquer ${actionsTotal - actionsNbMax} actions` : `Voir ${actionsTotal - actionsNbMax} actions de plus` }}
			</a>
		</div>
		<h2
			:ref="'actions_' + actionsType"
			v-if="!print && labelSingular && (actionsTotal || alwaysShowTitle)"
			v-html="
				$options.filters.plural(
					actionsTotal,
					'<span class=\'bis\'>' + labelSingular + '</span>',
					'<span class=\'bis\'>' + labelPlural + '</span>'
				)
			"
		></h2>
		<h1
			class="mb-3"
			v-if="print && labelSingular && (actionsTotal || alwaysShowTitle)"
			v-html="
				$options.filters.plural(
					actionsTotal,
					'<span class=\'bis\'>' + labelSingular + '</span>',
					'<span class=\'bis\'>' + labelPlural + '</span>'
				)
			"
		></h1>

		<div class="card-table-action" v-for="row_ac in actions" :key="row_ac.ac_id">
			<div style="float: right; margin-left: 15px" v-if="coldone">
				<v-switch
					v-if="row_ac.ac_type == 'call' || row_ac.ac_type == 'todo' || row_ac.ac_type == 'email' || row_ac.ac_type == 'visit'"
					v-model="row_ac.ac_done"
					:name="$Utils.randomstring('ac_done')"
					color="#53bd7b"
					:inset="true"
					:light="true"
					label
					dense
					class="mt-0"
					style="height: 32px"
					@change="switchDone(row_ac)"
				></v-switch>
				<v-chip
					v-if="
						(row_ac.ac_type == 'present' ||
							row_ac.ac_type == 'newoffer' ||
							row_ac.ac_type == 'newcontact' ||
							row_ac.ac_type == 'signature') &&
							row_ac.ac_done
					"
					class="text-no-wrap"
					small
					color="green"
					text-color="white"
					>Fait</v-chip
				>
			</div>
			<div class="d-flex">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<div v-on="on" style="padding-top: 3px">
							<icon class="actionwinedit_icon mr-2" :name="getIcon(row_ac)"></icon>
						</div>
					</template>
					<span>
						{{ row_ac.ac_type | formatFromArray($store.state.items_ac_type) }}
					</span>
				</v-tooltip>
				<!-- <div
          :class="row_ac.classDate ? row_ac.classDate : ''"
          :inner-html.prop="row_ac.ac_date | formatDateTime"
        ></div> -->
				<!-- <div>
          {{ row_ac.ac_type | formatFromArray($store.state.items_ac_type) }}
        </div> -->
				<div>
					<span> {{ getTitle(row_ac).title }}</span>
					<span
						><a @click="getTitleLink(row_ac)">{{ getTitle(row_ac).who }}</a> le {{ getTitle(row_ac).date }}</span
					>
				</div>
				<div class="ml-3" v-if="!print">
					<a @click="openWinAction(row_ac.ac_id)"><icon class="" name="edit"></icon></a>
				</div>
				<div class="ml-auto" v-if="colchips">
					<v-chip
						v-if="(row_ac.ac_type == 'todo' || row_ac.ac_type == 'call' || row_ac.ac_type == 'email') && row_ac.ac_done"
						class="text-no-wrap"
						x-small
						color="green"
						text-color="white"
						>Fait</v-chip
					>
					<v-chip
						v-if="(row_ac.ac_type == 'todo' || row_ac.ac_type == 'call' || row_ac.ac_type == 'email') && !row_ac.ac_done"
						class="text-no-wrap"
						x-small
						color="purple"
						text-color="white"
						>A faire</v-chip
					>
					<v-chip
						v-if="row_ac.ac_type == 'signature' && row_ac.ac_signature_sum"
						class="text-no-wrap"
						x-small
						color="green"
						text-color="white"
						>{{ row_ac.ac_signature_sum | formatPrice }}</v-chip
					>
					<v-chip v-if="row_ac.ac_type == 'report' && row_ac.ac_done" class="text-no-wrap" x-small color="green" text-color="white"
						>Rempli</v-chip
					>
					<v-chip v-if="row_ac.ac_type == 'report' && !row_ac.ac_done" class="text-no-wrap" x-small color="red" text-color="white"
						>A remplir</v-chip
					>
					<v-chip v-if="row_ac.ac_type == 'present'" class="text-no-wrap" x-small color="green" text-color="white">Présenté</v-chip>
					<v-chip v-if="row_ac.ac_type == 'visit' && row_ac.ac_done" class="text-no-wrap" x-small color="green" text-color="white"
						>Visité</v-chip
					>
					<v-chip
						v-if="row_ac.ac_type == 'visit' && !row_ac.ac_done && $moment(row_ac.ac_date).isBefore($moment())"
						class="text-no-wrap"
						x-small
						color="red"
						text-color="white"
						>RDV annulé ?</v-chip
					>
					<v-chip
						v-if="row_ac.ac_type == 'visit' && !row_ac.ac_done && $moment(row_ac.ac_date).isAfter($moment())"
						class="text-no-wrap"
						x-small
						color="yellow"
						text-color="black"
						>RDV à venir</v-chip
					>
				</div>
			</div>
			<div v-if="colagent && row_ac.ac_type != 'comment'" style="font-size: 11px; line-height: 12px">
				Par :
				<span :inner-html.prop="row_ac.co_id_user | formatContactName"></span>
			</div>
			<div v-if="row_ac.co_id_contact && (colcontact || (row_ac.ac_type == 'comment' && row_ac.co_id_contact))">
				Contact :
				<span :inner-html.prop="row_ac.co_id_contact | formatContactName"></span>
			</div>
			<div v-if="row_ac.co_id_agence_confrere && (colconfrere || (row_ac.ac_type == 'comment' && row_ac.co_id_agence_confrere))">
				Confrère :
				<span :inner-html.prop="row_ac.co_id_agence_confrere | formatContactName"></span>
			</div>
			<div v-if="coloffers && row_ac.of_id">
				<div v-if="!offerShortName">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							Concerne le bien :
							<a @click="gotoOffer(row_ac.of_id)" v-on="on">{{ row_ac.of_id | formatOfferName }}</a>
						</template>
						<span>{{ row_ac.of_id | formatOfferName }}</span>
					</v-tooltip>
				</div>
				<div v-if="offerShortName">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							Concerne le bien :
							<a @click="gotoOffer(row_ac.of_id)" v-on="on">{{ row_ac.of_id | formatOfferNameShort2 }}</a>
						</template>
						<span>{{ row_ac.of_id | formatOfferName }}</span>
					</v-tooltip>
				</div>
			</div>

			<div>
				<span :inner-html.prop="row_ac.ac_comment"></span>
				<div v-if="row_ac.ac_type == 'newoffer'">
					Nouveau bien :
					{{ row_ac.of_id | formatOfferName }}
				</div>
				<div v-if="row_ac.ac_type == 'newcontact'">Nouveau contact</div>
			</div>
		</div>

		<div style="text-align: right" v-if="actionsTotal > actionsNbMax && actionsNbMax > 0">
			<a @click="actionsShowMore = !actionsShowMore">
				{{ actionsShowMore ? `Masquer ${actionsTotal - actionsNbMax} actions` : `Voir ${actionsTotal - actionsNbMax} actions de plus` }}
			</a>
		</div>

		<!-- <table class="tablestriped overclick" v-if="actions.length">
      <thead v-if="header">
        <tr>
          <th></th>
          <th>Date</th>
          <th v-if="colagent">Agent</th>
          <th v-if="colcontact">Contact</th>
          <th v-if="colcomment">Commentaire</th>
          <th v-if="coloffers">Biens</th>
          <th v-if="coldone">Fait</th>
          <th v-if="colchips"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row_ac, index) in actions"
          :key="row_ac.ac_id"
          :class="{
            'd-none': index >= actionsNbMax && !actionsShowMore,
            withpointer: true
          }"
        >
          <td style="width:40px;" class @click="openWinAction(row_ac.ac_id)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <icon
                    class="actionwinedit_icon"
                    :name="getIcon(row_ac)"
                  ></icon>
                </div>
              </template>
              <span>
                {{
                  row_ac.ac_type | formatFromArray($store.state.items_ac_type)
                }}
              </span>
            </v-tooltip>
          </td>

          <td style="width:90px;" @click="openWinAction(row_ac.ac_id)">
            <span
              :class="row_ac.classDate ? row_ac.classDate : ''"
              :inner-html.prop="row_ac.ac_date | formatDateTime"
            ></span>
          </td>

          <td
            style="width:120px;"
            @click="openWinAction(row_ac.ac_id)"
            v-if="colagent"
          >
            <span>{{ row_ac.co_id_user | formatContactName }}</span>
          </td>

          <td
            style="width:120px;"
            @click="openWinAction(row_ac.ac_id)"
            v-if="colcontact"
          >
            <span
              :inner-html.prop="row_ac.co_id_contact | formatContactName"
            ></span>
          </td>

          <td class @click="openWinAction(row_ac.ac_id)" v-if="colcomment">
            <span :inner-html.prop="row_ac.ac_comment"></span>
            <div v-if="row_ac.ac_type == 'newoffer'">
              Nouveau bien :
              {{ row_ac.of_id | formatOfferName }}
            </div>
            <div v-if="row_ac.ac_type == 'newcontact'">Nouveau contact</div>
          </td>

          <td
            style="width:120px;"
            class
            @click="openWinAction(row_ac.ac_id)"
            v-if="coloffers"
          >
            <div v-if="!offerShortName">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a @click="nada" v-on="on">{{
                    row_ac.of_id | formatOfferName
                  }}</a>
                </template>
                <span>{{ row_ac.of_id | formatOfferName }}</span>
              </v-tooltip>
            </div>
            <div v-if="offerShortName">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a @click="nada" v-on="on">{{
                    row_ac.of_id | formatOfferNameShort2
                  }}</a>
                </template>
                <span>{{ row_ac.of_id | formatOfferName }}</span>
              </v-tooltip>
            </div>
          </td>

          <td style="width:60px;" class="pa-1" v-if="coldone">
            <v-switch
              v-if="
                row_ac.ac_type == 'call' ||
                  row_ac.ac_type == 'todo' ||
                  row_ac.ac_type == 'email' ||
                  row_ac.ac_type == 'visit'
              "
              v-model="row_ac.ac_done"
              :name="$Utils.randomstring('ac_done')"
              color="#53bd7b"
              :inset="true"
              :light="true"
              label
              dense
              class="mt-0 mr-2"
              style="height:32px;"
              @change="switchDone(row_ac)"
            ></v-switch>
            <v-chip
              v-if="
                (row_ac.ac_type == 'present' ||
                  row_ac.ac_type == 'newoffer' ||
                  row_ac.ac_type == 'newcontact' ||
                  row_ac.ac_type == 'signature') &&
                  row_ac.ac_done
              "
              class="text-no-wrap"
              small
              color="green"
              text-color="white"
              >Fait</v-chip
            >
          </td>

          <td
            v-if="colchips"
            class="text-right"
            @click="openWinAction(row_ac.ac_id)"
            style="width:90px;"
          >
            <v-chip
              v-if="
                (row_ac.ac_type == 'todo' ||
                  row_ac.ac_type == 'call' ||
                  row_ac.ac_type == 'email') &&
                  row_ac.ac_done
              "
              class="text-no-wrap"
              x-small
              color="green"
              text-color="white"
              >Fait</v-chip
            >
            <v-chip
              v-if="
                (row_ac.ac_type == 'todo' ||
                  row_ac.ac_type == 'call' ||
                  row_ac.ac_type == 'email') &&
                  !row_ac.ac_done
              "
              class="text-no-wrap"
              x-small
              color="red"
              text-color="white"
              >A faire</v-chip
            >
            <v-chip
              v-if="row_ac.ac_type == 'signature' && row_ac.ac_signature_sum"
              class="text-no-wrap"
              x-small
              color="green"
              text-color="white"
              >{{ row_ac.ac_signature_sum | formatPrice }}</v-chip
            >
            <v-chip
              v-if="row_ac.ac_type == 'report' && row_ac.ac_done"
              class="text-no-wrap"
              x-small
              color="green"
              text-color="white"
              >Rempli</v-chip
            >
            <v-chip
              v-if="row_ac.ac_type == 'report' && !row_ac.ac_done"
              class="text-no-wrap"
              x-small
              color="red"
              text-color="white"
              >A remplir</v-chip
            >
            <v-chip
              v-if="row_ac.ac_type == 'present'"
              class="text-no-wrap"
              x-small
              color="green"
              text-color="white"
              >Présenté</v-chip
            >
            <v-chip
              v-if="row_ac.ac_type == 'visit' && row_ac.ac_done"
              class="text-no-wrap"
              x-small
              color="green"
              text-color="white"
              >Visité</v-chip
            >
            <v-chip
              v-if="
                row_ac.ac_type == 'visit' &&
                  !row_ac.ac_done &&
                  $moment(row_ac.ac_date).isBefore($moment())
              "
              class="text-no-wrap"
              x-small
              color="red"
              text-color="white"
              >RDV annulé ?</v-chip
            >
            <v-chip
              v-if="
                row_ac.ac_type == 'visit' &&
                  !row_ac.ac_done &&
                  $moment(row_ac.ac_date).isAfter($moment())
              "
              class="text-no-wrap"
              x-small
              color="yellow"
              text-color="black"
              >RDV à venir</v-chip
            >
          </td>
        </tr>
      </tbody>
    </table> -->

		<action-win-edit ref="actionwinedit" @actionWinEditSaved="actionWinEditSaved"></action-win-edit>
	</div>
</template>
<script>
export default {
	name: "cardtableactions",
	props: {
		print: {
			default: false,
			type: Boolean
		},
		reload: Number,
		actionsNbMax: {
			default: 5,
			type: Number
		},
		actionsNbSupermax: {
			default: 0,
			type: Number
		},
		actionsMaintype: String,
		actionsType: String,
		actionsOnlynotdone: {
			default: false,
			type: Boolean
		},
		alwaysShowTitle: {
			default: false,
			type: Boolean
		},
		actionId: String,
		// ofid: String,
		labelSingular: {
			default: "",
			type: String
		},
		labelPlural: {
			default: "",
			type: String
		},
		offerShortName: {
			default: true,
			type: Boolean
		},
		colchips: {
			default: true,
			type: Boolean
		},
		colcontact: {
			default: true,
			type: Boolean
		},
		colconfrere: {
			default: true,
			type: Boolean
		},
		colcomment: {
			default: true,
			type: Boolean
		},
		colagent: {
			default: true,
			type: Boolean
		},
		coloffers: {
			default: true,
			type: Boolean
		},
		coldone: {
			default: true,
			type: Boolean
		},
		notclickable: {
			default: false,
			type: Boolean
		},
		header: {
			default: true,
			type: Boolean
		}
	},
	data() {
		return {
			actions: [],
			actionsTotal: 0,
			actionsShowMore: false
			// showContactButNotUser: false
		};
	},
	watch: {
		reload() {
			// console.log("reload");
			this.loadActions();
		},
		actionsOnlynotdone(v, oldv) {
			this.loadActions();
		},
		actionsShowMore(v, oldv) {
			this.loadActions();
		},
		actionId(v, oldv) {
			this.loadActions();
		}
		//  todo: {
		//    handler: function(newVal, oldVal) {
		//      console.log("newVal", newVal);
		//    },
		//    deep: true
		//  }
	},
	mounted() {
		//  console.log("this.actionId", this.actionId);
		// console.log("this.actionsTotal", this.actionsTotal);
		if (this.actionId) this.loadActions();
	},
	methods: {
		gotoOffer(row_of) {
			if (row_of && row_of.of_id) {
				this.$router.push("/offers/" + row_of.of_id + "/details");
			}
		},
		gotoContactOrUser(row_ac) {},
		getTitleLink(row_ac) {
			if (row_ac.co_id_user && row_ac.ac_type == "comment") {
				this.$router.push("/contacts/" + row_ac.co_id_user.co_id + "/details");
			} else if (row_ac.co_id_user_todo && row_ac.ac_type == "todo") {
				this.$router.push("/contacts/" + row_ac.co_id_user_todo.co_id + "/details");
			} else if (row_ac.co_id_contact) {
				this.$router.push("/contacts/" + row_ac.co_id_contact.co_id + "/details");
			}
		},
		getTitle(row_ac) {
			let title = "",
				who = "",
				date = "",
				fcn = this.$options.filters.formatContactName,
				fdt = this.$options.filters.formatDateTime,
				fon = this.$options.filters.formatOfferName;
			// subtitle = "Par " + fcn(row_ac.co_id_user);
			// this.showContactButNotUser = false
			who = fcn(row_ac.co_id_contact);
			if (row_ac.ac_type == "email") {
				title = "Envoyé par email à ";
			} else if (row_ac.ac_type == "todo") {
				title = "A faire par ";
				if (row_ac.co_id_user_todo && this.$_.isPlainObject(row_ac.co_id_user_todo)) {
					who = fcn(row_ac.co_id_user_todo);
				}
			} else if (row_ac.ac_type == "signature") {
				title = "Signature de ";
			} else if (row_ac.ac_type == "present") {
				title = "Présenté à ";
			} else if (row_ac.ac_type == "report") {
				title = "Compte rendu de ";
			} else if (row_ac.ac_type == "visit") {
				title = "Visite faite à ";
			} else if (row_ac.ac_type == "notinterested") {
				title = "Non intéressé ";
			} else if (row_ac.ac_type == "newcontact") {
				title = "Nouveau contact ";
			} else if (row_ac.ac_type == "newoffer") {
				title = "Nouvelle offre ";
				who = fon(row_ac.of_id);
			} else if (row_ac.ac_type == "call") {
				if (row_ac.ac_type_call == "callout") {
					title = "Appel sortant à ";
				} else if (row_ac.ac_type_call == "callout") {
					title = "Appel entrant de ";
				} else {
					title = "A rappeler ";
				}
			} else if (row_ac.ac_type == "comment") {
				title = "Commentaire de ";
				who = fcn(row_ac.co_id_user);
				// this.showContactButNotUser = true

				// subtitle = "";
				// if (row_ac.co_id_contact)
				//   subtitle = "Concerne le contact " + fcn(row_ac.co_id_contact);
			}
			date = fdt(row_ac.ac_date);
			return { title, who, date };
		},
		nada(evt) {
			evt.stopPropagation();
		},
		async loadActions() {
			if (!this.actionId) {
				this.actions = [];
				this.actionsTotal = 0;
				this.$emit("total", this.actionsTotal);
				return;
			}
			let q = "/api/1.0/actions/" + this.actionsMaintype + "/" + this.actionId + "/" + this.actionsType;
			// console.log("q", q);
			let limit = this.actionsNbMax;
			if (this.actionsShowMore) limit = 0;
			else if (limit == 0) limit = 1;
			if (limit == 0 && this.actionsNbSupermax > 0 && limit > this.actionsNbSupermax) limit = this.actionsNbSupermax;
			let response = await this.$axios.get(this.$config.server_url + q, {
				params: { limit, onlynotdone: this.actionsOnlynotdone }
			});
			if (this.actionsNbMax == 0 && !this.actionsShowMore) this.actions = [];
			else this.actions = response.data.data;
			this.actionsTotal = response.data.meta.total;
			this.$emit("total", this.actionsTotal);
			setTimeout(() => {
				this.$emit("dataLoaded", this.actionsType);
			}, 500);
		},
		getIcon(row_ac) {
			return this.$_.result(
				this.$_.find(this.$store.state.items_ac_type, {
					value: row_ac.ac_type
				}),
				"icon",
				"exclamation-triangle"
			);
		},
		async switchDone(row_ac) {
			// console.log("row_ac.ac_done", row_ac.ac_done);
			await this.$axios.put(this.$config.server_url + "/api/1.0/actions/" + row_ac.ac_id, { ac_done: row_ac.ac_done });
			// this.$emit("actionWinEditSaved");
			this.$store.commit("set_reload_actions");
			this.loadActions();
		},
		openWinAction(ac_id) {
			if (this.notclickable) return;
			if (this.$_.isPlainObject(ac_id)) ac_id = ac_id.ac_id;
			this.$refs.actionwinedit.open(ac_id);
		},
		actionWinEditSaved() {
			this.loadActions();
			// this.$emit("actionWinEditSaved");
		}
		// async loadActions() {
		//   this.$emit("actionWinEditSaved");
		// }
	}
};
</script>
<style scoped lang="scss">
@import "../sass/variables.scss";
.card-table-actions {
	.card-table-action {
		padding: 5px 10px;
		margin-bottom: 10px;
		&:nth-child(odd) {
			background-color: $mygris-clair;
		}
	}
}
</style>
