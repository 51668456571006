<template>
	<v-card-text>
		<v-file-input
			chips
			multiple
			v-model="files"
			:label="label"
			:accept="type === 'images' ? 'image/*' : 'file/*'"
			@change="handleChangeFile"
		></v-file-input>
		<v-layout wrap align-center v-if="type === 'images'">
			<div v-for="row_fi in offer.images" :key="row_fi.fi_id" style="padding: 2px">
				<v-img
					:src="`${$config.server_url}/api/1.0/images/${row_fi.fi_id}/75/75?token=${$store.state.accesstoken}`"
					height="75px"
					width="75px"
				></v-img>
			</div>
		</v-layout>
		<v-layout wrap align-center v-if="type === 'files'">
			<div v-for="row_fi in offer.files" :key="row_fi.fi_id" style="padding: 2px">
				<v-img :src="`${$config.server_url}/api/1.0/files/${row_fi.fi_id}`" height="75px" width="75px"></v-img>
			</div>
		</v-layout>
	</v-card-text>
</template>

<script>
export default {
	name: "mselect",
	components: {},
	props: {
		label: String,
		type: String,
		offer: Object
	},
	data() {
		return {
			files: []
		};
	},
	mounted() {},
	methods: {
		handleChangeFile(e) {
			let files = this.files;
			if (!files.length) return;
			this.$emit("inputfile", this.files, this.type);
		},
		reset() {
			this.files = [];
		}
	}
};
</script>
<style lang="scss"></style>
