<template>
	<div class="fill-height overflow-hidden calendarParent" ref="calendarParent">
		<div ref="calendarContainer" class="fill-height calendar-view2"></div>

		<calendar-win-edit
			ref="CalendarWinEdit"
			:offers-selected="offersSelected"
			:contact-selected="contactSelected"
			:edit-only-veid="editOnlyVeid"
			@calendarWinEditSaved="calendarWinEditSaved"
			@calendarWinEditCanceled="calendarWinEditCanceled"
			@calendarWinEditDeleted="calendarWinEditDeleted"
		/>
		<div ref="hoverdiv" class="hoverdiv"></div>
	</div>
</template>

<script>
import { Calendar } from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import frLocale from "@fullcalendar/core/locales/fr";

export default {
	name: "calendardetails",
	components: {},
	props: {
		value: String,
		users: {
			default: function() {
				return [this.$store.state.user];
			},
			type: Array
		},
		offersSelected: Array,
		contactSelected: Object,
		editOnlyVeid: {
			default: 0,
			type: Number
		},
		defaultView: {
			default: "timeGridWeek",
			type: String
		},
		paddingHeight: {
			default: 60,
			type: Number
		},
		showHeader: {
			default: true,
			type: Boolean
		}
	},

	data() {
		return {
			calendar: null,
			defaultColor: "#2096f3",
			events: []
		};
	},
	watch: {
		// offersSelected(val) {
		//   console.log("this.offersSelected", val);
		// },
		// offersSelected(val) {
		//   console.log("this.offersSelected", val);
		// },
		editOnlyVeid(va) {
			if (this.calendar) this.calendar.refetchEvents();
		},
		value(val) {
			// console.log("val", val);
			if (this.calendar) this.calendar.gotoDate(this.$moment(val).format("YYYY-MM-DD"));
		},
		users(val) {
			if (this.calendar) this.calendar.refetchEvents();
		}
	},
	sockets: {
		calendar_event(data) {
			// console.log("calendar_event", data);
			if (this.calendar) this.calendar.refetchEvents();
		}
	},

	async mounted() {
		let header = {
			left: "title",
			center: "",
			right: "prev,next"
		};

		// console.log("this.offersSelected", this.offersSelected);
		if (this.defaultView == "timeGridWeek") {
			header = {
				left: "title",
				center: "timeGridDay,timeGridWeek,dayGridMonth",
				right: "today prev,next"
			};
		}
		if (!this.showHeader) header = false;
		// console.log("this.value", this.value);
		let val = this.value;
		if (!this.value) val = new Date();
		// console.log("val", val);
		this.calendar = new Calendar(this.$refs.calendarContainer, {
			plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
			defaultView: this.defaultView,
			views: {
				timeGridDay: {
					titleFormat: { day: "numeric", month: "long" }
				}
			},
			defaultDate: val,
			editable: true,
			selectable: true,
			firstDay: 1,
			scrollTime: "09:00:00",
			locale: frLocale,
			header,
			dateClick: info => {},
			select: info => {
				this.openCalendarWinEdit(-1, this.$moment(info.start), this.$moment(info.end), info.allDay);
			},
			eventMouseEnter: info => {
				// console.log("info", info);
				let f = this.$_.find(this.events, { ve_id: info.event.id * 1 });
				// console.log("f", f);
				if (f) {
					let txt = [];
					if (f.co_id_user) txt.push("Utilisateur : <b>" + this.$options.filters.formatContactName(f.co_id_user) + "</b>");
					if (f.co_id_contact) txt.push("Contact : <b>" + this.$options.filters.formatContactName(f.co_id_contact) + "</b>");
					if (f.ve_summary) txt.push("Sujet : <b>" + f.ve_summary + "</b>");
					if (f.ve_description) txt.push("Description : <b>" + this.$options.filters.nl2br(f.ve_description) + "</b>");
					for (let iVi = 0; iVi < f.visits.length; iVi++) {
						const visit = f.visits[iVi];
						if (visit.of_id) txt.push(this.$options.filters.formatOfferName(visit.of_id));
					}
					this.$refs.hoverdiv.innerHTML = txt.join("<br/>");
					this.$refs.hoverdiv.style.display = "block";
					this.$refs.hoverdiv.style.left =
						info.el.getBoundingClientRect().left - this.$refs.calendarParent.getBoundingClientRect().left + "px";
					this.$refs.hoverdiv.style.top =
						info.el.getBoundingClientRect().top -
						this.$refs.calendarParent.getBoundingClientRect().top +
						info.el.getBoundingClientRect().height +
						"px";
				}
			},
			eventMouseLeave: info => {
				this.$refs.hoverdiv.style.display = "none";
			},
			eventClick: info => {
				// console.log("info", info);
				let editable = true;
				if (this.editOnlyVeid) {
					editable = false;
					if (this.editOnlyVeid == info.event.id) {
						editable = true;
					}
				}
				if (!editable) return;
				this.openCalendarWinEdit(info.event.id);
			},
			eventResize: info => {
				this.saveEventUpdated(info.event);
			},
			eventDrop: info => {
				this.saveEventUpdated(info.event);
			},
			events: async (fetchInfo, successCallback, failureCallback) => {
				// let users = [];
				// for (let ius = 0; ius < this.users.length; ius++) {
				//   const user = this.users[ius];
				//   users.push(user.co_id);
				// }
				// if (this.users.length === 0) {
				//   users.push("-1");
				// }
				// console.log("this.editOnlyVeid", this.editOnlyVeid);
				let users = this.users.map(row_co => {
					return row_co.co_id;
				});
				let params = {
					from: this.$moment(fetchInfo.start).format("YYYY-MM-DD HH:mm:ss"),
					to: this.$moment(fetchInfo.end).format("YYYY-MM-DD HH:mm:ss"),
					// envoyer aussi un tableau avec le ou les agents
					users: users
				};
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/events", { params });
				// console.log("response", response);
				this.events = response.data.data;
				this.$emit("events", response.data.data);
				let events = [];
				for (let iEv = 0; iEv < response.data.data.length; iEv++) {
					const row_ve = response.data.data[iEv];
					let title = "";
					if (row_ve.ve_summary) title += row_ve.ve_summary + " | ";
					if (row_ve.co_id_contact) title += this.$options.filters.formatContactName(row_ve.co_id_contact);
					if (this.$_.isArray(row_ve.visits) && row_ve.visits.length) {
						title += " | ";
						let tabTemp = [];
						this.$_.each(row_ve.visits, row_ac => {
							//console.log("row_ac", row_ac);
							// todo  afficher la ref à la place de l'id !
							for (let iVi = 0; iVi < row_ac.offers.length; iVi++) {
								const offer = row_ac.offers[iVi];
								tabTemp.push(offer.of_ref);
							}
						});
						title += tabTemp.join(" | ");
					}
					let editable = true;
					let color =
						row_ve.co_id_user.co_preferences && row_ve.co_id_user.co_preferences.color
							? row_ve.co_id_user.co_preferences.color.hex
							: this.defaultColor;
					if (this.editOnlyVeid) {
						editable = false;
						if (this.editOnlyVeid == row_ve.ve_id) {
							editable = true;
							color = "red";
						}
					}
					events.push({
						editable,
						id: row_ve.ve_id,
						title: title,
						start: this.$moment(row_ve.ve_dtstart).toDate(),
						end: this.$moment(row_ve.ve_dtend).toDate(),
						allDay: row_ve.ve_allday,
						color
					});
				}
				successCallback(events);
			}
		});

		this.calendar.render();
		this.setCalendarHeight();

		window.addEventListener("resize", this.setCalendarHeight);
	},
	destroyed() {
		window.removeEventListener("resize", this.setCalendarHeight);
	},
	methods: {
		reloadEvents() {
			this.calendar.refetchEvents();
		},
		setCalendarHeight() {
			let setheight = () => {
				this.calendar.setOption("height", this.$refs.calendarParent.offsetHeight - 0); // -this.paddingHeight
				this.calendar.scrollToTime("09:00:00");
			};
			setheight();
			setTimeout(() => {
				setheight();
			}, 100);
		},
		openCalendarWinEdit(ve_id, start, end, allday) {
			this.$emit("calendarWinEditOpen", ve_id, start, end, allday);
			this.$refs.CalendarWinEdit.open(ve_id, start, end, allday);
		},
		calendarWinEditDeleted(ve_id) {
			this.calendar.refetchEvents();
			this.$emit("calendarWinEditSaved", ve_id);
		},
		calendarWinEditSaved(ve_id) {
			this.calendar.refetchEvents();
			this.$emit("calendarWinEditSaved", ve_id);
		},
		calendarWinEditCanceled(ve_id) {
			this.calendar.refetchEvents();
			this.$emit("calendarWinEditCanceled", ve_id);
		},
		async saveEventUpdated(evt) {
			await this.$axios.put(this.$config.server_url + "/api/1.0/events/" + evt.id, {
				ve_dtstart: this.$moment(evt.start).format("YYYY-MM-DD HH:mm:ss"),
				ve_dtend: this.$moment(evt.end).format("YYYY-MM-DD HH:mm:ss"),
				ve_allday: evt.allDay
			});
			this.$emit("calendarWinEditSaved", evt.id);
			this.$socket.emit("calendar_event", evt.id);
		}
	}
};
</script>
<style scoped lang="scss">
.calendarParent {
	position: relative;
}
.calendar-view2 {
	.fc-left {
		font-size: 100px;
		color: red;
	}
}
.hoverdiv {
	position: absolute;
	display: none;
	z-index: 4;
	top: 100px;
	left: 100px;
	background-color: #7e8892;
	color: white;
	border-radius: 8px;
	padding: 5px 10px;
	font-size: 0.8rem;
	line-height: 1rem;
}
</style>
