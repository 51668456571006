<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="1800px" width="90%" ref="winPresent" :transition="false">
			<v-card>
				<v-card-title class="pa-0 mb-3 actionwinedit_cardtitle">
					<v-layout row d-inline-flex ma-0>
						<button
							type="button"
							v-for="(item, index) in items_ac_type_ok"
							:key="index"
							v-ripple
							:class="{
								actionwinedit_btn: true,
								active: row_ac.ac_type == item.value
							}"
							:disabled="row_ac.ac_id ? true : false"
							@click="row_ac.ac_type = item.value"
						>
							<icon class="actionwinedit_icon" :name="item.icon" :style="`width:${item.iconwidth}`"></icon>
							<div>{{ item.text }}</div>
						</button>
					</v-layout>
				</v-card-title>
				<v-card-text grid-list-xl :style="stylecontainer">
					<div v-if="row_ac.ac_type == 'visit'" class="formlittle">
						<p>&nbsp;</p>
						<!-- <b>Evénement lié :</b>
			<span v-if="row_ac.ve_id">
				{{ $options.filters.formatEventName(row_ac.ve_id) }}
			</span>
			<span v-else class="text-red">Aucun</span> -->
					</div>
					<v-container fluid pa-0 grid-list-xl ref="mycontainer">
						<v-layout>
							<v-flex md5 style="border-right: 5px solid #e8e8e7" v-if="row_ac.ac_type != 'visit'">
								<v-card>
									<v-card-text>
										<div v-if="row_ac.ac_type == 'call'" class="mb-3">
											<m-form-radio-group v-model="row_ac.ac_type_call">
												<m-form-radio
													v-for="item in $store.state.items_ac_type_call"
													:key="item.value"
													:label="item.text"
													:value="item.value"
												></m-form-radio>
											</m-form-radio-group>
										</div>

										<div
											v-if="row_ac.ac_type == 'todo' || row_ac.ac_type == 'call' || row_ac.ac_type == 'visit'"
											class="formlittle d-flex justify-end"
										>
											<div style="width: 130px">
												<v-switch
													v-model="row_ac.ac_done"
													:name="$Utils.randomstring('ac_done')"
													color="#53bd7b"
													:inset="true"
													:light="true"
													:label="row_ac.ac_done ? 'Fait' : 'A faire'"
													class="mt-0 ml-4 mr-2"
												></v-switch>
											</div>
										</div>

										<div v-if="row_ac.ac_type == 'visit'" class="formlittle">
											<p>
												Evénement lié :
												<span v-if="row_ac.ve_id">
													{{ $options.filters.formatEventName(row_ac.ve_id) }}
												</span>
												<span v-else class="text-red">Aucun</span>
											</p>
										</div>

										<div v-if="row_ac.ac_type == 'signature'">
											<v-layout row>
												<v-flex class="md6 d-flex">
													<m-form-radio-group v-model="row_ac.ac_type_signature">
														<m-form-radio
															v-for="item in $store.state.items_ac_type_signature"
															:key="item.value"
															:label="item.text"
															:value="item.value"
														></m-form-radio>
													</m-form-radio-group>
												</v-flex>
												<v-flex class="md6 py-1">
													<m-form-text
														type="number"
														:name="$Utils.randomstring('ac_signature_sum')"
														label="Montant signé"
														:label-width="7"
														@keyup="calculateCommission"
														v-model="row_ac.ac_signature_sum"
													></m-form-text>
													<m-form-text
														type="number"
														:name="$Utils.randomstring('ac_signature_sum2')"
														label="M. Commission"
														:label-width="7"
														v-model="row_ac.ac_signature_sum2"
													></m-form-text>
													<m-form-text
														type="number"
														:name="$Utils.randomstring('ac_signature_sum3')"
														label="% commission"
														:label-width="7"
														@keyup="calculateCommission"
														v-model="row_ac.ac_signature_sum3"
													></m-form-text>
												</v-flex>
											</v-layout>

											<div class="mt-3"></div>
										</div>

										<div v-if="row_ac.ac_type == 'email'" class="formlittle">
											<v-switch
												v-model="row_ac.ac_sendemail"
												:name="$Utils.randomstring('ac_sendemail')"
												color="#53bd7b"
												dense
												:inset="true"
												:light="true"
												label="Envoyer l'email"
												class="mt-0 ml-4 mr-2"
											></v-switch>
										</div>

										<div class="mt-3">
											<m-form-combobox
												:disabled="!$store.state.preferences.rights.action_edit"
												v-model="row_ac.co_id_user"
												:label="row_ac.ac_type == 'todo' ? `Nom du négociateur qui demande` : `Nom du négociateur`"
												label-position="top"
												prepend-icon="mdi-account"
												:store-url="$config.server_url + '/api/1.0/contacts'"
												:store-params="{ gr_id: 'users', ignoremiglior: true }"
												item-value="co_id"
												:item-text="$options.filters.formatContactName"
											></m-form-combobox>
											<m-form-date-hour v-model="row_ac.ac_date"></m-form-date-hour>
										</div>

										<div
											v-if="row_ac.ac_type == 'todo' || (row_ac.ac_type == 'call' && row_ac.ac_type_call == 'tocall')"
											class="mt-3"
										>
											<m-form-combobox
												:name="$Utils.randomstring('co_id_user_todo')"
												v-model="row_ac.co_id_user_todo"
												label="Nom de du négociateur qui doit faire l'action"
												label-position="top"
												prepend-icon="mdi-account"
												:store-url="$config.server_url + '/api/1.0/contacts'"
												:store-params="{ gr_id: 'users', ignoremiglior: true }"
												item-value="co_id"
												:item-text="$options.filters.formatContactName"
											></m-form-combobox>
											<m-form-date-hour v-model="row_ac.ac_todo_date"></m-form-date-hour>
										</div>

										<div class="mt-3">
											<label>
												Nom du contact / client / prospect
												<v-btn color="success" class="mr-2" @click="$refs.contactwinedit.open(-1)" small>Nouveau</v-btn>
												<v-btn
													v-if="row_ac.co_id_contact && row_ac.co_id_contact.co_id"
													color="primary2"
													@click="$refs.contactwinedit.open(row_ac.co_id_contact.co_id)"
													small
													>Modifier</v-btn
												>
											</label>
											<m-form-combobox
												:name="$Utils.randomstring('co_id_contact')"
												v-model="row_ac.co_id_contact"
												prepend-icon="mdi-account"
												:store-url="$config.server_url + '/api/1.0/contacts'"
												:store-params="{ gr_id: 'contacts' }"
												item-value="co_id"
												:item-text="$options.filters.formatContactName"
												@input="loadContact"
											></m-form-combobox>
											<m-form-combobox
												v-model="row_ac.co_id_agence_confrere"
												label="Confrère"
												label-position="top"
												prepend-icon="mdi-account"
												:store-url="$config.server_url + '/api/1.0/contacts'"
												:store-params="{ gr_id: 'agencies' }"
												item-value="co_id"
												:item-text="$options.filters.formatContactName"
											></m-form-combobox>
										</div>

										<div v-if="true" class="mt-3">
											<label>
												Concerne le(s) bien(s) associé(s)
												<!-- <v-icon
							v-if="
							row_ac.ac_type != 'signature' &&
								row_ac.ac_type != 'report'
							"
							@click="row_ac.ac_offers.push({ of_id: {} })"
							>mdi-plus</v-icon
						> -->
											</label>
											<m-form-combobox
												v-model="addOffer"
												:name="$Utils.randomstring('of_id')"
												:store-url="$config.server_url + '/api/1.0/offers'"
												item-value="of_id"
												:item-text="$options.filters.formatOfferName"
											></m-form-combobox>
											<!-- <table class="w-100">
						<tr
							v-for="(row_of, index) in row_ac.ac_offers"
							:key="index"
						>
							<td>
							<m-form-combobox
								v-model="row_of.of_id"
								:name="$Utils.randomstring('of_id')"
								:store-url="$config.server_url + '/api/1.0/offers'"
								item-value="of_id"
								:item-text="$options.filters.formatOfferName"
							></m-form-combobox>
							</td>
							<td style="width:40px;" class="text-right">
							<v-icon @click="row_ac.ac_offers.splice(index, 1)"
								>mdi-trash-can</v-icon
							>
							</td>
						</tr>
						</table> -->

											<table class="w-100">
												<tr v-for="(row_ac_temp, indexOf) in row_ac.ac_offers" :key="indexOf">
													<td valign="top">
														<v-icon color="#2096f3" small>mdi-arrow-right-thick</v-icon>
														<!-- <span style='color:#2096f3;'>•</span> -->
													</td>
													<td v-html="$options.filters.formatOfferName(row_ac_temp.of_id, false, true)"></td>
													<td style="width: 40px" class="text-right" valign="top">
														<v-icon @click="row_ac.ac_offers.splice(indexOf, 1)">mdi-trash-can</v-icon>
													</td>
												</tr>
											</table>
										</div>

										<div class="mt-3" v-if="row_ac.ac_type == 'email'">
											<m-form-select
												label="Envoyer à"
												:label-width="2"
												label-position="top"
												:items="items_emails"
												v-model="currentEmail"
												:disabled="!row_ac.co_id_contact || !currentEmail"
											></m-form-select>
										</div>

										<div class="mt-1" v-if="row_ac.ac_type == 'email'">
											<m-form-select
												label="Template mail à utiliser"
												:label-width="2"
												label-position="top"
												:items="items_languages"
												v-model="selectedLanguage"
												:disabled="!row_ac.co_id_contact || !currentEmail"
											></m-form-select>
										</div>

										<div class="mt-1" v-if="row_ac.ac_type == 'email'">
											<m-form-checkbox
												label="Envoyer le(s) bien(s) avec toutes les images"
												:name="$Utils.randomstring('pdfcomplete')"
												v-model="pdfcomplete"
												class="mr-3"
											></m-form-checkbox>
										</div>

										<div class="mt-1">
											<m-form-text
												type="textarea"
												:name="$Utils.randomstring('ac_comment')"
												outline
												:label="
													row_ac.ac_type == 'report'
														? `Commentaire compte-rendu de visite`
														: row_ac.ac_type == 'email'
														? `Commentaire ('Cher M(me) NOM PRÉNOM' sera rajouté automatiquement au début du message)`
														: `Commentaire`
												"
												label-position="top"
												v-model="row_ac.ac_comment"
											></m-form-text>
										</div>
									</v-card-text>
								</v-card>
							</v-flex>
							<v-flex
								style="background-color: #e8e8e7"
								:class="{
									md7: row_ac.ac_type != 'visit',
									md12: row_ac.ac_type == 'visit'
								}"
							>
								<!-- <v-card
					v-if="row_co_details && row_ac.ac_type!='visit' && row_ac.ac_type!='report'"
					class="mt-3"
				>
					<v-card-text>
					<contact-details v-model="row_co_details" @contactWinEditSaved="loadContact"></contact-details>
					</v-card-text>
				</v-card>-->

								<v-card class="mt-0" style="background-color: #e8e8e7">
									<v-card-text class="my-0">
										<v-tabs
											v-if="row_ac.ac_type != 'visit'"
											v-model="tabvisit"
											background-color="transparent"
											color="basil"
											grow
											@change="tabvisitchange"
										>
											<v-tab key="calendar" v-show="row_ac.ac_type == 'visit'">Calendrier</v-tab>
											<v-tab key="report" v-show="row_ac.ac_type == 'report'">Compte rendu</v-tab>
											<v-tab key="contact">Contact</v-tab>
											<v-tab v-for="(item, index) in row_ac.ac_offers" :key="`offer_${index}`">
												Bien
												{{ item.of_id && item.of_id.of_ref ? item.of_id.of_ref : "..." }}
											</v-tab>
										</v-tabs>

										<v-tabs-items v-model="tabvisit" style="background-color: transparent">
											<v-tab-item key="calendar">
												<div
													style="height: 500px; overflow: hidden; margin-top: 15px; padding: 10px; background-color: white"
												>
													<calendar-details
														ref="calendarDetails"
														:offers-selected="offersSelectedForCalendar"
														:contact-selected="contactSelectedForCalendar"
														:edit-only-veid="editOnlyVeidForCalendar"
														v-model="row_ac.ac_date"
														@calendarWinEditSaved="calendarWinEditSaved"
													></calendar-details>
												</div>
											</v-tab-item>
											<v-tab-item key="report">
												<div class="mt-3 overline">
													Saisir un commentaire de compte-rendu de visite et au moins 2 feedbacks pour valider le
													compte-rendu.
												</div>
												<div class="formlittle mt-2 d-flex">
													<div class="mr-auto">
														<v-switch
															v-model="row_ac.ac_report_proposition"
															:name="$Utils.randomstring('ac_report_proposition')"
															color="#53bd7b"
															:inset="true"
															:light="true"
															label="Acquéreur potentiel"
															class="mt-0 ml-4 mr-2"
														></v-switch>
													</div>
													<div>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('ac_report_propositionprice')"
															label="Montant proposé"
															:label-width="7"
															v-model="row_ac.ac_report_propositionprice"
														></m-form-text>
													</div>
												</div>
												<div style="margin-top: 15px">
													<div v-for="feedbacktype in $store.state.items_feedbackstypes" :key="feedbacktype.value">
														<h2>{{ feedbacktype.text }}</h2>
														<div
															v-for="feedback in $store.state.items_feedbacks.filter(it => {
																return it.type == feedbacktype.value;
															})"
															:key="feedback.value"
															class="d-flex flex-row formlittle"
															style="line-height: 0.875rem"
														>
															<div
																class="withpointer"
																style="width: 35%; text-align: right; padding: 9px 15px 0 0"
																@click="row_ac.ac_report['feedback' + feedback.value] = -1"
															>
																{{ feedback.text2 }}
															</div>
															<div style="width: 30%">
																<v-slider
																	:value="0"
																	color="#eceff1"
																	track-color="#eceff1"
																	thumb-color="#46c9e2"
																	v-model="row_ac.ac_report['feedback' + feedback.value]"
																	:min="-1"
																	:max="1"
																	ticks="always"
																	tick-size="1"
																></v-slider>
															</div>
															<div
																class="withpointer"
																style="width: 35%; padding: 9px 0 0 15px"
																@click="row_ac.ac_report['feedback' + feedback.value] = 1"
															>
																{{ feedback.text1 }}
															</div>
														</div>
													</div>
												</div>
											</v-tab-item>
											<v-tab-item key="contact" style="background-color: #e8e8e7">
												<div style="margin-top: 15px" v-if="row_co_details">
													<contact-details
														:short="true"
														:notclickable="true"
														v-model="row_co_details"
														@contactDetailsLoaded="setContainerStyle"
														@contactWinEditSaved="loadContact"
													></contact-details>
												</div>
											</v-tab-item>
											<v-tab-item
												v-for="(item, index) in row_ac.ac_offers"
												:key="`offer_${index}`"
												style="background-color: #e8e8e7"
											>
												<div style="margin-top: 15px" v-if="item.of_id && item.of_id.of_id">
													<offers-details
														:short="true"
														v-model="item.of_id"
														@offersDetailsLoaded="setContainerStyle"
													></offers-details>
												</div>
											</v-tab-item>
										</v-tabs-items>
									</v-card-text>
								</v-card>

								<!-- <v-card v-if="row_ac.ac_type=='report'" class="mt-3">
					<v-card-text>
					<div
						v-for="feedbacktype in $store.state.items_feedbackstypes"
						:key="feedbacktype.value"
					>
						<h2>{{feedbacktype.text}}</h2>
						<div
						v-for="(feedback) in $store.state.items_feedbacks.filter((it)=>{return it.type==feedbacktype.value})"
						:key="feedback.value"
						class="d-flex flex-row formlittle"
						style="line-height:0.875rem;"
						>
						<div
							class="withpointer"
							style="width:35%;text-align:right;padding:9px 15px 0 0;"
							@click="row_ac.ac_report['feedback'+feedback.value]=-1"
						>{{feedback.text2}}</div>
						<div style="width:30%;">
							<v-slider
							:value="0"
							color="#eceff1"
							track-color="#eceff1"
							thumb-color="#46c9e2"
							v-model="row_ac.ac_report['feedback'+feedback.value]"
							:min="-1"
							:max="1"
							ticks="always"
							tick-size="1"
							></v-slider>
						</div>
						<div
							class="withpointer"
							style="width:35%;padding:9px 0 0 15px;"
							@click="row_ac.ac_report['feedback'+feedback.value]=1"
						>{{feedback.text1}}</div>
						</div>
					</div>
					</v-card-text>
				</v-card>-->
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn :disabled="!(row_ac.ac_id * 1 > 0) || !isEditable" color="error2" @click="confirmDeleteDialog = true">Effacer</v-btn>
					<v-spacer></v-spacer>
					<v-btn class="primary2--text" color text @click="cancelWin()">Annuler</v-btn>
					<v-btn color="primary2" @click="saveWin()" :disabled="!isEditable || tempDisabled"
						><span v-if="row_ac.ac_type !== 'email'">Enregistrer</span> <span v-else>Envoyer</span></v-btn
					>
				</v-card-actions>
			</v-card>
			<m-confirm-dialog
				v-model="confirmDeleteDialog"
				text="Souhaitez-vous effacer cette action ?"
				title="Confirmation"
				@canceled="confirmDeleteDialog = false"
				@confirmed="deleteWin()"
			></m-confirm-dialog>
			<contact-win-edit ref="contactwinedit" @contactWinEditSaved="contactWinEditSaved"></contact-win-edit>
		</v-dialog>
		<v-snackbar v-model="emailSended" top :timeout="5000">
			Votre message est envoyé
			<v-btn color="pink" text @click="emailSended = false">Fermé</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	name: "actionwinedit",
	props: ["offersSelected", "contactSelected"],
	components: {},
	data() {
		let row_ac = { ac_report: {}, ac_offers: [] };
		// console.log("items_languages", items_languages);
		this.initAcReport(row_ac);
		return {
			emailSended: false,
			pdfcomplete: true,
			tempDisabled: false,
			items_emails: [],
			currentEmail: "",
			// items_languages,
			addOffer: null,
			selectedLanguage: "",
			previousAcType: "",
			tabvisit: null,
			row_ac,
			row_co_details: null,
			menuAc: false,
			dialog: false,
			stylecontainer: ``,
			confirmDeleteDialog: false
		};
	},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	computed: {
		items_languages() {
			let items_languages = this.$_.cloneDeep(this.$store.state.items_languages);
			items_languages.unshift({ value: "", text: "Choisir" });
			return items_languages;
		},
		// currentcontact: {
		//   get() {
		//     return this.$store.state.currentcontact;
		//   },
		//   set(value) {
		//     this.$store.commit("set_currentcontact", value);
		//   }
		// },
		editOnlyVeidForCalendar() {
			if (this.row_ac.ve_id) {
				let ve_id = this.row_ac.ve_id;
				if (this.$_.isPlainObject(ve_id)) ve_id = this.row_ac.ve_id.ve_id;
				return ve_id;
			}
			return 0;
		},
		contactSelectedForCalendar() {
			return this.row_ac.co_id_contact;
		},
		offersSelectedForCalendar() {
			let forCalendar = [];
			for (let iEvt = 0; iEvt < this.row_ac.ac_offers.length; iEvt++) {
				const row_ac = this.row_ac.ac_offers[iEvt];
				if (row_ac.of_id) forCalendar.push(row_ac.of_id);
			}
			return forCalendar;
		},
		isEditable() {
			if (!this.selectedLanguage && this.row_ac.ac_type == "email") return false;
			if (this.$store.state.preferences.rights.action_edit) return true;
			if (this.row_ac.co_id_user && this.row_ac.co_id_user.co_id == this.$store.state.user.co_id) return true;
			return false;
		},
		items_ac_type_ok() {
			return this.$store.state.items_ac_type.filter(item => {
				if (item.value == "newoffer" || item.value == "newcontact" || item.value == "notinterested") return false;
				return true;
			});
		}
	},
	watch: {
		addOffer(v) {
			if (v && v.of_id) {
				this.row_ac.ac_offers.push({ of_id: v });
				this.addOffer = {};
			}
		},
		selectedLanguage(v) {
			this.row_ac.ac_comment = this.getDefaultMessage(v);
		},
		// row_co_details(v) {
		//   console.log("v", v);
		// },
		row_ac: {
			handler: function(newVal, oldVal) {
				// console.log(
				//   "newVal.ac_type,this.previousAcType",
				//   newVal.ac_type,
				//   this.previousAcType
				// );
				if (this.previousAcType != newVal.ac_type && newVal.ac_type == "visit") {
					this.tabvisit = 0;
					this.$refs.calendarDetails.setCalendarHeight();
					this.$refs.calendarDetails.reloadEvents();
				} else if (this.previousAcType != newVal.ac_type && newVal.ac_type == "report") this.tabvisit = 1;
				else if (this.previousAcType != newVal.ac_type) this.tabvisit = 2;
				this.previousAcType = newVal.ac_type;

				this.setContainerStyle();
			},
			deep: true
		},
		tabvisit(v) {
			// console.log("v", v);
		}
	},
	methods: {
		getDefaultMessage(v) {
			let txt = "";
			if (this.$store.state.preferences.shared.modelesMail && this.$store.state.preferences.shared.modelesMail[v]) {
				txt = this.$store.state.preferences.shared.modelesMail[v];
			}
			return txt;
		},
		async contactWinEditSaved(co_id) {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + co_id);
			// console.log("response.data.data", response.data.data);
			this.row_ac.co_id_contact = response.data.data;
		},
		calculateCommission() {
			this.row_ac.ac_signature_sum2 = Math.ceil((this.row_ac.ac_signature_sum * this.row_ac.ac_signature_sum3) / 100);
		},
		tabvisitchange(v) {
			// console.log("v", v);
			this.setContainerStyle();
			// window.setTimeout(this.setContainerStyle, 200);
		},
		initAcReport(row_ac) {
			if (!row_ac) row_ac = {};
			if (!row_ac.ac_report) row_ac.ac_report = {};
			for (let iRep = 0; iRep < this.$store.state.items_feedbacks.length; iRep++) {
				const feedback = this.$store.state.items_feedbacks[iRep];
				if (!row_ac.ac_report["feedback" + feedback.value]) row_ac.ac_report["feedback" + feedback.value] = 0;
			}
		},
		async loadContact(v) {
			let row_co = v;
			if (this.$_.isPlainObject(v)) {
				row_co = v;
				this.$store.commit("set_currentcontact", v);
			} else {
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + v);
				this.$store.commit("set_currentcontact", response.data.data);
				row_co = response.data.data;
			}
			// console.log("co_id", co_id);
			this.row_co_details = this.$_.isPlainObject(row_co) ? row_co.co_id : row_co;
			this.items_emails = [];
			this.currentEmail = "";
			// console.log("this.row_co_details", row_co);
			if (row_co && row_co.co_email1) {
				this.items_emails.push({ value: row_co.co_email1, text: row_co.co_email1 });
				this.currentEmail = row_co.co_email1;
			}
			if (row_co && row_co.co_email2) this.items_emails.push({ value: row_co.co_email2, text: row_co.co_email2 });
			if (row_co && row_co.co_email3) this.items_emails.push({ value: row_co.co_email3, text: row_co.co_email3 });
		},
		// async loadContact(v) {
		//   let co_id = v;
		//   if (this.$_.isPlainObject(v)) co_id = v.co_id;
		//   if (!co_id) {
		//     this.row_co_details = null;
		//     return;
		//   }
		//   let response = await this.$axios.get(
		//     this.$config.server_url + "/api/1.0/contacts/" + co_id
		//   );
		//   this.row_co_details = response.data.data;
		//   this.setContainerStyle();
		// },
		setContainerStyle() {
			let me = this;
			function go() {
				if (!me.$refs.mycontainer) return;
				if (me.$refs.mycontainer.clientHeight > window.innerHeight - 300) me.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
				else me.stylecontainer = "";
			}
			go();
			window.setTimeout(go, 100);
			window.setTimeout(go, 1000);
		},
		async open(ac_id, ac_type) {
			this.tempDisabled = false;
			// console.log("this.contactSelected", this.contactSelected);
			// console.log("this.$route", this.$route.name=='offersdetails');
			if (!ac_id) ac_id = "-1";
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/actions/" + ac_id);
			let oneMoreOffer = null;
			if (ac_id == "-1" && this.$route.name == "offersdetails") {
				let responseOffer = await this.$axios.get(this.$config.server_url + "/api/1.0/offers/" + this.$route.params.of_id);
				oneMoreOffer = responseOffer.data.data;
			}
			setTimeout(() => {
				if (!this.$_.isArray(response.data.data.ac_offers)) response.data.data.ac_offers = [];
				// if (response.data.data.ac_offers.length == 0)
				//   response.data.data.ac_offers.push({ of_id: {} });
				this.initAcReport(response.data.data);
				if (ac_type) response.data.data.ac_type = ac_type;
				let row_ac = response.data.data;
				// console.log("row_ac", row_ac.ac_offers);

				// console.log("this.offersSelected", this.offersSelected);
				if (this.$_.isArray(this.offersSelected) && !row_ac.ac_id) {
					let ac_offers = [];
					for (let iOffer = 0; iOffer < this.offersSelected.length; iOffer++) {
						const selected = this.offersSelected[iOffer];
						ac_offers.push({ of_id: selected });
					}
					row_ac.ac_offers = ac_offers;
				}
				if (oneMoreOffer) {
					row_ac.ac_offers.push({ of_id: oneMoreOffer });
				}
				// console.log("row_ac.ac_offers", row_ac.ac_offers);

				// console.log("this.contactSelected", this.contactSelected);
				if (!row_ac.ac_id && this.$store.state.currentcontact) {
					// console.log("this.$store.state.currentcontact", this.$store.state.currentcontact);
					row_ac.co_id_contact = this.$_.cloneDeep(this.$store.state.currentcontact);
				}

				if (!row_ac.ac_id && this.contactSelected) {
					row_ac.co_id_contact = this.contactSelected;
					this.row_co_details = this.contactSelected.co_id;
				}
				if (row_ac.co_id_contact) this.row_co_details = row_ac.co_id_contact.co_id;
				else this.row_co_details = null;
				if (row_ac.ac_type == "visit") this.tabvisit = 0;
				else if (row_ac.ac_type == "report") this.tabvisit = 1;
				else this.tabvisit = 2;

				// if (!row_ac.ac_id && row_ac.ac_type == "email") {
				//   row_ac.ac_comment = this.getDefaultMessage("fr");
				// }

				this.selectedLanguage = row_ac.ac_maillang;

				this.row_ac = row_ac;
			}, 100);

			this.dialog = true;
			this.setContainerStyle();
		},
		cancelWin() {
			this.dialog = false;
		},
		async saveWin() {
			this.tempDisabled = true;
			// let response;
			if (this.row_ac.ac_type == "signature") this.row_ac.ac_done = true;
			if (this.row_ac.ac_type == "email") {
				this.row_ac.ac_maillang = this.selectedLanguage;
				this.row_ac.pdfcomplete = this.pdfcomplete;
			}
			if (this.currentEmail) this.row_ac.currentEmail = this.currentEmail;
			let ac_id = this.row_ac.ac_id;
			if (ac_id) {
				// response =
				await this.$axios.put(this.$config.server_url + "/api/1.0/actions/" + this.row_ac.ac_id, this.row_ac);
			} else {
				// response =
				let response = await this.$axios.post(this.$config.server_url + "/api/1.0/actions", this.row_ac);
				ac_id = response.data.data.ac_id;
			}

			this.tempDisabled = false;
			this.dialog = false;
			this.pdfcomplete = true;
			// if (this.row_ac.ac_type=="email")
			this.emailSended = true;
			this.$store.dispatch("load_nbclientsuivi");
			this.$store.commit("set_reload_actions");
			this.$socket.emit("actions_update", ac_id);
			this.$emit("actionWinEditSaved", ac_id);
		},
		async deleteWin() {
			let ac_id = this.row_ac.ac_id;
			await this.$axios.delete(this.$config.server_url + "/api/1.0/actions/" + ac_id);
			this.confirmDeleteDialog = false;
			this.dialog = false;
			this.$store.dispatch("load_nbclientsuivi");
			this.$store.commit("set_reload_actions");
			this.$emit("actionWinEditSaved", ac_id);
		},
		async calendarWinEditSaved(ve_id) {
			// console.log("ve_id", ve_id);
			// let response = await this.$axios.get(
			//   this.$config.server_url + "/api/1.0/events/" + ve_id
			// );
			// // console.log("response.data.data", response.data.data);
			// this.row_ac.ve_id = response.data.data;
			// this.row_ac.ac_offers = [];
			// for (let iVisit = 0; iVisit < this.row_ac.ve_id.visits.length; iVisit++) {
			//   const row_vi = this.row_ac.ve_id.visits[iVisit];
			//   this.row_ac.ac_offers.push(row_vi.of_id);
			//   this.row_ac.of_id = row_vi.of_id.of_id;
			// }
			// // this.row_ac
			// if (this.row_ac.ve_id && this.row_ac.ve_id.ve_deleted)
			//   this.row_ac.ve_id = null;
			// // this.$forceUpdate();
			// this.saveWin();
			this.dialog = false;
		}
	}
};
</script>

<style lang="scss">
.actionwinedit_cardtitle {
	background-color: #e8e8e7;
	color: #8c8c8c;
	.actionwinedit_btn {
		// color: #dddddd;
		cursor: pointer;
		font-size: 0.875rem;
		line-height: 0.875rem;
		padding-top: 10px;
		width: 90px;
		height: 80px;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// justify-items: center;
		text-align: center;
		&.active {
			background-color: #2d2d2d;
			color: white;
		}
		.actionwinedit_icon {
			width: 40px;
			height: 40px;
		}
		&[disabled] {
			color: #dddddd;
		}
	}
}
</style>
