<template>
	<v-layout column>
		<v-flex class>
			<div class="d-flex flex-row">
				<div style="margin-right: 30px" v-if="row_co.co_avatar">
					<v-img :src="srcAvatar" :height="short ? '90px' : '120px'" :width="short ? '90px' : '120px'" class="rounded-image"></v-img>
				</div>
				<div v-else style="margin-right: 30px">
					<div
						:class="{
							'container-no-avatar': true,
							'rounded-image': true,
							short: true
						}"
					>
						<div ref="noavatar" :class="{ 'no-avatar': true, 'rounded-image': true }"></div>
					</div>
				</div>
				<div class="d-flex align-content-center flex-wrap">
					<div>
						<h1>
							<span v-html="$options.filters.formatContactName(row_co, true, true)"></span>
							<!-- <span v-if="row_co.co_society">&nbsp;|&nbsp;{{row_co.co_society}}</span> -->
						</h1>
						<h2 v-if="row_co.co_id_society">
							<span v-html="$options.filters.formatContactName(row_co.co_id_society, false, true)"></span>&nbsp;
							<a v-if="row_co.co_id_society" @click="gotoContact(row_co.co_id_society)">
								&nbsp;
								<v-icon color="#1976d2">mdi-magnify</v-icon>
							</a>
						</h2>
						<h2 v-if="row_co.co_issociety" class="h2nomargin">Société</h2>
						<h2 v-if="row_co.co_isagent" class="h2nomargin">Négociateur immobilier</h2>
						<h2 v-if="row_co.co_isagency" class="h2nomargin">Agence immobilière</h2>
						<h2 v-if="row_co.co_isowner" class="h2nomargin">Propriétaire</h2>
						<h2 v-if="row_co.co_islawyer" class="h2nomargin">Avocat</h2>
						<h2 v-if="row_co.co_isrenter" class="h2nomargin">Locataire</h2>
						<h2 v-if="row_co.co_issyndic" class="h2nomargin">Syndic</h2>
						<h2 v-if="row_co.co_isbuilding" class="h2nomargin">Immeuble</h2>
						<h2 v-if="row_co.co_iscaretaker" class="h2nomargin">Concierge</h2>
						<h2 v-if="row_co.co_isintermediaire" class="h2nomargin">Intermédiaire/apporteur d'affaires</h2>
						<h2 v-if="row_co.co_function && !row_co.co_issociety" class="h2nomargin">{{ row_co.co_function }}</h2>
						<h3 v-if="row_co.co_type && row_co.co_type != 'contact'">Droit : {{ co_type_text }}</h3>
					</div>
				</div>
				<div class="formlittle ml-auto" v-if="row_co.co_type == 'contact'">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-switch
								:disabled="!$store.state.preferences.rights.contact_follows"
								v-on="$store.state.preferences.personal.helpBubble ? on : false"
								:name="$Utils.randomstring('co_followed')"
								v-model="clientsuivi"
								color="#53bd7b"
								:inset="true"
								:light="true"
								:label="clientsuivi ? 'Nouveau contact' : 'Nouveau contact'"
								class="mt-0 ml-4 mr-2"
								@change="onChangeSuivi"
							></v-switch>
						</template>
						<span>Indique que vous suivez ce contact</span>
					</v-tooltip>
				</div>
				<!-- <span class="client mr-3" :class="clientSuiviClass">{{clientsuiviLabel}}</span> -->
				<div class="ml-auto">
					<v-btn color="info" @click="openWinEdit()" :disabled="!isEditable">Modifier</v-btn>
				</div>
			</div>

			<v-layout v-if="row_co.contactsassociated.length">
				<v-flex>
					<v-card class="card3">
						<v-card-text>
							Contacts associés :
							<span v-for="(row_co_associated, index) in row_co.contactsassociated" :key="index">
								<a @click="gotoContact(row_co_associated)">
									<span>
										{{ $options.filters.formatContactName(row_co_associated) }}
									</span>
								</a>
								<span v-if="index < row_co.contactsassociated.length - 1">&nbsp;-&nbsp;</span>
							</span>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>

			<v-layout v-if="row_co.co_type == 'contact'">
				<v-flex md4>
					<v-card class="card3 h-100" @click="toTop('presents_user')">
						<v-card-text>
							<div class="text-md-center">
								<b>Nombre</b>
								<br />de biens présentés
								<!-- <h1>{{ row_co.presents_contact.length }}</h1> -->
								<h1>{{ row_co.nbPresents }}</h1>
							</div>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex md4>
					<v-card class="card3 h-100" @click="toTop('visits_user')">
						<v-card-text>
							<div class="text-md-center">
								<b>Nombre</b>
								<br />de visites
								<!-- <h1>{{ row_co.visits_contact.length }}</h1> -->
								<h1>{{ row_co.nbVisits }}</h1>
							</div>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex md4>
					<v-card class="card3 h-100" @click="toTop('properties')">
						<v-card-text>
							<div class="text-md-center">
								<b>Nombre</b>
								<br />de biens propriétaire
								<!-- <h1>{{ row_co.properties.length }}</h1> -->
								<h1>{{ row_co.nbProperties }}</h1>
							</div>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>

			<div v-if="row_co.co_type && row_co.co_type != 'contact'" class="mt-3">
				<!-- <div style="float:right; width:220px; margin-top:-20px;">
          <div>Statistiques Du... Au...</div>
          <div style="clear:both;"></div>
          <div style="float:right; width:110px;">
            <m-form-date
              :name="$Utils.randomstring('filterto')"
              v-model="filterto"
            />
          </div>
          <div style="float:right; width:110px;">
            <m-form-date
              :name="$Utils.randomstring('filterfrom')"
              v-model="filterfrom"
            />
          </div>
        </div> -->
				<!-- <h1>
          Chiffre d'affaires : Vente {{ revenueSell }} - Location
          {{ revenueRent }}
        </h1> -->
				<div style="clear: both"></div>
				<v-layout v-if="$store.state.preferences.rights.contact_stats || $store.state.user.co_id == row_co.co_id">
					<v-flex md4>
						<v-card class="card3 h-100">
							<v-card-text>
								<div class="text-md-center">
									<b>Nombre</b>
									<br />de c. rendus à remplir
									<h1>{{ row_co.reports_tocomplete.length }}</h1>
								</div>
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex md4>
						<v-card class="card3 h-100">
							<v-card-text>
								<div class="text-md-center">
									<b>Nombre</b>
									<br />de nouveaux clients
									<h1>{{ row_co.nbNewContacts }}</h1>
								</div>
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex md4>
						<v-card class="card3 h-100">
							<v-card-text>
								<div class="text-md-center">
									<b>Nombre</b>
									<br />de clients suivis
									<h1>{{ followscontacts.length }}</h1>
								</div>
							</v-card-text>
						</v-card>
					</v-flex>
					<v-flex md4>
						<v-card class="card3 h-100">
							<v-card-text>
								<div class="text-md-center">
									<b>Nombre</b>
									<br />de biens suivis
									<h1>{{ followsoffers.length }}</h1>
								</div>
							</v-card-text>
						</v-card>
					</v-flex>
				</v-layout>

				<v-layout v-if="$store.state.preferences.rights.contact_stats || $store.state.user.co_id == row_co.co_id">
					<v-flex d-flex flex-row justify-space-around>
						<div class="mr-3 d-flex flex-row">
							<div class="indicateur-de-travail vert d-flex flex-row">
								<!-- <div>{{ row_co.signatures_sell_user.length }}</div> -->
								<div>{{ row_co.nbSells }}</div>
							</div>
							<h2 class="ml-2 pt-3"><span class="bis">Ventes</span> chef de produits</h2>
						</div>
						<div class="mr-3 d-flex flex-row">
							<div class="indicateur-de-travail bleu-clair d-flex flex-row">
								<!-- <div>{{ row_co.signatures_rent_user.length }}</div> -->
								<div>{{ row_co.nbRents }}</div>
							</div>
							<h2 class="ml-2 pt-3"><span class="bis">Locations</span> chef de produits</h2>
						</div>
						<div class="mr-3 d-flex flex-row">
							<div class="indicateur-de-travail rouge d-flex flex-row">
								<!-- <div>{{ row_co.actions_todo_user.length }}</div> -->
								<div>{{ row_co.nbTodos }}</div>
							</div>
							<h2 class="ml-2 pt-3"><span class="bis">Actions</span> à faire</h2>
						</div>
						<div class="d-flex flex-row">
							<div class="indicateur-de-travail jaune d-flex flex-row">
								<!-- <div>{{ row_co.visits_user.length }}</div> -->
								<div>{{ row_co.nbEvents }}</div>
							</div>
							<h2 class="ml-2 pt-3"><span class="bis">RDV</span> pris</h2>
						</div>
					</v-flex>
				</v-layout>
			</div>

			<v-row>
				<v-col md4>
					<h2>Coordonnées</h2>
					<table class="tableinfos">
						<tr v-if="row_co.co_email1">
							<td class="text-no-wrap">Email 1</td>
							<td v-html="$options.filters.formatEmail(row_co.co_email1)"></td>
						</tr>
						<tr v-if="row_co.co_email2">
							<td class="text-no-wrap">Email 2</td>
							<td v-html="$options.filters.formatEmail(row_co.co_email2)"></td>
						</tr>
						<tr v-if="row_co.co_email3">
							<td class="text-no-wrap">Email 3</td>
							<td v-html="$options.filters.formatEmail(row_co.co_email3)"></td>
						</tr>

						<tr v-if="row_co.co_tel1">
							<td class="text-no-wrap">Téléphone 1</td>
							<td v-html="$options.filters.formatTel(row_co.co_tel1)"></td>
						</tr>
						<tr v-if="row_co.co_tel2">
							<td class="text-no-wrap">Téléphone 2</td>
							<td v-html="$options.filters.formatTel(row_co.co_tel2)"></td>
						</tr>
						<tr v-if="row_co.co_tel3">
							<td class="text-no-wrap">Téléphone 3</td>
							<td v-html="$options.filters.formatTel(row_co.co_tel3)"></td>
						</tr>

						<tr v-if="row_co.co_mobile1">
							<td class="text-no-wrap">Mobile 1</td>
							<td v-html="$options.filters.formatTel(row_co.co_mobile1)"></td>
						</tr>
						<tr v-if="row_co.co_mobile2">
							<td class="text-no-wrap">Mobile 2</td>
							<td v-html="$options.filters.formatTel(row_co.co_mobile2)"></td>
						</tr>
						<tr v-if="row_co.co_mobile3">
							<td class="text-no-wrap">Mobile 3</td>
							<td v-html="$options.filters.formatTel(row_co.co_mobile3)"></td>
						</tr>

						<tr v-if="row_co.co_web1">
							<td class="text-no-wrap">Site web 1</td>
							<td v-html="$options.filters.formatWeb(row_co.co_web1)"></td>
						</tr>
						<tr v-if="row_co.co_web2">
							<td class="text-no-wrap">Site web 2</td>
							<td v-html="$options.filters.formatWeb(row_co.co_web2)"></td>
						</tr>
						<tr v-if="row_co.co_web3">
							<td class="text-no-wrap">Site web 3</td>
							<td v-html="$options.filters.formatWeb(row_co.co_web3)"></td>
						</tr>

						<tr v-if="row_co.co_fax1">
							<td class="text-no-wrap">Fax 1</td>
							<td v-html="$options.filters.formatTel(row_co.co_fax1)"></td>
						</tr>
						<tr v-if="row_co.co_fax2">
							<td class="text-no-wrap">Fax 2</td>
							<td v-html="$options.filters.formatTel(row_co.co_fax2)"></td>
						</tr>
						<tr v-if="row_co.co_fax3">
							<td class="text-no-wrap">Fax 3</td>
							<td v-html="$options.filters.formatTel(row_co.co_fax3)"></td>
						</tr>
					</table>
				</v-col>
				<v-col md4>
					<h2>Adresse</h2>
					<div v-html="$options.filters.formatContactAdress(row_co)"></div>
				</v-col>
				<v-col md4>
					<h2>Autres infos</h2>
					<h4 v-if="row_co.co_voeux" style="color: red">Voeux de fin d'année</h4>
					<div v-if="row_co.co_id_commercial" class>
						Négociateur :
						<a @click="gotoContact(row_co.co_id_commercial)">
							<span v-html="$options.filters.formatContactName(row_co.co_id_commercial)"></span>
						</a>
					</div>
					<div v-if="row_co.co_login" class>Identifiant : {{ row_co.co_login }}</div>
					<div v-if="row_co.co_comment" class>{{ row_co.co_comment }}</div>
					<div v-if="row_co.co_siret">SIRET : {{ row_co.co_siret }}</div>
					<div v-if="row_co.co_code">Code : {{ row_co.co_code }}</div>

					<div v-if="showFinancialBtn">
						<a @click="showFinancial = !showFinancial">Voir l'enveloppe financière</a>
					</div>
					<table v-if="showFinancial">
						<tr v-for="(financialpart, financialpartindex) in financialparts" :key="'financialparts' + financialpartindex">
							<td v-if="row_co[financialpart[0]]">{{ financialpart[1] }}</td>
							<td v-if="row_co[financialpart[0]]">
								{{ row_co[financialpart[0]] | formatPrice }}
							</td>
						</tr>
					</table>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div v-if="followerscontacts.length">
						Ce contact est suivi par ({{ followerscontacts.length }}) :
						<span v-for="(item, indexItem) in followerscontacts" :key="indexItem">
							<a @click="gotoContact(item)">
								<span v-html="$options.filters.formatContactName(item)"></span>
							</a>
							<span v-if="indexItem < followerscontacts.length - 1">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
						</span>
					</div>
					<div v-if="followscontacts.length" style="margin-top: 10px">
						Ce négociateur suit ({{ followscontacts.length }}) :
						<span v-for="(item, indexItem) in followscontacts" :key="indexItem">
							<a @click="gotoContact(item.co_id)">
								<span v-html="$options.filters.formatContactName(item.co_id)"></span>
							</a>
							<span v-if="indexItem < followscontacts.length - 1">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
						</span>
					</div>
					<div v-if="followsoffers.length" style="margin-top: 10px">
						Ce négociateur suit les biens ({{ followsoffers.length }}) :
						<span v-for="(item, indexItem) in followsoffers" :key="indexItem">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a @click="gotoOffer(item.of_id)" v-on="on">{{ item.of_id.of_ref }}</a>
								</template>
								<span>{{ item.of_id | formatOfferName }}</span>
							</v-tooltip>
							<span v-if="indexItem < followsoffers.length - 1">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
						</span>
					</div>
					<div v-if="commercialsoffersrent.length" style="margin-top: 10px">
						Chef des produits à la location ({{ commercialsoffersrent.length }}) :
						<span v-for="(item, indexItem) in commercialsoffersrent" :key="indexItem">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a @click="gotoOffer(item)" v-on="on">{{ item.of_ref }}</a>
								</template>
								<span>{{ item | formatOfferName }}</span>
							</v-tooltip>
							<span v-if="indexItem < commercialsoffersrent.length - 1">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
						</span>
					</div>
					<div v-if="commercialsofferssell.length" style="margin-top: 10px">
						Chef des produits à la vente ({{ commercialsofferssell.length }}) :
						<span v-for="(item, indexItem) in commercialsofferssell" :key="indexItem">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a @click="gotoOffer(item)" v-on="on">{{ item.of_ref }}</a>
								</template>
								<span>{{ item | formatOfferName }}</span>
							</v-tooltip>
							<span v-if="indexItem < commercialsofferssell.length - 1">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
						</span>
					</div>
				</v-col>
			</v-row>

			<v-card class="card5 mt-3" v-if="row_co.co_lab0">
				<v-card-title>
					<h1>
						Déclaration
						<span class="bis">LAB</span>
					</h1>
				</v-card-title>
				<v-card-text>
					<div style="border: 1px solid black; padding: 15px">
						<h2>Cadre négociateur</h2>
						<div>Déclaré aux juristes : {{ row_co.co_lab1 | formatYesNo }}</div>
					</div>

					<div style="border: 1px solid black; padding: 15px; margin-top: 15px">
						<h2>Cadre juriste</h2>
						<v-layout>
							<div class="mr-4">
								<table>
									<tr>
										<td>Date début relation d'affaire</td>
										<td>{{ row_co.co_lab4 | formatDate }}</td>
									</tr>
									<tr>
										<td>Date début du contrôle interne</td>
										<td>{{ row_co.co_lab5 | formatDate }}</td>
									</tr>
									<tr>
										<td>Date fin du contrôle interne</td>
										<td>{{ row_co.co_lab6 | formatDate }}</td>
									</tr>
									<tr>
										<td>Pièces manquantes</td>
										<td>{{ row_co.co_lab18 | formatYesNo }}</td>
									</tr>
									<tr>
										<td>Déclarer au responsable</td>
										<td>{{ row_co.co_lab2 }}</td>
									</tr>
								</table>
							</div>
							<div class="mr-4">
								<table>
									<tr>
										<td>Etablissement bancaire</td>
										<td>{{ row_co.co_lab7 }}</td>
									</tr>
									<tr>
										<td>Notaire</td>
										<td>{{ row_co.co_lab8 }}</td>
									</tr>
									<tr>
										<td>Conseil juridique</td>
										<td>{{ row_co.co_lab9 }}</td>
									</tr>
								</table>
							</div>
							<div class="mr-4">
								<table>
									<tr>
										<td>Représentant</td>
										<td>{{ row_co.co_lab12 | formatContactName }}</td>
									</tr>
									<tr>
										<td>Bénéficiaire entreprise</td>
										<td>{{ row_co.co_lab13 | formatContactName }}</td>
									</tr>
									<tr>
										<td>Profil à risque</td>
										<td>{{ row_co.co_lab11 | formatYesNo }}</td>
									</tr>
									<tr>
										<td>Libre</td>
										<td>{{ row_co.co_lab10 }}</td>
									</tr>
								</table>
							</div>
						</v-layout>
					</div>

					<div style="border: 1px solid black; padding: 15px; margin-top: 15px">
						<h2>Cadre responsable</h2>
						<div>
							Statut actuel :
							{{ row_co.co_lab3 | formatFromArray($store.state.items_co_lab3) }}
						</div>
					</div>
				</v-card-text>
			</v-card>

			<div v-if="searches && searches.length" class="mt-3">
				<hr class="mb-3" />
				<h2>
					Ses
					<span class="bis">
						<span>{{ nbtotalcorrelations }}</span> corrélations
					</span>
					<small>
						(Pour
						<span>{{ searches.length }}</span> recherches)
					</small>
					<span class="withpointer" @click="$router.push('/offers')">
						<v-icon>mdi-plus</v-icon>
					</span>
				</h2>
				<div class="formlittle">
					<v-switch
						v-model="filterimmotools"
						:name="$Utils.randomstring('filterimmotools')"
						label="Voir les biens Immotools"
						color="#53bd7b"
						inset
						:light="true"
						dense
						@change="switchFilterImmotools"
					></v-switch>
				</div>
				<div v-for="(search, indexSearch) in searches" :key="indexSearch">
					<h3>
						<span v-html="$options.filters.formatSearchName(search.se_filters, search.se_comment)"></span>
						<span class="withpointer" @click="goWithFilter(search)">
							<v-icon>mdi-magnify</v-icon>
						</span>
						<span class="withpointer" @click="deleteSearch(search)">
							<v-icon>mdi-trash-can</v-icon>
						</span>
					</h3>
					<div v-if="!search.showCorrelations">
						<a @click="goShowCorrelation(search)">Voir les {{ search.corr.length }} corrélations</a>
					</div>
					<div v-else>
						<a @click="goShowCorrelation(search)">Masquer les {{ search.corr.length }} corrélations</a>
						<offers-list
							:short="true"
							:offers="search.corr"
							:filter="filter"
							:limit="offers_limit"
							:skip="offers_skip"
							:total="offers_total"
							:current="offers_current"
							:contact-selected="row_co"
							@changerange="changeOffersRange"
							@actionWinEditSaved="loadContact"
						></offers-list>
					</div>
				</div>
			</div>
			<hr class="mt-3" />

			<div v-if="row_co.co_type && row_co.co_type != 'contact'">
				<div v-for="(actionstab, actionstabindex) in usertabs" :key="'actionscontactstabs' + actionstabindex">
					<div class="mt-3">
						<!-- <h2
              v-html="
              $options.filters.plural(
                actionstab[4],
                '<span class=\'bis\'>' + actionstab[1] + '</span>',
                '<span class=\'bis\'>' + actionstab[2] + '</span>'
              )
            "
            ></h2>-->
						<div :id="actionstab[0]"></div>
						<card-table-actions2
							:label-singular="actionstab[1]"
							:label-plural="actionstab[2]"
							actions-maintype="user"
							:actions-type="actionstab[3]"
							:actions-nb-max="actionsNbMax"
							:action-id="row_co.co_id"
							:header="false"
							:offerShortName="true"
							:colcontact="row_co.co_type != 'contact'"
							:colagent="row_co.co_type == 'contact'"
							:coldone="false"
						></card-table-actions2>
					</div>
				</div>
			</div>
			<div v-if="row_co.co_type == 'contact'">
				<div v-for="(actionstab, actionstabindex) in contacttabs" :key="'actionscontactstabs' + actionstabindex">
					<div class="mt-3">
						<!-- <h2
              v-html="
              $options.filters.plural(
                actionstab[4],
                '<span class=\'bis\'>' + actionstab[1] + '</span>',
                '<span class=\'bis\'>' + actionstab[2] + '</span>'
              )
            "
            ></h2>-->
						<div :id="actionstab[0]"></div>
						<card-table-actions2
							:label-singular="actionstab[1]"
							:label-plural="actionstab[2]"
							actions-maintype="contact"
							:actions-type="actionstab[3]"
							:actions-nb-max="actionsNbMax"
							:action-id="row_co.co_id"
							:header="false"
							:offerShortName="true"
							:colcontact="row_co.co_type != 'contact'"
							:colagent="row_co.co_type == 'contact'"
							:coldone="false"
							:notclickable="notclickable"
							@total="actionstab[4] = $event"
						></card-table-actions2>
					</div>
				</div>
			</div>

			<div v-if="row_co.nbProperties > 0" id="properties">
				<h2
					v-html="
						$options.filters.plural(
							row_co.nbProperties,
							'<span class=\'bis\'> Bien propriétaire</span>',
							'<span class=\'bis\'> Biens propriétaire</span>'
						)
					"
				></h2>
				<div v-for="row_of in row_co.properties" :key="row_of.of_id" class="card-table-propertie">
					<icon class="actionwinedit_icon mr-2" name="home"></icon>
					<a @click="$router.push('/offers/' + row_of.of_id + '/details')">
						<span v-html="$options.filters.formatOfferName(row_of, true, false, 'fr')"></span>
					</a>
					<div v-if="row_of.bu_id && row_of.bu_id.bu_id">Immeuble : {{ row_of.bu_id.bu_name }}</div>
					<div v-if="row_of.co_id_commercial1 && row_of.co_id_commercial1.co_id">
						Commercial : {{ row_of.co_id_commercial1 | formatContactName }}
					</div>
				</div>
			</div>

			<hr class="mt-3 mb-3" />
			<div class="" style="font-size: 0.6rem; line-height: 0.6rem">
				ID : {{ row_co.co_id }}<br />
				Créé le {{ row_co.createdAt | formatDateTime }} par {{ row_co.co_id_created | formatContactName }}<br />
				Modifié le {{ row_co.updatedAt | formatDateTime }} par
				{{ row_co.co_id_updated | formatContactName }}
			</div>
		</v-flex>
		<contact-win-edit
			ref="ContactWinEdit"
			@contactWinEditSaved="contactWinEditSaved"
			@contactWinEditCanceled="contactWinEditCanceled"
			@contactWinEditDeleted="contactWinEditDeleted"
		></contact-win-edit>
		<v-snackbar v-model="messageSuivi" top :timeout="5000">
			Ce contact est déjà suivi par
			{{ this.$store.state.preferences.shared.nbMaxSuiviContact }} personnes, vous ne pouvez pas le suivre également
			<v-btn color="pink" text @click="messageSuivi = false">Fermé</v-btn>
		</v-snackbar>
		<action-win-edit ref="actionwinedit"></action-win-edit>
		<m-confirm-dialog
			v-model="confirmDeleteSearchDialog"
			text="Souhaitez-vous effacer cette recherche ?"
			title="Confirmation"
			@canceled="confirmDeleteSearchDialog = false"
			@confirmed="deleteSearchOk()"
		></m-confirm-dialog>
	</v-layout>
</template>

<script>
import OffersList from "../views/OffersList";

export default {
	name: "contactdetails",
	components: { "offers-list": OffersList },
	props: {
		value: String,
		short: Boolean,
		notclickable: {
			default: false,
			type: Boolean
		}
	},
	data() {
		let actionsShowMore = [];
		for (let i = 0; i < 50; i++) {
			actionsShowMore["chk" + i] = false;
		}
		return {
			searches: [],
			filterimmotools: false,
			offers: [],
			offers_skip: 0,
			offers_total: 0,
			offers_limit: 25,
			offers_current: "",
			offer_current: "",
			filter: {},

			tabShowCorrelations: [],
			// contactsassociated: [],
			confirmDeleteSearchDialog: false,
			actionsNbMax: 5,
			showFinancialBtn: true,
			showFinancial: false,
			actionsShowMore: actionsShowMore,
			filterfrom: this.$moment()
				.add(-1, "month")
				.format("YYYY-MM-DD"),
			filterto: this.$moment().format("YYYY-MM-DD"),
			// row_co: this.value ? this.value : this.defaultContact(),
			row_co: this.defaultContact(),
			messageSuivi: false,
			clientsuivi: false,
			followerscontacts: [],
			followscontacts: [],
			followsoffers: [],
			commercialsoffersrent: [],
			commercialsofferssell: [],

			financialparts: [
				["co_annualincomes", "Ressources : Revenu / mois"],
				["co_familybenefits", "Ressources : Allocations familliales / mois"],
				["co_realincomes", "Ressources : Revenus immobiliers / mois"],
				["co_othersincomes", "Ressources : Autres revenus / mois"],
				["co_alimonies", "Charges : Pensions alimentaires / mois"],
				["co_consumercredit", "Charges : Crédits consommation / mois"],
				["co_automobilecredit", "Charges : Crédits automobile / mois"],
				["co_otherscredit", "Charges : Autres crédits / mois"],
				["co_ratedebt", "Résultat : Taux endettement maxi (%)"],
				["co_rateloan", "Résultat : Taux du prêt (%)"],
				["co_durationloan", "Résultat : Durée du prêt (années)"],
				["co_financialallocation", "Résultat : Enveloppe financière"]
			],

			usertabs: [
				["calls_contact", "Action téléphonique", "Actions téléphonique", "call", 0],
				["todos_contact", "A faire", "A faire", "todo", 0],
				["comments_contact", "Commentaire", "Commentaires", "comment", 0],
				["signatures_rent_contact", "Signature", "Signatures", "signature", 0],
				["emails_contact", "Emails", "Emails", "email", 0],
				["presents_contact", "Bien présenté", "Biens présentés", "present", 0],
				["visits_contact", "Bien visité", "Biens visités", "visit", 0],
				["reports_contact", "Compte-rendu", "Comptes-rendus", "report", 0],
				["notinteresteds_contact", "Pas intéressé", "Pas intéressés", "notinterested", 0]
			],
			contacttabs: [
				["calls_user", "Action téléphonique", "Actions téléphonique", "call", 0],
				["todos_user", "A faire", "A faire", "todo", 0],
				["signatures_sell_user", "Signature", "Signatures", "signature", 0],
				["emails_user", "Email", "Emails", "email", 0],
				["presents_user", "Bien présenté", "Biens présentés", "present", 0],
				["visits_user", "Bien visité", "Biens visités", "visit", 0],
				["reports_user", "Compte-rendu", "Comptes-rendus", "report", 0],
				["notinteresteds_contact", "Pas intéressé", "Pas intéressés", "notinterested", 0]
			]
		};
	},
	computed: {
		nbtotalcorrelations() {
			let nbtotalcorrelations = 0;
			for (let iSe = 0; iSe < this.searches.length; iSe++) {
				const search = this.searches[iSe];
				for (let iCorr = 0; iCorr < search.correlations.length; iCorr++) {
					nbtotalcorrelations++;
				}
			}
			return nbtotalcorrelations;
		},
		reload_actions() {
			return this.$store.state.reload_actions;
		},
		isEditable() {
			if (this.row_co && this.row_co.co_id == this.$store.state.user.co_id) return true;
			if (this.$store.state.preferences.rights.contact_edit_all && this.row_co && this.row_co.co_type == "contact") return true;
			if (this.$store.state.preferences.rights.contact_edit_users && this.row_co && this.row_co.co_type != "contact") return true;
			return false;
		},
		srcAvatar() {
			// console.log("srcAvatar", this.row_co.co_id);
			// window.setTimeout(() => {
			//   this.$forceUpdate();
			// }, 200);
			return (
				this.$config.server_url +
				"/api/1.0/avatar/" +
				this.row_co.co_id +
				"/180/180?d=" +
				this.row_co.updatedAt +
				"&token=" +
				this.$store.state.accesstoken
			);
		},
		co_type_text() {
			// console.log('this.$store.state.user.co_type', this.$store.state.user.co_type);
			return this.$_.result(
				this.$_.find(this.$store.state.items_co_type, {
					value: this.row_co.co_type
				}),
				"text",
				"N/A"
			);
		},
		revenueSell() {
			let val = 0;
			this.$_.each(this.row_co.signatures_sell_user, row_ac => {
				val += row_ac.ac_signature_sum * 1;
			});
			return this.$options.filters.formatPrice(val);
		},
		revenueRent() {
			let val = 0;
			this.$_.each(this.row_co.signatures_rent_user, row_ac => {
				val += row_ac.ac_signature_sum * 1;
			});
			return this.$options.filters.formatPrice(val);
		}
		// clientsuiviLabel: function() {
		//   if (this.clientsuivi) return "Contact suivi";
		//   else return "Contact non-suivi ";
		// }
	},
	mounted() {
		if (this.value) {
			this.loadContact();
		}
	},
	created() {},
	watch: {
		// clientsuivi(val) {
		// },
		async reload_actions() {
			await this.loadContact();
		},
		filterfrom(val) {
			// this.$parent.$parent.setCurrent(this.row_co.co_id);
			this.loadContact();
		},
		filterto(val) {
			// this.$parent.$parent.setCurrent(this.row_co.co_id);
			this.loadContact();
		},
		value(val) {
			// this.row_co = val ? val : this.defaultContact();
			this.loadContact();
		}
	},
	methods: {
		toTop(dest) {
			// console.log("dest", dest);
			let rect = document.getElementById(dest).getBoundingClientRect();
			// console.log("rect", rect);

			var scrollOptions = {
				left: 0,
				top: rect.top - 64,
				behavior: "smooth"
			};
			document.getElementById("container_contactdetail_scroll").scrollTo(scrollOptions);

			/* 	console.log("document.getElementById(dest)", document.getElementById(dest));
			if (document.getElementById(dest)) {
				let options = { duration: 500 };
				this.$vuetify.goTo("#" + dest, options);
			} */
		},
		switchFilterImmotools() {
			this._setSearchCorr();
			// this.$forceUpdate();
		},
		_setSearchCorr() {
			for (let iSe = 0; iSe < this.searches.length; iSe++) {
				const search = this.searches[iSe];
				let corr = [];
				for (let iCorr = 0; iCorr < search.correlations.length; iCorr++) {
					const row_of = search.correlations[iCorr].of_id;
					row_of.mListTrClass = "";
					if (this.$_.trim(row_of.of_agency_txt) !== this.$store.state.preferences.shared.societyName)
						row_of.mListTrClass = "offers-bg-otheragency";
					if (row_of.of_confidential) row_of.mListTrClass = "offers-bg-confidential";
					if (this.filterimmotools) corr.push(row_of);
					else if (!row_of.of_immotools) corr.push(row_of);
				}
				search.corr = corr;
			}
		},
		changeOffersRange(skip, scrollTop) {
			// this.$store.commit("set_offersscrolltop", scrollTop);
			// this.$store.commit("set_offersskip", skip);
			// console.log("scrollTop", scrollTop);
			// console.log("ici1");
			// this.getOffers(this.filter, skip);
		},
		goShowCorrelation(search) {
			search.showCorrelations = !search.showCorrelations;
			if (search.showCorrelations) {
				if (this.tabShowCorrelations.indexOf(search.se_id) < 0) this.tabShowCorrelations.push(search.se_id);
			} else {
				this.tabShowCorrelations = this.tabShowCorrelations.filter(v => v != search.se_id);
			}
			// console.log("this.tabShowCorrelations", this.tabShowCorrelations);
		},
		async switchCorrelation(of_id) {
			// console.log("of_id", of_id);
			await this.$axios.post(this.$config.server_url + "/api/1.0/actions", {
				ac_type: "present",
				co_id_contact: this.row_co.co_id,
				co_id_user: this.$store.getters.get_user.co_id,
				co_id_user_todo: this.$store.getters.get_user.co_id,
				ac_date: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
				of_id: of_id,
				// ac_offers: [of_id],
				ac_done: true,
				ac_comment: "Présenté via une correlation"
			});
			await this.loadContact();
		},

		defaultContact() {
			return {
				// properties: [],

				contactsassociated: [],
				searches: [],

				newcontact_user: [],
				newoffer_user: [],
				actions_todo_user: [],
				calls_user: [],
				calls_contact: [],
				todos_user: [],
				todos_contact: [],
				signatures_sell_user: [],
				signatures_sell_contact: [],
				signatures_rent_user: [],
				signatures_rent_contact: [],
				emails_user: [],
				emails_contact: [],
				presents_user: [],
				presents_contact: [],
				notinteresteds_user: [],
				notinteresteds_contact: [],
				visits_user: [],
				visits_contact: [],
				reports_user: [],
				reports_contact: [],
				reports_tocomplete: []
			};
		},
		clickShowMore(actionstabindex) {
			this.actionsShowMore["chk" + actionstabindex] = !this.actionsShowMore["chk" + actionstabindex];
			this.$forceUpdate();
		},
		openWinAction(ac_id) {
			if (this.$_.isPlainObject(ac_id)) ac_id = ac_id.ac_id;
			this.$refs.actionwinedit.open(ac_id);
		},
		gotoContact(row_co) {
			this.$router.push("/contacts/" + row_co.co_id + "/details");
			this.$emit("input", row_co.co_id);
			// if (this.$parent.$parent.setCurrent)
			//   this.$parent.$parent.setCurrent(row_co.co_id);
		},
		gotoOffer(row) {
			// console.log('row', row);
			let of_id = row.of_id;
			if (this.$_.isPlainObject(of_id)) of_id = row.of_id.of_id;
			this.$router.push("/offers/" + of_id + "/details");
		},
		async onChangeSuivi(v) {
			if (
				parseInt(this.$store.state.preferences.shared.nbMaxSuiviContact) === 0 ||
				this.followerscontacts.length < parseInt(this.$store.state.preferences.shared.nbMaxSuiviContact)
			) {
				await this.$axios.post(
					this.$config.server_url + "/api/1.0/followers/contact/" + this.row_co.co_id + "/user/" + this.$store.getters.get_user.co_id,
					{ params: { suivi: v } }
				);
			} else {
				this.messageSuivi = true;
			}
			this.$emit("suiviChanged");
			this.loadSuivi();
			this.$store.dispatch("load_nbclientsuivi");
		},
		async loadSuivi() {
			let co_id = this.value;
			// let user = this.$store.getters.get_user;
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/followers/contact/" + co_id);
			// console.log('response.data', response.data);
			// affichage du switch contact suivi ou non par l'utilisateur connecté
			let ok = false;
			// let historySuiviContact = [];
			for (let iAgents = 0; iAgents < response.data.followerscontacts.length; iAgents++) {
				const row_co_agent = response.data.followerscontacts[iAgents];
				if (row_co_agent.co_id_user && row_co_agent.co_id_user.co_id === this.$store.state.user.co_id) ok = true;
				// historySuiviContact.push(row_co_agent);
			}
			// if (historySuiviContact.length) {
			//   ok = historySuiviContact[historySuiviContact.length - 1].fo_state;
			// }
			this.clientsuivi = ok;

			// affichage des utilisateurs qui suivent ce contact
			// let tabSuivi = [];
			// for (
			//   let iAgents = 0;
			//   iAgents < response.data.followerscontacts.length;
			//   iAgents++
			// ) {
			//   const row_co_agent = response.data.followerscontacts[iAgents];
			//   if (
			//     this.$_.findIndex(tabSuivi, {
			//       co_id_user: row_co_agent.co_id_user.co_id
			//     }) >= 0
			//   ) {
			//     let index = this.$_.findIndex(tabSuivi, {
			//       co_id_user: row_co_agent.co_id_user.co_id
			//     });
			//     tabSuivi[index].row_follows.push(row_co_agent);
			//   } else {
			//     tabSuivi.push({
			//       co_id_user: row_co_agent.co_id_user.co_id,
			//       user: row_co_agent.co_id_user,
			//       row_follows: [row_co_agent]
			//     });
			//   }
			// }
			// let whoSuiviContact = [];
			// for (let s = 0; s < tabSuivi.length; s++) {
			//   const suivi = tabSuivi[s];
			//   if (suivi.row_follows[suivi.row_follows.length - 1].fo_state === true) {
			//     whoSuiviContact.push(suivi.user);
			//   }
			// }
			// @Marina : je ne comprends pas pourquoi tu re-vérifié le fo_state alors que c déjà vérifié coté serveur
			let whoSuiviContact = [];
			for (let iAgents = 0; iAgents < response.data.followerscontacts.length; iAgents++) {
				const row_co_agent = response.data.followerscontacts[iAgents];
				whoSuiviContact.push(row_co_agent.co_id_user);
			}

			this.followerscontacts = whoSuiviContact;

			this.followscontacts = response.data.followscontacts;
			this.followsoffers = response.data.followsoffers;
			this.commercialsofferssell = response.data.commercialsofferssell;
			this.commercialsoffersrent = response.data.commercialsoffersrent;
			this.$emit("contactDetailsLoaded", this.row_co);
		},
		async loadContact() {
			this.showFinancialBtn = false;
			this.showFinancial = false;
			for (let i = 0; i < 50; i++) {
				this.actionsShowMore["chk" + i] = false;
			}
			if (!this.value) {
				this.row_co = this.defaultContact();
				this.$emit("contactDetailsLoaded", null);
				return;
			}
			let params = {};
			if (this.filterfrom) params.filterfrom = this.filterfrom;
			if (this.filterto) params.filterto = this.filterto;
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + this.value, { params });

			response.data.data.actions_todo_user = this.$Utils.concatActionsUser(response.data.data).filter(item => {
				return !item.ac_done;
			});
			this.row_co = response.data.data;
			// this._setSearchCorr();

			// console.log("response.data.data co", response.data.data);
			for (let iFinanciel = 0; iFinanciel < this.financialparts.length; iFinanciel++) {
				if (this.row_co[this.financialparts[iFinanciel][0]]) this.showFinancialBtn = true;
			}

			// @Marina : impossible de charger tous les contacts !!! Pour Miells par exemple, il y en a plus de 5000.
			// à la place il suffit juste de rajouter dans le chargement d'un contact, les contacts associés à la société
			//load associated contacts
			// this.contactsassociated = [];
			// let params2 = { gr_id: "all" };
			// let response2 = await this.$axios.get(
			//   this.$config.server_url + "/api/1.0/contacts",
			//   { params2 }
			// );
			// for (let ico = 0; ico < response2.data.data.length; ico++) {
			//   const row_co = response2.data.data[ico];
			//   if (
			//     row_co.co_id_society &&
			//     row_co.co_id_society.co_id === this.row_co.co_id
			//   ) {
			//     this.contactsassociated.push(row_co);
			//   }
			// }

			this.loadSuivi();
			this.loadCorrelations();
			this.$emit("contactDetailsLoaded", this.row_co.co_id);

			// this.loadSuivi(response.data.data.co_id);
			// this.$router.push("/contacts/" + this.contact.co_id + "/details");var text = 'Making the Web a Better Place Making the Web';
			this.$nextTick(() => {
				if (this.row_co.co_avatar) return;
				var text = "";
				// @Marina : le défaut est que s'il y a un prénom et pas de nom çà déconne
				// text =
				//   this.row_co.co_name.slice(0, 1) +
				//   this.row_co.co_firstname.slice(0, 1);
				// if (text.length < 2) text = this.row_co.co_name.slice(0, 2);
				if (this.row_co.co_name && this.row_co.co_firstname) text = this.row_co.co_name.slice(0, 1) + this.row_co.co_firstname.slice(0, 1);
				else if (this.row_co.co_name) text = this.row_co.co_name.slice(0, 2);
				else if (this.row_co.co_firstname) text = this.row_co.co_firstname.slice(0, 2);
				else text = "--";
				if (this.$refs.noavatar) this.$refs.noavatar.innerHTML = text;
			});
		},
		goWithFilter(search) {
			this.$router.push("/offers/" + search.se_id + "/search");
		},
		deleteSearch(row_se) {
			this.searchToDelete = row_se.se_id;
			this.confirmDeleteSearchDialog = true;
			// console.log("coucou");
		},
		async loadCorrelations() {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/searches/contact/" + this.row_co.co_id);
			// console.log("response.data.data", response.data.data);
			this.searches = response.data.data;
			this._setSearchCorr();
			for (let iTabShowCorrelation = 0; iTabShowCorrelation < this.tabShowCorrelations.length; iTabShowCorrelation++) {
				const se_id = this.tabShowCorrelations[iTabShowCorrelation];
				for (let iSearch = 0; iSearch < this.searches.length; iSearch++) {
					const row_se = this.searches[iSearch];
					if (row_se.se_id == se_id) row_se.showCorrelations = true;
				}
			}
		},
		async deleteSearchOk() {
			await this.$axios.delete(this.$config.server_url + "/api/1.0/searches/" + this.searchToDelete);
			this.loadContact();
			this.confirmDeleteSearchDialog = false;
		},
		openWinEdit(co_id) {
			if (!co_id) co_id = this.value;
			// return console.log("this.value", this.value);
			this.$refs.ContactWinEdit.open(co_id);
			this.$emit("contactWinEditOpen", co_id);
		},

		// cancelWin() {
		//   this.dialog = false;
		// },
		// async saveWin() {
		//   await this.$axios.put(
		//     this.$config.server_url + "/api/1.0/visits/" + this.row_vi.vi_id,
		//     this.row_vi
		//   );
		//   this.dialog = false;
		// },
		contactWinEditSaved(co_id) {
			this.loadContact();
			this.$emit("contactWinEditSaved", co_id);
			// this.$emit("change", co_id);
		},
		contactWinEditCanceled(co_id) {
			this.$emit("contactWinEditCanceled", co_id);
		},
		contactWinEditDeleted(co_id) {
			this.$store.dispatch("load_nbclientsuivi");
			this.$emit("contactWinEditDeleted", co_id);
			// this.$emit("change", co_id);
		}
	}
};
</script>

<style scoped lang="scss">
@import "../sass/variables.scss";
// .container-header-detail-contact {
//   justify-content: space-between;
// }
.h-100 {
	height: 100%;
}
.tableinfos {
	td {
		padding-right: 20px;
	}
}
.client-suivi {
	background-color: #8dc38d;
}
.client-nonsuivi {
	background-color: #c5c5c5;
}
.client {
	color: #fff;
	padding: 8px;
	border-radius: 20px;
}
.card3 {
	.text-md-center {
		line-height: 1rem;
	}
	h1 {
		margin: 5px 0 0 0;
	}
}
.container-no-avatar {
	width: 120px;
	height: 120px;
	display: flex;
	position: relative;
	background-color: $mygris;
	.no-avatar {
		width: 100px;
		height: 100px;
		position: absolute;
		top: 10px;
		left: 10px;

		display: flex;
		color: #c5c5c5;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		font-size: 4rem;
		overflow: hidden;
	}
	&.short {
		width: 90px;
		height: 90px;
		.no-avatar {
			font-size: 2rem;
			top: 0;
			left: 0;
			width: 90px;
			height: 90px;
		}
	}
}

.card-table-propertie {
	padding: 5px 10px;
	margin-bottom: 10px;
	&:nth-child(odd) {
		background-color: $mygris-clair;
	}
}
#inspire h2.h2nomargin {
	margin: 0;
	line-height: 1.2rem;
}
</style>
