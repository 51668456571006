<template>
	<v-dialog v-model="dialog" persistent :max-width="width ? width : '290'">
		<v-card>
			<v-card-title class="headline">
				<v-layout>
					<v-icon large color="warning2">mdi-alert</v-icon>
					<h1 class="pt-2 ml-3">{{ title }}</h1>
				</v-layout>
			</v-card-title>
			<v-card-text>
				<h3>{{ text }}</h3>
				<p>{{ sousText ? sousText : "" }}</p>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin">Annuler</v-btn>
				<v-btn color="warning2" text @click="deleteWin">Ok</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "mconfirmdialog",
	props: ["value", "title", "text", "sousText", "width"],
	data() {
		return {
			dialog: false
		};
	},
	mounted() {},
	watch: {
		value: function (val) {
			this.dialog = val;
		}
	},
	components: {},
	methods: {
		cancelWin() {
			this.$emit("canceled");
		},
		deleteWin() {
			this.$emit("confirmed");
		}
	}
};
</script>
