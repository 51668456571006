<template>
	<div>
		<div v-if="isDayPrint" class="container-events">
			<h1>{{ currentDateTxt }}</h1>
			<div class="mb-5">
				<div v-for="row_ve in rows_ve" :key="row_ve.ve_id" class="mt-5">
					<h2>{{ row_ve.ve_dtstart | formatFromTo(row_ve.ve_dtend) }}</h2>
					<div v-if="row_ve.ve_summary">
						<b>Commentaire :</b>
						{{ row_ve.ve_summary }}
					</div>
					<div v-if="row_ve.co_id_contact" class="mt-2">
						<div>
							<b>Contact :</b>
							{{ row_ve.co_id_contact | formatContactName }}
						</div>
						<table class="tableinfos">
							<tr v-if="row_ve.co_id_contact.co_email1">
								<td class="text-no-wrap">Email 1</td>
								<td v-html="$options.filters.formatEmail(row_ve.co_id_contact.co_email1)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_email2">
								<td class="text-no-wrap">Email 2</td>
								<td v-html="$options.filters.formatEmail(row_ve.co_id_contact.co_email2)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_email3">
								<td class="text-no-wrap">Email 3</td>
								<td v-html="$options.filters.formatEmail(row_ve.co_id_contact.co_email3)"></td>
							</tr>

							<tr v-if="row_ve.co_id_contact.co_tel1">
								<td class="text-no-wrap">Téléphone 1</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_tel1)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_tel2">
								<td class="text-no-wrap">Téléphone 2</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_tel2)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_tel3">
								<td class="text-no-wrap">Téléphone 3</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_tel3)"></td>
							</tr>

							<tr v-if="row_ve.co_id_contact.co_mobile1">
								<td class="text-no-wrap">Mobile 1</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_mobile1)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_mobile2">
								<td class="text-no-wrap">Mobile 2</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_mobile2)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_mobile3">
								<td class="text-no-wrap">Mobile 3</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_mobile3)"></td>
							</tr>

							<tr v-if="row_ve.co_id_contact.co_web1">
								<td class="text-no-wrap">Site web 1</td>
								<td v-html="$options.filters.formatWeb(row_ve.co_id_contact.co_web1)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_web2">
								<td class="text-no-wrap">Site web 2</td>
								<td v-html="$options.filters.formatWeb(row_ve.co_id_contact.co_web2)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_web3">
								<td class="text-no-wrap">Site web 3</td>
								<td v-html="$options.filters.formatWeb(row_ve.co_id_contact.co_web3)"></td>
							</tr>

							<tr v-if="row_ve.co_id_contact.co_fax1">
								<td class="text-no-wrap">Fax 1</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_fax1)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_fax2">
								<td class="text-no-wrap">Fax 2</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_fax2)"></td>
							</tr>
							<tr v-if="row_ve.co_id_contact.co_fax3">
								<td class="text-no-wrap">Fax 3</td>
								<td v-html="$options.filters.formatTel(row_ve.co_id_contact.co_fax3)"></td>
							</tr>
						</table>
					</div>
					<div v-if="row_ve.visits.length">
						<b>Bien(s) à visiter :</b>
						<ul>
							<div v-for="row_ac in row_ve.visits" :key="row_ac.ac_id">
								<li>{{ row_ac.of_id | formatOfferName }}</li>
							</div>
						</ul>
					</div>
					<hr />
				</div>
			</div>
		</div>
		<div v-for="row_of in rows_of" :key="row_of.of_id">
			<v-container class="container-fiche-agent">
				<v-flex :class="{ md12: !short, md12: short }">
					<v-layout>
						<v-flex md6 pr-0 pt-0 class="col1">
							<v-card class="mt-3" v-if="row_of.images.length">
								<v-card-text>
									<img
										:src="
											$config.server_url +
												'/api/1.0/images/' +
												row_of.images[0].fi_id +
												`/350/300?token=${$store.state.accesstoken}&d=${row_of.images[0].updatedAt}`
										"
										alt
										@load="imageLoaded(row_of.of_id)"
									/>
									<!-- <div
                class="hooperimage"
                :style="{ backgroundImage: `url(${$config.server_url}/api/1.0/images/${row_of_image.fi_id}/550/300)` }"
                  ></div>-->
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-title>
									<h1>
										Les
										<span class="bis">détails</span>
									</h1>
								</v-card-title>
								<v-card-text>
									<offer-frame-details :items_frame="$store.state.items_frame_details" :row_of="row_of" :lang="langSelected" />
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-title>
									<h1>
										Le
										<span class="bis">proprétaire</span> et le
										<span class="bis">mandat</span>
									</h1>
								</v-card-title>
								<v-card-text>
									<offer-frame-details :items_frame="$store.state.items_frame_mandat" :row_of="row_of" :lang="langSelected" />
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-title>
									<h1>
										Adresse
										<span class="bis">et quartier</span>
									</h1>
								</v-card-title>
								<v-card-text>
									<offer-frame-details
										:items_frame="$store.state.items_frame_building"
										:row_of="row_of.bu_id"
										:lang="langSelected"
									/>
								</v-card-text>
							</v-card>
						</v-flex>
						<v-flex md6 pt-0 class="col1">
							<v-card class="mt-3">
								<v-card-title>
									<h1>
										Description
										<span class="bis">du bien</span>
									</h1>
								</v-card-title>
								<v-card-text>
									<h2>{{ row_of | formatOfferName }}</h2>
									<p>{{ row_of.of_text_fr }}</p>
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-title>
									<h1>
										Statut et
										<span class="bis">visibilité</span>
									</h1>
								</v-card-title>
								<v-card-text>
									<offer-frame-details :items_frame="$store.state.items_frame_visibility" :row_of="row_of" :lang="langSelected" />
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-title>
									<h1>Informations commerciales... et consignes de visites</h1>
								</v-card-title>
								<v-card-text>
									<offer-frame-details
										:items_frame="$store.state.items_frame_commercial"
										:row_of="row_of"
										:forceshowprivate="true"
										:lang="langSelected"
									/>
								</v-card-text>
							</v-card>

							<v-card class="mt-3" v-if="row_of.of_sell">
								<v-card-title>
									<h1>
										A la
										<span class="bis">vente</span>
									</h1>
								</v-card-title>
								<v-card-text>
									<offer-frame-details :items_frame="$store.state.items_frame_tosell" :row_of="row_of" :lang="langSelected" />
								</v-card-text>
							</v-card>
							<v-card class="mt-3" v-if="row_of.of_rent">
								<v-card-title>
									<h1>
										A la
										<span class="bis">location</span>
									</h1>
								</v-card-title>
								<v-card-text>
									<offer-frame-details :items_frame="$store.state.items_frame_torent" :row_of="row_of" :lang="langSelected" />
								</v-card-text>
							</v-card>
							<!-- <v-card class="mt-3">
            <v-card-title>
              <h1>
                Avis des
                <span class="bis">clients</span>
              </h1>
            </v-card-title>
            <v-card-text>
              <canvas id="myChart" width="200" height="200" class="chart"></canvas>
            </v-card-text>
              </v-card>-->
						</v-flex>
					</v-layout>
				</v-flex>
			</v-container>
		</div>
	</div>
</template>

<script>
// import Chart from "chart.js";

export default {
	name: "offersdetailsprintagent",
	components: {},
	data() {
		return {
			rows_ve: [],
			isDayPrint: false,
			currentDate: "",
			rows_of: [],
			row_of_image: {},
			currentNumber: 0,
			langSelected: "",
			// myChart: null,
			// row_of: { presents: [], visits: [], properties: [], images: [] },
			data_done: false
		};
	},
	props: {
		value: Object,
		short: Boolean
	},
	watch: {
		// async data_done(val) {
		//   // console.log("val", val);
		//   if (val) {
		//     window.print();
		//     window.close();
		//   }
		// }
	},
	computed: {
		currentDateTxt() {
			return this.currentDate.format("dddd DD MMMM Y");
		}
	},
	async mounted() {
		this.langSelected = this.$route.query.lang ? this.$route.query.lang : "fr";
		this.$root.$children[0].hideMenus();
		let of_id = this.$route.params.id;
		if (this.value && this.value.of_id) of_id = this.value.of_id;

		var labels = [];
		this.$_.each(this.$store.state.items_feedbackstypes, val => {
			labels.push(val.text);
		});

		// console.log("this.$route", this.$route);
		if (this.$route.name == "offersdetailsprintdayagent") {
			this.currentDate = this.$moment(this.$route.params.date);
			this.isDayPrint = true;
		}

		// this.myChart = new Chart(
		//   document.getElementById("myChart").getContext("2d"),
		//   {
		//     type: "radar",
		//     options: {
		//       legend: {
		//         display: false
		//       },
		//       scale: {
		//         display: true
		//       }
		//     },
		//     data: {
		//       labels: labels,
		//       datasets: [
		//         {
		//           label: "",
		//           backgroundColor: "rgba(151,187,205,0.2)",
		//           borderColor: "rgba(151,187,205,1)",
		//           data: [50, 50, 50, 50, 50, 50, 50]
		//         },
		//         {
		//           label: "",
		//           backgroundColor: "rgba(220,220,220,0.0)",
		//           borderColor: "rgba(220,220,220,0.0)",
		//           data: [100, 100, 100, 100, 100, 100, 100]
		//         },
		//         {
		//           label: "",
		//           backgroundColor: "rgba(220,220,220,0.2)",
		//           borderColor: "rgba(220,220,220,1)",
		//           data: [50, 50, 50, 50, 50, 50, 50]
		//         },
		//         {
		//           label: "",
		//           backgroundColor: "rgba(220,220,220,0)",
		//           borderColor: "rgba(220,220,220,0)",
		//           data: [0, 0, 0, 0, 0, 0, 0]
		//         }
		//       ]
		//     }
		//   }
		// );
		await this.loadDatas(of_id);
	},
	methods: {
		imageLoaded(of_id) {
			let ok = true;
			for (let iOf = 0; iOf < this.rows_of.length; iOf++) {
				const row_of = this.rows_of[iOf];
				if (row_of.of_id == of_id) row_of.imageLoaded = true;
				if (!row_of.imageLoaded) ok = false;
			}
			/* if (ok && navigator.userAgent.toLowerCase().indexOf("safari") < 0) {
				// alert("toto");
				window.print();
				window.close();
			} */
			if (ok) {
				window.print();
				window.close();
			}
		},
		async loadDatas(of_id) {
			// this.sliderStop();
			let of_ids = of_id.split("|");
			let rows_of = [];
			for (let iOf = 0; iOf < of_ids.length; iOf++) {
				const of_id = of_ids[iOf];
				if (of_id == "nothing") continue;
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers/" + of_id);
				// this.row_of = response.data.data;
				response.data.data.imageLoaded = true;
				if (response.data.data.images && response.data.data.images.length) response.data.data.imageLoaded = false;
				rows_of.push(response.data.data);
			}

			if (this.isDayPrint) {
				let params = {
					from: this.currentDate.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
					to: this.currentDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
					// envoyer aussi un tableau avec le ou les agents
					users: [this.$store.state.user.co_id]
				};
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/events", { params });
				this.rows_ve = response.data.data;
				// console.log("response", response);
			}

			this.rows_of = rows_of;
			// if (this.rows_of.length == 0) {
			// this.$nextTick(() => {
			// 	window.print();
			// 	window.close();
			// });
			// }
			// alert("toto");
			setTimeout(() => {
				if (navigator.userAgent.toLowerCase().indexOf("safari") < 0) {
					window.print();
					window.close();
				}
			}, 1000);

			// let vals = {};
			// this.$_.each(this.row_of.visits, val => {
			//   if (val.vi_report) {
			//     this.$_.each(this.$store.state.items_feedbackstypes, fbtype => {
			//       if (!vals["type_" + fbtype.value])
			//         vals["type_" + fbtype.value] = [0, 0];
			//       this.$_.each(this.$store.state.items_feedbacks, fb => {
			//         if (fbtype.value == fb.type) {
			//           vals["type_" + fbtype.value][0] +=
			//             val.vi_report["feedback" + fb.value] * 1;
			//           vals["type_" + fbtype.value][1]++;
			//         }
			//       });
			//     });
			//   }
			// });
			// let data = [];
			// this.$_.each(this.$store.state.items_feedbackstypes, fbtype => {
			//   if (!vals["type_" + fbtype.value])
			//     vals["type_" + fbtype.value] = [0, 0];
			//   data.push(
			//     ((vals["type_" + fbtype.value][0] + vals["type_" + fbtype.value][1]) /
			//       (vals["type_" + fbtype.value][1] * 2)) *
			//       100
			//   );
			// });
			// this.myChart.data.datasets[0].data = data;
			// await this.myChart.update();

			// if (!this.row_of.images.length) {
			//   setTimeout(() => {
			//     this.data_done = true;
			//   }, 100);
			// }
		}
	}
};
</script>

<style lang="scss" scoped>
.hooperimage {
	background-size: contain;
	height: 300px;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #aaaaaa;
}
.v-application ul.hooper-track {
	padding-left: 0;
}
.container-fiche-agent {
	width: 100%;
	// height: 100%;
	page-break-after: always;
}
.container-events {
	padding: 1cm 2cm;
	page-break-after: always;
}
@media print {
	@page {
		margin: 0;
	}
	body {
		margin: 0;
	}
	.v-card.theme--light.v-sheet {
		border: solid 1px gray;
	}
	// .hooperimage {
	// 	// background-image: url("");
	// }
	.col1 {
		width: 510px;
	}
	.chart {
		max-width: 300px;
		max-height: 300px;
	}
}
</style>
