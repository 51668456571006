<template>
	<v-dialog v-model="dialog" persistent :transition="false" max-width="950px" ref="winAssociation">
		<v-card>
			<v-card-title>
				<div>
					<h1>
						Associer la recherche
						<span class="bis">à un contact</span>
					</h1>
				</div>
			</v-card-title>
			<v-card-text grid-list-xl :style="stylecontainer">
				<v-container fluid pa-0 grid-list-xl formlittle ref="mycontainer">
					<v-layout>
						<v-flex md5 style="border-right: 5px solid #e8e8e7">
							<v-card>
								<v-card-text>
									<v-form ref="form" v-model="valid">
										<m-form-combobox
											v-model="currentcontact"
											label
											prepend-icon="mdi-account"
											:rules="row_co_rules"
											:store-url="$config.server_url + '/api/1.0/contacts'"
											:store-params="{ gr_id: 'contacts' }"
											item-value="co_id"
											:item-text="$options.filters.formatContactName"
										></m-form-combobox>

										<m-form-text
											type="textarea"
											:name="$Utils.randomstring('se_comment')"
											outline
											label="Rajouter un commentaire à la recherche"
											label-position="top"
											v-model="se_comment"
										></m-form-text>
									</v-form>
								</v-card-text>
							</v-card>
						</v-flex>
						<v-flex md7 style="background-color: #e8e8e7">
							<v-card class="mt-0" style="background-color: transparent">
								<v-card-text class="my-0">
									<div v-if="row_co_details">
										<contact-details
											:short="true"
											:notclickable="true"
											v-model="row_co_details"
											@contactDetailsLoaded="setContainerStyle"
										></contact-details>
									</div>
								</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin()" :disabled="!valid">Enregistrer</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "offerssearchassociationwinedit",
	props: ["filters"],
	data() {
		return {
			se_comment: "",
			dialog: false,
			// row_co: "",
			row_co_details: null,
			stylecontainer: ``,
			valid: true,
			row_co_rules: [
				v => {
					if (v && v.co_id) return true;
					return "Contact obligatoire";
				}
			]
		};
	},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	computed: {
		currentcontact: {
			get() {
				return this.$store.state.currentcontact;
			},
			set(v) {
				this.$store.commit("set_currentcontact", v);
				if (!v || !v.co_id) {
					this.row_co_details = null;
					this.setContainerStyle();
					return;
				}
				this.row_co_details = v.co_id;
			}
		}
	},
	watch: {
		// async row_co(v) {
		//   if (!v || !v.co_id) {
		//     this.row_co_details = null;
		//     this.setContainerStyle();
		//     return;
		//   }
		//   this.row_co_details = v.co_id;
		//   // let response = await this.$axios.get(
		//   //   this.$config.server_url + "/api/1.0/contacts/" + v.co_id
		//   // );
		//   // this.row_co_details = response.data.data;
		//   // this.setContainerStyle();
		// }
	},
	components: {},
	methods: {
		setContainerStyle() {
			window.setTimeout(() => {
				if (!this.$refs.mycontainer) return;
				if (this.$refs.mycontainer.clientHeight > window.innerHeight - 300)
					this.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
				else this.stylecontainer = "";
			}, 10);
		},
		open() {
			this.se_comment = "";
			this.dialog = true;
			if (this.$store.state.currentcontact) {
				this.row_co_details = this.$store.state.currentcontact.co_id;
			}
		},
		cancelWin() {
			this.dialog = false;
			// this.$emit('input');
		},
		async saveWin() {
			if (!this.currentcontact || !this.currentcontact.co_id) return;
			await this.$axios.post(this.$config.server_url + "/api/1.0/searches", {
				co_id_contact: this.currentcontact.co_id,
				se_filters: this.filters,
				se_comment: this.se_comment
			});
			this.dialog = false;
		}
	}
};
</script>

<style lang="scss"></style>
