<template>
	<div>
		<v-app id="inspire" v-if="!this.$store.state.ficheAgent">
			<v-app-bar app clipped-right light d-flex flex-row flat v-if="showMenuss">
				<!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
				<v-icon @click.stop="drawer = !drawer">mdi-menu</v-icon>
				<v-text-field
					placeholder="Rechercher dans tous les modules"
					prepend-inner-icon="mdi-magnify"
					v-model="search"
					hide-details
					style="font-size: 1.4rem; font-weight: 200; max-width: 370px; margin-left: 15px"
				></v-text-field>

				<!-- <div class="flex-grow-1"></div> -->

				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn
							class="ml-auto mr-auto"
							color="primary2"
							v-on="$store.state.preferences.personal.helpBubble ? on : false"
							@click="$refs.actionwinedit.open('-1')"
						>
							Nouvelle action&nbsp;&nbsp;
							<v-icon small>mdi-pencil</v-icon>
						</v-btn>
					</template>
					<span>
						Créer une nouvelle action téléphonique, un todo, signaler une signature,
						<br />une présentation orale, une nouvelle visite ou un compte rendu.
					</span>
				</v-tooltip>

				<!-- <div class="flex-grow-1"></div> -->
				<div class="container-icon-suivi" style="font-size: 30px">
					<v-icon v-if="$store.state.isMainLoading" x-large style="color: #2096f3">mdi-backup-restore</v-icon>
					<v-icon v-if="!$store.state.isMainLoading" x-large style="color: #eeeeee">mdi-backup-restore</v-icon>
				</div>

				<v-toolbar-items>
					<div class="container-icon-suivi d-flex flex-row withpointer" @click="$router.push('/contacts/group/follows')">
						<div class="container-imgicon-suivi imgicon-client"></div>
						<div class="text-icon-suivi">
							Nouveaux
							<br />contacts
						</div>
						<!-- <v-icon large class="icon-suivi icon-bien-suivi">mdi-home</v-icon> -->
						<div class="nb-suivi" id="nb-bien-suivi">
							{{ $store.state.nbClientSuivi }}
						</div>
					</div>
					<div class="container-icon-suivi d-flex flex-row withpointer" @click="$router.push('/offers/follows')">
						<div class="container-imgicon-suivi imgicon-bien"></div>
						<div class="text-icon-suivi">
							Biens
							<br />suivis
						</div>
						<!-- <v-icon large class="icon-suivi icon-client-suivi">mdi-account</v-icon> -->
						<div class="nb-suivi" id="nb-client-suivi">
							{{ $store.state.nbBienSuivi }}
						</div>
					</div>
					<v-menu :open-on-hover="true" :close-on-click="true" :close-on-content-click="true" :offset-y="true">
						<template v-slot:activator="{ on }">
							<div class="container-icon-suivi d-flex flex-row withpointer" v-on="on">
								<div class="container-imgicon-suivi imgicon-report"></div>
								<div class="text-icon-suivi">
									Comptes
									<br />rendus
								</div>
								<!-- <v-icon large class="icon-suivi icon-bien-suivi">mdi-home</v-icon> -->
								<div class="nb-suivi" id="nb-report-suivi">
									{{ $store.state.reports_tocomplete.length }}
								</div>
							</div>
						</template>
						<div class="dropdown-cr">
							<table class="tablestriped">
								<thead>
									<tr>
										<th>Bien</th>
										<th>Date visite</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in $store.state.reports_tocomplete"
										:key="index"
										class="withpointer"
										@click="openWinActionCR(item.ac_id)"
									>
										<!-- <td>{{(item.ac_offers && item.ac_offers.length ) ? item.ac_offers[0].of_id.of_ref : ""}}</td> -->
										<td v-if="item.of_id">{{ item.of_id.of_ref }}</td>
										<td>
											<div class="dateCR" :class="item.classDate">
												{{ item.ac_date | formatDateTime }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</v-menu>

					<v-menu offset-y>
						<template v-slot:activator="{ on }">
							<div class="d-flex withpointer" v-on="on">
								<div class="my-5 ml-5 mr-3" v-if="user">
									{{ user.co_firstname }}
									<span style="font-weight: bold">{{ user.co_name }}</span>
									<v-icon class="ml-2">mdi-settings</v-icon>
								</div>
								<v-img
									:src="
										user.co_id
											? $config.server_url +
											  '/api/1.0/avatar/' +
											  user.co_id +
											  '/40/40?d=' +
											  user.updatedAt +
											  '&token=' +
											  $store.state.accesstoken
											: '/img/default-profile.jpg'
									"
									height="40px"
									width="40px"
									class="rounded-image mt-3"
								></v-img>
							</div>
						</template>
						<v-list>
							<v-list-item @click="$router.push('/contacts/' + $store.state.user.co_id + '/edit')">
								<v-list-item-title>Mon compte</v-list-item-title>
							</v-list-item>
							<v-list-item @click="confirmLogoutDialog = true">
								<v-list-item-title>Logout</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-toolbar-items>
				<!-- </div> -->
				<!-- <v-spacer></v-spacer> -->
			</v-app-bar>

			<v-navigation-drawer v-model="drawer" app dark width="167" v-if="showMenuss">
				<v-list-item-title class="text-center">
					<div style="padding-left: 10px; background-color: white">
						<v-img :src="$store.state.preferences.shared.logoUrl" height="64px" width="147px" contain></v-img>
					</div>
				</v-list-item-title>
				<v-list nav>
					<!-- <v-list-item link :class="{ active: $route.name=='login' }" @click="go('/')">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
          </v-list-item>-->

					<v-list-item link :class="{ active: $route.name == 'home' }" @click="go('/home')">
						<v-list-item-icon>
							<icon class="icon-menu" name="bullseye"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Accueil</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						v-if="$store.state.preferences.rights.offer_access"
						link
						:class="{ active: $route.path.indexOf('/offers') == 0 }"
						@click="go('/offers')"
					>
						<v-list-item-icon>
							<icon class="icon-menu" name="home"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Biens</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						v-if="$store.state.preferences.rights.contact_access"
						link
						:class="{ active: $route.path.indexOf('/contacts') == 0 }"
						@click="go('/contacts')"
					>
						<v-list-item-icon>
							<icon class="icon-menu" name="user"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Contacts</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						v-if="$store.state.preferences.rights.calendar_access"
						link
						:class="{ active: $route.path.indexOf('/calendar') == 0 }"
						@click="go('/calendar')"
					>
						<v-list-item-icon>
							<icon class="icon-menu" name="calendar-day"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Calendrier</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item
						v-if="$store.state.preferences.rights.message_access"
						link
						:class="{ active: $route.name == 'messages' }"
						@click="go('/messages')"
					>
						<v-list-item-icon>
							<icon class="icon-menu" name="comments"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Fil d'infos</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<!-- <v-list-item>
          <v-list-item-icon>
            <icon class="icon-menu" name="calculator"></icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Calculatrice</v-list-item-title>
          </v-list-item-content>
          </v-list-item>-->

					<!--        <v-list-item link :class="{ active: $route.name == 'ranking' }" @click="go('/ranking')">
          <v-list-item-icon>
            <icon class="icon-menu" name="crown"></icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Classement</v-list-item-title>
          </v-list-item-content>
          </v-list-item>-->
					<hr />

					<!-- <v-list-item
						v-if="$store.state.preferences.rights.stats_access"
						link
						:class="{ active: $route.name == 'statistiques' }"
						@click="go('/statistiques')"
					>
						<v-list-item-icon>
							<icon class="icon-menu" name="chart-pie"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Tableau de bord</v-list-item-title>
						</v-list-item-content>
					</v-list-item> -->
					<v-list-item link :class="{ active: $route.name == 'offerorder' }" @click="go('/offerorder')">
						<v-list-item-icon>
							<icon class="icon-menu" name="sort-alpha-down"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Tri des biens</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item link :class="{ active: $route.name == 'preferences' }" @click="go('/preferences')">
						<v-list-item-icon>
							<icon class="icon-menu" name="cogs"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Préférences</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<hr />
					<v-list-item link @click="confirmLogoutDialog = true">
						<v-list-item-icon>
							<icon class="icon-menu" name="power-off"></icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Logout</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>

			<v-content>
				<v-container fluid fill-height grid-list-xl class="mainContainer" ref="mainContainer">
					<!-- <v-layout justify-center> -->
					<transition name="component-fade" mode="out-in">
						<router-view v-if="!search"></router-view>
						<research-view v-if="search" :researchVal="search" @deleteResearch="deleteResearch"></research-view>
					</transition>
					<!-- </v-layout> -->
				</v-container>
			</v-content>

			<!-- <v-footer app color="blue-grey" class="white--text">
      <span>Vuetify</span>
      <v-spacer></v-spacer>
      <span>&copy; 2019</span>
      </v-footer>-->
		</v-app>
		<m-confirm-dialog
			v-model="confirmLogoutDialog"
			text="Souhaitez-vous vous déconnecter ?"
			title="Confirmation"
			@canceled="confirmLogoutDialog = false"
			@confirmed="logout()"
		></m-confirm-dialog>
		<action-win-edit ref="actionwinedit" @actionWinEditSaved="actionWinEditSaved"></action-win-edit>
		<!-- <contact-win-edit
      ref="contactwinedit"
      @contactWinEditSaved="contactWinEditSaved"
    ></contact-win-edit>-->

		<!-- <div v-if="this.$store.state.ficheAgent">
      <v-container>
        <offer-details />
      </v-container>
    </div>-->
	</div>
</template>

<script>
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
// import Vue from "vue";
// import OfferDetails from "./views/OffersDetails";
// Vue.component("offer-details", OfferDetails);

export default {
	name: "App",
	components: {},
	data: () => ({
		confirmLogoutDialog: false,
		showMenuss: true,
		drawer: true,
		nbClientSuivi: 0,
		nbBienSuivi: 0,
		// user: null,
		search: ""
		// itemsDropdownCR: []
	}),
	sockets: {
		connect() {
			this.$store.dispatch("socketjoinroom", this.$socket);
		},
		rooms_connected(data) {
			// console.log("rooms_connected", data);
			this.$store.commit("set_rooms_connected", data);
		},
		updatecontacts() {
			this.$store.dispatch("load_nbclientsuivi");
		}
	},
	computed: {
		user() {
			return this.$store.state.user;
		}
	},
	async mounted() {
		if (this.$route.query.action) {
			this.$refs.actionwinedit.open(this.$route.query.action);
		}
		this.resizeMainContainer();
		window.addEventListener("resize", this.resizeMainContainer);

		// await this.$store.dispatch("load_dropdownMenuCR");
		// let itemsDropdownCR = this.$store.getters.get_compteRenduToDo;
		// if (itemsDropdownCR) this.itemsDropdownCR = itemsDropdownCR;
	},
	created() {
		this.nbBienSuivi = this.$store.getters.get_nbbiensuivi;
		this.nbClientSuivi = this.$store.getters.get_nbclientsuivi;
		// this.user = this.$store.state.user;
	},
	methods: {
		// openWinContact(co_id) {
		//   this.$refs.contactwinedit.open(co_id);
		// },
		hideMenus() {
			this.showMenuss = false;
		},
		showMenus() {
			this.showMenuss = true;
		},
		openWinActionCR(ac_id) {
			// console.log("ac_id", ac_id);
			if (this.$_.isPlainObject(ac_id)) ac_id = ac_id.ac_id;
			this.$refs.actionwinedit.open(ac_id);
		},
		actionWinEditSaved() {},
		resizeMainContainer() {
			if (!this.$refs.mainContainer || this.$route.name === "offersdetailsprintowner" || this.$route.name === "offersdetailsprintdayagent")
				return;
			// if (this.$route.name == "login") {
			//   this.$refs.mainContainer.style.height = "100%";
			//   return;
			// }
			this.$refs.mainContainer.style.height = parseInt(window.innerHeight) - 64 + "px";
		},
		go(where) {
			this.deleteResearch();
			this.$router.push(where);
		},
		deleteResearch() {
			this.search = "";
		},
		logout() {
			this.confirmLogoutDialog = false;
			this.$store.commit("set_connexion", {
				accesstoken: null,
				refreshtoken: null,
				user: null,
				preferences: null
			});
			this.$router.push("/");
		},
		saveWinUser() {
			// console.warn("this.$store.state.user.co_name", this.$store.state.user);
			// this.$refs.modal1.close();
		},
		openWinUser() {
			this.$refs.modal1.open();
		},
		closeWinUser() {
			this.$refs.modal1.close();
		}
	}
};
</script>

<style scoped lang="scss">
@import "./sass/variables.scss";
.mainContainer {
	background-color: transparent;
}
#inspire .v-list .v-list-item {
	min-height: 48px;
	max-height: 48px;
}
.dropdown-cr {
	background-color: #fff;
	padding: 10px;
}
.dateCR {
	text-align: center;
	padding: 2px;
}

#nb-report-suivi {
	left: 20px;
	background-color: $myteal;
}
#nb-bien-suivi {
	left: 20px;
	background-color: $myrouge-clair;
}
#nb-client-suivi {
	left: 20px;
	background-color: $myviolet;
}
</style>
