<template>
	<v-layout align-center justify-center fill-height>
		<v-flex fill-height md9 class="card1">
			<v-card class="fill-height overflow-auto" flat>
				<v-card-title>
					<h1>
						<span>{{ nbmessages }}</span> Fil d'infos
					</h1>
					<div class="ml-auto d-flex flex-row container-filters">
						<div class="mr-3 pt-3" style="font-size: 30px">
							<v-icon v-if="$store.state.isMainLoading" x-large style="color: #2096f3">mdi-backup-restore</v-icon>
							<v-icon v-if="!$store.state.isMainLoading" x-large style="color: #eeeeee">mdi-backup-restore</v-icon>
						</div>
						<div>
							<m-form-date
								:name="$Utils.randomstring('filterfrom')"
								v-model="filterfrom"
								label="Date de début"
								labelPosition="top"
								class="filter-date mr-3"
							/>
						</div>
						<div>
							<m-form-date
								:name="$Utils.randomstring('filterto')"
								v-model="filterto"
								label="Date de fin"
								labelPosition="top"
								class="filter-date"
							/>
						</div>
					</div>
				</v-card-title>
				<v-card-text>
					<messages-card
						:limit="1000000"
						:users="selectedUsers"
						:autoload="false"
						:readonly="false"
						:filterfrom="filterfrom"
						:filterto="filterto"
						@newMessage="newMessage"
						@nbmessages="nbMessages"
					></messages-card>
				</v-card-text>
			</v-card>
		</v-flex>
		<v-flex fill-height md3 class="card1">
			<v-card class="fill-height overflow-auto" flat>
				<v-card-title>
					<h1>
						Les
						<span class="bis">utilisateurs</span>
					</h1>
					<m-form-checkbox class="users-checkbox" v-model="selectAllUsers"></m-form-checkbox>
				</v-card-title>
				<v-card-text>
					<div class="user d-flex flex-row align-items-center" v-for="user in users" :key="user.co_id">
						<m-form-checkbox class="user-checkbox" v-model="selectedUsersObj[user.co_id]"></m-form-checkbox>
						<div class="messageIcon mr-3">
							<v-img
								:src="
									user.co_id
										? $config.server_url +
										  '/api/1.0/avatar/' +
										  user.co_id +
										  '/180/180?d=' +
										  user.updatedAt +
										  '&token=' +
										  $store.state.accesstoken
										: '/img/default-profile.jpg'
								"
								height="35px"
								width="35px"
								class="rounded-image"
							></v-img>
						</div>
						<div class="d-flex flex-column">
							<div>{{ user | formatContactName }}</div>
							<div class="last-message" v-if="user.lastMessageDate">
								Dernier message le
								{{ $moment(user.lastMessageDate).format("DD/MM/YY [à] HH:mm") }}
							</div>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-flex>
	</v-layout>
</template>
<script>
export default {
	name: "messages",
	data() {
		return {
			nbmessages: 0,
			filterfrom: this.$moment()
				.add(-6, "months")
				.format("YYYY-MM-DD"),
			filterto: this.$moment().format("YYYY-MM-DD"),
			users: [],
			selectedUsersObj: {},
			selectedUsers: ["-1"],
			selectAllUsers: true
		};
	},
	watch: {
		// filterfrom(val){

		// },
		selectAllUsers(val) {
			let selectedUsersObj = {};
			this.$_.each(this.selectedUsersObj, (v, co_id) => {
				selectedUsersObj[co_id] = val;
			});
			this.selectedUsersObj = selectedUsersObj;
		},
		selectedUsersObj: {
			handler: function(newVal, oldVal) {
				// console.log("newVal", newVal);
				let selectedUsers = ["-1"];
				this.$_.each(this.selectedUsersObj, (v, co_id) => {
					if (v) selectedUsers.push(co_id);
				});
				this.selectedUsers = selectedUsers;
			},
			deep: true
		}
	},
	mounted() {
		this.getUsers();
	},
	methods: {
		nbMessages(nbmessages) {
			this.nbmessages = nbmessages;
		},
		async getUsers() {
			let params = { gr_id: "users", getLastMessage: true };
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts", { params });
			this.users = response.data.data;
			// this.users.sort((a, b) => {
			//   return a.co_name.localeCompare(b.co_name);
			// });
			let selectedUsersObj = {};
			for (let iUs = 0; iUs < this.users.length; iUs++) {
				const row_co = this.users[iUs];
				selectedUsersObj[row_co.co_id] = true;
			}
			this.selectedUsersObj = selectedUsersObj;
			// console.log("this.selectedUsers", this.selectedUsers);
			// @Marina : éviter d'appeler de trop nombreux ajax ; ici le mieux est de remonter ces infos avec /api/1.0/contacts !!! (pour cela j'ai rajouté un parametre "getLastMessage" demandant de rajouter cela aux users remontés)
			// for (let ius = 0; ius < this.users.length; ius++) {
			//   const user = this.users[ius];
			//   user.selected = true;
			//   let lastMessage = await this.getLastMessage(user);
			//   user.lastMessage = lastMessage;
			//   if (this.$_.isPlainObject(user.lastMessage))
			//     user.lastMessageDate = user.lastMessage.me_date;
			// }
		},
		// async getLastMessage(user) {
		//   let response = await this.$axios.get(
		//     this.$config.server_url + "/api/1.0/messages/last/" + user.co_id
		//   );
		//   return response.data.data;
		// },
		async newMessage(user) {
			this.getUsers();
			// let lastMessage = await this.getLastMessage(user);

			// user.lastMessage = lastMessage;
			// user.lastMessageDate = user.lastMessage.me_date;
			// console.log("je passe", user);
		}
		// selectUsers() {
		//   if (this.selectAllUsers) {
		//     // this.selectedUsers = this.users;
		//   }
		// }
		// selectUser(user) {
		//   user.selected = !user.selected;
		// }
	}
};
</script>
<style lang="scss" scoped>
.backgroud-white {
	background-color: #fff;
}
.container-messages {
	width: 100%;
	height: 100%;
	overflow: auto;
}
.card-title {
	justify-content: space-between;
}
.container-filters {
	line-height: 0.875rem;
	font-size: 0.875rem;
	// float: right;
	// input {
	//   height: 24px;
	// }
}
.user {
	position: relative;
	background-color: #eceff1;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 8px;
	border-radius: 3px;
	align-items: center;
}
.user-checkbox {
	position: absolute;
	top: 0;
	right: 0;
}
.users-checkbox {
	position: absolute;
	top: 0;
	right: 15px;
}
.last-message {
	font-size: small;
	color: #757575;
}
</style>
