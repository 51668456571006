import Vue from "vue";
import Router from "vue-router";

import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import Calendar from "../views/Calendar.vue";
import Preferences from "../views/Preferences.vue";
//import Ranking from "../views/Ranking.vue";
import ResearchView from "../views/ResearchView.vue";
import Statistiques from "../views/Statistiques.vue";
import Messages from "../views/Messages.vue";
import OfferOrder from "../views/OfferOrder.vue";
import Contacts from "../views/Contacts.vue";
import Offers from "../views/Offers.vue";
import OffersDetails from "../views/OffersDetails.vue";
import OffersDetailsPrintAgent from "../views/OffersDetailsPrintAgent.vue";
import OffersDetailsPrintOwner from "../views/OffersDetailsPrintOwner.vue";
import store from "./store";
import axios from "axios";

Vue.use(Router);

const router = new Router({
	routes: [
		{
			path: "/",
			name: "login",
			component: Login
		},
		{
			path: "/resetpass/:co_id/:challenge",
			name: "resetpass",
			component: Login
		},
		{
			path: "/home",
			name: "home",
			component: Home
		},
		{
			path: "/home/action/:id",
			name: "homeaction",
			component: Home
		},
		{
			path: "/offers/:id/printagent",
			name: "offersdetailsprintagent",
			component: OffersDetailsPrintAgent
		},
		{
			path: "/offers/:id/printowner",
			name: "offersdetailsprintowner",
			component: OffersDetailsPrintOwner
		},
		{
			path: "/offers/:id/:date/printdayagent",
			name: "offersdetailsprintdayagent",
			component: OffersDetailsPrintAgent
		},
		{
			path: "/offers",
			name: "offers",
			component: Offers
		},
		{
			path: "/offers/follows",
			name: "offersfollows",
			component: Offers
		},
		{
			path: "/offers/:se_id/search",
			name: "offerssearch",
			component: Offers
		},
		{
			path: "/offers/:of_id/details",
			name: "offersdetails",
			component: OffersDetails,
			meta: {}
		},
		{
			path: "/offers/:of_id/edit",
			name: "offersedit",
			component: OffersDetails,
			meta: { edit: true }
		},
		// {
		// 	path: "/offers/*",
		// 	name: "offersresearch",
		// 	component: Offers,
		// 	meta: {}
		// },
		{
			path: "/calendar",
			name: "calendar",
			component: Calendar
		},
		{
			path: "/calendar/:ve_id/edit",
			name: "calendaredit",
			component: Calendar,
			meta: { editcalendar: true }
		},
		{
			path: "/calendar/:ve_id/edit/:start/:end/:allday",
			name: "calendareditmoreargs",
			component: Calendar,
			meta: { editcalendar: true }
		},
		{
			path: "/contacts",
			name: "contactslist",
			component: Contacts
		},
		{
			path: "/contacts/group/:group",
			name: "contactsgroup",
			component: Contacts
		},
		{
			path: "/contacts/:co_id/details",
			name: "contactdetails",
			component: Contacts
		},
		{
			path: "/contacts/:co_id/edit",
			name: "contactedit",
			component: Contacts,
			meta: { edit: true }
		},
		{
			path: "/preferences",
			name: "preferences",
			component: Preferences
		},
		/* {
         path: "/ranking",
         name: "ranking",
         component: Ranking
      }, */
		{
			path: "/research",
			name: "research",
			component: ResearchView,
			props: true
		},
		{
			path: "/messages",
			name: "messages",
			component: Messages
		},
		{
			path: "/offerorder",
			name: "offerorder",
			component: OfferOrder
		},
		{
			path: "/statistiques",
			name: "statistiques",
			component: Statistiques
		}
	]
});

router.beforeEach(async (to, from, next) => {
	// console.log("to,from", to, from);
	async function autolog() {
		// console.log("store.state.accesstoken", store.state.accesstoken);
		if (!store.state.accesstoken) return false;
		if (store.state.connected) return true;
		try {
			let response = await axios.post(process.env.VUE_APP_SERVER_URL + "/api-web/1.0/autosignin", {
				accesstoken: store.state.accesstoken
			});
			if (response.data.err) {
				return false;
			}
			store.commit("set_connexion", {
				accesstoken: response.data.accesstoken,
				refreshtoken: response.data.refreshtoken,
				user: response.data.data,
				preferences: response.data.preferences
			});
			return true;
		} catch (error) {
			console.error("ko", error);
			return false;
		}
	}

	if (to.name == "resetpass") return next();

	if (!store.state.connected) await autolog();
	if (to.name == "login" && store.state.connected) return next({ path: "/home" });
	if (to.name == "login" && !store.state.connected) return next();

	if (!store.state.connected) return next({ path: "/" });

	return next();
});
export default router;
