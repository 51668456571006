import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./plugins/store";
// import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import "vue-awesome/icons/home";
import "vue-awesome/icons/crown";
import "vue-awesome/icons/chart-pie";
import "vue-awesome/icons/calculator";
import "vue-awesome/icons/user";
import "vue-awesome/icons/envelope";
import "vue-awesome/icons/sort-alpha-down";
import "vue-awesome/icons/sort";
import "vue-awesome/icons/eye";
import "vue-awesome/icons/eye-slash";
import "vue-awesome/icons/trash";
import "vue-awesome/icons/key";
import "vue-awesome/icons/sticky-note";
import "vue-awesome/icons/bullseye";
import "vue-awesome/icons/cogs";
import "vue-awesome/icons/calendar-day";
import "vue-awesome/icons/power-off";
import "vue-awesome/icons/minus-square";
import "vue-awesome/icons/plus-square";
import "vue-awesome/icons/handshake";
import "vue-awesome/icons/clipboard-check";
import "vue-awesome/icons/paper-plane";
import "vue-awesome/icons/exclamation-triangle";
import "vue-awesome/icons/user-plus";
import "vue-awesome/icons/folder-plus";
import "vue-awesome/icons/comments";
import "vue-awesome/icons/at";
import "vue-awesome/icons/print";
import "vue-awesome/icons/times";
import "vue-awesome/icons/phone";
import "vue-awesome/icons/comment";
import "vue-awesome/icons/edit";
import Icon from "vue-awesome/components/Icon";
Vue.component("Icon", Icon);
import moment from "moment";
// Importing the global css file
import "@/sass/main.scss";

moment.locale("fr");
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
// import jquery from "jquery";
// Object.defineProperty(Vue.prototype, "$jquery", { value: jquery });
import axios from "axios";
Object.defineProperty(Vue.prototype, "$axios", { value: axios });
axios.defaults.headers.common["x-auth-webaccesstoken"] = store.state.accesstoken;
axios.interceptors.request.use(
	function(config2) {
		store.commit("set_mainisloading", true);
		return config2;
	},
	function(error) {
		console.warn("error axios", error);
		// Do something with request error
		return Promise.reject(error);
	}
);
// console.log("process.env.VUE_APP_SERVER_URL", process.env.VUE_APP_SERVER_URL);
axios.interceptors.response.use(
	async function(response) {
		store.commit("set_mainisloading", false);
		store.commit("set_wasconnected", true);
		let ok = true;
		// const originalRequest = response.config;
		if (response.data && response.data.err) {
			console.warn("response.data.err", response.data.err);
			if (response.data.err.key == "token_mandatory" || response.data.err.key == "user_not_found") {
				ok = false;
			}
			if (response.data.err.key == "token_expired") {
				if (store.state.wasconnected) window.open("/", "_self");
				// return await axios
				// 	.get(process.env.VUE_APP_SERVER_URL + "/api-web/1.0/refreshtoken", {
				// 		headers: { "x-auth-refreshtoken": store.state.refreshtoken }
				// 	})
				// 	.then(response2 => {
				// 		if (!response2.data.err) {
				// 			store.commit("set_tokens", {
				// 				accesstoken: response2.data.accesstoken,
				// 				refreshtoken: response2.data.refreshtoken
				// 			});
				// 			originalRequest.headers["x-auth-webaccesstoken"] = response2.data.accesstoken;
				// 			return axios(originalRequest);
				// 		} else {
				// 			ok = false;
				// 		}
				// 	});
			}
		}
		if (!ok) {
			App.methods.logout();
		}
		return response;
	},
	function(error) {
		if (axios.isCancel(error)) {
			console.warn("Request canceled", error.message);
		} else {
			console.error("response error", error.message);
			if (store.state.wasconnected) window.open("/", "_self");
			store.commit("set_wasconnected", false);
			return Promise.reject(error);
		}
	}
);

const config = {
	server_url: process.env.VUE_APP_SERVER_URL,
	backoffice_url: process.env.VUE_APP_BACKOFFICE_URL
};

import _ from "lodash";
Object.defineProperty(Vue.prototype, "$_", { value: _ });
//import config from "./config.js";
Object.defineProperty(Vue.prototype, "$config", { value: config });
import Utils from "./plugins/utils.js";
Object.defineProperty(Vue.prototype, "$Utils", { value: Utils });
import Validation from "./plugins/validation.js";
Object.defineProperty(Vue.prototype, "$Validation", { value: Validation });

import VueSocketIO from "vue-socket.io";
// console.log("process.env.VUE_APP_SERVER_URL", process.env.VUE_APP_SERVER_URL);
Vue.use(
	new VueSocketIO({
		debug: false,
		connection: process.env.VUE_APP_SERVER_URL,
		vuex: {
			store,
			actionPrefix: "SOCKET_",
			mutationPrefix: "SOCKET_"
		},
		options: {} //{ path: "/my-app/" } //Optional options
	})
);

var numeral = require("numeral");
numeral.register("locale", "fr", {
	delimiters: {
		thousands: ".",
		decimal: ","
	},
	abbreviations: {
		thousand: "k",
		million: "m",
		billion: "b",
		trillion: "t"
	},
	ordinal: function(number) {
		return number === 1 ? "er" : "ème";
	},
	currency: {
		symbol: "€"
	}
});
numeral.locale("fr");

Vue.config.productionTip = true;

import "./plugins/filters";
import "./plugins/components";

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount("#app");
