import axios from "axios";
import config from "../config";
import store from "./store";
import _ from "lodash";

const Utils = {
	concatActionsUser(data) {
		if (data.calls_user)
			return _.concat(
				data.calls_user,
				data.todos_user,
				data.signatures_sell_user,
				data.signatures_rent_user,
				data.emails_user,
				data.visits_user,
				data.presents_user,
				data.reports_user
			);
		return [];
	},
	randomColor() {
		var letters = "0123456789ABCDEF";
		var color = "#";
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	},

	// formatContactName(row_co) {
	// 	let txt = [];
	// 	if (row_co.co_name) txt.push(row_co.co_name.toUpperCase());
	// 	if (row_co.co_firstname) txt.push(row_co.co_firstname.toUpperCase());
	// 	return txt.join(" ");
	// },
	// formatOfferPrices(row_of, sep) {
	// 	let txt = "";
	// 	if (row_of.of_sell) {
	// 		if (row_of.of_sell_uponrequest) txt += "À vendre : Prix sur demande" + sep;
	// 		else if (row_of.of_sell_sum) txt += "À vendre : " + row_of.of_sell_sum + "&nbsp;€" + sep;
	// 	}
	// 	if (row_of.of_rent) {
	// 		if (row_of.of_rent_uponrequest) txt += "À louer : Prix sur demande" + sep;
	// 		else {
	// 			if (row_of.of_rent_sum) txt += "À louer : " + row_of.of_rent_sum + "&nbsp;€/mois" + sep;
	// 			if (row_of.of_rent_sum_year) txt += "À louer : " + row_of.of_rent_sum_year + "&nbsp;€/an" + sep;
	// 		}
	// 	}
	// 	return txt;
	// },
	// formatSurface(row_of, sep) {
	// 	return row_of.of_surface + "&nbsp;m<sup>3</sup>";
	// },
	randomstring(keep = "", length = 12) {
		var result = "";
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		if (keep) result = keep + "__" + result;
		// console.log('result',result);
		return result;
	},

	async wait(funcToTest, maxTime) {
		return new Promise(function(resolve, reject) {
			let timeoutTest, timeoutMaxTime;
			function test() {
				timeoutTest = window.setTimeout(() => {
					if (funcToTest()) {
						if (timeoutMaxTime) window.clearTimeout(timeoutMaxTime);
						return resolve(true);
					}
					test();
				}, 10);
			}
			timeoutMaxTime = window.setTimeout(() => {
				if (timeoutTest) window.clearTimeout(timeoutTest);
				console.warn("timeoutmax");
				resolve(false);
			}, maxTime);
			test();
		});
	},
	async doRequestIo(method, url, params) {
		try {
			let response = await axios[method](config.serverurl + url, params ? params : null, {
				headers: {
					"x-auth-webaccesstoken": store.state.accesstoken,
					"Access-Control-Allow-Origin": "*"
				}
			});
			return response.data;
		} catch (e) {
			console.error("Error Ajax REquest - :", e);
			return null;
		}
	}
};
export default Utils;
