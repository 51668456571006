<template>
	<m-form-base
		:id="id2"
		:label="label"
		:label-width="labelWidth"
		:label-position="labelPosition"
		:prepend-icon="prependIcon"
		:append-icon="appendIcon"
		:errormsg="errormsg2"
	>
		<v-tooltip :bottom="tooltipPosition == 'bottom'" :top="tooltipPosition == 'top'">
			<template v-slot:activator="{ on }">
				<input
					v-on="tooltip && $store.state.preferences.personal.helpBubble ? on : false"
					v-if="type != 'textarea'"
					:readonly="readonly"
					:autocomplete="autocomplete"
					:disabled="disabled"
					:name="name"
					:type="type"
					:value="value"
					:id="id2"
					@input="oninput($event)"
					@focus="onfocus($event)"
					@keyup="onkeyup($event)"
					@click="onclick"
					:placeholder="placeholder"
				/>
				<textarea
					v-on="tooltip && $store.state.preferences.personal.helpBubble ? on : false"
					v-if="type == 'textarea'"
					:readonly="readonly"
					:autocomplete="autocomplete"
					:disabled="disabled"
					:name="name"
					:value="value"
					:id="id2"
					:rows="rows"
					@input="oninput($event)"
					@focus="onfocus($event)"
					@click="onclick"
					:placeholder="placeholder"
				></textarea>
			</template>
			<span v-html="tooltip"></span>
		</v-tooltip>
	</m-form-base>
</template>


<script>
import MFormBase from "./MFormBase";

export default {
	name: "mformtext",
	extends: MFormBase,
	props: {
		value: [String, Number],
		id: {
			default: "",
			type: String
		},
		type: {
			default: "text",
			type: String
		},
		name: {
			default: "",
			type: String
		},
		rows: {
			default: 4,
			type: Number
		},
		autocomplete: {
			default: "new-password",
			type: String
		},
		readonly: {
			default: false,
			type: Boolean
		},
		disabled: {
			default: false,
			type: Boolean
		},
		autoSelectOnFocus: {
			default: true,
			type: Boolean
		},
		tooltip: {
			default: "",
			type: String
		},
		tooltipPosition: {
			default: "bottom",
			type: String
		},
		rules: {
			default: function () {
				return [];
			},
			type: Array
		},
		prependIcon: String,
		appendIcon: String,
		placeholder: String
	},
	mounted() {
		if (!this.id2) this.id2 = this.$Utils.randomstring();
	},
	data() {
		return {
			id2: "",
			value2: "",
			errormsg2: ""
		};
	},
	watch: {
		value: function (val) {}
	},
	components: {},
	methods: {
		informValid() {
			this.errormsg2 = "";
		},
		informInvalid(txt) {
			this.errormsg2 = txt;
		},
		validate() {
			let kos = [];
			for (let iRule = 0; iRule < this.rules.length; iRule++) {
				const rule = this.rules[iRule];
				let ok = rule(this.value2);
				if (this.$_.isString(ok)) kos.push(ok);
			}
			if (kos.length == 0) {
				this.informValid();
				return true;
			}
			this.informInvalid(kos.join(","));
			return false;
		},
		onkeyup(evt) {
			this.$emit("keyup", evt);
		},
		oninput(evt) {
			this.value2 = evt.target.value;
			this.validate();
			this.$emit("input", this.value2);
		},
		onclick(evt) {
			// evt.stopPropagation();
			this.$emit("click", evt);
		},
		onfocus(evt) {
			if (this.autoSelectOnFocus) evt.target.select();
			// evt.stopPropagation();
			// this.$emit("click", evt);
		}
	}
};
</script>

<style lang="scss">
.m-form-base {
	.m-form-base__container-input {
		input {
			border-radius: 5px;
		}
		textarea {
			border-radius: 5px;
		}
	}
}
</style>