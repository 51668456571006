<template>
	<v-dialog v-model="dialog" ref="winOffer" fullscreen hide-overlay transition="dialog-bottom-transition">
		<v-card style="background-color: black">
			<v-card-text class="pt-5">
				<div>
					<a @click="dialog = false"><v-icon color="white">mdi-close</v-icon></a>
				</div>
				<v-carousel hide-delimiters :height="carouselHeight">
					<v-carousel-item v-for="(row_fi, indx) in row_of.images" :key="indx">
						<v-img
							contain
							:height="carouselHeight"
							:src="
								`${$config.server_url}/api/1.0/images/${row_fi.fi_id}/950/600?token=${$store.state.accesstoken}&d=${row_fi.updatedAt}`
							"
						></v-img>
					</v-carousel-item>
				</v-carousel>
			</v-card-text>

			<!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary2" text @click="cancelWin()">Fermer</v-btn>
      </v-card-actions> -->
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "OffersWinZoom",
	components: {},
	data() {
		return {
			carouselHeight: parseInt(window.innerHeight) - 100,
			dialog: false,
			stylecontainer: ``
		};
	},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	computed: {
		isEditable() {
			return true;
		}
	},
	watch: {},
	props: {
		row_of: {
			default: function() {
				return {};
			},
			type: Object
		}
	},
	methods: {
		setContainerStyle() {
			// if (!this.$refs.mycontainer) return;
			// if (this.$refs.mycontainer.clientHeight > window.innerHeight - 300)
			//   this.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
			// else this.stylecontainer = "";
			this.carouselHeight = parseInt(window.innerHeight) - 100;
			// this.$forceUpdate();
		},
		open(tr_id) {
			this.dialog = true;
		},

		cancelWin() {
			this.$emit("offersWinZoomCanceled");
			this.dialog = false;
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hooperimage2 {
	background-size: contain;
	height: 600px;
	background-position: center center;
	background-repeat: no-repeat;
}
</style>
