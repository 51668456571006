<template>
	<div>
		<table class="tablestriped overclick">
			<thead v-if="header">
				<tr>
					<th></th>
					<th>Date</th>
					<th v-if="colagent">Agent</th>
					<th v-if="colcontact">Contact</th>
					<th v-if="colcomment">Commentaire</th>
					<th v-if="coloffers">Biens</th>
					<th v-if="coldone">Fait</th>
					<th v-if="colchips"></th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(row_ac, index) in actions"
					:key="row_ac.ac_id"
					:class="{
						'd-none': index >= actionsNbMax && !actionsShowMore,
						withpointer: true
					}"
				>
					<td style="width: 40px" class @click="openWinAction(row_ac.ac_id)">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<div v-on="on">
									<icon class="actionwinedit_icon" :name="getIcon(row_ac)"></icon>
								</div>
							</template>
							<span>
								{{ row_ac.ac_type | formatFromArray($store.state.items_ac_type) }}
							</span>
						</v-tooltip>
					</td>

					<td style="width: 90px" @click="openWinAction(row_ac.ac_id)">
						<span :class="row_ac.classDate ? row_ac.classDate : ''" :inner-html.prop="row_ac.ac_date | formatDateTime"></span>
					</td>

					<td style="width: 120px" @click="openWinAction(row_ac.ac_id)" v-if="colagent">
						<!-- {{row_ac.co_id_user_todo ? row_ac.co_id_user_todo.co_firstname :row_ac.co_id_user.co_firstname }} -->
						<span>{{ row_ac.co_id_user | formatContactName }}</span>
					</td>

					<td style="width: 120px" @click="openWinAction(row_ac.ac_id)" v-if="colcontact">
						<span :inner-html.prop="row_ac.co_id_contact | formatContactName"></span>
					</td>

					<td class @click="openWinAction(row_ac.ac_id)" v-if="colcomment">
						{{ row_ac.ac_comment }}
						<div v-if="row_ac.ac_type == 'newoffer'">
							Nouveau bien :
							<!-- <span
                v-if="row_ac.ac_offers.length"
              >{{row_ac.ac_offers[0].of_id | formatOfferName}}</span>-->
							{{ row_ac.of_id | formatOfferName }}
						</div>
						<div v-if="row_ac.ac_type == 'newcontact'">Nouveau contact</div>
					</td>

					<td style="width: 120px" class @click="openWinAction(row_ac.ac_id)" v-if="coloffers">
						<!-- <ul v-if="!offerShortName">
              <li
                v-for="row_ac in row_ac.ac_offers"
                :key="row_ac.ac_id"
              >{{row_ac.of_id | formatOfferName}}</li>
            </ul>-->
						<div v-if="!offerShortName">
							{{ row_ac.of_id | formatOfferName }}
						</div>
						<div v-if="offerShortName">
							{{ row_ac.of_id | formatOfferNameShort2 }}
							<!-- <span v-for="(row_ac) in row_ac.ac_offers" :key="row_ac.ac_id">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{row_ac.of_id | formatOfferNameShort2}}</div>
                  </template>
                  <span>{{row_ac.of_id | formatOfferName}}</span>
                </v-tooltip>
              </span>-->
						</div>
					</td>

					<td style="width: 60px" class="pa-1" v-if="coldone">
						<v-switch
							v-if="row_ac.ac_type == 'call' || row_ac.ac_type == 'todo' || row_ac.ac_type == 'email' || row_ac.ac_type == 'visit'"
							v-model="row_ac.ac_done"
							:name="$Utils.randomstring('ac_done')"
							color="#53bd7b"
							:inset="true"
							:light="true"
							label
							dense
							class="mt-0 mr-2"
							style="height: 32px"
							@change="switchDone(row_ac)"
						></v-switch>
						<v-chip
							v-if="
								(row_ac.ac_type == 'present' ||
									row_ac.ac_type == 'newoffer' ||
									row_ac.ac_type == 'newcontact' ||
									row_ac.ac_type == 'signature') &&
									row_ac.ac_done
							"
							class="text-no-wrap"
							small
							color="green"
							text-color="white"
							>Fait</v-chip
						>
					</td>

					<td v-if="colchips" class="text-right" @click="openWinAction(row_ac.ac_id)" style="width: 90px">
						<v-chip
							v-if="(row_ac.ac_type == 'todo' || row_ac.ac_type == 'call') && row_ac.ac_done"
							class="text-no-wrap"
							x-small
							color="green"
							text-color="white"
							>Fait</v-chip
						>
						<v-chip
							v-if="(row_ac.ac_type == 'todo' || row_ac.ac_type == 'call') && !row_ac.ac_done"
							class="text-no-wrap"
							x-small
							color="purple"
							text-color="white"
							>A faire</v-chip
						>
						<v-chip
							v-if="row_ac.ac_type == 'signature' && row_ac.ac_signature_sum"
							class="text-no-wrap"
							x-small
							color="green"
							text-color="white"
							>{{ row_ac.ac_signature_sum | formatPrice }}</v-chip
						>
						<v-chip v-if="row_ac.ac_type == 'report' && row_ac.ac_done" class="text-no-wrap" x-small color="green" text-color="white"
							>Rempli</v-chip
						>
						<v-chip v-if="row_ac.ac_type == 'report' && !row_ac.ac_done" class="text-no-wrap" x-small color="red" text-color="white"
							>A remplir</v-chip
						>
						<v-chip v-if="row_ac.ac_type == 'present'" class="text-no-wrap" x-small color="green" text-color="white">Présenté</v-chip>
						<v-chip v-if="row_ac.ac_type == 'visit' && row_ac.ac_done" class="text-no-wrap" x-small color="green" text-color="white"
							>Visité</v-chip
						>
						<v-chip
							v-if="row_ac.ac_type == 'visit' && $moment(row_ac.ac_date).isBefore($moment())"
							class="text-no-wrap"
							x-small
							color="red"
							text-color="white"
							>RDV annulé ?</v-chip
						>
						<v-chip
							v-if="row_ac.ac_type == 'visit' && $moment(row_ac.ac_date).isAfter($moment())"
							class="text-no-wrap"
							x-small
							color="yellow"
							text-color="black"
							>RDV à venir</v-chip
						>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="text-right mt-2" v-if="actionsTotal > actionsNbMax">
			<a @click="actionsShowMore = !actionsShowMore">
				{{ actionsShowMore ? `Masquer ${actionsTotal - actionsNbMax} actions` : `Voir ${actionsTotal - actionsNbMax} actions de plus` }}
			</a>
		</div>
		<action-win-edit ref="actionwinedit" @actionWinEditSaved="actionWinEditSaved"></action-win-edit>
	</div>
</template>
<script>
// @Marina : j'ai modifié le nom du fichier "actions" plutot que "todo" / ok

export default {
	name: "cardtableactions",
	props: {
		actionsNbMax: Number,
		// @Marina : j'ai modifié le nom de la variable principale "actions" plutot que "todo" / ok
		actions: Array,
		actionsTotal: Number,
		offerShortName: {
			default: true,
			type: Boolean
		},
		// @Marina : j'ai modifié les variables pour l'affichage des colonnes / ok
		colchips: {
			default: true,
			type: Boolean
		},
		colcontact: {
			default: true,
			type: Boolean
		},
		colcomment: {
			default: true,
			type: Boolean
		},
		colagent: {
			default: true,
			type: Boolean
		},
		coloffers: {
			default: true,
			type: Boolean
		},
		coldone: {
			default: true,
			type: Boolean
		},
		header: {
			default: true,
			type: Boolean
		}
	},
	data() {
		return {
			actionsShowMore: false
		};
	},
	// watch: {
	//   todo: {
	//     handler: function(newVal, oldVal) {
	//       console.log("newVal", newVal);
	//     },
	//     deep: true
	//   }
	// },
	mounted() {
		// console.log("this.actionsTotal", this.actionsTotal);
	},
	methods: {
		getIcon(row_ac) {
			return this.$_.result(
				this.$_.find(this.$store.state.items_ac_type, {
					value: row_ac.ac_type
				}),
				"icon",
				"exclamation-triangle"
			);
		},
		async switchDone(row_ac) {
			// console.log("row_ac.ac_done", row_ac.ac_done);
			await this.$axios.put(this.$config.server_url + "/api/1.0/actions/" + row_ac.ac_id, { ac_done: row_ac.ac_done });
			this.$emit("actionWinEditSaved");
			this.$store.commit("set_reload_actions");
		},
		openWinAction(ac_id) {
			if (this.$_.isPlainObject(ac_id)) ac_id = ac_id.ac_id;
			this.$refs.actionwinedit.open(ac_id);
		},
		actionWinEditSaved() {
			this.$emit("actionWinEditSaved");
		}
		// async loadActions() {
		//   this.$emit("actionWinEditSaved");
		// }
	}
};
</script>
<style scoped></style>
