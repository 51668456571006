import { render, staticRenderFns } from "./OffersList.vue?vue&type=template&id=39051452&scoped=true&"
import script from "./OffersList.vue?vue&type=script&lang=js&"
export * from "./OffersList.vue?vue&type=script&lang=js&"
import style0 from "./OffersList.vue?vue&type=style&index=0&id=39051452&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39051452",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VList,VListItem,VListItemIcon,VListItemTitle})
