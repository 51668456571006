<template>
	<v-dialog v-model="dialog" max-width="500">
		<v-card class="content-modal">
			<v-card-title>
				<h2>Options d'impression</h2>
			</v-card-title>
			<v-card-text>
				<m-form-select
					class="mt-3"
					:name="$Utils.randomstring('typeFiche')"
					:items="itemsType"
					v-model="typeFiche"
					:label-width="6"
					label="Type de fiche"
				></m-form-select>
				<m-form-select
					:disabled="!(tosell && torent)"
					class="mt-1"
					:name="$Utils.randomstring('aVendreEtALouer')"
					:items="itemsAVendreEtALouer"
					v-model="aVendreEtALouer"
					:label-width="6"
					label="Si bien à vendre et à louer"
				></m-form-select>
				<m-form-select
					class="mt-1"
					:name="$Utils.randomstring('langSelected')"
					:items="$store.state.items_languages"
					v-model="langSelected"
					:label-width="6"
					label="Langue"
					:disabled="typeFiche === 'proprietaire' || typeFiche === 'negociateur' ? true : false"
				></m-form-select>
				<m-form-select
					class="mt-1"
					:name="$Utils.randomstring('quality')"
					:items="itemsQuality"
					v-model="quality"
					:label-width="6"
					label="Qualité des images"
				></m-form-select>
				<m-form-checkbox
					label="Avec logo et adresse"
					:name="$Utils.randomstring('withLogo')"
					v-model="withLogo"
					class="mt-3 "
					:disabled="typeFiche === 'proprietaire' || typeFiche === 'negociateur' ? true : false"
				></m-form-checkbox>
				<m-form-checkbox
					label="Avec prix"
					:name="$Utils.randomstring('withPrice')"
					v-model="withPrice"
					class="mt-3 ml-3"
					:disabled="typeFiche === 'proprietaire' || typeFiche === 'negociateur' ? true : false"
				></m-form-checkbox>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn class="btn-cancel" @click="deleteWin">Annuler</v-btn>
				<v-btn class="btn" @click="printWin">imprimer</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "offersPrintDialog",
	props: {
		value: Boolean,
		tosell: { type: Boolean, default: true },
		torent: { type: Boolean, default: true }
	},
	data() {
		return {
			dialog: false,
			langSelected: "fr",
			withLogo: true,
			withPrice: true,
			typeFiche: "simple",
			aVendreEtALouer: 1,
			quality: "5",
			itemsType: [
				{ text: "Fiche simple", value: "simple" },
				{ text: "Fiche complete", value: "complete" },
				{ text: "Fiche propriétaire", value: "proprietaire" },
				{ text: "Fiche négociateur", value: "negociateur" }
			],
			itemsQuality: [
				{ text: "Qualité 1 (la plus basse)", value: "1" },
				// { text: "Qualité 2", value: "2" },
				{ text: "Qualité 3", value: "3" },
				// { text: "Qualité 4", value: "4" },
				{ text: "Qualité 5", value: "5" },
				// { text: "Qualité 6", value: "6" },
				{ text: "Qualité 7", value: "7" },
				// { text: "Qualité 8", value: "8" },
				// { text: "Qualité 9", value: "9" },
				{ text: "Qualité 10 (la meilleure)", value: "10" },
				{ text: "Photos originales", value: "11" }
			],
			itemsAVendreEtALouer: [
				// { text: "Dupliquer la 1ere page", value: true },
				// { text: "Ne pas dupliquer la 1ere page", value: false }
			]
		};
	},
	mounted() {
		// console.log("this.typeFiche", this.typeFiche, this.aVendreEtALouer, this.langSelected, this.aVendreEtALouer);
		this.setItemsAvendreALouer();
	},
	watch: {
		value: function(val) {
			this.dialog = val;
			if (val) this.setItemsAvendreALouer();
		}
	},
	components: {},
	methods: {
		setItemsAvendreALouer() {
			this.itemsAVendreEtALouer = [
				{ text: "Dupliquer la 1ere page", value: 1 },
				{ text: "Ne pas dupliquer la 1ere page", value: 0 }
			];
			if (this.tosell && this.itemsAVendreEtALouer.findIndex(item => item.value === "onlysell") < 0)
				this.itemsAVendreEtALouer.push({ text: "Uniquement A VENDRE en 1ere page", value: "onlysell" });
			if (this.torent && this.itemsAVendreEtALouer.findIndex(item => item.value === "onlyrent") < 0)
				this.itemsAVendreEtALouer.push({ text: "Uniquement A LOUER en 1ere page", value: "onlyrent" });
			if (this.tosell && this.torent) {
				this.aVendreEtALouer = 1;
			} else {
				if (this.tosell) this.aVendreEtALouer = "onlysell";
				if (this.torent) this.aVendreEtALouer = "onlyrent";
			}
		},
		printWin() {
			this.$emit("print", {
				langue: this.langSelected,
				typeFiche: this.typeFiche,
				duplicate1page: this.aVendreEtALouer === 1 ? true : this.aVendreEtALouer === 0 ? false : this.aVendreEtALouer,
				withLogo: this.withLogo,
				withPrice: this.withPrice,
				quality: this.quality
			});
		},
		deleteWin() {
			this.$emit("input", false);
		}
	}
};
</script>
<style scoped>
.content-modal {
	font-size: 18px !important;
}
.btn {
	color: #fff !important;
	background-color: #2096f3 !important;
}
.btn-cancel {
	color: #fff !important;
	background-color: #808080 !important;
}
</style>
