<template>
	<v-dialog v-model="dialog" persistent :transition="false" max-width="450px" ref="winOffer">
		<v-card>
			<v-card-title>
				<div>
					<h1>
						Edition d'un
						<span class="bis">élément</span>
					</h1>
				</div>
			</v-card-title>

			<v-card-text grid-list-xl :style="stylecontainer">
				<div>
					<m-form-select
						:items="$store.state.items_lists"
						v-model="row_li.li_name"
						label="Nom de la liste"
						label-position="top"
					></m-form-select>
				</div>
				<div>
					<m-form-text
						type="text"
						:name="$Utils.randomstring('value')"
						outline
						label="Clé (ne pas modifier)"
						label-position="top"
						v-model="row_li.value"
					></m-form-text>
				</div>
				<div>
					<m-form-text
						type="text"
						:name="$Utils.randomstring('text')"
						outline
						label="Valeur"
						label-position="top"
						v-model="row_li.text"
					></m-form-text>
				</div>
				<div>
					<m-form-text
						type="text"
						:name="$Utils.randomstring('option')"
						outline
						label="Options (champs particulier, voir documentation)"
						label-position="top"
						v-model="row_li.options"
					></m-form-text>
				</div>
			</v-card-text>

			<v-card-actions>
				<v-btn color="error2" @click="confirmDeleteDialog = true" :disabled="!isEditable">Effacer</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin()" :disabled="!isEditable">Enregistrer</v-btn>
			</v-card-actions>
		</v-card>
		<m-confirm-dialog
			v-model="confirmDeleteDialog"
			text="Souhaitez-vous effacer cet élément ?"
			title="Confirmation"
			@canceled="confirmDeleteDialog = false"
			@confirmed="deleteWin()"
		></m-confirm-dialog>
	</v-dialog>
</template>

<script>
export default {
	name: "ListWinEdit",
	data() {
		return {
			showComment: false,
			tabvisit: null,
			row_co_details: null,
			confirmDeleteDialog: false,
			dialog: false,
			row_li: {},
			stylecontainer: ``
		};
	},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	computed: {
		isEditable() {
			return true;
		}
	},
	watch: {},
	props: ["offersSelected"],
	methods: {
		setContainerStyle() {
			if (!this.$refs.mycontainer) return;
			if (this.$refs.mycontainer.clientHeight > window.innerHeight - 300) this.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
			else this.stylecontainer = "";
		},
		open(li_id) {
			this.dialog = true;
			this.getList(li_id);
		},
		async getList(li_id) {
			let params = {};

			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/lists/" + li_id, { params });
			this.row_li = response.data.data;

			setTimeout(() => {
				this.setContainerStyle();
			}, 10);
		},
		async saveWin() {
			let response;
			if (this.row_li.li_id) {
				response = await this.$axios.put(this.$config.server_url + "/api/1.0/lists/" + this.row_li.li_id, this.row_li);
			} else {
				response = await this.$axios.post(this.$config.server_url + "/api/1.0/lists", this.row_li);
			}

			this.$emit("listWinEditSaved", response.data.data.li_id);
			this.dialog = false;
		},
		cancelWin() {
			this.$emit("listWinEditCanceled", this.row_li.li_id);
			this.dialog = false;
		},
		async deleteWin() {
			let li_id = this.row_li.li_id;
			await this.$axios.delete(this.$config.server_url + "/api/1.0/lists/" + li_id);
			this.confirmDeleteDialog = false;
			this.dialog = false;
			this.$emit("listWinEditSaved", li_id);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
