import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

Vue.use(Vuex);

let accesstoken = window.localStorage.getItem("accesstoken") ? window.localStorage.getItem("accesstoken") : "";
let refreshtoken = window.localStorage.getItem("refreshtoken") ? window.localStorage.getItem("refreshtoken") : "";
let defaultUser = {
	co_id: "",
	co_name: "",
	co_firstname: "",
	co_function: "",
	co_mobile1: "",
	co_tel1: "",
	co_email1: "",
	co_type: ""
};
let user = _.cloneDeep(defaultUser);

export default new Vuex.Store({
	state: {
		wasconnected: false,
		rooms_connected: [],
		currentcontact: null,
		// lastcoid: "",
		isMainLoading: false,
		mysocket: null,
		ficheAgent: false,
		// compteRenduToDo: [],
		nbClientSuivi: 0,
		nbBienSuivi: 0,
		// nbReportsToComplete: 0,
		reports_tocomplete: [],
		connected: false,
		accesstoken,
		refreshtoken,
		user,
		preferences: {
			personal: {},
			shared: {},
			rights: {},
			trads: {}
		},
		offers_sort: "",
		offers_sortorder: true,

		offersfilters: {
			immotools: false,
			room_1: false,
			room_2: false,
			room_3: false,
			room_4: false,
			room_5: false,
			confidential: false,
			archives: false,
			types: [],
			sell: false,
			rent: false,
			newprogram: false,
			new: false,
			budget: "",
			surface: "",
			agency: "",
			negociator: "",
			building: {},
			district: "",
			text: "",
			monaco: true,
			france: false,
			follows: false,
			hideagency: true,
			offerUpdatedAt: false
		},
		offersscrolltop: 0,
		offersskip: 0,
		items_languages: [],
		items_languages_all: [],
		items_countries: [],
		items_of_type: [],
		items_of_city: [],
		items_of_typecook: [],
		items_of_notary: [],
		items_of_typeheating: [],
		items_of_natureheating: [],
		items_of_typeboiler: [],
		items_of_generalstate: [],
		items_of_sunny: [],
		items_of_status: [],
		items_of_web: [],
		items_of_ci: [],
		items_of_disponibility: [],
		items_of_honoraires: [],
		items_of_sign: [],
		items_of_typemandat: [],
		items_of_exclusivite: [],
		items_of_rent_regimebail: [],
		items_of_classeenergie: [],
		items_of_classegaes: [],
		items_bu_standing: [],
		items_bu_construction: [],
		items_bu_roof: [],
		items_bu_facade: [],
		items_of_urgency: [],
		items_bu_syndic: [
			{ value: 0, text: "Choisir" },
			{ value: 1, text: "syndic1" },
			{ value: 2, text: "syndic2" }
		],
		items_bu_state: [],

		items_co_type: [],
		items_feedbacks: [],
		items_feedbackstypes: [],
		items_co_civilites: [],

		items_ac_type: [],
		items_ac_type_signature: [],
		items_ac_type_call: [],
		items_page_right: [],

		items_frame_details: [],
		items_frame_tosell: [],
		items_frame_building: [],
		items_frame_torent: [],
		items_frame_visibility: [],
		items_frame_diagnostics: [],
		items_frame_mandat: [],
		items_frame_commercial: [],
		items_frame_details: [],
		items_fi_subtype: [
			{ value: "photo", text: "Photo" },
			{ value: "plan", text: "Plan" },
			{ value: "illustration", text: "Illustration" },
			{ value: "map", text: "Carte" },
			{ value: "other", text: "Autre" },
			{ value: "building", text: "Immeuble" }
		],
		items_lists: [
			{
				value: "items_of_classegaes",
				text: "Biens > Class. gaz à eff. de serre"
			},
			{
				value: "items_of_classeenergie",
				text: "Biens > Classification énergétique"
			},
			{ value: "items_bu_construction", text: "Biens > Construction" },
			{ value: "items_of_disponibility", text: "Biens > Disponibilité" },
			{ value: "items_of_typeboiler", text: "Biens > Eau chaude" },
			{ value: "items_of_sunny", text: "Biens > Ensoleillement" },
			{ value: "items_of_exposition", text: "Biens > Exposition" },
			{ value: "items_of_generalstate", text: "Biens > Etat général" },
			{ value: "items_of_exclusivite", text: "Biens > Exclusivité" },
			{ value: "items_of_natureheating", text: "Biens > Nature de chauffage" },
			{ value: "items_of_notary", text: "Biens > Notaire" },
			{ value: "items_of_sign", text: "Biens > Vitrine" },
			{ value: "items_bu_district", text: "Biens > Quartiers" },
			{ value: "items_bu_standing", text: "Biens > Standing" },
			{ value: "items_of_typesubscription", text: "Biens > Type d'abonnement eau" },
			{ value: "items_of_rent_regimebail", text: "Biens > Type de bail" },
			{ value: "items_of_type", text: "Biens > Type de biens" },
			{ value: "items_of_typeheating", text: "Biens > Type de chauffage" },
			{ value: "items_of_typecook", text: "Biens > Type de cuisine" },
			{ value: "items_bu_facade", text: "Biens > Type de façade" },
			{ value: "items_of_rent_typecharge", text: "Biens > Type de charge" },
			{ value: "items_of_typemandat", text: "Biens > Type de mandat" },
			{ value: "items_bu_roof", text: "Biens > Type de toit" },
			{ value: "items_of_urgency", text: "Biens > Urgence" }
		],
		items_co_lab3: [
			{ value: 0, text: "En cours" },
			{ value: 1, text: "Validé" },
			{ value: 2, text: "*DS*" }
		],
		items_of_immotools: [
			{ value: 0, text: "REMC" },
			{ value: 1, text: "Immotools" }
		],

		reload_actions: 0
	},
	mutations: {
		set_wasconnected(state, val) {
			state.wasconnected = val;
		},
		set_rooms_connected(state, rooms) {
			// console.log("rooms", rooms);
			state.rooms_connected = rooms;
		},
		set_currentcontact(state, val) {
			state.currentcontact = val;
		},
		set_mainisloading(state, ok) {
			state.isMainLoading = ok;
		},
		// set_lastcoid(state, co_id) {
		//   state.lastcoid = co_id;
		// },
		set_mysocket(state, socket) {
			state.mysocket = socket;
		},
		set_reload_actions(state) {
			state.reload_actions = Math.random();
		},
		// utiliser simplement pour mettre à jour les infos essentielles
		set_user_simple(state, user) {
			state.user.co_name = user.co_name;
			state.user.co_firstname = user.co_firstname;
			state.user.co_type = user.co_type;
			state.user.updatedAt = user.updatedAt;
		},
		set_tokens(state, what) {
			axios.defaults.headers.common["x-auth-webaccesstoken"] = what.accesstoken;
			window.localStorage.setItem("accesstoken", what.accesstoken);
			state.accesstoken = what.accesstoken;
			window.localStorage.setItem("refreshtoken", what.refreshtoken);
			state.refreshtoken = what.refreshtoken;
		},
		set_connexion(state, what) {
			if (!what.accesstoken) {
				axios.defaults.headers.common["x-auth-webaccesstoken"] = "";
				window.localStorage.removeItem("accesstoken");
				state.accesstoken = null;
				window.localStorage.removeItem("refreshtoken");
				state.refreshtoken = null;
				window.localStorage.removeItem("user");
				state.user = _.cloneDeep(defaultUser);
				state.connected = false;
				return;
			}
			// console.log("token, refreshtoken, user", what.accesstoken, what.refreshtoken, what.user);
			axios.defaults.headers.common["x-auth-webaccesstoken"] = what.accesstoken;
			window.localStorage.setItem("accesstoken", what.accesstoken);
			state.accesstoken = what.accesstoken;
			window.localStorage.setItem("refreshtoken", what.refreshtoken);
			state.refreshtoken = what.refreshtoken;
			window.localStorage.setItem("user", JSON.stringify(what.user));
			state.user = _.cloneDeep(what.user);
			state.preferences = _.cloneDeep(what.preferences);
			// console.log("state.preferences.trads", state.preferences.trads);
			// console.log("state.preferences.personal", state.preferences.personal.azure);
			// state.forMonaco = true;
			// console.log("what.preferences", what.preferences);
			_.each(what.preferences.items, (val, key) => {
				state[key] = what.preferences.items[key];
			});

			state.connected = true;
			this.dispatch("load_nbclientsuivi");
			this.dispatch("socketjoinroom");
		},
		set_offersfilters(state, offersfilters) {
			//console.log("offersfilters", offersfilters);
			state.offersfilters = _.cloneDeep(offersfilters);
		},
		set_offersscrolltop(state, scrollTop) {
			state.offersscrolltop = scrollTop;
		},
		set_offerssort(state, sort, sortorder) {
			state.offers_sort = sort;
			state.offers_sortorder = sortorder;
		},
		set_offersskip(state, skip) {
			state.offersskip = skip;
		},
		set_preferences(state, data) {
			state.preferences[data.preference] = data.data;
		},
		async set_nbclientsuivi(state, data) {
			state.nbClientSuivi = data;
		},
		async set_nbbiensuivi(state, data) {
			state.nbBienSuivi = data;
		},
		// set_sharedPreference(state, data) {
		// 	state.user.sharedpreference = data;
		// },
		set_reportsToComplete(state, data) {
			state.reports_tocomplete = data;
		},
		set_ficheAgent(state, data) {
			state.ficheAgent = data;
		}
		// set_socketCalendarEvent(state, data) {
		// 	state.socketCalendarEvent = data;
		// }
	},
	actions: {
		async refresh_rooms_connected(ctx) {
			if (ctx.state.mysocket) ctx.state.mysocket.emit("refresh_rooms_connected");
		},
		socketjoinroom(ctx, socket) {
			if (socket) ctx.commit("set_mysocket", socket);
			if (ctx.state.user && ctx.state.user.co_id && ctx.state.mysocket) ctx.state.mysocket.emit("join", ctx.state.user.co_id);
		},
		// SOCKET_connect(ctx) {
		// 	console.log("this.$socket", this.$socket);
		// },
		// SOCKET_calendar_event(ctx, data) {
		// 	console.warn("calendar_event", data);
		// 	ctx.commit("set_socketCalendarEvent", data);
		// },
		async load_nbclientsuivi(state) {
			let currentuser = this.state.user;
			if (!currentuser || !currentuser.co_id) return;
			let response = await axios.get(process.env.VUE_APP_SERVER_URL + "/api/1.0/followers/contact/" + currentuser.co_id);
			this.commit("set_nbclientsuivi", response.data.followscontacts.length);
			this.commit("set_nbbiensuivi", response.data.followsoffers.length);

			for (let index = 0; index < response.data.reports_tocomplete.length; index++) {
				const element = response.data.reports_tocomplete[index];
				moment(element.ac_date).isBefore(moment().subtract(2, "d")) ? (element.classDate = "dateCRred") : (element.classDate = "dateCRgreen");
			}
			this.commit("set_reportsToComplete", response.data.reports_tocomplete);
		}
	},
	// setters: {
	//   currentcontact(state)
	// },
	getters: {
		get_nbclientsuivi(state) {
			return state.nbClientSuivi;
		},
		get_nbbiensuivi(state) {
			return state.nbBienSuivi;
		},
		get_user(state) {
			return state.user;
		},
		// get_compteRenduToDo(state) {
		// 	return state.compteRenduToDo;
		// },
		get_ficheAgent(state) {
			return state.get_ficheAgent;
		}
	}
});
