<template>
	<v-layout row mx-0>
		<v-card class="fill-height">
			<v-card-text class="fill-height d-flex flex-column">
				<div class="flex-grow-0" v-if="$store.state.preferences.rights.calendar_showall">
					<v-layout row wrap>
						<v-flex md10>
							<v-combobox
								v-model="selectedUsers"
								:items="users"
								:name="$Utils.randomstring('users')"
								label="Sélectionner les agents pour afficher leur calendrier"
								multiple
								small-chips
								solo
							>
								<template v-slot:selection="{ attrs, item, parent, selected }">
									<v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" small>
										<v-avatar
											:color="
												`${item.co_preferences && item.co_preferences.color ? item.co_preferences.color.hex : defaultColor}`
											"
											left
											v-text="item.co_name.slice(0, 1).toUpperCase()"
										></v-avatar>
										<span class="pr-2">{{ item | formatContactName }}</span>
										<span @click="cancelItem(item)">
											<icon class="icon-cross withpointer" name="times"></icon>
										</span>
									</v-chip>
								</template>

								<template v-slot:item="{ index, item }">
									<v-chip small class="ships-in-list">
										<v-avatar
											:color="
												`${item.co_preferences && item.co_preferences.color ? item.co_preferences.color.hex : defaultColor}`
											"
											left
											v-text="item.co_name.slice(0, 1).toUpperCase()"
										></v-avatar>
										<span class="pr-2">{{ item | formatContactName }}</span>
									</v-chip>
								</template>
							</v-combobox>
						</v-flex>
						<v-flex md2 d-flex justify-center v-if="$store.state.preferences.shared.useAzure">
							<v-menu offset-y>
								<template v-slot:activator="{ on }">
									<div>
										<v-img src="/img/outlook.png" height="40px" width="40px" class="mt-3"></v-img>
									</div>
									<div class="ml-2 pt-5" style="line-height: 0.75rem">
										<div v-if="$store.state.preferences.personal.azure" v-on="on">
											Compte Azure
											<br />
											<b>{{ $store.state.preferences.personal.azure.name }}</b>
										</div>
										<div v-else>
											<a @click="$router.push('/preferences')">Configurer un compte Azure</a>
										</div>
									</div>
								</template>
								<v-list>
									<v-list-item @click="forceAzureSynchro">
										<v-list-item-title>Forcer la synchronisation</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-flex>
					</v-layout>
				</div>

				<div class="flex-grow-1 overflow-hidden">
					<calendar-details
						ref="calendarDetails"
						:users="selectedUsers"
						@calendarWinEditOpen="calendarWinEditOpen"
						@calendarWinEditSaved="calendarWinEditSaved"
						@calendarWinEditCanceled="calendarWinEditCanceled"
						@calendarWinEditDeleted="calendarWinEditDeleted"
					></calendar-details>
				</div>
			</v-card-text>
		</v-card>
	</v-layout>
</template>

<script>
export default {
	name: "calendar",
	components: {},

	data() {
		let selectedUsers = [this.$store.state.user];
		return {
			select: [],
			users: [],
			selectedUsers,
			show: false,
			defaultColor: "#2096f3"
		};
	},
	watch: {
		selectedUsers(val) {
			for (let ico = 0; ico < val.length; ico++) {
				const co = val[ico];
				let indexCo = this.$_.findIndex(this.users, { co_id: co.co_id });
				if (indexCo >= 0) {
					this.users.splice(indexCo, 1);
				}
			}
		}
		// $route(to, from) {
		//   if (to.meta.edit) this.$refs.CalendarWinEdit.open();
		// }
	},
	async mounted() {
		// get agent for combobox and select me
		await this.getAgents();
		// let user = this.$store.getters.get_user;
		// this.selectedUsers.push(user);
		// let ve_id = this.$route.params.ve_id;
		// // await this.getEvent(ve_id);
		// if (this.$route.meta.edit) this.$refs.CalendarWinEdit.open(ve_id);
		// this.$refs.calendarDetails
		let ve_id = this.$route.params.ve_id;
		// await this.getEvent(ve_id);
		if (this.$route.meta.editcalendar) {
			if (ve_id == "-1") {
				this.$refs.calendarDetails.openCalendarWinEdit(
					-1,
					this.$moment(this.$route.params.start, "YYYY-MM-DD-HH-mm"),
					this.$moment(this.$route.params.end, "YYYY-MM-DD-HH-mm"),
					this.$route.params.allday
				);
			} else {
				this.$refs.calendarDetails.openCalendarWinEdit(this.$route.params.ve_id);
			}
		}
		//   this.$refs.calendarDetails.$refs.CalendarWinEdit.open(ve_id);
	},
	destroyed() {},
	methods: {
		async getAgents() {
			let params = { gr_id: "users" };
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts", { params });
			this.users = response.data.data;
			// this.users.sort((a, b) => {
			//   return a.co_name.localeCompare(b.co_name);
			// });
		},
		cancelItem(item) {
			let indexToCancel = this.$_.findIndex(this.selectedUsers, {
				co_id: item.co_id
			});
			if (indexToCancel >= 0) {
				this.selectedUsers.splice(indexToCancel, 1);
			}
			this.users.push(item);
			this.users.sort((a, b) => {
				return a.co_name.localeCompare(b.co_name);
			});
		},
		calendarWinEditOpen(ve_id, start, end, allday) {
			if (ve_id == -1) {
				this.$router.push(
					"/calendar/-1/edit/" + start.format("YYYY-MM-DD-HH-mm") + "/" + end.format("YYYY-MM-DD-HH-mm") + "/" + (allday ? 1 : 0)
				);
			} else {
				this.$router.push("/calendar/" + ve_id + "/edit");
			}
			// this.$refs.calendarDetails.$refs.CalendarWinEdit.open(
			//   ve_id,
			//   start,
			//   end,
			//   allday
			// );
		},
		calendarWinEditSaved(ve_id) {
			// console.log("this.$router", this.$route);
			if (this.$route.fullPath != "/calendar") this.$router.push("/calendar");
		},
		calendarWinEditCanceled(ve_id) {
			if (this.$route.fullPath != "/calendar") this.$router.push("/calendar");
		},
		calendarWinEditDeleted(ve_id) {
			if (this.$route.fullPath != "/calendar") this.$router.push("/calendar");
		},
		async forceAzureSynchro() {
			let params = {};
			await this.$axios.get(this.$config.server_url + "/api/1.0/events/forceazuresynchro", { params });
			this.$refs.calendarDetails.reloadEvents();
		}
	}
};
</script>
<style scoped>
.ships-in-list {
	width: 200px;
}
</style>
