<template>
	<v-dialog v-model="dialog" persistent :transition="false" max-width="1100px" ref="winContact">
		<v-card>
			<v-card-title>
				<div class="d-flex flex-row winedit-container-header">
					<h1>
						Edition d'un
						<span class="bis">contact {{ row_co | formatContactName }}</span>
					</h1>
					<!-- <div class="d-flex flex-row">
            <div style="font-size: 15px;">
              <m-combobox-contact
                :name="$Utils.randomstring('co_id_follower')"
                v-model="agentfollower"
                :label-width="5"
                class="ml-4"
                :value="agentfollower"
              />
            </div>
            <v-switch
              :name="$Utils.randomstring('co_followed')"
              v-model="clientsuivi"
              color="#53bd7b"
              :inset="true"
              :light="true"
              label="Contact suivi"
              class="mt-0 ml-4 mr-2"
            ></v-switch>
          </div>-->
				</div>
			</v-card-title>
			<!-- <v-card-text grid-list-xl> -->
			<v-card-text :style="stylecontainer">
				<m-form ref="mainform" :rules="[validateMainForm]">
					<v-container fluid pa-0 grid-list-xl formlittle ref="mycontainer">
						<v-card class="card1">
							<v-card-text>
								<v-layout>
									<v-flex md4>
										<m-form-select
											:items="$store.state.items_co_type"
											v-model="row_co.co_type"
											label="Type"
											:name="$Utils.randomstring('co_type')"
											:rules="[$Validation.mandatory]"
										></m-form-select>
									</v-flex>
									<v-flex md8 v-if="row_co.co_type === 'contact'">
										<v-layout align-center justify-start ma-0 flex-wrap>
											<m-form-checkbox
												label="Propriétaire"
												:name="$Utils.randomstring('co_isowner')"
												v-model="row_co.co_isowner"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Locataire"
												:name="$Utils.randomstring('co_isrenter')"
												v-model="row_co.co_isrenter"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Négociateur immo"
												:name="$Utils.randomstring('co_isagent')"
												v-model="row_co.co_isagent"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Concierge"
												:name="$Utils.randomstring('co_iscaretaker')"
												v-model="row_co.co_iscaretaker"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Avocat"
												:name="$Utils.randomstring('co_islawyer')"
												v-model="row_co.co_islawyer"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Société"
												:name="$Utils.randomstring('co_issociety')"
												v-model="row_co.co_issociety"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Agence immo"
												:name="$Utils.randomstring('co_isagency')"
												v-model="row_co.co_isagency"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Syndic"
												:name="$Utils.randomstring('co_issyndic')"
												v-model="row_co.co_issyndic"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Immeuble"
												:name="$Utils.randomstring('co_isbuilding')"
												v-model="row_co.co_isbuilding"
												class="mr-3"
											></m-form-checkbox>
											<m-form-checkbox
												label="Intermédiaire/apporteur d'affaires"
												:name="$Utils.randomstring('co_isintermediaire')"
												v-model="row_co.co_isintermediaire"
												class="mr-3"
											></m-form-checkbox>
										</v-layout>
									</v-flex>
									<v-flex md4 v-if="row_co.co_type != 'contact'">
										<m-form-text
											:name="$Utils.randomstring('co_login')"
											v-model="row_co.co_login"
											label="Identifiant"
											:label-width="4"
										></m-form-text>
									</v-flex>
									<v-flex md4 v-if="row_co.co_type != 'contact'">
										<m-form-text
											type="password"
											:name="$Utils.randomstring('co_password')"
											v-model="row_co.co_password"
											label="Mot de passe"
											:label-width="4"
										></m-form-text>
									</v-flex>
								</v-layout>
								<div v-if="row_co.co_type == 'contact'" style="max-width: 32%">
									<m-form-combobox
										v-model="row_co.co_id_commercial"
										label="Négociateur"
										:label-width="5"
										prepend-icon="mdi-account"
										:name="$Utils.randomstring('co_id_commercial')"
										:store-url="$config.server_url + '/api/1.0/contacts'"
										:store-params="{ addnone: true, gr_id: 'users', ignoremiglior: true }"
										item-value="co_id"
										:item-text="$options.filters.formatContactName"
									></m-form-combobox>
								</div>
							</v-card-text>
						</v-card>
						<v-card class="mt-3 card2">
							<v-card-text>
								<v-layout>
									<v-flex md2>
										<m-form-select
											v-if="!row_co.co_issociety && !row_co.co_isbuilding"
											:items="$store.state.items_co_civilites"
											v-model="row_co.co_civility"
											label="Civilité"
											:name="$Utils.randomstring('co_civility')"
										></m-form-select>
										<div class="d-flex">
											<div style="width: 25px; height: 25px">
												<v-img
													v-if="row_co.co_avatar"
													:src="
														$config.server_url +
															'/api/1.0/avatar/' +
															row_co.co_id +
															'/25/25?d=' +
															row_co.updatedAt +
															'&token=' +
															$store.state.accesstoken
													"
													height="25px"
													width="25px"
													class="rounded-image"
												></v-img>
											</div>
											<m-form-file class="input-avatar" label="avatar" ref="inputAvatar" @inputfile="saveAvatar" />
										</div>
										<m-form-checkbox
											v-if="row_co.co_avatar"
											label="Effacer l'avatar"
											v-model="row_co.deleteavatar"
										></m-form-checkbox>
									</v-flex>
									<v-flex md4>
										<m-form-text
											label="Nom"
											ref="co_name"
											:label-width="3"
											:name="$Utils.randomstring('co_name')"
											v-model="row_co.co_name"
										></m-form-text>
										<div v-if="!row_co.co_issociety && !row_co.co_isbuilding">
											<m-form-combobox
												v-model="row_co.co_id_society"
												label="Société"
												:label-width="3"
												:disabled="co_society_new != ''"
												prepend-icon="mdi-account"
												:name="$Utils.randomstring('co_id')"
												:store-url="$config.server_url + '/api/1.0/contacts'"
												:store-params="{ addnone: true, gr_id: 'societies' }"
												item-value="co_id"
												:item-text="$options.filters.formatContactName"
											></m-form-combobox>
											<m-form-text
												v-if="!row_co.co_id_society || !row_co.co_id_society.co_id || row_co.co_id_society.co_id == '0'"
												label="Nouvelle société"
												ref="co_society_new"
												:label-width="5"
												:name="$Utils.randomstring('co_society_new')"
												v-model="co_society_new"
											></m-form-text>
										</div>
									</v-flex>
									<v-flex md3>
										<m-form-text
											v-if="!row_co.co_issociety && !row_co.co_isbuilding"
											label="Prénom"
											:name="$Utils.randomstring('co_firstname')"
											v-model="row_co.co_firstname"
										></m-form-text>
										<m-form-text label="Siret" :name="$Utils.randomstring('co_siret')" v-model="row_co.co_siret"></m-form-text>
									</v-flex>
									<v-flex md3>
										<m-form-text
											v-if="!row_co.co_issociety && !row_co.co_isbuilding"
											label="Fonction"
											:name="$Utils.randomstring('co_function')"
											v-model="row_co.co_function"
										></m-form-text>
										<m-form-text label="Code" :name="$Utils.randomstring('co_code')" v-model="row_co.co_code"></m-form-text>
									</v-flex>
								</v-layout>
							</v-card-text>
						</v-card>
						<v-layout class="mt-3">
							<v-flex py-0>
								<v-card class="fill-height card3">
									<v-card-title class="pb-1">
										<h2>Coordonnées</h2>
									</v-card-title>
									<v-card-text>
										<m-form-text
											label="eMail 1"
											:name="$Utils.randomstring('co_email1')"
											v-model="row_co.co_email1"
										></m-form-text>
										<transition name="slide-fade">
											<m-form-text
												label="eMail 2"
												:name="$Utils.randomstring('co_email2')"
												v-show="row_co.co_email1"
												v-model="row_co.co_email2"
											></m-form-text>
										</transition>
										<transition name="slide-fade">
											<m-form-text
												label="eMail 3"
												:name="$Utils.randomstring('co_email3')"
												v-show="row_co.co_email2"
												v-model="row_co.co_email3"
											></m-form-text>
										</transition>
										<m-form-text
											label="Téléphone 1"
											:name="$Utils.randomstring('co_tel1')"
											v-model="row_co.co_tel1"
										></m-form-text>
										<transition name="slide-fade">
											<m-form-text
												label="Téléphone 2"
												:name="$Utils.randomstring('co_tel2')"
												v-model="row_co.co_tel2"
												v-show="row_co.co_tel1"
											></m-form-text>
										</transition>
										<transition name="slide-fade">
											<m-form-text
												label="Téléphone 3"
												:name="$Utils.randomstring('co_tel3')"
												v-model="row_co.co_tel3"
												v-show="row_co.co_tel2"
											></m-form-text>
										</transition>
										<m-form-text
											label="Mobile 1"
											:name="$Utils.randomstring('co_mobile1')"
											v-model="row_co.co_mobile1"
										></m-form-text>
										<transition name="slide-fade">
											<m-form-text
												label="Mobile 2"
												:name="$Utils.randomstring('co_mobile2')"
												v-show="row_co.co_mobile1"
												v-model="row_co.co_mobile2"
											></m-form-text>
										</transition>
										<transition name="slide-fade">
											<m-form-text
												label="Mobile 3"
												:name="$Utils.randomstring('co_mobile3')"
												v-show="row_co.co_mobile2"
												v-model="row_co.co_mobile3"
											></m-form-text>
										</transition>
										<m-form-text label="Fax 1" :name="$Utils.randomstring('co_fax1')" v-model="row_co.co_fax1"></m-form-text>
										<transition name="slide-fade">
											<m-form-text
												label="Fax 2"
												:name="$Utils.randomstring('co_fax2')"
												v-show="row_co.co_fax1"
												v-model="row_co.co_fax2"
											></m-form-text>
										</transition>
										<transition name="slide-fade">
											<m-form-text
												label="Fax 3"
												:name="$Utils.randomstring('co_fax3')"
												v-show="row_co.co_fax2"
												v-model="row_co.co_fax3"
											></m-form-text>
										</transition>
										<m-form-text label="Site web 1" :name="$Utils.randomstring('co_web1')" v-model="row_co.co_web1"></m-form-text>
										<transition name="slide-fade">
											<m-form-text
												label="Site web 2"
												:name="$Utils.randomstring('co_web2')"
												v-show="row_co.co_web1"
												v-model="row_co.co_web2"
											></m-form-text>
										</transition>
										<transition name="slide-fade">
											<m-form-text
												label="Site web 3"
												:name="$Utils.randomstring('co_web3')"
												v-show="row_co.co_web2"
												v-model="row_co.co_web3"
											></m-form-text>
										</transition>
									</v-card-text>
								</v-card>
							</v-flex>
							<v-flex py-0 px-0>
								<v-card class="fill-height card4">
									<v-card-title class="pb-1">
										<h2>Adresse</h2>
									</v-card-title>
									<v-card-text>
										<m-form-text
											label="Adresse 1"
											:name="$Utils.randomstring('co_address1')"
											v-model="row_co.co_address1"
										></m-form-text>
										<transition name="slide-fade">
											<m-form-text
												label="Adresse 2"
												:name="$Utils.randomstring('co_address2')"
												v-show="row_co.co_address1"
												v-model="row_co.co_address2"
											></m-form-text>
										</transition>
										<transition name="slide-fade">
											<m-form-text
												label="Adresse 3"
												:name="$Utils.randomstring('co_address3')"
												v-show="row_co.co_address2"
												v-model="row_co.co_address3"
											></m-form-text>
										</transition>
										<div class="d-flex flex-row">
											<div class="flex-grow-1">
												<m-form-text
													label="CP / Ville"
													:label-width="7"
													:name="$Utils.randomstring('co_zip')"
													v-model="row_co.co_zip"
												></m-form-text>
											</div>
											<div class="flex-grow-1">
												<m-form-text
													:label-width="0"
													:name="$Utils.randomstring('co_city')"
													v-model="row_co.co_city"
												></m-form-text>
											</div>
										</div>
										<m-form-select
											label="Pays"
											:items="$store.state.items_countries"
											:name="$Utils.randomstring('co_country')"
											v-model="row_co.co_country"
										></m-form-select>
									</v-card-text>
								</v-card>
							</v-flex>
							<v-flex py-0>
								<v-card class="fill-height card5">
									<v-card-title class="pb-1">
										<h2>Infos</h2>
									</v-card-title>
									<v-card-text>
										<!-- <v-textarea outline label="Commentaire" v-model="row_co.co_comment"></v-textarea> -->
										<m-form-text
											type="textarea"
											label="Commentaire"
											label-position="top"
											:name="$Utils.randomstring('co_comment')"
											v-model="row_co.co_comment"
										></m-form-text>
										<m-form-checkbox
											:name="$Utils.randomstring('co_voeux')"
											label="Voeux de fin d'année"
											:label-width="6"
											v-model="row_co.co_voeux"
										></m-form-checkbox>
									</v-card-text>
								</v-card>
							</v-flex>
						</v-layout>
						<v-card class="card4 mt-3" v-if="row_co.co_type === 'contact' && $store.state.preferences.shared.useFinancial">
							<v-card-title>
								<h1>Enveloppe financière</h1>
							</v-card-title>
							<v-card-text>
								<v-layout row wrap mx-0>
									<v-row class="d-flex flex-row">
										<v-col>
											<h2>Ressources</h2>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_annualincomes')"
												label="Revenu / mois"
												:label-width="9"
												v-model="row_co.co_annualincomes"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_familybenefits')"
												label="Allocations familliales / mois"
												:label-width="9"
												v-model="row_co.co_familybenefits"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_realincomes')"
												label="Revenus immobiliers / mois"
												:label-width="9"
												v-model="row_co.co_realincomes"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_othersincomes')"
												label="Autres revenus / mois"
												:label-width="9"
												v-model="row_co.co_othersincomes"
											></m-form-text>
										</v-col>
										<v-col>
											<h2>Charges</h2>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_alimonies')"
												label="Pensions alimentaires / mois"
												:label-width="9"
												v-model="row_co.co_alimonies"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_consumercredit')"
												label="Crédits consommation / mois"
												:label-width="9"
												v-model="row_co.co_consumercredit"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_automobilecredit')"
												label="Crédits automobile / mois"
												:label-width="9"
												v-model="row_co.co_automobilecredit"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_otherscredit')"
												label="Autres crédits / mois"
												:label-width="9"
												v-model="row_co.co_otherscredit"
											></m-form-text>
										</v-col>
										<v-col>
											<h2>Résultat</h2>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_ratedebt')"
												label="Taux endettement maxi (%)"
												:label-width="9"
												v-model="row_co.co_ratedebt"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_rateloan')"
												label="Taux du prêt (%)"
												:label-width="9"
												v-model="row_co.co_rateloan"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_durationloan')"
												label="Durée du prêt (années)"
												:label-width="9"
												v-model="row_co.co_durationloan"
											></m-form-text>
											<m-form-text
												type="number"
												:name="$Utils.randomstring('co_financialallocation')"
												label="Enveloppe financière"
												:label-width="9"
												v-model="row_co.co_financialallocation"
											></m-form-text>
										</v-col>
									</v-row>
								</v-layout>
							</v-card-text>
						</v-card>
						<v-card class="card5 mt-3" v-if="row_co.co_type === 'contact' && $store.state.preferences.shared.useLab">
							<v-card-title>
								<h1>
									Déclaration
									<span class="bis">LAB</span>
								</h1>
								<v-switch
									:name="$Utils.randomstring('co_lab0')"
									v-model="row_co.co_lab0"
									color="#53bd7b"
									:inset="true"
									:light="true"
									class="mt-0 ml-4 mr-2"
								></v-switch>
							</v-card-title>
							<v-card-text v-if="row_co.co_lab0">
								<div style="border: 1px solid black; padding: 15px">
									<h2>Cadre négociateur</h2>
									<m-form-checkbox
										:name="$Utils.randomstring('co_lab1')"
										label="Déclaré aux juristes"
										:label-width="6"
										v-model="row_co.co_lab1"
									></m-form-checkbox>
								</div>

								<div style="border: 1px solid black; padding: 15px; margin-top: 15px">
									<h2>Cadre juriste</h2>
									<v-layout row wrap ma-0>
										<v-row class="d-flex flex-row">
											<v-col>
												<m-form-date
													:name="$Utils.randomstring('co_lab4')"
													label="Date début relation d'affaire"
													:label-width="8"
													v-model="row_co.co_lab4"
												/>
												<m-form-date
													:name="$Utils.randomstring('co_lab5')"
													label="Date début du contrôle interne"
													:label-width="8"
													v-model="row_co.co_lab5"
												/>
												<m-form-date
													:name="$Utils.randomstring('co_lab6')"
													label="Date fin du contrôle interne"
													:label-width="8"
													v-model="row_co.co_lab6"
												/>
												<m-form-checkbox
													:name="$Utils.randomstring('co_lab18')"
													label="Pièces manquantes"
													:label-width="6"
													v-model="row_co.co_lab18"
												></m-form-checkbox>
												<m-form-checkbox
													:name="$Utils.randomstring('co_lab2')"
													label="Déclarer au responsable"
													:label-width="6"
													v-model="row_co.co_lab2"
												></m-form-checkbox>
											</v-col>
											<v-col>
												<m-form-text
													:name="$Utils.randomstring('co_lab7')"
													label="Etablissement bancaire"
													:label-width="7"
													v-model="row_co.co_lab7"
												></m-form-text>
												<m-form-text
													:name="$Utils.randomstring('co_lab8')"
													label="Notaire"
													:label-width="7"
													v-model="row_co.co_lab8"
												></m-form-text>
												<m-form-text
													:name="$Utils.randomstring('co_lab9')"
													label="Conseil juridique"
													:label-width="7"
													v-model="row_co.co_lab9"
												></m-form-text>
											</v-col>
											<v-col>
												<m-form-combobox
													v-model="row_co.co_lab12"
													label="Représentant"
													:label-width="5"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('co_lab12')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'contacts' }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>
												<m-form-combobox
													v-model="row_co.co_lab13"
													label="Bénéficiaire entreprise"
													:label-width="5"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('co_lab13')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'contacts' }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>
												<m-form-select
													:name="$Utils.randomstring('co_lab11')"
													:items="[
														{ value: 0, text: 'Non' },
														{ value: 1, text: 'Oui' }
													]"
													v-model="row_co.co_lab11"
													label="Profil à risque"
													:label-width="5"
												></m-form-select>
												<m-form-text
													:name="$Utils.randomstring('co_lab10')"
													label="Libre"
													:label-width="5"
													v-model="row_co.co_lab10"
												></m-form-text>
											</v-col>
										</v-row>
									</v-layout>
								</div>

								<div style="border: 1px solid black; padding: 15px; margin-top: 15px">
									<h2>Cadre responsable</h2>
									<m-form-select
										:name="$Utils.randomstring('co_lab3')"
										:items="$store.state.items_co_lab3"
										v-model="row_co.co_lab3"
										label="Statut actuel"
										:label-width="5"
									></m-form-select>
								</div>
							</v-card-text>
						</v-card>
					</v-container>
				</m-form>
			</v-card-text>
			<v-card-actions>
				<v-btn color="error2" @click="confirmdelete = true" :disabled="row_co.co_id ? false : true">Effacer</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin()">Enregistrer</v-btn>
			</v-card-actions>
		</v-card>
		<m-confirm-dialog
			v-model="confirmdelete"
			text="Souhaitez-vous effacer ce contact ?"
			title="Confirmation"
			@canceled="confirmdelete = false"
			@confirmed="deleteWin()"
		></m-confirm-dialog>

		<v-overlay absolute :value="overlayWin">
			<v-progress-circular indeterminate color="amber"></v-progress-circular>
		</v-overlay>
		<!-- <v-dialog v-model="confirmdelete" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          <v-layout>
            <v-icon large color="warning2">mdi-alert</v-icon>
            <h1>Confirm</h1>
          </v-layout>
        </v-card-title>
        <v-card-text>Êtes-vous certain de vouloir effacer ce contact ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary2" text @click="confirmdelete = false">Annuler</v-btn>
          <v-btn color="warning2" text @click="deleteWin()">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
	</v-dialog>
</template>

<script>
export default {
	name: "ContactWinEdit",
	components: {},
	data() {
		return {
			overlayWin: false,
			co_society_new: "",
			avatar: null,
			confirmdelete: false,
			dialog: false,
			row_co: {},
			stylecontainer: ``,
			clientsuivi: false
			// agentfollower: {}
			// switch1: false
		};
	},
	filters: {},
	computed: {},
	watch: {
		row_co() {
			this.setContainerStyle();
		}
		/* switch1(newValue) {
      //called whenever switch1 changes
      if (this.switch1) {
        this.row_co.co_id_follower = this.row_co.co_id_follower
          ? this.row_co.co_id_follower
          : this.$store.getters.get_user;
        this.row_co.co_followed = true;
      } else {
        this.row_co.co_id_follower = null;
        this.row_co.co_followed = false;
      }
    } */
	},

	props: {},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	created() {},
	methods: {
		saveAvatar(files) {
			if (files.length) this.avatar = files[0];
		},
		setContainerStyle() {
			this.$nextTick(() => {
				if (!this.$refs.mycontainer) return;
				if (this.$refs.mycontainer.clientHeight > window.innerHeight - 300)
					this.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
				else this.stylecontainer = "";
			});
		},
		open(co_id) {
			// let co_id = this.$route.params.co_id;
			this.dialog = true;
			this.getContact(co_id);
		},
		async getContact(co_id) {
			this.avatar = null;
			if (this.$refs.inputAvatar) this.$refs.inputAvatar.reset();
			let params = {};
			if (co_id == "-1") params = {};
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + co_id, { params });
			let row_co = response.data.data;
			this.row_co = row_co;
			// this.loadSuivi(row_co.co_id);

			setTimeout(() => {
				this.setContainerStyle();
			}, 10);
		},
		// async loadSuivi(co_id) {
		//   // let user = this.$store.getters.get_user;
		//   await this.$axios.get(
		//     this.$config.server_url + "/api/1.0/followers/contact/" + co_id
		//   );
		//   // console.log("response.data.data.co_id", response.data.data);
		//   // if (response.data.suivi) {
		//   //   this.clientsuivi = true;
		//   //   this.agentfollower = response.data.data;
		//   // } else {
		//   //   this.clientsuivi = false;
		//   //   this.agentfollower = null;
		//   // }
		// },
		async validateMainForm(vEls) {
			// console.log("vEls", vEls);
			if (!this.row_co.co_name) {
				this.$refs.co_name.informInvalid("Le nom d'un contact ou d'une société est obligatoire");
				return false;
			}
			return true;
		},
		async saveWin() {
			// console.log("this.avatar", this.avatar);
			if (!(await this.$refs.mainform.validate())) return;
			this.overlayWin = true;
			let response;
			delete this.row_co.events;
			delete this.row_co.newcontact_user;
			delete this.row_co.newoffer_user;
			delete this.row_co.properties;
			delete this.row_co.searches;
			delete this.row_co.co_preferences;
			delete this.row_co.co_searches;
			delete this.row_co.calls_user;
			delete this.row_co.calls_contact;
			delete this.row_co.todos_user;
			// delete this.row_co.todos_todo_user;
			delete this.row_co.todos_contact;
			delete this.row_co.todos_todo_contact;
			delete this.row_co.signatures_sell_user;
			delete this.row_co.signatures_sell_contact;
			delete this.row_co.signatures_rent_user;
			delete this.row_co.signatures_rent_contact;
			delete this.row_co.emails_user;
			delete this.row_co.emails_contact;
			delete this.row_co.presents_user;
			delete this.row_co.notinteresteds_contact;
			delete this.row_co.presents_contact;
			delete this.row_co.visits_user;
			delete this.row_co.visits_contact;
			delete this.row_co.reports_user;
			delete this.row_co.reports_contact;
			delete this.row_co.reports_tocomplete;

			this.row_co.co_society_new = this.co_society_new;
			if (this.row_co.co_id) {
				// if (this.row_co.co_id_follower) {
				//   this.row_co.co_id_follower = this.row_co.co_id_follower.co_id;
				// }
				response = await this.$axios.put(this.$config.server_url + "/api/1.0/contacts/" + this.row_co.co_id, this.row_co);
			} else {
				response = await this.$axios.post(this.$config.server_url + "/api/1.0/contacts", this.row_co);
			}
			let row_co = response.data.data;
			if (this.avatar) {
				let formData = new FormData();
				formData.append("co_id", row_co.co_id);
				formData.append("image", this.avatar);
				await this.$axios.post(this.$config.server_url + "/api/1.0/avatar", formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				});
			}

			if (row_co.co_id == this.$store.state.user.co_id) {
				this.$store.commit("set_user_simple", row_co);
			}
			this.$store.dispatch("load_nbclientsuivi");
			this.$emit("contactWinEditSaved", row_co.co_id);
			this.overlayWin = false;
			this.dialog = false;
		},
		cancelWin() {
			this.dialog = false;
			this.$emit("contactWinEditCanceled", this.row_co.co_id);
		},
		async deleteWin() {
			let co_id = this.row_co.co_id;
			await this.$axios.delete(this.$config.server_url + "/api/1.0/contacts/" + co_id);
			this.confirmdelete = false;
			this.dialog = false;
			this.$store.dispatch("load_nbclientsuivi");
			this.$emit("contactWinEditDeleted", co_id);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.slide-fade-enter-active {
	transition: all 0.5s ease;
}
.slide-fade-leave-active {
	transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
	transform: translateY(-10px);
	opacity: 0;
}
.winedit-container-header {
	width: 100%;
	justify-content: space-between;
}
.input-avatar {
	padding: 0;
}
</style>
