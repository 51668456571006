<template>
	<v-dialog v-model="dialog" persistent :transition="false" max-width="1400px" ref="winOffer">
		<v-card>
			<v-card-title>
				<div class="d-flex flex-row winedit-container-header">
					<h1>
						Edition du bien
						<span class="bis">{{ row_of.of_name_fr }}</span>
					</h1>
					<!-- <div class="d-flex flex-row"> -->
					<!-- <div style="font-size: 15px;">
              <m-combobox-contact
                :name="$Utils.randomstring('co_id_follower')"
                v-model="row_of.co_id_commercial"
                :label-width="5"
                class="ml-4"
              />
          </div>-->
					<!-- <v-switch
            :name="$Utils.randomstring('co_followed')"
            v-model="row_of.of_followed"
            color="#53bd7b"
            :inset="true"
            :light="true"
            label="Bien suivi"
            class="mt-0 ml-4 mr-2"
          ></v-switch>-->
					<!-- </div> -->
				</div>
			</v-card-title>
			<v-card-text style="height: calc(100vh - 253px); overflow: auto">
				<m-form ref="mainform" :rules="[validateMainForm]">
					<v-container fluid pa-0 grid-list-xl formlittle>
						<v-layout wrap>
							<v-flex md4 pr-0>
								<v-card class="card1">
									<v-card-title class="d-flex flex-row titreDetail">
										<h1>
											Type et
											<span class="bis">détails</span>
										</h1>
										<a @click="showField = !showField" class="hideField">{{
											showField ? "Masquer des champs" : "Afficher des champs"
										}}</a>
									</v-card-title>
									<v-card-text>
										<v-layout wrap>
											<v-flex md6 pb-0>
												<m-form-select
													:items="$store.state.items_of_type"
													v-model="row_of.of_type"
													label="Type de bien  <span style='color:red;font-size:18px;'>•</span>"
													label-position="top"
													:rules="[$Validation.mandatory]"
												></m-form-select>
												<m-form-text
													type="number"
													:name="$Utils.randomstring('of_surface')"
													class="alignright"
													label="Surface totale <span style='color:red;font-size:18px;'>•</span>"
													:label-width="8"
													suffix="m2"
													v-model="row_of.of_surface"
												></m-form-text>
											</v-flex>
											<v-flex md6 pb-0>
												<m-form-text
													:name="$Utils.randomstring('of_ref')"
													label="Référence <span style='color:red;font-size:18px;'>•</span>"
													label-position="top"
													v-model="row_of.of_ref"
													placeholder="Vide pour réf. auto."
													tooltip="Laissez vide pour une référence automatique.<br />Mettre un C pour créer une référence d'un bien confrère."
												></m-form-text>

												<m-form-checkbox
													:name="$Utils.randomstring('of_surface_approximate')"
													label="Surface approximative"
													v-model="row_of.of_surface_approximate"
												></m-form-checkbox>
											</v-flex>
										</v-layout>
										<v-layout wrap>
											<v-flex md6 py-0>
												<m-form-text
													type="number"
													:name="$Utils.randomstring('of_surface_livable')"
													class="alignright"
													label="Surface habitable <span style='color:red;font-size:18px;'>•</span>"
													:label-width="8"
													suffix="m2"
													v-model="row_of.of_surface_livable"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_surface_terrace')"
													label="Surface terrasse <span style='color:red;font-size:18px;'>•</span>"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_surface_terrace"
												></m-form-text>
												<m-form-text
													type="text"
													v-if="showField"
													:name="$Utils.randomstring('of_etage')"
													label="Étage <span style='color:red;font-size:18px;'>•</span>"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_etage"
												></m-form-text>
											</v-flex>
											<v-flex md6 py-0>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_surface_garden')"
													class="alignright"
													label="Surface jardin <span style='color:red;font-size:18px;'>•</span>"
													:label-width="8"
													v-model="row_of.of_surface_garden"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_surface_terrain')"
													class="alignright"
													label="Surface terrain <span style='color:red;font-size:18px;'>•</span>"
													:label-width="8"
													v-model="row_of.of_surface_terrain"
												></m-form-text>
											</v-flex>
										</v-layout>
										<v-layout wrap>
											<v-flex md4 py-0>
												<m-form-text
													type="number"
													:name="$Utils.randomstring('of_nbpieces')"
													label="Pièces <span style='color:red;font-size:18px;'>•</span>"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbpieces"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_nbcaves')"
													label="Caves <span style='color:red;font-size:18px;'>•</span>"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbcaves"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_nbbalconies')"
													label="Balcons"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbbalconies"
												></m-form-text>
											</v-flex>
											<v-flex md4 py-0 px-0>
												<m-form-text
													type="number"
													:name="$Utils.randomstring('of_nbrooms')"
													label="Chambres <span style='color:red;font-size:18px;'>•</span>"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbrooms"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_nbparkings')"
													label="Parking <span style='color:red;font-size:18px;'>•</span>"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbparkings"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_nbboxes')"
													label="Boxes <span style='color:red;font-size:18px;'>•</span>"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbboxes"
												></m-form-text>
											</v-flex>
											<v-flex md4 py-0>
												<m-form-text
													type="number"
													:name="$Utils.randomstring('of_nbbathrooms')"
													label="S. de bains"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbbathrooms"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_nbwc')"
													label="WC"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbwc"
												></m-form-text>
												<m-form-text
													type="number"
													v-if="showField"
													:name="$Utils.randomstring('of_nbfloor')"
													label="Niveaux"
													labelPosition="right"
													:label-width="8"
													class="alignright"
													v-model="row_of.of_nbfloor"
												></m-form-text>
											</v-flex>
										</v-layout>
										<v-layout wrap>
											<v-flex md12>
												<m-form-select
													v-if="showField"
													:name="$Utils.randomstring('of_typecook')"
													:items="$store.state.items_of_typecook"
													v-model="row_of.of_typecook"
													label="Type de cuisine"
													:label-width="5"
												></m-form-select>
												<m-form-select
													v-if="showField"
													:name="$Utils.randomstring('of_typeheating')"
													:items="$store.state.items_of_typeheating"
													v-model="row_of.of_typeheating"
													label="Type de chauffage"
													:label-width="5"
												></m-form-select>
												<m-form-select
													v-if="showField && !$store.state.preferences.shared.forMonaco"
													:name="$Utils.randomstring('of_natureheating')"
													:items="$store.state.items_of_natureheating"
													v-model="row_of.of_natureheating"
													label="Nature de chauffage"
													:label-width="5"
												></m-form-select>
												<m-form-select
													v-if="showField"
													:name="$Utils.randomstring('of_generalstate')"
													:items="$store.state.items_of_generalstate"
													v-model="row_of.of_generalstate"
													label="Etat général <span style='color:red;font-size:18px;'>•</span>"
													:label-width="5"
												></m-form-select>
												<m-form-select
													v-if="showField"
													:name="$Utils.randomstring('of_sunny')"
													:items="$store.state.items_of_sunny"
													v-model="row_of.of_sunny"
													label="Ensoleillement"
													:label-width="5"
												></m-form-select>
												<m-form-select
													v-if="showField"
													:name="$Utils.randomstring('of_exposition')"
													:items="$store.state.items_of_exposition"
													v-model="row_of.of_exposition"
													label="Exposition"
													:label-width="5"
												></m-form-select>
												<m-form-select
													v-if="showField && !$store.state.preferences.shared.forMonaco"
													:name="$Utils.randomstring('of_typeboiler')"
													:items="$store.state.items_of_typeboiler"
													v-model="row_of.of_typeboiler"
													label="Eau chaude"
													:label-width="5"
												></m-form-select
												><m-form-select
													v-if="showField"
													:name="$Utils.randomstring('of_typesubscription')"
													:items="$store.state.items_of_typesubscription"
													v-model="row_of.of_typesubscription"
													label="Type abonnement eau"
													:label-width="5"
												></m-form-select>
											</v-flex>
										</v-layout>

										<m-form-checkbox
											style="width: 180px"
											:name="$Utils.randomstring('of_newprogram')"
											v-model="row_of.of_newprogram"
											label="Programme Neuf"
											class=""
										></m-form-checkbox>
										<!-- 										<v-radio-group row>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_airconditioning')"
												v-model="row_of.of_airconditioning"
												label="Climatisation"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												label="Prestige"
												:name="$Utils.randomstring('of_prestige')"
												v-if="showField"
												v-model="row_of.of_prestige"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												label="Meublé"
												:name="$Utils.randomstring('of_furnished')"
												v-if="showField"
												v-model="row_of.of_furnished"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												v-if="!$store.state.preferences.shared.forMonaco"
												:name="$Utils.randomstring('of_newprogram')"
												v-model="row_of.of_newprogram"
												label="Programme neuf"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_modernbuilding')"
												v-model="row_of.of_modernbuilding"
												label="Immeuble moderne"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_bourgeoisbuilding')"
												v-model="row_of.of_bourgeoisbuilding"
												label="Immeuble bourgeois"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_concierge')"
												v-model="row_of.of_concierge"
												label="Concierge"
												class="mr-4"
											></m-form-checkbox>

											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_law1235')"
												v-model="row_of.of_law1235"
												label="Sous loi 1235"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_law887')"
												v-model="row_of.of_law887"
												label="Sous loi 887"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_freeoflaw')"
												v-model="row_of.of_freeoflaw"
												label="Libre de loi"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_penthouse')"
												v-model="row_of.of_penthouse"
												label="Penthouse"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_interestingview')"
												v-model="row_of.of_interestingview"
												label="Vue interessante"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_seaview')"
												v-model="row_of.of_seaview"
												label="Vue mer"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_individualhouse')"
												v-model="row_of.of_individualhouse"
												label="Maison individuelle"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_mixeduse')"
												v-model="row_of.of_mixeduse"
												label="Usage mixte"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												style="width: 180px"
												:name="$Utils.randomstring('of_roofterrace')"
												v-model="row_of.of_roofterrace"
												label="Toit terrasse"
												class="mr-4"
											></m-form-checkbox>
											<m-form-checkbox
												v-if="$store.state.preferences.shared.forMonaco"
												style="width: 180px"
												:name="$Utils.randomstring('of_before1947')"
												v-model="row_of.of_before1947"
												label="Avant 1947"
												class="mr-4"
											></m-form-checkbox>
										</v-radio-group> -->
										<v-layout wrap>
											<v-flex md12 pb-3 style="flex-wrap: wrap; display: flex">
												<div
													v-for="(service, index) in $store.state.preferences.items.items_of_services"
													:key="index"
													style="width: 50%"
												>
													<m-form-checkbox
														style="width: 180px"
														:label="labelService(service)"
														:name="$Utils.randomstring('of_furnished')"
														v-if="showField"
														v-model="services[service.valuetxt]"
														class="mr-4"
													></m-form-checkbox>
												</div>
											</v-flex>
										</v-layout>
										<p v-if="row_of.of_before1947" style="color: red">Droit de préemption de l'état</p>
									</v-card-text>
								</v-card>
								<offer-card-building
									v-if="row_of_done"
									ref="buildingCard"
									v-model="row_of.bu_id"
									@reloadofferimages="reloadofferimages"
									@calculMaxLength="calculMaxLength"
									:descriptionTooLong="descriptionTooLong"
								></offer-card-building>
								<card-files
									cardtitle="Fichiers partagés"
									btntext="Ajouter"
									ref="cardFiles"
									v-model="row_of.files"
									@loadFiles="loadFile"
								></card-files>
								<v-card class="card3 mt-3" v-if="$store.state.preferences.shared.useDiagnostic">
									<v-card-title>
										<h1>
											Diagnostics
											<span class="bis">immobiliers</span>
										</h1>
									</v-card-title>
									<v-card-text>
										<m-form-text
											type="number"
											:name="$Utils.randomstring('of_carrez')"
											label="Métrage Carrez"
											:label-width="7"
											suffix="m2"
											class="alignright"
											v-model="row_of.of_carrez"
										></m-form-text>
										<m-form-date
											:name="$Utils.randomstring('of_plomb')"
											v-model="row_of.of_plomb"
											label="Plomb (-1 an)"
											:label-width="7"
										/>
										<m-form-date
											:name="$Utils.randomstring('of_termite')"
											v-model="row_of.of_termite"
											label="Termite (-3 mois)"
											:label-width="7"
										/>
										<m-form-date
											:name="$Utils.randomstring('of_amiante')"
											v-model="row_of.of_amiante"
											label="Amiante"
											:label-width="7"
										/>
										<m-form-date
											:name="$Utils.randomstring('of_gaz')"
											v-model="row_of.of_gaz"
											label="Gaz (-1 an)"
											:label-width="7"
										/>
										<m-form-date
											:name="$Utils.randomstring('of_ernt')"
											v-model="row_of.of_ernt"
											label="ERNT (-6 mois)"
											:label-width="7"
										/>
										<m-form-date
											:name="$Utils.randomstring('of_dpe')"
											v-model="row_of.of_dpe"
											label="DPE (-10 ans)"
											:label-width="7"
										/>
										<m-form-text
											:name="$Utils.randomstring('of_consoenergie')"
											label="Consommation énergétique"
											:label-width="7"
											class="alignright"
											v-model="row_of.of_consoenergie"
										></m-form-text>
										<m-form-select
											:name="$Utils.randomstring('of_classeenergie')"
											:items="$store.state.items_of_classeenergie"
											v-model="row_of.of_classeenergie"
											label="Classification énergétique"
											:label-width="7"
										></m-form-select>
										<m-form-text
											:name="$Utils.randomstring('of_emissiongaes')"
											label="Conso. gaz à eff. de serre"
											:label-width="7"
											class="alignright"
											v-model="row_of.of_emissiongaes"
										></m-form-text>
										<m-form-select
											:name="$Utils.randomstring('of_classegaes')"
											:items="$store.state.items_of_classegaes"
											v-model="row_of.of_classegaes"
											label="Class. gaz à eff. de serre"
											:label-width="7"
										></m-form-select>
									</v-card-text>
								</v-card>
								<v-card class="mt-3" style="background-color: transparent">
									<v-card-text>
										<div class="caption">ID : {{ row_of.of_id }}</div>
										<div class="caption">Créé le : {{ row_of.createdAt | formatDateTime }}</div>
										<div class="caption">Modifié le : {{ row_of.updatedAt | formatDateTime }}</div>
									</v-card-text>
								</v-card>
							</v-flex>
							<v-flex md8>
								<v-card class="card2">
									<v-card-title>
										<h1>
											Nom et
											<span class="bis">description</span>
										</h1>
										<div style="font-size: 12px" class="ml-5">
											<span v-for="item in $store.state.items_languages" :key="item.value">
												<a
													v-if="row_of_done"
													@click="changeLang(item)"
													:class="!row_of['of_name_' + item.value] || !row_of['of_text_' + item.value] ? 'color-red' : ''"
													>{{ item.text }}</a
												>
												/
											</span>
										</div>
									</v-card-title>
									<v-card-text>
										<div v-for="item in $store.state.items_languages" :key="item.value">
											<m-form-text
												:ref="`of_name_${item.value}`"
												:name="$Utils.randomstring(`of_name_${item.value}`)"
												v-show="show_lang == item.value"
												:label="`Nom en ${item.text} <span style='color:red;font-size:18px;'>•</span>`"
												:label-width="3"
												v-model="row_of['of_name_' + item.value]"
											></m-form-text>
											<m-form-text
												type="textarea"
												:rows="4"
												:name="$Utils.randomstring(`of_text_${item.value}`)"
												v-show="show_lang == item.value"
												:label="
													`Description complète + Composition en ${item.text} <span style='color:red;font-size:18px;'>•</span>`
												"
												:label-width="3"
												v-model="row_of['of_text_' + item.value]"
												@input="calculMaxLength"
											></m-form-text>
											<div v-if="show_lang == item.value && descriptionTooLong[show_lang]" style="width:75%;margin-left:auto;">
												<p class="text-red">
													Description de l'offre plus description de l'immeuble trop longue en {{ item.text }}
												</p>
											</div>
											<m-form-text
												v-if="!$store.state.preferences.shared.forMonaco"
												type="textarea"
												:rows="2"
												:name="$Utils.randomstring(`of_summary_${item.value}`)"
												v-show="show_lang == item.value"
												:label="`Bref descriptif en ${item.text}`"
												:label-width="3"
												v-model="row_of['of_summary_' + item.value]"
											></m-form-text>
											<m-form-text
												v-if="!$store.state.preferences.shared.forMonaco"
												type="textarea"
												:rows="2"
												:name="$Utils.randomstring(`of_summary_web_${item.value}`)"
												v-show="show_lang == item.value"
												:label="`Composition web en ${item.text}`"
												:label-width="3"
												v-model="row_of['of_summary_web_' + item.value]"
											></m-form-text>
											<m-form-text
												:name="$Utils.randomstring(`of_slug_${item.value}`)"
												v-show="show_lang == item.value"
												:label="`Nom page web (url) en ${item.text}`"
												:label-width="3"
												v-model="row_of['of_slug_' + item.value]"
											></m-form-text>
											<!-- <label v-show="show_lang == item.value">Mots clés</label>
                      <v-autocomplete
                        :name="$Utils.randomstring('of_keywords')"
                        v-show="show_lang == item.value"
                        :items="$store.state.items_of_keywords"
                        v-model="row_of['of_keywords']"
                        chips
                        multiple
                        autocomplete
                      ></v-autocomplete> -->
										</div>
									</v-card-text>
								</v-card>
								<v-card class="card3 mt-3">
									<v-card-title class="pb-0 mb-0">
										<h1>
											Les images <span style="color:red;font-size:18px;">• </span>
											<span style="font-size:12px; color:black;">({{ nbweb }})</span>
											<v-menu offset-y>
												<template v-slot:activator="{ on }">
													<v-btn color="primary2" x-small style="margin-left: 30px" v-on="on">
														<v-icon>mdi-plus</v-icon>
													</v-btn>
												</template>
												<v-list>
													<v-list-item @click="$refs.inputImage.addImages()">
														<v-list-item-title>Ajouter une nouvelle image</v-list-item-title>
													</v-list-item>
													<!-- <v-list-item
														@click="$refs.inputImage.addBuildingImages($refs.buildingCard.getImages(), row_of.of_id)"
													>
														<v-list-item-title
															>Ajouter {{ imagesBuilding.length }} photo(s) de l'immeuble</v-list-item-title
														>
													</v-list-item> -->
												</v-list>
											</v-menu>
										</h1>
									</v-card-title>
									<v-card-text>
										<div class="mb-2">
											<m-form-checkbox
												label="Afficher les photos de l'immeuble"
												:name="$Utils.randomstring('of_showbuildingphotos')"
												v-model="row_of.of_showbuildingphotos"
												class="mr-3"
												@change="showBuildingPhotos()"
											></m-form-checkbox>
											<m-form-checkbox
												label="Recopier dans le pdf la photo de couverture"
												:name="$Utils.randomstring('of_pdfcopiephotos1')"
												v-model="row_of.of_pdfcopiephotos1"
												class="mr-3"
											></m-form-checkbox>
										</div>
										<m-form-images
											v-if="row_of_done"
											label="Joindre une ou des images JPG ou PNG"
											v-model="images"
											ref="inputImage"
											@reloadofferimages="reloadofferimages"
										/>
										<!-- <v-file-input
                      chips
                      multiple
                      label="Joindre une ou des images JPG ou PNG"
                      v-model="imagesFiles"
                      accept="image/*"
                    ></v-file-input>
                    <v-layout wrap align-center>
                      <div
                        v-for="(row_fi) in row_of.images"
                        :key="row_fi.fi_id"
                        style="padding:2px;"
                      >
                        <v-img
                          :src="`${$config.server_url}/api/1.0/images/${row_fi.fi_id}/75/75`"
                          height="75px"
                          width="75px"
                        ></v-img>
                      </div>
                    </v-layout>-->

										<div style="padding-left: 10px; padding-right: 10px">
											<m-form-text
												:name="$Utils.randomstring('of_urlvideo')"
												label="URL vidéo <span style='color:red;font-size:18px;'>•</span>"
												label-position="top"
												v-model="row_of.of_urlvideo"
												placeholder="Lien youtube"
											></m-form-text>
										</div>
										<div style="padding-left: 10px; padding-right: 10px">
											<m-form-text
												:name="$Utils.randomstring('of_urlvisitevirtuelle')"
												label="URL StreetView <span style='color:red;font-size:18px;'>•</span>"
												label-position="top"
												v-model="row_of.of_urlvisitevirtuelle"
												placeholder="Lien Street View"
											></m-form-text>
										</div>
									</v-card-text>
								</v-card>

								<v-layout>
									<v-flex md6 pr-0>
										<v-card class="card4 mt-0 md6">
											<v-card-title>
												<h1>
													Statut et
													<span class="bis">visibilité</span>
												</h1>
											</v-card-title>
											<v-card-text>
												<m-form-select
													:name="$Utils.randomstring('of_status')"
													:items="$store.state.items_of_status"
													v-model="row_of.of_status"
													label="Statut"
													:label-width="6"
													@change="changeOfStatus"
												></m-form-select>
												<m-form-select
													:name="$Utils.randomstring('of_sign')"
													:items="$store.state.items_of_sign"
													v-model="row_of.of_sign"
													label="Vitrine"
													:label-width="6"
												></m-form-select>

												<m-form-select
													:name="$Utils.randomstring('of_disponibility')"
													:items="$store.state.items_of_disponibility"
													v-model="row_of.of_disponibility"
													label="Disponibilité"
													:label-width="6"
												></m-form-select>
												<m-form-date
													:name="$Utils.randomstring('of_disponibility_date')"
													v-model="row_of.of_disponibility_date"
													label="Date de disponibilité <span style='color:red;font-size:18px;'>•</span>"
													:label-width="6"
												/>

												<m-form-date
													:name="$Utils.randomstring('of_date_entry')"
													v-model="row_of.of_date_entry"
													label="Date entrée du bien"
													:label-width="6"
												/>
												<m-form-select
													:name="$Utils.randomstring('of_exclusivite')"
													:items="$store.state.items_of_exclusivite"
													v-model="row_of.of_exclusivite"
													label="Exclusivité <span style='color:red;font-size:18px;'>•</span>"
													:label-width="6"
												></m-form-select>

												<!-- <m-form-checkbox
                          :name="$Utils.randomstring('of_window')"
                          label="Vitrine"
                          :label-width="6"
                          v-model="row_of.of_window"
                        ></m-form-checkbox> -->
												<m-form-checkbox
													class="mr-2"
													:name="$Utils.randomstring('of_correlation')"
													label="Pas de corrélation"
													:label-width="6"
													v-model="row_of.of_correlation"
												></m-form-checkbox>
												<m-form-checkbox
													class="mr-2"
													:name="$Utils.randomstring('of_confidential')"
													label="Confidentiel"
													:label-width="6"
													v-model="row_of.of_confidential"
												></m-form-checkbox>
												<m-form-checkbox
													:name="$Utils.randomstring('of_isnew')"
													label="Nouveau"
													:label-width="6"
													v-model="row_of.of_isnew"
												></m-form-checkbox>
												<div>&nbsp;</div>
												<m-form-select
													:name="$Utils.randomstring('of_web')"
													:items="$store.state.items_of_web"
													v-model="row_of.of_web"
													label="Sur internet <span style='color:red;font-size:18px;'>•</span>"
													:label-width="6"
												></m-form-select>
												<m-form-select
													:name="$Utils.randomstring('of_ci')"
													:items="$store.state.items_of_ci"
													v-model="row_of.of_ci"
													label="Sur Immotoolsbox <span style='color:red;font-size:18px;'>•</span>"
													:label-width="6"
													@change="changeOfCi"
												></m-form-select>

												<!-- <v-layout wrap>
													<v-flex md12 pb-3 style="flex-wrap: wrap; display: flex"> -->
												<div>Diffusions <span style="color:red;font-size:18px;">•</span> :</div>
												<div
													v-for="(diffusion, index) in $store.state.preferences.items.items_of_diffusions"
													:key="index"
													style="width: 100%"
												>
													<m-form-checkbox
														style="width: 180px"
														:label="diffusion.label"
														:name="$Utils.randomstring('of_diffusions')"
														v-if="showField"
														v-model="diffusions[diffusion.valuetxt]"
														class="mr-4"
													></m-form-checkbox>
												</div>
												<!-- </v-flex>
												</v-layout> -->
											</v-card-text>
										</v-card>
									</v-flex>
									<v-flex md6>
										<v-card class="card4 mt-0 fill-height">
											<v-card-title>
												<h1>
													Le
													<span class="bis">propriétaire</span> et le
													<span class="bis">mandat</span>
												</h1>
											</v-card-title>
											<v-card-text>
												<!-- <m-form-select
                        :items="$store.state.items_of_type"
                        v-model="row_of.co_id_owner"
                        label="Propriétaire"
                        ></m-form-select>-->

												<m-form-combobox
													v-model="row_of.co_id_owner"
													label="Propriétaire"
													:label-width="5"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('co_id_owner')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'contacts' }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>
												<v-btn color="success" @click="$refs.contactwineditowner.open(-1)" small>Nouveau propriétaire</v-btn>

												<m-form-radio-group v-model="row_of.of_immotools" :label-width="5" label="Agence à la vente">
													<m-form-radio
														v-for="item in $store.state.items_of_immotools"
														:key="item.value"
														:label="item.text"
														:value="item.value"
													></m-form-radio>
												</m-form-radio-group>

												<m-form-combobox
													v-model="row_of.of_agency"
													:label-width="5"
													label="Gestion"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('of_agency')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'agencies' }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>

												<!-- <m-form-text
                          :name="$Utils.randomstring('of_agency_text')"
                          label="Agence texte"
                          v-model="row_of.of_agency_text"
                        ></m-form-text> -->

												<m-form-combobox
													v-model="row_of.co_id_society"
													label="Gestion"
													:label-width="5"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('co_id_owner')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: '' }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>

												<m-form-text
													:name="$Utils.randomstring('of_mandat')"
													label="Mandat de vente"
													:label-width="5"
													v-model="row_of.of_mandat"
												></m-form-text>

												<m-form-checkbox
													v-if="!$store.state.preferences.shared.forMonaco"
													:name="$Utils.randomstring('of_typemandat2')"
													label="Mandat exclusif"
													v-model="row_of.of_typemandat2"
												></m-form-checkbox>

												<m-form-select
													v-if="!$store.state.preferences.shared.forMonaco"
													:name="$Utils.randomstring('of_typemandat')"
													:items="$store.state.items_of_typemandat"
													v-model="row_of.of_typemandat"
													label="Type de mandat <span style='color:red;font-size:18px;'>•</span>"
													:label-width="5"
												></m-form-select>
												<m-form-date
													:name="$Utils.randomstring('of_startmandat')"
													v-model="row_of.of_startmandat"
													label="Date début mandat"
													:label-width="5"
												/>
												<m-form-date
													:name="$Utils.randomstring('of_endmandat')"
													v-model="row_of.of_endmandat"
													label="Date fin mandat"
													:label-width="5"
												/>
												<!-- <m-form-text
                          :name="$Utils.randomstring('of_nummandat')"
                          label="Numéro de mandat"
                          :label-width="5"
                          v-model="row_of.of_nummandat"
                        ></m-form-text> -->

												<m-form-select
													v-if="showField"
													:name="$Utils.randomstring('of_notary')"
													:items="$store.state.items_of_notary"
													v-model="row_of.of_notary"
													label="Notaire"
													:label-width="5"
												></m-form-select>
												<m-form-combobox
													v-model="row_of.co_id_lawyer"
													label="Avocat"
													:label-width="5"
													prepend-icon="mdi-scale-balance"
													:name="$Utils.randomstring('co_id_lawyer')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'lawyers' }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>

												<!-- <m-form-combobox
                          v-model="row_of.co_id_notary"
                          label="Notaire"
                          :label-width="5"
                          prepend-icon="mdi-account"
                          :name="$Utils.randomstring('co_id_notary')"
                          :store-url="$config.server_url + '/api/1.0/contacts'"
                          :store-params="{ gr_id: 'contacts' }"
                          item-value="co_id"
                          :item-text="$options.filters.formatContactName"
                        ></m-form-combobox> -->
											</v-card-text>
										</v-card>
									</v-flex>
								</v-layout>
								<v-card class="card5 mt-0">
									<v-card-title>
										<h1>Informations commerciales... et consignes de visites</h1>
									</v-card-title>
									<v-card-text>
										<v-layout row wrap>
											<v-flex md6>
												<m-form-combobox
													v-model="row_of.co_id_commercial1"
													label="Chef de produit 1"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('co_id_commercial1')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'users', ignoremiglior: true }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>

												<m-form-combobox
													v-model="row_of.co_id_contributor"
													label="Apporteur d'affaire"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('co_id_contributor')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'contacts' }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>

												<m-form-text
													type="number"
													:name="$Utils.randomstring('of_commissionseller')"
													label="Commission Vendeur %"
													v-model="row_of.of_commissionseller"
												></m-form-text>
												<m-form-select
													:name="$Utils.randomstring('of_fn')"
													:items="$store.state.items_of_honoraires"
													v-model="row_of.of_fn"
													ref="of_fn"
													label="Honoraires"
												></m-form-select>

												<m-form-text
													v-if="!$store.state.preferences.shared.forMonaco"
													type="number"
													:name="$Utils.randomstring('of_percentage')"
													label="Tantième"
													v-model="row_of.of_percentage"
												></m-form-text>
												<m-form-text
													v-if="!$store.state.preferences.shared.forMonaco"
													:name="$Utils.randomstring('of_digicode')"
													label="Digicode"
													v-model="row_of.of_digicode"
												></m-form-text>
											</v-flex>
											<v-flex md6>
												<m-form-combobox
													v-model="row_of.co_id_commercial2"
													label="Chef de produit 2"
													prepend-icon="mdi-account"
													:name="$Utils.randomstring('co_id_commercial2')"
													:store-url="$config.server_url + '/api/1.0/contacts'"
													:store-params="{ gr_id: 'users', ignoremiglior: true }"
													item-value="co_id"
													:item-text="$options.filters.formatContactName"
												></m-form-combobox>
												<m-form-select
													:name="$Utils.randomstring('of_urgency')"
													:items="$store.state.items_of_urgency"
													v-model="row_of.of_urgency"
													label="Urgence"
												></m-form-select>
												<m-form-text
													type="number"
													:name="$Utils.randomstring('of_purcentcontributor')"
													label="% apporteur"
													v-model="row_of.of_purcentcontributor"
												></m-form-text>
												<m-form-text
													:name="$Utils.randomstring('of_nblots')"
													label="Num. de lots"
													v-model="row_of.of_nblots"
												></m-form-text>
												<m-form-text
													:name="$Utils.randomstring('of_numkey')"
													label="Numéro clé à l'agence"
													v-model="row_of.of_numkey"
												></m-form-text>
											</v-flex>
										</v-layout>
										<v-layout row wrap>
											<v-flex
												:class="{
													md4: !$store.state.preferences.shared.forMonaco,
													md6: $store.state.preferences.shared.forMonaco
												}"
											>
												<m-form-text
													type="textarea"
													label="Consigne de visite"
													label-position="top"
													:name="$Utils.randomstring('of_access')"
													outline
													:rows="3"
													v-model="row_of.of_access"
												></m-form-text>
											</v-flex>
											<v-flex
												v-if="!$store.state.preferences.shared.forMonaco"
												:class="{
													md4: !$store.state.preferences.shared.forMonaco,
													md6: $store.state.preferences.shared.forMonaco
												}"
											>
												<m-form-text
													type="textarea"
													label="Raison de la vente"
													label-position="top"
													:name="$Utils.randomstring('of_reasonsale')"
													outline
													:rows="3"
													v-model="row_of.of_reasonsale"
												></m-form-text>
											</v-flex>
											<v-flex v-if="!$store.state.preferences.shared.forMonaco" :class="{ md4: true }">
												<m-form-text
													type="textarea"
													label="Notes client"
													label-position="top"
													:name="$Utils.randomstring('of_notes_customer')"
													outline
													:rows="3"
													v-model="row_of.of_notes_customer"
												></m-form-text>
											</v-flex>
											<v-flex
												:class="{
													md12: !$store.state.preferences.shared.forMonaco,
													md6: $store.state.preferences.shared.forMonaco
												}"
											>
												<m-form-text
													type="textarea"
													label="Notes privées (texte masqué)"
													label-position="top"
													:name="$Utils.randomstring('of_notes_private')"
													outline
													:rows="3"
													v-model="row_of.of_notes_private"
												></m-form-text>
											</v-flex>
										</v-layout>
										<m-form-text
											type="textarea"
											label="Numéro de lot / appartement / parking (texte masqué)"
											label-position="top"
											:name="$Utils.randomstring('of_notes_private2')"
											outline
											:rows="3"
											v-model="row_of.of_notes_private2"
										></m-form-text>
									</v-card-text>
								</v-card>
								<v-card class="card1 mt-3">
									<v-card-title>
										<v-layout ma-0 wrap>
											<m-form-checkbox
												style="margin-top: -8px"
												:name="$Utils.randomstring('of_sell')"
												label
												v-model="row_of.of_sell"
											></m-form-checkbox>
											<h1 @click="row_of.of_sell = !row_of.of_sell" style="margin-top: 0; padding-left: 8px">
												&nbsp;A la
												<span class="bis">vente</span> <span style="color:red;font-size:18px;">•</span>
											</h1>
										</v-layout>
									</v-card-title>
									<v-card-text>
										<v-expand-transition>
											<div v-if="row_of.of_sell">
												<v-layout wrap>
													<v-flex md5>
														<m-form-checkbox
															:name="$Utils.randomstring('of_sell_uponrequest')"
															label="Prix sur demande"
															v-model="row_of.of_sell_uponrequest"
														></m-form-checkbox>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_sum')"
															class="alignright"
															label="Prix de présentation <span style='color:red;font-size:18px;'>•</span>"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_sum"
															@input="changeSellSum"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_sum_fix')"
															class="alignright"
															label="Prix net vendeur"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_sum_fix"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_lifeannuity')"
															class="alignright"
															label="Viager €/mois"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_lifeannuity"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_charge')"
															class="alignright"
															label="Charges totales/an <span style='color:red;font-size:18px;'>•</span>"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_charge"
														></m-form-text>
														<m-form-text
															v-if="!$store.state.preferences.shared.forMonaco"
															type="number"
															:name="$Utils.randomstring('of_sell_taxdwelling')"
															class="alignright"
															label="Taxe d'habibation"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_taxdwelling"
														></m-form-text>
														<m-form-text
															v-if="!$store.state.preferences.shared.forMonaco"
															type="number"
															:name="$Utils.randomstring('of_sell_taxrealestate')"
															class="alignright"
															label="Taxe foncière"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_taxrealestate"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_workenvisaged')"
															class="alignright"
															label="Travaux envisagés"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_workenvisaged"
														></m-form-text>
														<m-form-text
															v-if="!$store.state.preferences.shared.forMonaco"
															type="number"
															:name="$Utils.randomstring('of_sell_start')"
															class="alignright"
															label="Prix de départ"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_sell_start"
														></m-form-text>
													</v-flex>
													<v-flex md7>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_honorairevendeur')"
															class="alignright"
															label="Honoraire à charge du vendeur TTC"
															:label-width="8"
															suffix="€"
															v-model="row_of.of_sell_honorairevendeur"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_honoraireacquereur')"
															class="alignright"
															label="Honoraire à charge de l'acquéreur TTC"
															:label-width="8"
															suffix="€"
															v-model="row_of.of_sell_honoraireacquereur"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_fraisdenotaire')"
															class="alignright"
															label="Frais de notaire"
															:label-width="8"
															suffix="€"
															v-model="row_of.of_sell_fraisdenotaire"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_purcentfeevendeur')"
															class="alignright"
															label="Pourcentage honoraire vendeur"
															:label-width="8"
															suffix="%"
															v-model="row_of.of_sell_purcentfee"
															@input="changeSellSum"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_sell_purcentfeeacquereur')"
															class="alignright"
															label="Pourcentage honoraire acquéreur"
															:label-width="8"
															suffix="%"
															v-model="row_of.of_sell_purcentfeeacquereur"
															@input="changeSellSum"
														></m-form-text>
														<m-form-text
															v-if="!$store.state.preferences.shared.forMonaco"
															type="textarea"
															:name="$Utils.randomstring('of_sell_workenvisaged2')"
															outline
															label="Travaux votés"
															label-position="top"
															v-model="row_of.of_sell_workenvisaged2"
														></m-form-text>
													</v-flex>
												</v-layout>
												<v-layout>
													<v-flex md5>
														<h4>Offre</h4>
														<div>
															<m-form-checkbox
																:name="$Utils.randomstring('of_offer_inprogress')"
																label="Offre en cours"
																v-model="row_of.of_offer_inprogress"
															></m-form-checkbox>
														</div>
														<div>
															<m-form-checkbox
																:name="$Utils.randomstring('of_offer_accepted')"
																label="Offre acceptée"
																v-model="row_of.of_offer_accepted"
															></m-form-checkbox>
														</div>
														<div>
															<m-form-checkbox
																:name="$Utils.randomstring('of_offer_account')"
																label="Acompte encaissé"
																v-model="row_of.of_offer_account"
															></m-form-checkbox>
														</div>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_offer_price')"
															class="alignright"
															label="Prix de vente"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_offer_price"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_offer_commission')"
															class="alignright"
															label="Commission HT"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_offer_commission"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_offer_retrocession')"
															class="alignright"
															label="Rétrocession"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_offer_retrocession"
														></m-form-text>
													</v-flex>
													<v-flex md7>
														<h4>&nbsp;.</h4>
														<m-form-combobox
															v-model="row_of.co_id_offer_taker"
															label="Acquéreur"
															:label-width="5"
															prepend-icon="mdi-account"
															:name="$Utils.randomstring('co_id_offer_taker')"
															:store-url="$config.server_url + '/api/1.0/contacts'"
															:store-params="{ gr_id: 'contacts' }"
															item-value="co_id"
															:item-text="$options.filters.formatContactName"
														></m-form-combobox>
														<m-form-combobox
															v-model="row_of.co_id_offer_owner"
															label="Vendeur"
															:label-width="5"
															prepend-icon="mdi-account"
															:name="$Utils.randomstring('co_id_offer_owner')"
															:store-url="$config.server_url + '/api/1.0/contacts'"
															:store-params="{ gr_id: 'contacts' }"
															item-value="co_id"
															:item-text="$options.filters.formatContactName"
														></m-form-combobox>
														<!-- <m-form-combobox
															v-model="row_of.co_id_offer_notary"
															label="Notaire"
															:label-width="5"
															prepend-icon="mdi-account"
															:name="$Utils.randomstring('co_id_offer_notary')"
															:store-url="$config.server_url + '/api/1.0/contacts'"
															:store-params="{ gr_id: 'contacts' }"
															item-value="co_id"
															:item-text="$options.filters.formatContactName"
														></m-form-combobox> -->
														<m-form-date
															:name="$Utils.randomstring('of_offer_date')"
															v-model="row_of.of_offer_date"
															label="Date de signature prévue"
															:label-width="5"
														/>
													</v-flex>
												</v-layout>
											</div>
										</v-expand-transition>
									</v-card-text>
								</v-card>
								<v-card class="card2 mt-3">
									<v-card-title>
										<v-layout wrap ma-0>
											<m-form-checkbox
												style="margin-top: -8px"
												:name="$Utils.randomstring('of_rent')"
												label
												v-model="row_of.of_rent"
											></m-form-checkbox>
											<h1 @click="row_of.of_rent = !row_of.of_rent" style="margin-top: 0; padding-left: 8px">
												&nbsp;A la
												<span class="bis">location</span> <span style="color:red;font-size:18px;">•</span>
											</h1>
										</v-layout>
									</v-card-title>
									<v-card-text>
										<v-expand-transition>
											<div v-if="row_of.of_rent">
												<v-layout wrap>
													<v-flex md5>
														<m-form-checkbox
															:name="$Utils.randomstring('of_rent_uponrequest')"
															label="Prix sur demande"
															v-model="row_of.of_rent_uponrequest"
														></m-form-checkbox>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_sum')"
															class="alignright"
															label="Loyer mensuel <span style='color:red;font-size:18px;'>•</span>"
															:label-width="7"
															suffix="€/mois"
															v-model="row_of.of_rent_sum"
															@input="changeRentSum"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_sum_year')"
															class="alignright"
															label="Loyer annuel"
															:label-width="7"
															suffix="€/an"
															v-model="row_of.of_rent_sum_year"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_charge')"
															class="alignright"
															label="Charges mensuelles"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_rent_charge"
															@input="changeRentCharge"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_charge_year')"
															class="alignright"
															label="Charges annuelles <span style='color:red;font-size:18px;'>•</span>"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_rent_charge_year"
														></m-form-text>
														<m-form-checkbox
															:name="$Utils.randomstring('of_rent_charge_included')"
															label="Charges incluses"
															v-model="row_of.of_rent_charge_included"
														></m-form-checkbox>

														<m-form-date
															:name="$Utils.randomstring('of_rent_echeancebail')"
															v-model="row_of.of_rent_echeancebail"
															label="Echéance du bail"
															:label-width="7"
														/>
														<m-form-date
															:name="$Utils.randomstring('of_rent_disponibilitebien')"
															v-model="row_of.of_rent_disponibilitebien"
															label="Disponibilité du bien"
															:label-width="7"
														/>
													</v-flex>
													<v-flex md7>
														<m-form-select
															:name="$Utils.randomstring('of_rent_typecharge')"
															:items="$store.state.items_of_rent_typecharge"
															v-model="row_of.of_rent_typecharge"
															label="Type de charge"
															:label-width="8"
														></m-form-select>
														<m-form-select
															:name="$Utils.randomstring('of_rent_regimebail')"
															:items="$store.state.items_of_rent_regimebail"
															v-model="row_of.of_rent_regimebail"
															label="Type de bail"
															:label-width="8"
														></m-form-select>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_honorairebailleur')"
															class="alignright"
															label="Honoraire à charge du preneur TTC"
															:label-width="8"
															suffix="€"
															v-model="row_of.of_rent_honorairebailleur"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_honoraireacquereur')"
															class="alignright"
															label="Honoraire à charge du Bailleur TTC"
															:label-width="8"
															suffix="€"
															v-model="row_of.of_rent_honoraireacquereur"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_droitbail_honorairebailleur')"
															class="alignright"
															label="Honoraire sur droit au bail à charge du Vendeur"
															:label-width="8"
															suffix="€"
															v-model="row_of.of_rent_droitbail_honorairebailleur"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_rent_droitbail_honoraireacquereur')"
															class="alignright"
															label="Honoraire sur droit au bail à charge de l'acquéreur"
															:label-width="8"
															suffix="€"
															v-model="row_of.of_rent_droitbail_honoraireacquereur"
														></m-form-text>
														<m-form-checkbox
															:name="$Utils.randomstring('of_rent_resilier')"
															label="Résilier par anticipation"
															v-model="row_of.of_rent_resilier"
														></m-form-checkbox>
													</v-flex>
												</v-layout>
												<v-layout>
													<v-flex md5>
														<h4>Engagement</h4>
														<div>
															<m-form-checkbox
																:name="$Utils.randomstring('of_commitment_inprogress')"
																label="Engagement en cours"
																v-model="row_of.of_commitment_inprogress"
															></m-form-checkbox>
														</div>
														<div>
															<m-form-checkbox
																:name="$Utils.randomstring('of_commitment_accepted')"
																label="Engagement accepté"
																v-model="row_of.of_commitment_accepted"
															></m-form-checkbox>
														</div>
														<div>
															<m-form-checkbox
																:name="$Utils.randomstring('of_commitment_account')"
																label="Acompte accepté"
																v-model="row_of.of_commitment_account"
															></m-form-checkbox>
														</div>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_commitment_price')"
															class="alignright"
															label="Montant de location (mois)"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_commitment_price"
														></m-form-text>
														<m-form-text
															type="number"
															:name="$Utils.randomstring('of_commitment_retrocession')"
															class="alignright"
															label="Rétrocession HT"
															:label-width="7"
															suffix="€"
															v-model="row_of.of_commitment_retrocession"
														></m-form-text>
													</v-flex>
													<v-flex md7>
														<h4>&nbsp;.</h4>
														<m-form-combobox
															v-model="row_of.co_id_commitment_taker"
															label="Preneur"
															:label-width="5"
															prepend-icon="mdi-account"
															:name="$Utils.randomstring('co_id_commitment_taker')"
															:store-url="$config.server_url + '/api/1.0/contacts'"
															:store-params="{ gr_id: 'contacts' }"
															item-value="co_id"
															:item-text="$options.filters.formatContactName"
														></m-form-combobox>
														<m-form-combobox
															v-model="row_of.co_id_commitment_owner"
															label="Propriétaire"
															:label-width="5"
															prepend-icon="mdi-account"
															:name="$Utils.randomstring('co_id_commitment_owner')"
															:store-url="$config.server_url + '/api/1.0/contacts'"
															:store-params="{ gr_id: 'contacts' }"
															item-value="co_id"
															:item-text="$options.filters.formatContactName"
														></m-form-combobox>
														<m-form-date
															:name="$Utils.randomstring('of_commitment_date')"
															v-model="row_of.of_plomb"
															label="Bail à compter du"
															:label-width="5"
														/>
													</v-flex>
												</v-layout>
											</div>
										</v-expand-transition>
									</v-card-text>
								</v-card>
								<!-- <v-card class="card4 mt-3">
                  <v-card-title>
                    <h1>
                      Les
                      <span class="bis">pièces</span>
                    </h1>
                  </v-card-title>
                </v-card>-->
							</v-flex>
						</v-layout>
					</v-container>
				</m-form>
			</v-card-text>
			<v-card-actions>
				<v-btn color="error2" @click="confirmDeleteDialog = true">Effacer</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin(false)" :disabled="row_of.of_immotools !== 0">Enregistrer</v-btn>
				<v-btn color="error2" @click="duplicateWin()" v-if="row_of.of_immotools !== 0">Dupliquer</v-btn>
			</v-card-actions>
		</v-card>
		<m-confirm-dialog
			v-model="confirmDeleteDialog"
			text="Souhaitez-vous effacer ce bien ? "
			sousText="Cette action est irréversible et effacera le bien, les images et toutes les actions associées à ce bien. Vous pouvez le passer à l'état archive"
			title="Confirmation"
			width="400"
			@canceled="confirmDeleteDialog = false"
			@confirmed="deleteWin()"
		></m-confirm-dialog>
		<m-confirm-dialog
			v-model="confirmNoBuildingDialog"
			text="Aucun immeuble associé à ce bien. Est-ce normal ?"
			sousText="Vous devez choisir le nom d'un nouveau immeuble dans 'Nom de l'immeuble existant'. Cette offre ne sera pas publié sur Immotools."
			title="Confirmation"
			width="400"
			@canceled="confirmNoBuildingDialog = false"
			@confirmed="saveWin(true)"
		></m-confirm-dialog>
		<contact-win-edit ref="contactwineditowner" @contactWinEditSaved="contactWinEditSavedOwner"></contact-win-edit>

		<v-overlay absolute :value="overlayWin"><v-progress-circular indeterminate color="amber"></v-progress-circular> </v-overlay>
	</v-dialog>
</template>

<script>
// import MFormFiles from "../components/MFormFiles";
import Vue from "vue";
import OfferCardBuilding from "../views/OfferCardBuilding";

export default {
	name: "OfferWinEdit",
	components: {
		// MFormFiles,
		"offer-card-building": OfferCardBuilding
	},
	data(vm) {
		// console.log("this.$store.state.items_of_type", this.$store.state.items_of_type);
		return {
			duplicate: false,
			overlayWin: false,
			images: [],
			imagesBuilding: [],
			confirmDeleteDialog: false,
			confirmNoBuildingDialog: false,
			showField: true,
			// valid: false,
			// mandatory: [v => !!v || "Obligatoire"],

			show_lang: "fr",

			date: new Date().toISOString().substr(0, 10),
			dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
			menu1: false,
			menu2: false,
			// imagesFiles: [],
			fileFrom: "",
			services: {},
			diffusions: {},
			dialog: false,
			row_bu: {},
			row_of: {},
			row_of_done: false,
			timeoutInputDescription: false,
			descriptionTooLong: {
				fr: false,
				en: false,
				ru: false,
				it: false,
				es: false,
				de: false
			}
		};
	},
	computed: {
		nbweb() {
			let tab = this.images.filter(row_fi => {
				return row_fi.fi_online;
			});
			return tab.length + "/" + this.images.length + " sur le web";
		},
		// nbbuildingphoto() {
		// 	if (!this.row_of.bu_id || !this.row_of.bu_id.images) return 0;
		// 	return this.row_of.bu_id.images.length;
		// },
		computedDateFormatted() {
			return this.formatDate(this.date);
		}
	},
	watch: {
		date(val) {
			this.dateFormatted = this.formatDate(this.date);
		}
	},

	props: {
		// msg: String
	},
	methods: {
		labelService(service) {
			let label = service.label_fr;
			if (
				service.valuetxt === "avec-vue" ||
				service.valuetxt === "vue-mer" ||
				service.valuetxt === "usage-mixte" ||
				service.valuetxt === "meuble" ||
				service.valuetxt === "professional-sale" ||
				service.valuetxt === "seasonal-rental" ||
				service.valuetxt === "professional-rental" ||
				service.valuetxt === "viager"
			)
				label += " <span style='color:red;font-size:18px;'>•</span>";
			return label;
		},
		calculMaxLength() {
			if (this.timeoutInputDescription) clearTimeout(this.timeoutInputDescription);
			let that = this;
			this.timeoutInputDescription = setTimeout(async () => {
				await that.descriptionMaxLength();
				this.timeoutInputDescription = null;
			}, 1000);
		},
		async descriptionMaxLength() {
			let params = {
				lang: this.show_lang,
				text: this.row_of["of_text_" + this.show_lang],
				of_id: this.row_of.of_id
			};
			if (this.row_of.bu_id && this.row_of.bu_id["bu_description_" + this.show_lang])
				params.text += "\n\n" + this.row_of.bu_id["bu_description_" + this.show_lang];
			let response = await this.$axios.get(this.$config.server_url + "/api/2.0/offers/textmaxlength", { params });
			if (response.data.toolong) this.descriptionTooLong[this.show_lang] = true;
			else this.descriptionTooLong[this.show_lang] = false;
		},
		changeLang(item) {
			this.show_lang = item.value;
			this.calculMaxLength();
		},
		changeSellSum() {
			// console.log("prix changé");
			let purcentVendeur = this.row_of.of_sell_purcentfee;
			let purcentAquereur = this.row_of.of_sell_purcentfeeacquereur;
			this.row_of.of_sell_honorairevendeur = Math.round(((this.row_of.of_sell_sum * purcentVendeur) / 100) * 1.2);
			this.row_of.of_sell_honoraireacquereur = Math.round(((this.row_of.of_sell_sum * purcentAquereur) / 100) * 1.2);
			this.row_of.of_sell_fraisdenotaire = Math.round((this.row_of.of_sell_sum * 6) / 100);
			this.row_of.of_sell_sum_fix = Math.round(
				this.row_of.of_sell_sum - ((this.row_of.of_sell_sum * this.row_of.of_sell_purcentfee) / 100) * 1.2
			);
			// this.$forceUpdate();
		},
		changeRentSum() {
			this.row_of.of_rent_sum_year = Math.round(this.row_of.of_rent_sum * 12);
			this.row_of.of_rent_honorairebailleur = Math.round(((this.row_of.of_rent_sum * 12 * 10) / 100) * 1.2);
		},
		changeRentCharge() {
			this.row_of.of_rent_charge_year = Math.round(this.row_of.of_rent_charge * 12);
		},
		changeOfStatus() {
			// console.log("this.row_of.of_status", this.row_of.of_status);
			if (this.row_of.of_status * 1 > 5) {
				this.row_of.of_web = 0;
				this.row_of.of_ci = 0;
			}
		},

		changeOfCi() {
			if (this.row_of.of_ci * 1 > 0) this.row_of.of_diffusions = 1023;
			else this.row_of.of_diffusions = 0;
			this.diffusions = {};
			for (let isev = 0; isev < this.$store.state.preferences.items.items_of_diffusions.length; isev++) {
				const diffusion = this.$store.state.preferences.items.items_of_diffusions[isev];
				this.diffusions[diffusion.valuetxt] = (this.row_of.of_diffusions & diffusion.value) === diffusion.value;
			}
		},
		async contactWinEditSavedOwner(co_id) {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + co_id);
			let row_co = response.data.data;
			this.row_of.co_id_owner = row_co;
		},
		open() {
			let of_id = this.$route.params.of_id;
			this.dialog = true;
			this.getOffer(of_id);
		},
		async loadFile() {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers/" + this.row_of.of_id);
			// this.row_of = response.data.data;
			// console.log("response.data.data", response.data.data);
			this.row_of.files = response.data.data.files;
		},
		async reloadofferimages() {
			if (!this.row_of.of_id) {
				// let response = await this.$axios.get(this.$config.server_url + "/api/1.0/buildings/" + this.row_of.of_id);
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/buildings/" + this.building.bu_id);
				this.imagesBuilding = this.$_.cloneDeep(response.data.data.images);
				this.$refs.buildingCard.setImages(this.imagesBuilding);

				return;
			}
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers/" + this.row_of.of_id);
			let row_of = response.data.data;
			this.images = this.$_.cloneDeep(row_of.images);
			if (row_of.bu_id && row_of.bu_id.images) {
				this.imagesBuilding = this.$_.cloneDeep(row_of.bu_id.images);
				this.$refs.buildingCard.setImages(this.imagesBuilding);
			}
		},
		async showBuildingPhotos() {
			if (!this.row_of || !this.row_of.of_id) return;
			// let response =
			await this.$axios.put(this.$config.server_url + "/api/1.0/offers/" + this.row_of.of_id + "/showbuildingphotos", {
				of_showbuildingphotos: this.row_of.of_showbuildingphotos
			});
			this.reloadofferimages();
		},

		async getOffer(of_id) {
			this.duplicate = false;
			if (!of_id) of_id = this.row_of.of_id;
			// this.imagesFiles = [];
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers/" + of_id);
			if (response.data.data.bu_id) {
				response.data.data.bu_id.buildingreport_txt = "";
				response.data.data.bu_id.buildingreport_date = this.$moment().format("YYYY-MM-DD");
			}
			if (response.data.data.of_immotools) response.data.data.of_immotools = 1;
			else response.data.data.of_immotools = 0;
			this.row_of = response.data.data;
			this.images = this.$_.cloneDeep(this.row_of.images);
			if (this.row_of.bu_id && this.row_of.bu_id.images) this.imagesBuilding = this.$_.cloneDeep(this.row_of.bu_id.images);
			this.row_of_done = true;
			for (let isev = 0; isev < this.$store.state.preferences.items.items_of_services.length; isev++) {
				const service = this.$store.state.preferences.items.items_of_services[isev];
				this.services[service.valuetxt] =
					(window.BigInt(this.row_of.of_services) & window.BigInt(service.value)) === window.BigInt(service.value);
				// console.log('this.services', this.row_of.of_services,service.value,this.row_of.of_services*1 & service.value*1);
			}
			this.diffusions = {};
			for (let isev = 0; isev < this.$store.state.preferences.items.items_of_diffusions.length; isev++) {
				const diffusion = this.$store.state.preferences.items.items_of_diffusions[isev];
				this.diffusions[diffusion.valuetxt] = (this.row_of.of_diffusions & diffusion.value) === diffusion.value;
			}
			Vue.nextTick(() => {
				// this.$refs.inputFile.reset();
				this.$refs.inputImage.reset();
			});
			this.descriptionMaxLength();
		},
		// saveFiles(files, from) {
		//   this.imagesFiles = files;
		//   this.fileFrom = from;
		// },
		async validateMainForm(vEls) {
			// console.log("vEls", vEls);
			if (!this.row_of.of_name_fr) {
				// console.log("this.$refs.of_name_fr", this.$refs);
				this.$refs.of_name_fr[0].informInvalid("Le nom de l'offre en français est obligatoire");
				return false;
			}
			// console.log("row_of.of_fn", this.row_of.of_fn, this.$refs.of_fn);
			// if (!this.row_of.of_fn) {
			// 	alert("Les honoraires sont obligatoire");
			// 	this.$refs.of_fn.informInvalid("Les honoraires sont obligatoire");
			// 	return false;
			// }
			return true;
		},

		async duplicateWin() {
			if (
				!window.confirm(
					"Cette offre provient de Immotoolbox. En confirmant, la fiche va être dupliqué en attribuant une référence à vous. Etes-vous certain de continuer ?"
				)
			)
				return;
			this.duplicate = true;
			this.saveWin(true);
		},

		async saveWin(confirmedContinue) {
			// console.log("ici");
			if (!(await this.$refs.mainform.validate())) return;

			let of_services = 0;
			for (let iserv = 0; iserv < this.$store.state.preferences.items.items_of_services.length; iserv++) {
				const service = this.$store.state.preferences.items.items_of_services[iserv];
				if (this.services[service.valuetxt]) of_services += service.value;
			}
			this.row_of.of_services = of_services;

			let of_diffusions = 0;
			for (let iserv = 0; iserv < this.$store.state.preferences.items.items_of_diffusions.length; iserv++) {
				const service = this.$store.state.preferences.items.items_of_diffusions[iserv];
				if (this.diffusions[service.valuetxt]) of_diffusions += service.value;
			}
			this.row_of.of_diffusions = of_diffusions;

			if (!this.row_of.bu_id) this.row_of.bu_id = {};
			if (!this.row_of.bu_id.bu_id || this.row_of.bu_id.bu_id === "") {
				this.row_of.bu_id.bu_id = "0";
			}
			// console.log("this.row_of.bu_id", this.row_of.bu_id);
			// return;
			this.confirmNoBuildingDialog = false;
			if ((!this.row_of.bu_id.bu_id || this.row_of.bu_id.bu_id == "0") && !this.row_of.bu_id.bu_name_new && !confirmedContinue) {
				this.confirmNoBuildingDialog = true;
				return;
			}

			this.overlayWin = true;

			delete this.row_of.presents_offer;
			delete this.row_of.reports_offer;
			delete this.row_of.signatures_rent_offer;
			delete this.row_of.signatures_sell_offer;
			delete this.row_of.todos_offer;
			delete this.row_of.calls_offer;
			delete this.row_of.emails_offer;
			delete this.row_of.reports_offer;

			delete this.row_of.of_agency_txt;

			if (this.duplicate) {
				delete this.row_of.of_id;
				delete this.row_of.of_ref;
				this.row_of.duplicate = true;
			}

			let images = [];
			for (let iImg = 0; iImg < this.images.length; iImg++) {
				const image = this.$_.cloneDeep(this.images[iImg]);
				delete image.file;
				delete image.binary;
				image.fi_sort = iImg + 1;
				images.push(image);
			}
			this.row_of.images = images;

			let response;
			if (this.row_of.of_id) {
				response = await this.$axios.put(this.$config.server_url + "/api/1.0/offers/" + this.row_of.of_id, this.row_of);
			} else {
				response = await this.$axios.post(this.$config.server_url + "/api/1.0/offers", this.row_of);
			}
			let of_id = response.data.data.of_id;
			// console.log("of_id", of_id);

			await this.$refs.cardFiles.saveFiles(of_id);
			await this.$refs.inputImage.saveFiles(of_id);
			await this.$refs.buildingCard.saveFiles(of_id);
			// this.$store.dispatch("load_nbbiensuivi");
			this.$store.dispatch("load_nbclientsuivi");
			// this.$emit("offerWinEditSaved", of_slug, of_id);
			this.$emit("offerWinEditSaved", of_id);
			this.$socket.emit("offers_update", of_id);
			this.overlayWin = false;
			this.dialog = false;
		},
		cancelWin() {
			this.$emit("offerWinEditCanceled", this.row_of.of_id);
			this.dialog = false;
		},
		async deleteWin() {
			let of_id = this.row_of.of_id;
			await this.$axios.delete(this.$config.server_url + "/api/1.0/offers/" + of_id);
			this.confirmDeleteDialog = false;
			this.dialog = false;
			this.$emit("offerWinEditDeleted", of_id);
			this.$socket.emit("offers_update", of_id);
		},

		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		parseDate(date) {
			if (!date) return null;

			const [month, day, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.winedit-container-header {
	width: 100%;
	justify-content: space-between;
}
.hideField {
	font-size: 14px;
	color: #4a6990;
	cursor: pointer;
	text-decoration: underline;
}
.titreDetail {
	justify-content: space-between;
}
.color-red {
	color: red;
}
</style>
