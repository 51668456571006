<template>
	<v-dialog v-model="dialog" persistent :transition="false" max-width="800px" ref="winVisit">
		<v-card>
			<v-card-title>
				<div>
					<h1>
						Visite
						<span class="bis">effectuée</span>
					</h1>
				</div>
			</v-card-title>
			<v-card-text grid-list-xl style="height: calc(100vh - 253px); overflow: auto">
				<div style="background-color: #ddd" class="pa-3">
					<b>Bien</b>
					: {{ row_vi.of_id | formatOfferName }}
					<br />
					<b>Contact</b>
					: {{ row_vi.co_id_contact | formatContactName }}
					<br />
					<b>Négociateur</b>
					: {{ row_vi.co_id_user | formatContactName }}
					<br />
					<b>Date</b>
					: {{ row_vi.vi_date | formatDate }}
					<br />
				</div>
				<v-layout wrap>
					<v-flex md4>
						<m-form-checkbox label="Acquéreur potentiel" v-model="row_vi.vi_proposition"></m-form-checkbox>
					</v-flex>
					<v-flex md8>
						<v-text-field
							v-model="row_vi.vi_propositionprice"
							prefix="Somme proposée"
							name="vi_propositionprice"
							suffix="€"
							@focus="$event.target.select()"
						></v-text-field>
					</v-flex>
				</v-layout>
				<v-textarea outline label="Compte-rendu de visite" v-model="row_vi.vi_description"></v-textarea>
				<div v-for="feedbacktype in $store.state.items_feedbackstypes" :key="feedbacktype.value">
					<h2>{{ feedbacktype.text }}</h2>
					<div
						v-for="feedback in $store.state.items_feedbacks.filter(it => {
							return it.type == feedbacktype.value;
						})"
						:key="feedback.value"
						style="width: 70%; margin-left: 15%"
					>
						<v-slider
							:tick-labels="[feedback.text2, 'N/A', feedback.text1]"
							:value="0"
							v-model="row_vi.vi_report['feedback' + feedback.value]"
							min="-1"
							max="1"
							ticks="always"
							tick-size="1"
						></v-slider>
					</div>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin()">Enregistrer</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "visitwinedit",
	data() {
		return {
			dialog: false,
			row_vi: { vi_report: {} }
		};
	},
	computed: {},
	watch: {},
	components: {},
	methods: {
		async open(vi_id) {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/visits/" + vi_id);
			this.row_vi = response.data.data;
			if (!this.row_vi.vi_report) {
				this.row_vi.vi_report = {};
				for (let i = 0; i < this.$store.state.items_feedbacks.length; i++) {
					const feedback = this.$store.state.items_feedbacks[i];
					this.row_vi.vi_report["feedback" + feedback.value] = 0;
				}
			}

			this.dialog = true;
		},
		cancelWin() {
			this.dialog = false;
		},
		async saveWin() {
			await this.$axios.put(this.$config.server_url + "/api/1.0/visits/" + this.row_vi.vi_id, this.row_vi);
			this.dialog = false;
			this.$emit("visitwineditSaved");
		}
	}
};
</script>

<style lang="scss"></style>
