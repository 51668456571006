<template>
	<label class="m-form-radio">
		<input type="radio" :value="value" :name="name2" :checked="checked" @input="setValue($event.target.value)" />
		{{ label }}
	</label>
</template>

<script>
export default {
	name: "mformradio",
	props: {
		value: [String, Number],
		label: String,
		id: {
			default: "",
			type: String
		},
		name: {
			default: "",
			type: String
		},
		readonly: {
			default: false,
			type: Boolean
		},
		disabled: {
			default: false,
			type: Boolean
		}
	},
	mounted() {
		//  if (this.name) this.name2 = this.name;
		//  else this.name2 = this.$Utils.randomstring();
	},
	data() {
		return {
			id2: "",
			name2: "",
			checked: false,
			checked2: false
		};
	},
	watch: {
		checked2: function (val) {}
	},
	components: {},
	methods: {
		setValue(val) {
			this.$parent.$parent.setValue(val);
		},
		setName(name) {
			this.name2 = name;
		},
		setChecked(checked) {
			this.checked = !!checked;
		},
		onclick(evt) {
			// evt.stopPropagation();
			this.$emit("click", evt);
		},
		onfocus(evt) {
			if (this.autoSelectOnFocus) evt.target.select();
			// evt.stopPropagation();
			// this.$emit("click", evt);
		}
	}
};
</script>

<style lang="scss">
</style>