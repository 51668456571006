<template>
	<div class="container-preferences">
		<v-row class="page-preferences" column fill-height formlittle>
			<v-col class="py-0">
				<!-- <v-card
					v-if="$store.state.preferences.rights.preference_building_access"
					class="card card-buildingpreference mb-4"
					:class="immotoolspreference ? 'backgroud-col2' : 'backgroud-white'"
				>
					<v-card-title>
						<h1>
							Import des biens Immotools
						</h1>
						<v-btn class="ml-5 btn-immotoolspreference secondary2" @click="immotoolspreference = !immotoolspreference">{{
							immotoolspreference ? "Fermer" : "Ouvrir"
						}}</v-btn>
					</v-card-title>
					<v-card-text>
						<p class="overline">
							Importer les biens Immotools à partir de l'export Excel.<br /><a href="javascript:void(0);" @click="downloadLastXml"
								>Télécharger la dernière version</a
							>
						</p>
						<v-expand-transition>
							<div v-if="immotoolspreference" class="right-preference">
								<m-form-file
									class="input-avatar"
									label="Fichier Excel Immotools"
									ref="inputImmotools"
									@inputfile="saveFileImmotools"
								/>
								<div style="margin-bottom: 10px">
									<label for="" style=""><b>Type d'import :</b></label>
								</div>
								<m-form-radio-group v-model="typeimport" :label-width="0" label="">
									<m-form-radio label="Aucun" :value="0"></m-form-radio>
									<m-form-radio label="A la vente" :value="1"></m-form-radio>
									<m-form-radio label="A la location" :value="2"></m-form-radio>
								</m-form-radio-group>
								<br />
								<v-btn :disabled="typeimport == 0" class="btn-immotoolspreference primary2" @click="saveImmotoolspreference"
									>Enregistrer</v-btn
								>
								<div class="mt-3">
									<hr />
									<br />
									<v-btn
										:disabled="disableSynchImagesImmotools"
										class="btn-immotoolspreference warning2"
										@click="synchImagesImmotoolspreference"
										>Synchroniser les images (à utiliser le plus rarement possible)</v-btn
									>
									<div>{{ cmptSynchToolbox }}</div>
								</div>
							</div>
						</v-expand-transition>
					</v-card-text>
					<v-overlay absolute :value="overlayImmotools"><v-progress-circular indeterminate color="amber"></v-progress-circular> </v-overlay>
				</v-card> -->

				<v-card class="mb-4 card card-personalpreference" :class="personalpreference ? 'backgroud-blue' : 'backgroud-white'">
					<v-card-title>
						<h1>
							Préférences
							<span class="bis">personnelles</span>
						</h1>
						<v-btn class="ml-5 btn-personalpreference secondary2" @click="personalpreference = !personalpreference">{{
							personalpreference ? "Fermer" : "Ouvrir"
						}}</v-btn>
					</v-card-title>
					<v-card-text>
						<p class="overline">Définissez les options pour mon compte</p>
						<v-expand-transition>
							<div v-if="personalpreference" class="personal-preference">
								<v-row>
									<v-col cols="4" class="formlittle">
										<h3>Options générales</h3>
										<m-form-checkbox
											color="#5589e0"
											:name="$Utils.randomstring('helpBubble')"
											label="Afficher les bulles d'aide"
											v-model="personalPreferenceData.helpBubble"
										></m-form-checkbox>
										<m-form-checkbox
											color="#5589e0"
											:name="$Utils.randomstring('dailyMail')"
											label="Recevoir les mails quotidien"
											v-model="personalPreferenceData.dailyMail"
										></m-form-checkbox>
										<m-form-text
											type="number"
											label="Définir le début de la journée à X heures : "
											:name="$Utils.randomstring('startDay')"
											v-model="personalPreferenceData.startDay"
											placeholder="heures"
											label-position="top"
										></m-form-text>
									</v-col>
									<v-col class="formlittle">
										<h3>Options Calendrier</h3>
										<v-flex class="d-flex" v-if="$store.state.preferences.shared.useAzure">
											<v-btn v-if="!$store.state.preferences.personal.azure" color="primary2" @click="authazure"
												>Authorisation Azure</v-btn
											>
											<v-btn v-if="$store.state.preferences.personal.azure" color="secondary2" @click="deauthazure"
												>Déconnexion Azure</v-btn
											>
											<div v-if="$store.state.preferences.personal.azure" class="ml-3">
												Compte Azure configuré :
												<b
													>{{ $store.state.preferences.personal.azure.name }} ({{
														$store.state.preferences.personal.azure.preferred_username
													}})</b
												>
											</div>
											<div class="ml-3 text-red" v-else>Aucun compte Azure configuré</div>
											<div class="ml-3 overline">
												Un compte Azure ou Microsoft Exchange ou Office 365, vous permet de synchroniser votre calendrier
												mobile avec Immonaco.
											</div>
										</v-flex>
										<m-form-checkbox
											:name="$Utils.randomstring('showOtherCalendar')"
											color="#5589e0"
											label="Par default, voir les calendriers des autres utilisateurs plutôt que le sien"
											v-model="personalPreferenceData.showOtherCalendar"
										></m-form-checkbox>
										<m-form-checkbox
											:name="$Utils.randomstring('showWeekend')"
											color="#5589e0"
											label="Afficher les samedis et les dimanches"
											v-model="personalPreferenceData.showWeekend"
										></m-form-checkbox>
										<m-form-checkbox
											:name="$Utils.randomstring('confirmMoveEvent')"
											color="#5589e0"
											label="Confirmer le déplacement d'un événement"
											v-model="personalPreferenceData.confirmMoveEvent"
										></m-form-checkbox>
										<swatches-picker v-model="personalPreferenceData.color" />
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-btn class="btn-personalpreference primary2" @click="savePersonalPreference">Enregistrer</v-btn>
									</v-col>
								</v-row>
							</div>
						</v-expand-transition>
					</v-card-text>
				</v-card>

				<v-card
					v-if="$store.state.preferences.rights.preference_shared_access"
					class="mb-4 card card-sharedpreference"
					:class="sharedpreference ? 'backgroud-brown' : 'backgroud-white'"
				>
					<v-card-title class="d-flex flex-row container-title-shared">
						<div class="d-flex flex-column">
							<div class="d-flex flex-row">
								<h1>
									Préférences
									<span class="bis">partagées</span>
								</h1>
								<v-btn class="ml-5 btn-sharedpreference secondary2" @click="sharedpreference = !sharedpreference">{{
									sharedpreference ? "Fermer" : "Ouvrir"
								}}</v-btn>
							</div>
							<p class="overline">Définissez les options pour tous les utilisateurs</p>
						</div>
						<div class="container-logo" v-if="logoUrl || sharedPreferenceData.societyLogo">
							<div
								class="logo"
								:style="{
									backgroundImage: `url(${logoUrl ? logoUrl : sharedPreferenceData.societyLogo})`
								}"
							></div>
						</div>
						<div class="container-logo" v-if="logo2Url || sharedPreferenceData.societyLogo2">
							<div
								class="logo"
								:style="{
									backgroundImage: `url(${logo2Url ? logo2Url : sharedPreferenceData.societyLogo2})`
								}"
							></div>
						</div>
					</v-card-title>
					<v-card-text>
						<v-expand-transition>
							<div v-if="sharedpreference" class="shared-preference">
								<v-row>
									<v-col cols="3" class="pt-0">
										<m-form-combobox
											v-model="sharedPreferenceData.co_id_society"
											label="Fiche de mon agence"
											label-position="top"
											prepend-icon="mdi-account"
											:name="$Utils.randomstring('of_agency')"
											:store-url="$config.server_url + '/api/1.0/contacts'"
											:store-params="{ gr_id: 'agencies' }"
											item-value="co_id"
											:item-text="$options.filters.formatContactName"
										></m-form-combobox>
									</v-col>
									<v-col cols="3" class="pt-0">
										<m-form-text
											label="Nom de mon agence"
											disabled
											label-position="top"
											v-model="sharedPreferenceData.societyName"
										></m-form-text>
									</v-col>
									<v-col cols="3">
										<v-file-input
											class="my-3"
											hide-details
											label="Logo de la société, fond clair"
											accept="file/*"
											v-model="logo"
											@change="onLogoChange"
										></v-file-input>
									</v-col>
									<v-col cols="3">
										<v-file-input
											class="my-3"
											hide-details
											label="Logo de la société, fond fonçé"
											accept="file/*"
											v-model="logo2"
											@change="onLogoChange2"
										></v-file-input>
									</v-col>
								</v-row>
								<hr />
								<v-row>
									<v-col cols="4" class="formlittle">
										<h3>Autres options</h3>
										<div class="d-inline-flex flex-wrap">
											<m-form-checkbox
												color="#757575"
												label="Champs pour Monaco"
												v-model="sharedPreferenceData.forMonaco"
											></m-form-checkbox
											><br />
											<m-form-checkbox
												color="#757575"
												label="Envoyer mail au négociateur si calendier pas rempli"
												v-model="sharedPreferenceData.mailIfEmptyCalendar"
											></m-form-checkbox>
											<m-form-checkbox
												color="#757575"
												label="Utiliser la base immeuble"
												v-model="sharedPreferenceData.useRealestate"
											></m-form-checkbox>
											<m-form-checkbox
												color="#757575"
												label="Utiliser les diagnostiques"
												v-model="sharedPreferenceData.useDiagnostic"
											></m-form-checkbox>
											<m-form-checkbox
												color="#757575"
												label="Utiliser les infos LAB (Contacts)"
												v-model="sharedPreferenceData.useLab"
											></m-form-checkbox>
											<m-form-checkbox
												color="#757575"
												label="Utiliser les infos financières (Contacts)"
												v-model="sharedPreferenceData.useFinancial"
											></m-form-checkbox>
											<m-form-checkbox
												color="#757575"
												label="Utiliser Microsoft Azure"
												v-model="sharedPreferenceData.useAzure"
											></m-form-checkbox>
										</div>
										<m-form-text
											label="FTP realestate"
											v-model="sharedPreferenceData.ftpRealestate"
											placeholder
											label-position="top"
										></m-form-text>
										<m-form-text
											label="Prochaine référence bien"
											v-model="sharedPreferenceData.nextRefOffers"
											placeholder
											label-position="top"
										></m-form-text>
										<m-form-text label="jours fériés" v-model="sharedPreferenceData.holidays" label-position="top"></m-form-text>
										<m-form-text
											label="Ma fiche société"
											v-model="sharedPreferenceData.mySociety"
											label-position="top"
										></m-form-text>
									</v-col>
									<v-col cols="4" class="formlittle">
										<h3>Les langues</h3>
										<v-row>
											<v-col class="pt-0">
												<m-form-checkbox
													color="#757575"
													label="Français"
													v-model="sharedPreferenceData.french"
												></m-form-checkbox>
												<m-form-checkbox
													color="#757575"
													label="Anglais"
													v-model="sharedPreferenceData.english"
												></m-form-checkbox>
												<m-form-checkbox
													color="#757575"
													label="Italien"
													v-model="sharedPreferenceData.italian"
												></m-form-checkbox>
											</v-col>
											<v-col class="pt-0">
												<m-form-checkbox
													color="#757575"
													label="Russe"
													v-model="sharedPreferenceData.russian"
												></m-form-checkbox>
												<m-form-checkbox
													color="#757575"
													label="Allemand"
													v-model="sharedPreferenceData.german"
												></m-form-checkbox>
												<m-form-checkbox
													color="#757575"
													label="Espagnol"
													v-model="sharedPreferenceData.spanish"
												></m-form-checkbox>
											</v-col>
										</v-row>
										<h3 class="mt-4">Les suivis</h3>
										<m-form-text
											type="number"
											v-model="sharedPreferenceData.nbMaxSuiviContact"
											label="Nombre maximum de négociateur pouvant suivre un contact (0=>illimité)"
											label-position="top"
										></m-form-text>
										<m-form-text
											type="number"
											v-model="sharedPreferenceData.nbMaxSuiviOffer"
											label="Nombre maximum de négociateur pouvant suivre un bien (0=>illimité)"
											label-position="top"
										></m-form-text>
									</v-col>
									<v-col cols="4" class="formlittle">
										<h3 class="mt-5">Prefixe pour les références des biens</h3>
										<m-form-text
											label="model de référence"
											v-model="sharedPreferenceData.modelRefOffer"
											label-position="top"
										></m-form-text>
										<small>ex : mP-%_happy (le % représente le nombre de la référence)</small>
										<m-form-text
											type="number"
											label="nombre de chiffre"
											v-model="sharedPreferenceData.nbChiffreRefOffer"
											label-position="top"
										></m-form-text>
									</v-col>
									<!-- <v-col cols="3" class="formlittle">
                    <h3>Les quartiers</h3>
                    <table class="tablestriped backgroud-white tab-district">
                      <tbody>
                        <tr v-for="(district, indexDistricts) in districts" :key="indexDistricts">
                          <td d-flex flex-row v-if="district.text!='Aucun'">
                            <span>{{district.text}}</span>
                            <span @click="districts.splice(indexDistricts, 1)">
                              <icon class="icon-delete withpointer" name="minus-square"></icon>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <v-row>
                      <v-col cols="10" class="pr-0">
                        <m-form-text
                          v-model="newDistrictName"
                          label="Ajouter un quartier"
                          label-position="top"
                        ></m-form-text>
                      </v-col>
                      <v-col class="mt-5" @click="addDistrict()">
                        <icon class="icon-add withpointer" name="plus-square"></icon>
                      </v-col>
                    </v-row>
                  </v-col>-->
								</v-row>
								<v-row>
									<v-col cols="6" class>
										<h3>Modèle de mail</h3>
										<m-form-select
											label="Langue"
											:label-width="3"
											:items="$store.state.items_languages"
											v-model="selectedLanguage"
										></m-form-select>
										<m-form-text
											type="textarea"
											v-model="modelesMail[selectedLanguage]"
											:label-width="3"
											:label="'Modèle en ' + selectedLanguage"
											:name="$Utils.randomstring('modelmail')"
										></m-form-text>
										<v-row>
											<v-col cols="9" offset="3">
												<small>Le message commencera toujours par "Bonjour NOM PRÉNOM,"</small>
												<!-- <v-btn
                          small
                          v-if="selectedLanguage"
                          class="btn-sharedpreference"
                          @click="addModelMailLangue"
                        >Ajouter le modèle de mail pour la langue : {{selectedLanguage}}</v-btn>-->
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-btn class="btn-sharedpreference primary2" @click="saveSharedPreference">Enregistrer</v-btn>
									</v-col>
								</v-row>
							</div>
						</v-expand-transition>
					</v-card-text>
				</v-card>

				<v-card
					v-if="$store.state.preferences.rights.preference_right_access"
					class="card card-rightpreference mb-4"
					:class="rightpreference ? 'backgroud-col3' : 'backgroud-white'"
				>
					<v-card-title>
						<h1><span class="bis">Droits</span> des utilisateurs</h1>
						<v-btn class="ml-5 btn-rightpreference secondary2" @click="rightpreference = !rightpreference">{{
							rightpreference ? "Fermer" : "Ouvrir"
						}}</v-btn>
					</v-card-title>
					<v-card-text>
						<p class="overline">
							Définissez les droits d'accès pour chaque type d'utilisateur
							<br />Vous pouvez cliquer sur l'intitulé d'une ligne ou d'une colonne pour sélectionner toute la ligne ou toute la
							colonne.
						</p>
						<v-expand-transition>
							<div v-if="rightpreference" class="right-preference">
								<v-row>
									<v-col>
										<table class="tablestriped blackheader backgroud-white">
											<thead>
												<tr>
													<th>Droits</th>
													<th class="item-header" v-for="(item, index) in itemsType" :key="index" @click="checkCol(index)">
														<strong>{{ item.text }}</strong>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(itemRow, indexRow) in rightPreferenceData" :key="indexRow">
													<td class="bis" @click="checkLine(itemRow)">
														{{ itemRow.text }}
													</td>
													<td
														v-for="(itemCol, indexCol) in itemsType"
														:class="{
															'item-header': true,
															'text-center': indexCol > 0,
															'font-weight-bold': indexCol == 0
														}"
														:key="indexCol"
													>
														<v-simple-checkbox v-model="itemRow[itemCol.value]"></v-simple-checkbox>
													</td>
												</tr>
											</tbody>
										</table>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-btn class="btn-rightpreference primary2" @click="saveRightPreference">Enregistrer</v-btn>
									</v-col>
								</v-row>
							</div>
						</v-expand-transition>
					</v-card-text>
				</v-card>

				<v-card
					v-if="$store.state.preferences.rights.preference_building_access"
					class="card card-buildingpreference mb-4"
					:class="buildingpreference ? 'backgroud-gray' : 'backgroud-white'"
				>
					<v-card-title>
						<h1>
							Les
							<span class="bis">immeubles</span>
						</h1>
						<v-btn class="ml-5 btn-buildingpreference secondary2" @click="buildingpreference = !buildingpreference">{{
							buildingpreference ? "Fermer" : "Ouvrir"
						}}</v-btn>
					</v-card-title>
					<v-card-text>
						<p class="overline">Voir la liste des {{ buildings.length }} immeubles.</p>
						<v-expand-transition>
							<div v-if="buildingpreference" class="right-preference">
								<table class="tablestriped blackheader backgroud-white">
									<thead>
										<tr>
											<th>&nbsp;</th>
											<th>Nom de l'immeuble</th>
											<th>ID XML</th>
											<th>Quartier</th>
											<th>Adresse</th>
											<th>CP</th>
											<th>Ville</th>
											<th>Biens liés</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row_bu, indexBuilding) in buildings" :key="indexBuilding">
											<td>
												<div v-for="row_fi in row_bu.images" :key="row_fi.fi_id" class="item_image">
													<v-img
														:src="
															`${$config.server_url}/api/1.0/images/${row_fi.fi_id}/75/75?token=${$store.state.accesstoken}&d=${row_fi.updatedAt}`
														"
														height="75px"
														width="75px"
														:ref="`littleimg${row_fi.fi_id}`"
													></v-img>
												</div>
											</td>
											<td>{{ row_bu.bu_name }}</td>
											<td>{{ row_bu.bu_id_xml }}</td>
											<td>{{ row_bu.bu_district }}</td>
											<td>
												{{ row_bu.bu_address1 }}
												<br />
												{{ row_bu.bu_address2 }}
											</td>
											<td>{{ row_bu.bu_zip }}</td>
											<td>{{ row_bu.bu_city }}</td>
											<td>
												<ul v-if="row_bu.offers.length">
													<li v-for="row_of in row_bu.offers" :key="indexBuilding + '-' + row_of.of_id">
														<a @click="$router.push('/offers/' + row_of.of_id + '/edit')">{{
															row_of | formatOfferName
														}}</a>
													</li>
												</ul>
												<div v-else>
													<a @click="deleteBuilding(row_bu)">Effacer</a>
												</div>
											</td>
											<td @click="editBuilding(row_bu)"><icon class="withpointer" name="edit"></icon></td>
										</tr>
									</tbody>
								</table>
							</div>
						</v-expand-transition>
					</v-card-text>
					<v-dialog v-model="dialogBuilding" persistent :transition="false" max-width="900px" ref="winPresent">
						<v-card>
							<v-card-title>
								<div>
									<h1>
										Éditer
										<span class="bis">un immeuble</span>
									</h1>
								</div>
							</v-card-title>
							<v-card-text>
								<offer-card-building
									@reloadofferimages="reloadofferimages"
									v-model="buildingSelected"
									:canEditName="true"
									:descriptionTooLong="descriptionTooLong"
								></offer-card-building>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary2" text @click="cancelWinBuilding()">Annuler</v-btn>
								<v-btn color="primary2" @click="saveWinBuilding()">Enregistrer</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-card>

				<v-card
					v-if="$store.state.preferences.rights.preference_right_access"
					class="flex- card card-rightpreference mb-4"
					:class="listspreference ? 'backgroud-col3' : 'backgroud-white'"
				>
					<v-card-title>
						<h1>
							Les
							<span class="bis">listes</span>
						</h1>
						<v-btn class="ml-5 btn-rightpreference secondary2" @click="listspreference = !listspreference">{{
							listspreference ? "Fermer" : "Ouvrir"
						}}</v-btn>
					</v-card-title>
					<v-card-text>
						<p class="overline">
							Définissez les listes des différents combobox
							<br />Attention de définir les clés uniques par liste (pas de vérification pour le moment)
						</p>
						<v-expand-transition>
							<div v-if="listspreference" class="right-preference">
								<div class="mb-2">
									<v-btn color="primary2" @click="$refs.listwinedit.open(-1)">Nouveau élément</v-btn>
								</div>

								<table class="tablestriped blackheader backgroud-white">
									<thead>
										<tr>
											<th>Liste</th>
											<th>Clé</th>
											<th>Valeur</th>
											<th>Options</th>
											<th style="width: 50px">&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row_li, indexList) in lists" :key="indexList">
											<td>
												{{ row_li.li_name | formatFromArray($store.state.items_lists) }}
											</td>
											<td>{{ row_li.value }}</td>
											<td>{{ row_li.text }}</td>
											<td>{{ row_li.options }}</td>
											<td>
												<v-icon @click="$refs.listwinedit.open(row_li.li_id)">mdi-pencil</v-icon>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</v-expand-transition>
					</v-card-text>
				</v-card>

				<v-card
					v-if="$store.state.preferences.rights.preference_right_access"
					class="card card-rightpreference mb-4"
					:class="tradspreference ? 'backgroud-brown' : 'backgroud-white'"
				>
					<v-card-title>
						<h1>
							Les
							<span class="bis">traductions</span>
						</h1>
						<v-btn class="ml-5 btn-rightpreference secondary2" @click="tradspreference = !tradspreference">{{
							tradspreference ? "Fermer" : "Ouvrir"
						}}</v-btn>
					</v-card-title>
					<v-card-text>
						<p class="overline">Définissez les traductions des mots clés</p>
						<v-expand-transition>
							<div v-if="tradspreference" class="right-preference">
								<div class="mb-2">
									<v-btn color="primary2" @click="$refs.tradwinedit.open(-1)">Nouveau élément</v-btn>
								</div>
								<table class="tablestriped blackheader backgroud-white">
									<thead>
										<tr>
											<th>Clé</th>
											<th>Français</th>
											<th>Anglais</th>
											<th>Russe</th>
											<th>Italien</th>
											<th>Allemand</th>
											<th>Espagnol</th>
											<th style="width: 50px">&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row_tr, indexTrad) in trads" :key="indexTrad">
											<td>{{ row_tr.tr_key }}</td>
											<td>{{ row_tr.tr_fr }}</td>
											<td>{{ row_tr.tr_en }}</td>
											<td>{{ row_tr.tr_ru }}</td>
											<td>{{ row_tr.tr_it }}</td>
											<td>{{ row_tr.tr_de }}</td>
											<td>{{ row_tr.tr_sp }}</td>
											<td>
												<v-icon @click="$refs.tradwinedit.open(row_tr.tr_id)">mdi-pencil</v-icon>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</v-expand-transition>
					</v-card-text>
				</v-card>

				<v-card
					v-if="$store.state.preferences.rights.preference_right_access"
					class="card card-rightpreference mb-4"
					:class="tradspreference ? 'backgroud-brown' : 'backgroud-white'"
				>
					<v-card-title>
						<h1>
							Les
							<span class="bis">logs</span>
						</h1>
						<v-btn class="ml-5 btn-rightpreference secondary2" @click="logspreference = !logspreference">{{
							logspreference ? "Fermer" : "Ouvrir"
						}}</v-btn>
					</v-card-title>
					<v-card-text>
						<p class="overline">Voir tous les logs de l'application</p>
						<v-expand-transition>
							<div v-if="logspreference" class="logs-preference">
								<div class="mb-2">
									<!-- <v-btn color="primary2" @click="$refs.tradwinedit.open(-1)"
                    >Nouveau élément</v-btn
                  > -->
								</div>
								<table class="tablestriped blackheader backgroud-white">
									<thead>
										<tr>
											<th>Fait par</th>
											<th>Action</th>
											<th>Table</th>
											<th>Données</th>
											<th style="width: 50px">&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row_lo, indexLog) in logs" :key="indexLog">
											<td style="width: 150px">
												{{ row_lo.co_id_user | formatContactName }}
											</td>
											<td>{{ row_lo.lo_model_event }}</td>
											<td>{{ row_lo.lo_model_name }}</td>
											<td>{{ row_lo.lo_data }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</v-expand-transition>
					</v-card-text>
				</v-card>
			</v-col> </v-row
		><v-snackbar v-model="modalMessageSynchImmotoolbox" top :timeout="10000">
			En cours de synchronisation... le temps d'attente est d'environ 1H (les ventes s'importent après les locations).
			<v-btn color="green" text @click="modalMessageSynchImmotoolbox = false">Fermé</v-btn>
		</v-snackbar>
		<!-- <m-message-dialog
      v-model="modalMessageSaveData"
      text="En cours de synchronisation... le temps d'attente est d'environ 30mn."
      styleModal="ok"
      @closeModal="closeModalMessage()"
    ></m-message-dialog> -->
		<list-win-edit ref="listwinedit" @listWinEditSaved="loadLists"></list-win-edit>
		<trad-win-edit ref="tradwinedit" @tradWinEditSaved="loadTrads"></trad-win-edit>
		<v-snackbar v-model="modalMessageSaveData" top :timeout="3000">
			Vos modifications ont bien été prises en compte
			<v-btn color="green" text @click="modalMessageSaveData = false">Fermé</v-btn>
		</v-snackbar>
		<v-snackbar v-model="modalMessageImmotools" top :timeout="5000">
			Le fichier a été importé avec succès.
			<v-btn color="pink" text @click="modalMessageImmotools = false">Fermé</v-btn>
		</v-snackbar>
		<v-snackbar v-model="modalMessageImmotoolsError" top :timeout="5000">
			<span v-html="immotoolboxErrorTxt"> </span>
			<v-btn color="pink" text @click="modalMessageImmotoolsError = false">Fermé</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
// import Vue from "vue";
import { Swatches } from "vue-color";
import ListWinEdit from "./ListWinEdit";
import TradWinEdit from "./TradWinEdit";
import OfferCardBuilding from "../views/OfferCardBuilding";

export default {
	name: "preferences",
	components: {
		"swatches-picker": Swatches,
		"list-win-edit": ListWinEdit,
		"trad-win-edit": TradWinEdit,
		"offer-card-building": OfferCardBuilding
	},
	data() {
		return {
			typeimport: 0,
			cmptsynchtoolbox: { nbpages: 0, numpage: 0 },
			modalMessageSynchImmotoolbox: false,
			disableSynchImagesImmotools: false,
			immotoolboxErrorTxt: "",
			overlayImmotools: false,
			modalMessageImmotools: false,
			modalMessageImmotoolsError: false,
			fileImmotools: null,
			user: null,
			// affichage des parties
			personalpreference: false,
			logspreference: false,
			sharedpreference: false,
			rightpreference: false,
			buildingpreference: false,
			listspreference: false,
			tradspreference: false,
			immotoolspreference: false,
			dialogBuilding: false,
			buildingSelected: {},
			// data preferences
			personalPreferenceData: {},
			sharedPreferenceData: {},
			selectedLanguage: "fr",
			modelesMail: {},
			modeleMailtext: "",
			itemsPages: [],
			itemsType: [],
			rightPreferenceData: [],
			buildings: [],
			districts: [],
			lists: [],
			trads: [],
			logs: [],
			newDistrictName: "",
			modalMessageSaveData: false,
			logoUrl: null,
			logo: null,
			logo2Url: null,
			logo2: null,
			descriptionTooLong: {
				fr: false,
				en: false,
				ru: false,
				it: false,
				es: false,
				de: false
			}
		};
	},
	watch: {
		buildingpreference(v) {
			if (v) this.loadBuildings();
		},
		listspreference(v) {
			if (v) this.loadLists();
		},
		logspreference(v) {
			if (v) this.loadLogs();
		},
		tradspreference(v) {
			if (v) this.loadTrads();
		}
	},

	async mounted() {
		this.user = await this.$store.getters.get_user;
		this.personalPreferenceData = this.$store.state.preferences.personal;
		if (!this.personalPreferenceData) this.personalPreferenceData = {};
		if (!this.personalPreferenceData.color) this.personalPreferenceData.color = {};
		this.districts = this.$store.state.preferences.shared.districts;
		if (!this.districts) this.districts = [];
		// console.log(
		//   "this.$store.state.preferences.shared",
		//   this.$store.state.preferences.shared
		// );
		this.logoUrl = this.$store.state.preferences.shared.logoUrl;
		this.logo2Url = this.$store.state.preferences.shared.logo2Url;
		this.sharedPreferenceData = this.$store.state.preferences.shared;
		this.modelesMail = this.$store.state.preferences.shared.modelesMail;
		if (!this.modelesMail) this.modelesMail = {};
		this.itemsType = this.$store.state.items_co_type.filter(item => {
			return item.value;
		});
		this.itemsPages = this.$store.state.items_page_right;
		this.rightPreferenceData = await this.loadRight();
	},
	sockets: {
		updatecmptsynchtoolbox(data) {
			// console.log("updatecmptsynchtoolbox", data);
			this.cmptsynchtoolbox = data;
			this.disableSynchImagesImmotools = true;
		}
	},
	computed: {
		cmptSynchToolbox() {
			let f = "analyse la page";
			if (this.cmptsynchtoolbox.founded) f = "TROUVÉ " + this.cmptsynchtoolbox.founded;
			if (this.cmptsynchtoolbox.notfounded) f = "Non trouvé " + this.cmptsynchtoolbox.notfounded;
			return `Synchronisation en cours : ${this.cmptsynchtoolbox.type}, ${this.cmptsynchtoolbox.numpage}/${Math.ceil(
				this.cmptsynchtoolbox.nbpages
			)} (${f})`;
		}
	},
	methods: {
		async reloadofferimages() {
			let last = "";
			if (this.buildingSelected) last = this.buildingSelected.bu_id;
			await this.loadBuildings();
			if (last) this.editBuilding(this.$_.find(this.buildings, { bu_id: last }));
		},
		downloadLastXml() {
			window.open(this.$config.server_url + "/api/1.0/lastxml?token=" + this.$store.state.accesstoken, "_blank");
		},
		editBuilding(item) {
			this.buildingSelected = item;
			this.dialogBuilding = true;
		},
		cancelWinBuilding() {
			this.buildingSelected = {};
			this.dialogBuilding = false;
		},
		async saveWinBuilding() {
			if (this.buildingSelected.bu_id === 0) {
				this.buildingSelected.bu_name = this.buildingSelected.bu_name_new;
				await this.$axios.post(this.$config.server_url + "/api/1.0/buildings", this.buildingSelected);
				this.loadBuildings();
			} else {
				if (this.buildingSelected.edit_bu_name) this.buildingSelected.bu_name = this.buildingSelected.bu_name_new;
				await this.$axios.put(this.$config.server_url + "/api/1.0/buildings/" + this.buildingSelected.bu_id, this.buildingSelected);
			}
			this.cancelWinBuilding();
		},
		saveFileImmotools(files) {
			this.fileImmotools = files[0];
		},
		async synchImagesImmotoolspreference() {
			this.disableSynchImagesImmotools = true;
			await this.$axios.get(this.$config.server_url + "/api/1.0/offers/synchImagesImmotools");
			this.modalMessageSynchImmotoolbox = true;
		},
		async saveImmotoolspreference() {
			// if (!this.fileImmotools) return;
			this.overlayImmotools = true;
			let formData = new FormData();
			formData.append("typeimport", this.typeimport);
			formData.append("file", this.fileImmotools);
			let res = await this.$axios.post(this.$config.server_url + "/api/1.0/offers/importimmotools", formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			// console.log("res.data", res.data);
			if (res.data.error) {
				// console.log("this.typeimport", this.typeimport);
				if (this.typeimport === "1") {
					this.immotoolboxErrorTxt =
						"Le fichier que vous importé est un fichier de location !<br> Vous devez choisir 'A la location' dans le choix du type d'import.";
				}
				if (this.typeimport === "2") {
					this.immotoolboxErrorTxt =
						"Le fichier que vous importé est un fichier de vente !<br> Vous devez choisir 'A la vente' dans le choix du type d'import.";
				}
				this.modalMessageImmotoolsError = true;
				this.overlayImmotools = false;
				// console.log("modalMessageImmotoolsError", this.modalMessageImmotoolsError, this.immotoolboxErrorTxt);
			} else {
				this.modalMessageImmotools = true;
				this.overlayImmotools = false;
				this.$refs.inputImmotools.reset();
				this.typeimport = 0;
			}
		},
		async deauthazure() {
			let params = {};
			await this.$axios.delete(this.$config.server_url + "/api/1.0/deauthorizeazure", { params });
			document.location.reload();
		},
		async authazure() {
			window.open(this.$config.server_url + "/authorizeazure/" + encodeURIComponent(this.$store.state.accesstoken), "_self");
		},
		onLogoChange(f) {
			if (f.name.match(/\.(jpg|jpeg|png|gif)$/)) {
				// this.logo = f;
				var reader = new FileReader();
				var me = this;
				reader.onload = (function() {
					return function(e) {
						me.logoUrl = e.target.result;
					};
				})(f);
				reader.readAsDataURL(f);
			}
		},
		onLogoChange2(f) {
			if (f.name.match(/\.(jpg|jpeg|png|gif)$/)) {
				// this.logo2 = f;
				var reader = new FileReader();
				var me = this;
				reader.onload = (function() {
					return function(e) {
						me.logo2Url = e.target.result;
					};
				})(f);
				reader.readAsDataURL(f);
			}
		},
		async deleteBuilding(row_bu) {
			if (window.confirm(`Confirmer effacement de l'immeuble ?`)) {
				let params = {};
				await this.$axios.delete(this.$config.server_url + "/api/1.0/buildings/" + row_bu.bu_id, { params });
				await this.loadBuildings();
			}
		},
		async loadBuildings() {
			let params = { addoffers: true, sort: "bu_name", addimages: true };
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/buildings", { params });
			this.buildings = response.data.data;
		},
		async loadLists() {
			let params = { sort: "li_name, value" };
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/lists", { params });
			this.lists = response.data.data;
		},
		async loadLogs() {
			let params = { limit: 1000 };
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/logs", { params });
			this.logs = response.data.data;
		},
		async loadTrads() {
			let params = { sort: "tr_key" };
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/trads", { params });
			this.trads = response.data.data;
		},
		// addModelMailLangue() {
		//   if (!this.modeleMailtext) return;
		//   let newmodelMail = {
		//     value: this.selectedLanguage,
		//     text: this.modeleMailtext
		//   };
		//   this.modelesMail.push(newmodelMail);
		//   // console.log("this.modelesMail", this.modelesMail);
		// },
		showModalMessage() {
			this.modalMessageSaveData = true;
		},
		closeModalMessage() {
			this.modalMessageSaveData = false;
		},
		addDistrict() {
			if (!this.newDistrictName) return;
			let newDistrict = {
				value: this.newDistrictName,
				text: this.newDistrictName
			};
			this.districts.push(newDistrict);
			this.newDistrictName = "";
		},
		checkCol(col) {
			let ok = this.rightPreferenceData[0][this.itemsType[col].value];
			for (let iDataRi = 0; iDataRi < this.rightPreferenceData.length; iDataRi++) {
				const row = this.rightPreferenceData[iDataRi];
				for (let j = 0; j < this.itemsType.length; j++) {
					const type = this.itemsType[j];
					if (col == j) {
						row[type.value] = !ok;
					}
				}
			}
			// force un update... sinon problème (je ne sais pas pourquoi)
			this.$forceUpdate();
		},
		checkLine(line) {
			for (let iDataRi = 0; iDataRi < this.rightPreferenceData.length; iDataRi++) {
				const row = this.rightPreferenceData[iDataRi];
				if (line.value === row.value) {
					let ok = row[this.itemsType[0].value];
					for (let j = 0; j < this.itemsType.length; j++) {
						const type = this.itemsType[j];
						row[type.value] = !ok;
					}
				}
			}
		},
		async savePersonalPreference() {
			this.user.co_preferences = this.personalPreferenceData;
			await this.$axios.put(this.$config.server_url + "/api/1.0/contacts/" + this.user.co_id, { co_preferences: this.personalPreferenceData });
			this.$store.commit("set_preferences", {
				preference: "personal",
				data: this.personalPreferenceData
			});
			this.showModalMessage();
		},
		async loadRight() {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/rights");
			let data = [];
			for (let ipage = 0; ipage < this.itemsPages.length; ipage++) {
				const page = this.itemsPages[ipage];
				let itemsData = {};
				itemsData.text = page.text;
				itemsData.value = page.value;
				let tab_right_page = response.data.data.filter(element => {
					return element.ri_right === page.value;
				});
				for (let index = 0; index < tab_right_page.length; index++) {
					const row_ri = tab_right_page[index];
					itemsData[row_ri.ri_type] = row_ri.ri_value;
				}
				data.push(itemsData);
			}
			return data;
		},
		async saveSharedPreference() {
			this.sharedPreferenceData.districts = this.districts;
			this.sharedPreferenceData.modelesMail = this.modelesMail;
			this.sharedPreferenceData.logoUrl = this.logoUrl;
			this.sharedPreferenceData.logo2Url = this.logo2Url;
			// console.log("this.modelesMail", this.modelesMail);
			// console.log("sharedPreferenceData", this.sharedPreferenceData);
			await this.$axios.post(this.$config.server_url + "/api/1.0/rights/sharedpreference", this.sharedPreferenceData);
			// console.log("this.sharedPreferenceData", this.sharedPreferenceData);
			this.showModalMessage();
		},
		async saveRightPreference() {
			let rows_ri = [];
			for (let i = 0; i < this.rightPreferenceData.length; i++) {
				const page = this.rightPreferenceData[i];
				for (let j = 0; j < this.itemsType.length; j++) {
					const type = this.itemsType[j];
					if (!page[type.value]) {
						page[type.value] = false;
					}
					rows_ri.push({
						ri_right: page.value,
						ri_value: page[type.value],
						ri_type: type.value
					});
				}
			}
			await this.$axios.post(this.$config.server_url + "/api/1.0/rights/", rows_ri);
			this.showModalMessage();
			document.location.reload();
		}
	}
};
</script>
<style lang="scss" scoped>
.container-preferences {
	width: 100%;
	height: 100%;
	overflow: auto;
}
.card {
	padding: 20px;
}
h1 {
	width: 335px;
}
.backgroud-blue {
	background-color: #bde0f0;
}
.backgroud-brown {
	background-color: #d3c8ba;
}
.backgroud-gray {
	background-color: #dcd8d2;
}
.backgroud-col2 {
	background-color: #b8c8d4;
}
.backgroud-col3 {
	background-color: #dcd8d3;
}
.backgroud-white {
	background-color: #fff;
}
// .theme--light.v-btn {
//   color: black;
// }

.subtitle {
	font-size: 15px;
}
.tab-district {
	.icon-delete {
		float: right;
	}
}
.icon-add {
	margin-top: 10px;
	margin-left: 10px;
}
.tiret-ref {
	padding: 10px;
	margin-top: 18px;
}
.container-title-shared {
	justify-content: space-between;
}
#inspire .v-input.theme--light.v-text-field.v-text-field--is-booted.v-file-input {
	margin: 0 !important;
	padding: 0 !important;
}
.container-logo {
	float: right;
}
.logo {
	width: 162px;
	height: 70px;
	background-size: contain;
	background-position: center;
}
</style>
