<template>
	<v-layout align-center justify-center fill-height>
		<v-flex fill-height md6 class="card1">
			<v-card class="fill-height overflow-auto" flat>
				<v-card-text>
					<v-layout row wrap>
						<v-flex style="max-width: 140px">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-img
										@click="$router.push('/contacts/' + $store.state.user.co_id + '/edit')"
										:src="
											user.co_id
												? $config.server_url +
												  '/api/1.0/avatar/' +
												  user.co_id +
												  '/100/100?d=' +
												  user.updatedAt +
												  '&token=' +
												  $store.state.accesstoken
												: '/img/default-profile.jpg'
										"
										height="100px"
										width="100px"
										v-on="$store.state.preferences.personal.helpBubble ? on : false"
										class="rounded-image withpointer"
									></v-img>
								</template>
								<span>Cliquer pour modifier votre avatar</span>
							</v-tooltip>
						</v-flex>
						<v-flex>
							<h1 class="mb-0">
								{{ user | formatContactName }}
								<!-- <a @click="openWinUser()" href="javascript:void(0);">
                  <v-icon
                    @click="$router.push('/contacts/'+$store.state.user.co_id+'/edit')"
                  >mdi-eye</v-icon>
                </a>-->
							</h1>
							<h2>{{ user.co_function }}</h2>
							<!-- <div
                class="mt-4"
                v-if="user.co_mobile1"
                style="line-height:1rem;"
              >Mob : {{ user.co_mobile1 }}</div>
              <div v-if="user.co_tel1" style="line-height:1rem;">Tél : {{ user.co_tel1 }}</div>
              <div class="mt-4" v-if="user.co_email1" style="line-height:1rem;">
                eMail :
                <a :href="`mailto:${user.co_email1}`">{{ user.co_email1 }}</a>
              </div> -->
							<div v-if="user.co_type" style="line-height: 1rem">Droit : {{ co_type_text }}</div>
						</v-flex>
						<v-flex v-if="user.co_type == 'admin'"
							><b>Utilisateurs connectés :</b>
							<ul>
								<li v-for="con in rooms_connected" :key="con.co_id">{{ con | formatContactName }} ({{ con.cmpt }})</li>
							</ul>
						</v-flex>
					</v-layout>
					<hr />
					<v-layout>
						<v-flex d-flex flex-row justify-space-around>
							<div class="mr-3 d-flex flex-row" @click="gotoContact($store.state.user)" style="cursor: pointer">
								<div class="indicateur-de-travail vert d-flex flex-row">
									<div>{{ row_co.nbSells }}</div>
								</div>
								<h2 class="ml-2 pt-2" style="line-height: 1rem">Ventes<br />chef de produit</h2>
							</div>
							<div class="mr-3 d-flex flex-row" @click="gotoContact($store.state.user)" style="cursor: pointer">
								<div class="indicateur-de-travail bleu-clair d-flex flex-row">
									<div>{{ row_co.nbRents }}</div>
								</div>
								<h2 class="ml-2 pt-2" style="line-height: 1rem">Locations<br />chef de produit</h2>
							</div>
							<div class="d-flex flex-row" style="cursor: pointer">
								<div class="indicateur-de-travail rouge d-flex flex-row">
									<div>{{ actionsTotalTodos }}</div>
								</div>
								<h2 class="ml-2 pt-3">Actions à faire</h2>
							</div>
						</v-flex>
					</v-layout>
					<hr />
					<div class="mt-3 d-flex">
						<h2 class="mr-3">
							{{ $options.filters.plural(actionsTotalTodos, "action", "actions") }}
							{{ filteractionsdone ? "" : "à traiter " }}
						</h2>
						<div class="formlittle">
							<v-btn color="primary2" x-small style="float: right" @click="openWinAction(-1)">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
						<div class="ml-auto formlittle" style="padding-right: 10px">
							<label
								class="label-switch-left"
								v-html="actionsTotalTodos >= 200 ? `Voir les faits (limité à 200)` : `Voir les faits`"
								@click="filteractionsdone = !filteractionsdone"
							></label>
							<v-switch
								v-model="filteractionsdone"
								:name="$Utils.randomstring('filteractionsdone')"
								color="#53bd7b"
								inset
								:light="true"
								dense
							></v-switch>
						</div>
					</div>
					<card-table-actions2
						:reload="$store.state.reload_actions"
						actions-maintype="user"
						actions-type="report,todo,call,visit"
						:actions-onlynotdone="!filteractionsdone"
						:actions-nb-max="5"
						:actions-nb-supermax="200"
						:action-id="row_co.co_id"
						:header="false"
						:offerShortName="true"
						:colcontact="true"
						:colagent="false"
						:coldone="true"
						@total="actionsTotalTodos = $event"
					></card-table-actions2>

					<!-- <div class="mt-6 d-flex">
            <h2 class="mr-3">
              {{
                $options.filters.plural(
                  actionsTotalReports,
                  "compte-rendu",
                  "comptes-rendus"
                )
              }}
              {{ filteractionsdone ? " " : "à compléter " }}
            </h2>
            <div class="ml-auto formlittle" style="padding-right:10px;">
              <label
                class="label-switch-left"
                v-html="
                  actionsTotalReports >= 200
                    ? `Voir les faits (limité à 200)`
                    : `Voir les faits`
                "
                @click="filteractionsdone = !filteractionsdone"
              ></label>
              <v-switch
                v-model="filteractionsdone"
                :name="$Utils.randomstring('filteractionsdone')"
                color="#53bd7b"
                :inset="true"
                :light="true"
                dense
              ></v-switch>
            </div>
          </div>
          <card-table-actions2
            :reload="$store.state.reload_actions"
            actions-maintype="user"
            actions-type="report"
            :actions-onlynotdone="!filteractionsdone"
            :actions-nb-max="5"
            :actions-nb-supermax="200"
            :action-id="row_co.co_id"
            :header="false"
            :offerShortName="true"
            :colcontact="row_co.co_type != 'contact'"
            :colagent="row_co.co_type == 'contact'"
            :coldone="false"
            @total="actionsTotalReports = $event"
          ></card-table-actions2> -->
				</v-card-text>
			</v-card>
		</v-flex>
		<v-flex fill-height md3 class="card2">
			<v-card flat class="fill-height">
				<v-card-text class="fill-height d-flex flex-column" style="height: 100%">
					<!-- <div class="fill-height" ref="calendarContainer"></div> -->
					<div class="flex-grow-0">
						<div class="d-flex">
							<div class="home-bigday">
								{{ $moment(currentCalendarDate).format("DD") }}
							</div>
							<div style="margin-left: 10px">
								<div class="home-bigmonth">
									{{ $moment(currentCalendarDate).format("MMMM") }}
								</div>
								<div>
									<!-- {{$moment(currentCalendarDate).format('dddd')}} | -->
									Semaine {{ $moment(currentCalendarDate).format("ww") }}
								</div>
							</div>
							<v-spacer></v-spacer>
							<div @click="printDayAgent" class="align-self-center">
								<icon class="icon-print mr-4 withpointer" name="print"></icon>
							</div>
						</div>
						<div class="d-flex justify-space-between pb-3">
							<div
								class="withpointer pt-3"
								@click="
									currentCalendarDate = $moment(currentCalendarDate)
										.add(-1, 'week')
										.format('YYYY-MM-DD')
								"
							>
								<v-icon>mdi-skip-previous</v-icon>
							</div>
							<div
								class="withpointer"
								v-for="index in 7"
								:key="index"
								@click="
									currentCalendarDate = $moment(currentCalendarDate)
										.startOf('week')
										.add(index - 1, 'day')
										.format('YYYY-MM-DD')
								"
							>
								<div class="withpointer" style="text-transform: capitalize; color: #aaa; text-align: center">
									{{
										$moment(currentCalendarDate)
											.startOf("week")
											.add(index - 1, "day")
											.format("dd")
									}}
								</div>
								<div
									:class="{
										'home-days': true,
										active: $moment(currentCalendarDate)
											.startOf('week')
											.add(index - 1, 'day')
											.isSame($moment(currentCalendarDate))
									}"
								>
									{{
										$moment(currentCalendarDate)
											.startOf("week")
											.add(index - 1, "day")
											.format("DD")
									}}
								</div>
							</div>
							<div
								class="withpointer pt-3"
								@click="
									currentCalendarDate = $moment(currentCalendarDate)
										.add(1, 'week')
										.format('YYYY-MM-DD')
								"
							>
								<v-icon>mdi-skip-next</v-icon>
							</div>
						</div>
					</div>
					<div class="flex-grow-1 overflow-hidden">
						<calendar-details
							v-model="currentCalendarDate"
							defaultView="timeGridDay"
							:showHeader="false"
							:paddingHeight="0"
							@events="calendarDetailsEvents"
						></calendar-details>
					</div>
				</v-card-text>
			</v-card>
		</v-flex>
		<v-flex fill-height md3 class="card3" v-if="$store.state.preferences.rights.message_access">
			<v-card flat class="fill-height overflow-auto">
				<v-card-text>
					<h1>
						Fil
						<span class="bis">d'infos</span>
					</h1>
					<messages-card
						:limit="50"
						:autoload="true"
						:users="usersMessages"
						:readonly="false"
						:filterfrom="
							$moment()
								.add(-2, 'months')
								.format('YYYY-MM-DD')
						"
						:filterto="
							$moment()
								.add(1, 'day')
								.format('YYYY-MM-DD')
						"
					></messages-card>
					<!--   <m-form-text
            type="textarea"
            :name="$Utils.randomstring()"
            v-model="message"
            label="Message"
            label-position="top"
          ></m-form-text>
          <div class="combobox-message">
            <m-form-combobox
              v-model="me_contact"
              v-if="showComboboxContact"
              label="Contact"
              label-position="top"
              prepend-icon="mdi-account"
              class="combobox-message-contact"
              :store-url="$config.server_url + '/api/1.0/contacts'"
              :store-params="{gr_id:'contacts'}"
              item-value="co_id"
              :item-text="$options.filters.formatContactName"
            ></m-form-combobox>

            <m-form-combobox
              v-model="me_offer"
              v-if="showComboboxOffer"
              class="combobox-message-offer"
              label="Bien"
              label-position="top"
              :name="$Utils.randomstring('of_message')"
              :store-url="$config.server_url + '/api/1.0/offers'"
              item-value="of_id"
              :item-text="$options.filters.formatOfferName"
            ></m-form-combobox>
          </div>
          <div class="d-flex flex-row container-btn-input-message mt-2">
            <div>
              <v-icon
                class="home-icon-message home-icon-offer"
                @click="onclickIconofferMessage"
              >mdi-home</v-icon>
              <v-icon
                class="home-icon-message home-icon-contact"
                @click="onclickIconContactMessage"
              >mdi-account</v-icon>
            </div>
            <v-btn
              id="btn-envoyer-message"
              color="#757575"
              small
              depressed
              @click="sendMessage()"
            >envoyer</v-btn>
          </div>

          <div v-for="row_me in messages" :key="row_me.me_id">
            <div
              :class="{messageContainer:true, left:row_me.me_from.co_id==$store.state.user.co_id, right:row_me.me_from.co_id!=$store.state.user.co_id}"
            >
              <div
                class="messageTitle"
              >{{row_me.me_from | formatmessagewho($store.state.user)}} {{row_me.me_date | formatmessagedate}}</div>
              <div class="messageIcon">
                <v-img
                  :src="row_me.me_from.co_id?$config.server_url+'/api/1.0/avatar/'+row_me.me_from.co_id+'/180/180?d='+row_me.me_from.updatedAt:'/img/default-profile.jpg'"
                  height="35px"
                  width="35px"
                  class="rounded-image"
                ></v-img>
              </div>
              <div class="messageContent">
                <div class="messageArrow"></div>
                <div
                  class="messageDelete"
                  v-if="row_me.me_from.co_id==$store.state.user.co_id"
                  @click="me_id_to_delete=row_me.me_id; confirmDeleteMessageDialog = true;"
                >
                  <v-icon>mdi-delete</v-icon>
                </div>
                <div v-if="row_me.co_id" class="lien-message">
                  Contact :
                  <a
                    @click="$router.push('/contacts/'+ row_me.co_id.co_id +'/details')"
                  >{{$options.filters.formatContactName(row_me.co_id)}}</a>
                </div>
                <div v-if="row_me.of_id" class="lien-message">
                  Bien :
                  <a
                    @click="$router.push('/offers/'+ row_me.of_id.of_id +'/details')"
                  >{{$options.filters.formatOfferName(row_me.of_id)}}</a>
                </div>
                {{row_me.me_text}}
              </div>
            </div>
            <div class="clearfix"></div>
          </div>-->
				</v-card-text>
			</v-card>
		</v-flex>
		<!-- <m-confirm-dialog
      v-model="confirmDeleteMessageDialog"
      text="Souhaitez-vous effacer ce message ?"
      title="Confirmation"
      @canceled="confirmDeleteMessageDialog=false"
      @confirmed="deleteMessage()"
    ></m-confirm-dialog>-->
		<!-- <action-win-edit ref="actionwinedit" @actionWinEditSaved="loadActions"></action-win-edit> -->
	</v-layout>
</template>

<script>
// import { Calendar } from "@fullcalendar/core";
// import listPlugin from "@fullcalendar/list";
// import frLocale from "@fullcalendar/core/locales/fr";
//import moment from "moment";
// import Vue from "vue";
// moment.locale("fr");

export default {
	name: "home",
	props: {},
	/* filters: {
    formatmessagedate: date => {
      let until = moment(date).fromNow();
      return until;
    },
    formatmessagewho(row_co, user) {
      if (row_co.co_id == user.co_id) return "Moi";
      return Vue.filter("formatContactName")(row_co);
    }
  }, */
	computed: {
		rooms_connected() {
			return this.$store.state.rooms_connected;
		},
		reload_actions() {
			return this.$store.state.reload_actions;
		},
		co_type_text() {
			return this.$_.result(
				this.$_.find(this.$store.state.items_co_type, {
					value: this.$store.state.user.co_type
				}),
				"text",
				"N/A"
			);
		},
		nbReportToComplete() {
			return this.$store.state.reports_tocomplete;
		},
		user() {
			return this.$store.state.user;
		}
	},
	watch: {
		reload_actions() {
			this.loadActions();
			this.loadUser();
		},
		/* nbReportToComplete(val, oldVal) {
      // console.log("val,oldVal", val.length, oldVal.length);
      this.loadActions();
    }, */
		filteractionsdone(val) {
			// this.loadActions();
		}
		//   user: {
		//     handler: function(newVal, oldVal) {
		//       this.$store.commit("set_user", this.$_.cloneDeep(newVal));
		//     },
		//     deep: true
		//   }
	},
	data() {
		return {
			reload: Number,
			actionsTotalTodos: 0,
			actionsTotalReports: 0,
			lastCalendarDetailsEvents: [],
			usersMessages: [],
			users: [],
			// user: this.$_.cloneDeep(this.$store.state.user),
			filteractionsdone: false,
			row_co: {
				signatures_sell_user: [],
				signatures_rent_user: [],
				todos_user: []
			},
			// filterreportsdone: false,
			currentCalendarDate: this.$moment().format("YYYY-MM-DD"),
			// actionsNbMax: 2,
			/* showComboboxContact: false,
      showComboboxOffer: false,
      me_contact: {},
      me_offer: {},
      confirmDeleteMessageDialog: false,
      messages: [],
      message: "", */
			calendar: null,
			actionsShowMore1: false,
			actionsShowMore2: false,
			actionsHeaders: [
				{ text: "Action", value: "ac_type" },
				{ text: "Description", value: "ac_comment" },
				{
					text: "Contact",
					align: "left",
					sortable: true,
					value: "co_id_contact"
				},
				{ text: "Date", value: "ac_date", width: 180 }
			],
			actionsTodos: [],
			actionsReports: [],
			actionsTodosTodo: []
		};
	},
	mounted() {
		this.users.push(this.$store.state.user.co_id);
		//   this.loadMessages();
		this.loadUser();
		// this.loadActions();
	},
	methods: {
		gotoContact(row_co) {
			this.$router.push("/contacts/" + row_co.co_id + "/details");
		},
		calendarDetailsEvents(evts) {
			this.lastCalendarDetailsEvents = evts;
		},
		printDayAgent() {
			let of_ids = [];
			for (let iEvt = 0; iEvt < this.lastCalendarDetailsEvents.length; iEvt++) {
				const row_ve = this.lastCalendarDetailsEvents[iEvt];
				for (let iVisit = 0; iVisit < row_ve.visits.length; iVisit++) {
					const row_ac = row_ve.visits[iVisit];
					if (row_ac.of_id && row_ac.of_id.of_id) of_ids.push(row_ac.of_id.of_id);
				}
			}
			if (of_ids.length === 0) {
				of_ids.push("nothing");
			}
			window.open("/#/offers/" + of_ids.join("|") + "/" + this.$moment(this.currentCalendarDate).format("YYYY-MM-DD") + "/printdayagent");
		},
		openWinAction(ac_id) {
			this.$root.$children[0].openWinActionCR(ac_id);
		},
		/* openWinAction(ac_id) {
      if (this.$_.isPlainObject(ac_id)) ac_id = ac_id.ac_id;
      this.$refs.actionwinedit.open(ac_id);
    }, */
		/*     onclickIconContactMessage() {
      this.showComboboxContact = !this.showComboboxContact;
    },
    onclickIconofferMessage() {
      this.showComboboxOffer = !this.showComboboxOffer;
    }, */
		/* async deleteMessage() {
      await this.$axios.delete(
        this.$config.server_url + "/api/1.0/messages/" + this.me_id_to_delete
      );
      this.confirmDeleteMessageDialog = false;
      await this.loadMessages();
    },

    async sendMessage() {
      await this.$axios.post(this.$config.server_url + "/api/1.0/messages", {
        me_text: this.message,
        of_id: this.me_offer ? this.me_offer.of_id : null,
        co_id: this.me_contact ? this.me_contact.co_id : null
      });
      this.message = "";
      this.me_offer = null;
      this.me_contact = null;
      await this.loadMessages();
    }, */
		// loadActionsDone() {
		//   //this.reload = false;
		// },
		async loadActions() {
			/*  this.tableTodoKey++;
      this.tableCRKey++; */
			//this.reload = true;
			// let response = await this.$axios.get(
			//   this.$config.server_url + "/api/1.0/actions",
			//   {
			//     params: {
			//       limit: this.limitActions,
			//       sort: "ac_date DESC",
			//       done: this.filteractionsdone,
			//       co_id_user: this.$store.state.user.co_id
			//     }
			//   }
			// );
			// // console.log("this.filteractionsdone", this.filteractionsdone);
			// this.actionsReports = response.data.data.filter((val, ind) => {
			//   if (!this.filteractionsdone) {
			//     return (
			//       val.ac_type == "report" &&
			//       this.$moment(val.ac_date).isBefore(this.$moment())
			//     );
			//   }
			//   return val.ac_type == "report";
			// });
			// this.actionsTodos = response.data.data.filter((val, ind) => {
			//   return val.ac_type != "report";
			// });
			// this.actionsTodosTodo = response.data.data.filter((val, ind) => {
			//   return val.ac_type != "report" && !val.ac_done;
			// });
			// console.log("this.actionsTodos", this.actionsTodos);
		},
		/* async loadMessages() {
      let response = await this.$axios.get(
        this.$config.server_url + "/api/1.0/messages",
        {
          params: { sort: "me_date DESC", limit: "100" }
        }
      );
      // console.log("response.data.data", response.data.data);
      this.messages = response.data.data;
    }, */
		async loadUser() {
			let params = {};
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + this.$store.state.user.co_id, { params });
			this.row_co = response.data.data;
			this.$store.dispatch("refresh_rooms_connected");
		},
		openWinUser() {
			// this.$parent.openWinUser();
		}
	}
};
</script>

<style scoped lang="scss">
@import "../sass/variables.scss";

.home-bigday {
	font-size: 3rem;
	line-height: 3rem;
	font-weight: bold;
	color: $myteal;
}
.home-bigmonth {
	font-size: 1.5rem;
	line-height: 1.5rem;
	color: $myteal;
}
.home-days {
	border-radius: 50%;
	width: 25px;
	height: 25px;
	text-align: center;
	font-size: 0.875rem;
	line-height: 0.875rem;
	padding-top: 5px;
	&.active {
		background-color: $myteal;
		color: white;
	}
}
#inspire .indicateur-de-travail {
	font-size: 22px;
	width: 45px;
	height: 45px;
}
.card1 hr {
	border-color: #dad8d8;
}
.clearfix {
	clear: both;
}
.container-btn-input-message {
	justify-content: space-between;
	#btn-envoyer-message {
		padding: 0 10px;
		text-transform: uppercase;
	}
}
.tablestriped td {
	padding: 10px;
}
/* #btn-plus {
  padding: 0;
  min-width: 25px;
  height: 25px;
} */
/* .home-icon-message {
  cursor: pointer;
}
.lien-message {
  line-height: 0.8rem;
  font-size: 0.75rem;
  margin-bottom: 5px;
  //   cursor: pointer;
  //   color: #1767c0;
  //   text-decoration: underline;
}
.messageContainer {
  .messageTitle {
    font-size: 11px;
    text-align: center;
  }
  .messageIcon {
    float: right;
  }
  .messageContent {
    float: left;
    width: calc(100% - 35px - 8px);
    padding: 10px;
    background-color: $mygris;
    position: relative;
  }
  .messageArrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  .messageDelete {
    float: right;
    cursor: pointer;
    i {
      font-size: 18px;
    }
  }
  &.right {
    .messageIcon {
      float: right;
    }
    .messageContent {
      margin: 0 8px 10px 0;
    }
    .messageArrow {
      border-left: 8px solid #eceff1;
      position: absolute;
      right: -8px;
    }
  }
  &.left {
    .messageIcon {
      float: left;
    }
    .messageContent {
      margin: 0 0 10px 8px;
    }
    .messageArrow {
      border-right: 8px solid #eceff1;
      position: absolute;
      left: -8px;
    }
  }
} */
.v-card {
	border-radius: 0;
}
.card1,
.card3 {
	padding: 0 12px !important;
}
.card2 {
	padding: 0 !important;
}

.custom-switch.b-custom-control-lg .custom-control-label:before,
.input-group-lg .custom-switch .custom-control-label:before {
	width: 3.1875rem;
}
.custom-switch.b-custom-control-lg .custom-control-label:after,
.input-group-lg .custom-switch .custom-control-label:after {
	left: calc(-1.8125rem + 2px);
}
.custom-switch.b-custom-control-lg .custom-control-input:checked ~ .custom-control-label:after,
.input-group-lg .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
	transform: translateX(1.9375rem);
}
</style>
