<template>
	<v-layout row wrap overflow-auto ref="parentDiv">
		<v-flex md5 pr-0 v-if="!short">
			<div style="margin-bottom: 30px">
				<div style="position: relative" v-if="row_of.of_immotools">
					<div style="position: absolute; z-index: 10; width: 20%; top: 10px">
						<img style="width: 100%" src="/img/immotoolbox.png" alt="" />
					</div>
				</div>

				<vue-flux
					:options="vfOptions"
					:images="vfImages"
					:transitions="vfTransitions"
					:captions="vfCaptions"
					ref="slider"
					:class="carouselsize === 'contain' ? 'carousel-contain' : ''"
				>
					<template v-slot:preloader>
						<flux-preloader />
					</template>

					<template v-slot:caption>
						<flux-caption />
					</template>

					<template v-slot:controls>
						<flux-controls />
					</template>

					<template v-slot:pagination>
						<flux-pagination />
					</template>

					<template v-slot:index>
						<flux-index />
					</template>
				</vue-flux>
				<div>
					<v-btn class="ml-auto mr-3" color="secondary2" small @click="toggleFullScreen">
						Plein écran&nbsp;&nbsp;
						<v-icon small>mdi-fullscreen</v-icon>
					</v-btn>
					<v-btn class="ml-auto mr-3" color="secondary2" small @click="goUrl(row_of.of_urlvideo)" v-if="row_of.of_urlvideo">
						Vidéo&nbsp;&nbsp;
						<v-icon small>mdi-video</v-icon>
					</v-btn>
					<v-btn class="ml-auto" color="secondary2" small @click="goUrl(row_of.of_urlvisitevirtuelle)" v-if="row_of.of_urlvisitevirtuelle">
						Street View&nbsp;&nbsp;
						<v-icon small>mdi-human</v-icon>
					</v-btn>
				</div>
				<!-- <div v-if="row_of.of_urlvideo">
					Lien vidéo :
					<a :href="row_of.of_urlvideo" target="_bank">{{ row_of.of_urlvideo }}</a>
				</div>
				<div v-if="row_of.of_urlvisitevirtuelle">
					Lien street view :
					<a :href="row_of.of_urlvisitevirtuelle" target="_bank">{{ row_of.of_urlvisitevirtuelle }}</a>
				</div> -->

				<!-- <hooper
					ref="myhooper"
					style="height: 300px"
					:infiniteScroll="true"
					:autoPlay="false"
					:itemsToShow="1"
					:itemsToSlide="1"
					:trimWhiteSpace="true"
				>
					<hooper-navigation slot="hooper-addons" v-if="row_of.images.length > 0"></hooper-navigation>
					<hooper-pagination slot="hooper-addons" v-if="row_of.images.length > 0"></hooper-pagination>
					<slide v-for="(row_fi, indx) in row_of.images" :key="indx" :index="indx">
						<div
							class="hooperimage"
							@click="showZoom"
							:style="{
								backgroundImage: `url(${$config.server_url}/api/1.0/images/${row_fi.fi_id}/550/300?token=${$store.state.accesstoken})`
							}"
						>
							<div v-if="row_fi.fi_description !== 'undefined' && row_fi.fi_description" class="image-description">
								{{ row_fi.fi_description }}
							</div>
						</div>
					</slide>
					<slide v-if="row_of.images.length == 0">
						<div
							class="hooperimage"
							:style="{
								backgroundImage: `url(/img/principaute-de-monaco-2016.jpg)`
							}"
						>
						</div>
					</slide>
				</hooper> -->
			</div>

			<!-- <v-card v-if="row_of.images.length > 0">
				<v-card-text>
					<v-layout wrap align-center>
						<div v-for="(row_fi, indeximg) in row_of.images" :key="row_fi.fi_id" style="padding: 2px">
							<v-img
								:src="`${$config.server_url}/api/1.0/images/${row_fi.fi_id}/75/75?token=${$store.state.accesstoken}`"
								height="75px"
								width="75px"
								@click="sliderClick(indeximg)"
							></v-img>
						</div>
					</v-layout>
					<div v-if="row_of.of_urlvideo" style="text-align: center"><a :href="row_of.of_urlvideo" target="_blank">VOIR LA VIDÉO</a></div>
				</v-card-text>
			</v-card> -->

			<v-card class="mt-3">
				<!-- <v-card-title> -->
				<!-- <h1
            v-html="
              $options.filters.plural(
                row_of.presents_offer.length,
                '<span class=\'bis\'>présentation</span>',
                '<span class=\'bis\'>présentations</span>'
              )
            "
          >{{ row_of.presents_offer.length }}</h1> -->
				<!-- </v-card-title> -->
				<v-card-text>
					<div class="d-flex">
						<v-btn class="ml-auto" color="secondary2" small @click="openComment">
							Nouveau commentaire&nbsp;&nbsp;
							<v-icon small>mdi-pencil</v-icon>
						</v-btn>
					</div>
					<div v-for="(offertab, offertabindex) in offertabs" :key="'offertabs_' + offertabindex">
						<div class="mt-3">
							<card-table-actions2
								:reload="$store.state.reload_actions"
								:label-singular="offertab[0]"
								:label-plural="offertab[1]"
								:always-show-title="false"
								actions-maintype="offer"
								:actions-type="offertab[2]"
								:actions-nb-max="0"
								:action-id="row_of.of_id"
								:header="false"
								:offerShortName="true"
								:colcontact="offertab[2] != 'updateoffer'"
								:colchips="false"
								:colagent="true"
								:coldone="false"
								:coloffers="false"
								@total="offertab[3] = $event"
							></card-table-actions2>
						</div>
					</div>
				</v-card-text>
			</v-card>

			<!-- <v-card class="mt-3">
        <v-card-title>
          <h1
            v-html="
              $options.filters.plural(
                row_of.visits_offer.length,
                '<span class=\'bis\'>visite</span>',
                '<span class=\'bis\'>visites</span>'
              )
            "
          ></h1>
        </v-card-title>
        <v-card-text>
          
          
        </v-card-text>
      </v-card>-->

			<!-- <v-card class="mt-3">
        <v-card-title>
          <h1
            v-html="
              $options.filters.plural(
                row_of.reports_offer.length,
                '<span class=\'bis\'>compte rendu</span>',
                '<span class=\'bis\'>comptes rendus</span>'
              )
            "
          ></h1>
        </v-card-title>
        <v-card-text>
          <card-table-actions2
            label-singular="présentation"
            label-plural="présentations"
            actions-maintype="offer"
            actions-type="present"
            :actions-nb-max="5"
            :ofid="row_of.of_id"
            :header="false"
            :colcontact="true"
            :colagent="true"
            :coldone="true"
          ></card-table-actions2>
          
        </v-card-text>
      </v-card>-->

			<v-card class="mt-3">
				<div style="position: absolute; right: 15px; top: 15px">
					<a @click="setcheck">
						Tout
						<span v-if="allChecked">décocher</span>
						<span v-if="!allChecked">cocher</span>
					</a>
				</div>
				<v-card-title>
					<h1
						v-html="
							$options.filters.plural(
								row_of.correlations.length,
								'<span class=\'bis\'>corrélation</span>',
								'<span class=\'bis\'>corrélations</span>'
							)
						"
					></h1>
				</v-card-title>
				<v-card-text>
					<div
						style="display: inline-block; white-space: nowrap; margin-right: 0px; width: 50%"
						v-for="row_cr in row_of.correlations"
						:key="row_cr.cr_id"
					>
						<m-form-checkbox
							:label="$options.filters.formatContactName(row_cr.co_id)"
							v-model="contactsSelected[row_cr.cr_id]"
						></m-form-checkbox>
						<a @click="clickIconCorrelation(row_cr)"> <icon class="icon-menu" name="paper-plane" small></icon></a>&nbsp;
						<a @click="clickIconCorrelationEdit(row_cr)"> <icon class="icon-menu" name="edit" small></icon></a>
					</div>

					<!-- <span
            v-for="row_cr in row_of.correlations"
            :key="row_cr.cr_id"
            style="display:inline-block; white-space: nowrap;"
          >
            <div
              style="display:inline-block; cursor:pointer;"
              class="mr-2"
              @click="$router.push(`/contacts/${row_cr.co_id.co_id}/details`)"
            >
              {{ row_cr.co_id | formatContactName }}
            </div>
            <div style="display:inline-block;" class="formlittle">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-switch
                    v-on="on"
                    v-model="row_cr.presented"
                    :name="$Utils.randomstring('presented')"
                    color="#53bd7b"
                    :inset="true"
                    :light="true"
                    dense
                    @change="switchCorrelation(row_cr.co_id.co_id)"
                  ></v-switch>
                </template>
                <span
                  >Indiqué comme présenté pour
                  {{ row_cr.co_id | formatContactName }}</span
                >
              </v-tooltip>
            </div>
          </span>-->
					<div class="mt-3">
						<icon class="icon-menu" name="eye-slash" small></icon>
						<a @click="actionHide" style="display: inline-block; margin-right: 15px; margin-left: 5px">Pas intéressé</a>
						<icon class="icon-menu" name="eye" small></icon>
						<a @click="actionPresent" style="display: inline-block; margin-right: 15px; margin-left: 5px">Présenté</a>
						<!-- <icon class="icon-menu" name="paper-plane" small></icon>
            <a
              @click="actionEmail"
              style="display:inline-block; margin-right:15px; margin-left:5px;"
              >Envoyer Emails</a
            > -->
					</div>
				</v-card-text>
			</v-card>

			<v-card class="mt-3" v-if="row_of.of_id">
				<div style="position: absolute; right: 15px; top: 15px">
					<a @click="showMessage = !showMessage">
						<span v-if="showMessage">Masquer</span>
						<span v-if="!showMessage">Voir</span>
					</a>
				</div>
				<v-card-title>
					<h1>
						<span>{{ nbmessages }}</span> Fil d'infos
					</h1>
				</v-card-title>
				<v-card-text v-show="showMessage">
					<messages-card
						:limit="50"
						:autoload="true"
						:users="usersMessages"
						:readonly="true"
						:of_id="row_of.of_id"
						:filterfrom="
							$moment()
								.add(-2, 'months')
								.format('YYYY-MM-DD')
						"
						:filterto="
							$moment()
								.add(1, 'day')
								.format('YYYY-MM-DD')
						"
						@nbmessages="nbMessages"
					></messages-card>
				</v-card-text>
			</v-card>

			<v-card class="mt-3" v-if="$store.state.preferences.shared.useDiagnostic">
				<v-card-title>
					<h1>
						Diagnostics
						<span class="bis">immobiliers</span>
					</h1>
				</v-card-title>
				<v-card-text>
					<offer-frame-details
						:items_frame="$store.state.items_frame_diagnostics"
						:row_of="row_of"
						:lang="langSelected"
						:keyload="keyloadFrameDetail"
					/>
				</v-card-text>
			</v-card>

			<v-card class="mt-3" style="background-color: transparent">
				<v-card-text>
					<div class="caption">ID : {{ row_of.of_id }}</div>
					<div class="caption">
						Créé le : {{ row_of.createdAt | formatDateTime }} par
						{{ row_of.co_id_created | formatContactName }}
					</div>
					<div class="caption">
						Modifié le : {{ row_of.updatedAt | formatDateTime }} par
						{{ row_of.co_id_updated | formatContactName }}
					</div>
					<div @click="copyOffer">
						<button class="btn btn-primary small">Dupliquer le bien</button>
					</div>
					<!-- <v-alert v-if="offerCopied.of_id" dense text type="success">
						Le bien a été dupliqué :
						<span @click="$router.push('/offers/' + offerCopied.of_id + '/details')" class="withpointer">Voir le bien</span>
					</v-alert> -->
					<!-- <div v-if="offerCopied.of_id">Le bien a été dupliqué : <span @click="goToofferCopied" class="pointer">Voir le bien</span></div> -->
				</v-card-text>
			</v-card>
		</v-flex>
		<v-flex :class="{ md7: !short, md12: short }">
			<div>
				<v-layout ma-0 pa-0 formlittle>
					<v-btn class="secondary2" @click="$router.push('/offers')" v-if="!short">Retour liste</v-btn>
					<v-spacer></v-spacer>
					<v-switch
						:disabled="!$store.state.preferences.rights.offer_follows"
						:name="$Utils.randomstring('offersuivi')"
						v-model="offersuivi"
						color="#53bd7b"
						:inset="true"
						:light="true"
						:label="offersuiviLabel"
						class="my-0"
						@change="onChangeSuivi"
					></v-switch>
					<v-spacer></v-spacer>
					<div class="d-flex">
						<m-form-select
							class="mr-2"
							:name="$Utils.randomstring('of_status')"
							:items="$store.state.items_languages"
							v-model="langSelected"
							:label-width="6"
						></m-form-select>
						<div @click="showMenuPrint" class="align-self-center">
							<icon scale="1.5" class="icon-print mr-4 withpointer" name="print"></icon>
						</div>

						<!-- 						<div v-if="menuPrint" class="menuOffersPrint">
							<v-list>
								<v-list-group sub-group>
									<template v-slot:activator>
										<v-list-item-title>Fiche simple</v-list-item-title>
									</template>
									<v-list-item v-for="(langue, i) in $store.state.items_languages" :key="i" class="print-langue">
										<v-list-item-title
											v-text="'Imprimer en ' + langue.text"
											@click="toPrint('simple', langue.value)"
										></v-list-item-title>
									</v-list-item>
								</v-list-group>

								<v-list-group sub-group>
									<template v-slot:activator>
										<v-list-item-title>Fiche complète</v-list-item-title>
									</template>
									<v-list-item v-for="(langue, i) in $store.state.items_languages" :key="i" class="print-langue">
										<v-list-item-title
											v-text="'Imprimer en ' + langue.text"
											@click="toPrint('complete', langue.value)"
										></v-list-item-title>
									</v-list-item>
								</v-list-group>
								<v-list-item class="withpointer" @click="toPrintAgent">
									<v-list-item-title class="pl-12">Fiche négociateur</v-list-item-title>
								</v-list-item>
								<v-list-item class="withpointer" @click="toPrintOwner">
									<v-list-item-title class="pl-12">Fiche propriétaire</v-list-item-title>
								</v-list-item>
							</v-list>
						</div> -->

						<v-btn color="info" @click="editOffer()" :disabled="!isEditable" v-if="row_of.of_immotools * 1 === 0">Modifier</v-btn>
						<v-btn color="error2" @click="copyOffer()" v-else>Dupliquer</v-btn>
					</div>
				</v-layout>
			</div>
			<div v-if="row_of.of_immotools * 1 > 0" style="text-align:right;">
				<h3 style="color:#ff4b56;">Cette offre provient de Immotoolbox, il n'est pas modifiable</h3>
			</div>
			<v-card class="mt-3">
				<v-card-title>
					<h1>{{ "Description du bien" | trad(langSelected) }}</h1>
				</v-card-title>
				<v-card-text>
					<h2 v-html="$options.filters.formatOfferName(row_of, true, false, langSelected)"></h2>
					<p v-if="row_of.of_before1947" style="color: red">Droit de préemption de l'état</p>
					<p class="text-justify" v-if="row_of.bu_id" v-html="$options.filters.nl2br(row_of.bu_id['bu_description_' + langSelected])"></p>
					<p class="text-justify" v-html="$options.filters.nl2br(row_of['of_text_' + langSelected])"></p>
					<!-- <p v-html="$options.filters.nl2br(row_of.of_summary_fr)"></p> -->
					<!-- <p v-html="$options.filters.nl2br(row_of.of_ref)"></p> -->
				</v-card-text>
			</v-card>
			<v-layout>
				<v-flex md6 pr-0 pt-0>
					<v-card class="mt-3">
						<v-card-title>
							<h1>
								{{ "Les détails" | trad(langSelected) }}
							</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.items_frame_details"
								:row_of="row_of"
								:lang="langSelected"
								:keyload="keyloadFrameDetail"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3">
						<v-card-title>
							<h1>
								{{ "Les services" | trad(langSelected) }}
							</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.preferences.items.items_of_services"
								:row_of="row_of.of_services"
								:lang="langSelected"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3">
						<v-card-title>
							<h1>{{ "Le propriétaire et le mandat" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.items_frame_mandat"
								:row_of="row_of"
								:lang="langSelected"
								:keyload="keyloadFrameDetail"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3" v-if="row_of.bu_id">
						<v-card-title>
							<h1>{{ "Immeuble, addresse et quartier" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.items_frame_building"
								:row_of="row_of.bu_id"
								:lang="langSelected"
								:keyload="keyloadFrameDetail"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3" v-if="row_of.bu_id && row_of.bu_id.buildingsreports && row_of.bu_id.buildingsreports.length">
						<v-card-title>
							<h1>{{ "Assemblée générale" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<table style="width: 100%">
								<tr v-for="(row_bur, indexBur) in row_of.bu_id.buildingsreports" :key="row_bur.bur_id">
									<!-- <td style="border-bottom: 1px solid gray; padding: 5px 10px;">
                    {{ row_bur.bur_date | formatDate }}
                  </td> -->
									<td style="border-bottom: 1px solid gray; padding: 5px 10px">
										<div>
											<b>Le {{ row_bur.bur_date | formatDate }}</b>
										</div>
										<div
											style="cursor: pointer"
											v-if="!moreBurText['num' + indexBur]"
											v-html="$options.filters.limittext(row_bur.bur_text, 50, 'Voir plus...')"
											@click="clickMoreBurText(indexBur)"
										></div>
										<div
											v-if="moreBurText['num' + indexBur]"
											v-html="$options.filters.nl2br(row_bur.bur_text)"
											@click="clickMoreBurText(indexBur)"
										></div>
									</td>
								</tr>
							</table>
						</v-card-text>
					</v-card>
					<v-card class="mt-3" v-if="row_of.bu_id">
						<v-card-title>
							<h1>
								<span class="bis"> {{ "Fichiers" | trad(langSelected) }}</span>
							</h1>
						</v-card-title>
						<v-card-text v-if="row_of.files.length">
							<table class="tablestriped dense">
								<tbody>
									<tr v-for="file in row_of.files" :key="file.fi_id">
										<td style="width: 180px">
											<div style="width: 180px">
												{{ file.fi_description ? file.fi_description : file.fi_file }}
											</div>
										</td>
										<td>
											<v-btn @click="downloadFile(file)" color="secondary2" small>Télécharger</v-btn>
										</td>
										<td>
											<img
												:src="
													$config.server_url +
														'/api/1.0/images/' +
														file.fi_id +
														`/35/50?token=${$store.state.accesstoken}&d=${file.updatedAt}`
												"
												alt="apperçu du fichier"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex md6 pt-0>
					<v-card class="mt-3">
						<v-card-title>
							<h1>{{ "Statut et visibilité" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.items_frame_visibility"
								:row_of="row_of"
								:lang="langSelected"
								:keyload="keyloadFrameDetail"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3">
						<v-card-title>
							<h1>
								{{ "Diffusions" | trad(langSelected) }}
							</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.preferences.items.items_of_diffusions"
								:row_of="row_of.of_diffusions"
								:lang="langSelected"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3">
						<v-card-title>
							<h1>{{ "Informations commerciales... et consignes de visites" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.items_frame_commercial"
								:row_of="row_of"
								:lang="langSelected"
								:keyload="keyloadFrameDetail"
							/>
						</v-card-text>
					</v-card>

					<v-card class="mt-3" v-if="row_of.of_sell">
						<v-card-title>
							<h1>{{ "A la vente" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.items_frame_tosell"
								:row_of="row_of"
								:lang="langSelected"
								:keyload="keyloadFrameDetail"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3" v-if="row_of.of_rent">
						<v-card-title>
							<h1>{{ "A la location" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<offer-frame-details
								:items_frame="$store.state.items_frame_torent"
								:row_of="row_of"
								:lang="langSelected"
								:keyload="keyloadFrameDetail"
							/>
						</v-card-text>
					</v-card>
					<v-card class="mt-3">
						<v-card-title>
							<h1>{{ "Avis des clients" | trad(langSelected) }}</h1>
						</v-card-title>
						<v-card-text>
							<!-- <canvas id="myChart" width="300" height="300"></canvas> -->
							<offers-report-chart :row_of="row_of"></offers-report-chart>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
		</v-flex>
		<offer-win-edit
			ref="OfferWinEdit"
			@offerWinEditSaved="offerWinEditSaved"
			@offerWinEditCanceled="offerWinEditCanceled"
			@offerWinEditDeleted="offerWinEditDeleted"
		/>
		<visit-win-edit ref="VisitWinEdit" @visitwineditSaved="visitwineditSaved" />
		<v-snackbar v-model="messageSuivi" top :timeout="5000">
			Ce bien à déjà
			{{ this.$store.state.preferences.shared.nbMaxSuiviOffer }} personnes qui la suivent, vous ne pouvez pas la suivre également
			<v-btn color="pink" text @click="messageSuivi = false">Fermé</v-btn>
		</v-snackbar>
		<offers-win-zoom :row_of="row_of" ref="offerswinzoom"></offers-win-zoom>
		<action-win-edit
			ref="actionwinedit"
			:offers-selected="offersSelected"
			:contact-selected="contactSelected"
			@actionWinEditSaved="actionWinEditSaved"
		></action-win-edit>
		<offers-print-dialog @print="toPrint" v-model="offersPrintDialog" :tosell="row_of.of_sell" :torent="row_of.of_rent"></offers-print-dialog>
	</v-layout>
</template>

<script>
import Vue from "vue";
import OfferFrameDetails from "../views/OfferFrameDetails";
Vue.component("offer-frame-details", OfferFrameDetails);

// import { Hooper, Slide, Navigation as HooperNavigation, Pagination as HooperPagination } from "hooper";
// import "hooper/dist/hooper.css";
import { VueFlux, FluxCaption, FluxControls, FluxIndex, FluxPagination, FluxPreloader } from "vue-flux";
import OffersWinZoom from "../views/OffersWinZoom";

export default {
	name: "offersdetails",
	components: {
		VueFlux,
		FluxCaption,
		FluxControls,
		FluxIndex,
		FluxPagination,
		FluxPreloader,
		// Hooper,
		// Slide,
		// HooperNavigation,
		// HooperPagination,
		"offers-win-zoom": OffersWinZoom
	},
	data() {
		return {
			offerCopied: null,
			showMessage: false,
			nbmessages: 0,
			langSelected: "fr",
			keyloadFrameDetail: 0,
			vfOptions: {
				autoplay: true,
				allowFullscreen: true,
				delay: 2000
			},
			carouselsize: "contain",
			vfImages: [],
			vfTransitions: ["fade"],
			vfCaptions: [],

			moreBurText: {},
			offersSelected: [],
			contactSelected: null,
			allChecked: false,
			contactsSelected: {},
			//ficheAgent: false,
			itemsPrintLangue: [],
			menuPrint: false,
			messageSuivi: false,
			offersuivi: false,
			followersoffers: [],
			// followscontacts: [],
			currentNumber: 0,
			timer: null,
			row_of: {
				presents_offer: [],
				visits_offer: [],
				reports_offer: [],
				images: [],
				correlations: []
			},
			offerwinedit: false,
			offertabs: [
				["Action téléphonique", "Actions téléphonique", "call", 0],
				["A faire", "A faire", "todo", 0],
				["Commentaire", "Commentaires", "comment", 0],
				["Signature", "Signatures", "signature", 0],
				["Email", "Emails", "email", 0],
				["Bien présenté", "Biens présentés", "present", 0],
				["Bien visité", "Biens visités", "visit", 0],
				["Compte-rendu", "Comptes-rendus", "report", 0],
				["Pas intéressé", "Pas intéressés", "notinterested", 0],
				["mise à jour", "mises à jour", "updateoffer", 0]
			],
			usersMessages: [],
			offersPrintDialog: false
		};
	},
	props: {
		value: Object,
		short: Boolean
	},
	async mounted() {
		// console.log('this.$store.state.items_frame_details', this.$store.state.items_frame_details);
		//this.ficheAgent = this.$store.getters.get_ficheAgent;
		// this.itemsPrintLangue = this.$store.state.items_languages;
		// let slug = this.$route.params.of_id;
		// let response = await this.$axios.get(
		//   // this.$config.server_url + "/api/1.0/offers/slug/" + slug
		//   this.$config.server_url + "/api/1.0/offers/" + this.$route.params.of_id
		// );
		// console.log("row_of", row_of);
		// let row_of = response.data.data;
		// let of_id = "";
		// row_of ? (of_id = row_of.of_id) : (of_id = "-1");
		let of_id = this.$route.params.of_id;
		if (this.value && this.value.of_id) of_id = this.value.of_id;

		await this.getOffer(of_id);
		if (this.$route.meta.edit) this.$refs.OfferWinEdit.open(of_id);

		this.loadSuivi(of_id);
	},
	watch: {
		reload_actions() {
			this.getOffer(this.row_of.of_id);
		},
		async value(val) {
			if (this.value && this.value.of_id) {
				let of_id = this.value.of_id;
				await this.getOffer(of_id);
				this.loadSuivi(of_id);
			}
		},
		$route(to, from) {
			if (to.meta.edit) this.$refs.OfferWinEdit.open();
			// react to route of_id changes...
			if (to.params.of_id !== from.params.of_id) this.getOffer(to.params.of_id);
		}
	},
	computed: {
		reload_actions() {
			return this.$store.state.reload_actions;
		},
		isEditable() {
			if (this.$store.state.preferences.rights.offer_edit_all) return true;
			if (
				this.$store.state.preferences.rights.offer_edit_mine &&
				this.row_of &&
				((this.row_of.co_id_commercial1 && this.row_of.co_id_commercial1.co_id == this.$store.state.user.co_id) ||
					(this.row_of.co_id_commercial2 && this.row_of.co_id_commercial2.co_id == this.$store.state.user.co_id))
			)
				return true;
			return false;
		},
		offersuiviLabel() {
			if (this.offersuivi) return "Bien suivi";
			else return "Bien non-suivi";
		}
	},
	destroyed() {
		if (this.sliderTimer) window.clearInterval(this.sliderTimer);
		if (this.sliderTimerRestart) window.clearTimeout(this.sliderTimerRestart);
	},
	sockets: {
		offers_update(of_id) {
			if (of_id == this.row_of.of_id) {
				this.getOffer(of_id);
			}
		}
	},
	methods: {
		goUrl(url) {
			window.open(url, "_blank");
		},
		toggleFullScreen() {
			this.vfImages = [];
			for (let iImg = 0; iImg < this.row_of.images.length; iImg++) {
				const row_fi = this.row_of.images[iImg];
				this.vfImages.push(`${this.$config.server_url}/api/1.0/images/${row_fi.fi_id}/2000/1000/contain?d=${encodeURI(row_fi.updatedAt)}`);
				if (row_fi.fi_description == "undefined") row_fi.fi_description = "";
				this.vfCaptions.push(row_fi.fi_description);
			}
			if (this.row_of.images.length == 0) {
				this.vfImages.push("/img/principaute-de-monaco-2016.jpg");
			}
			this.$refs.slider.Display.toggleFullScreen();
		},
		async copyOffer() {
			if (window.confirm("Souhaitez-vous dupliquer ce bien ?")) {
				let response = await this.$axios.post(this.$config.server_url + "/api/1.0/offers/copy", { of_id: this.row_of.of_id });
				this.offerCopied = response.data.data;

				// console.log("this.offerCopied", this.offerCopied);
				this.goToofferCopied();

				/* setTimeout(() => {
					this.offerCopied = {};
				}, 1000); */
			}
		},
		goToofferCopied() {
			if (!this.offerCopied) return;
			this.$router.push("/offers/" + this.offerCopied.of_id + "/details");
		},
		nbMessages(nbmessages) {
			this.nbmessages = nbmessages;
		},
		clickMoreBurText(indexBur) {
			if (!this.moreBurText["num" + indexBur]) this.moreBurText["num" + indexBur] = false;
			this.moreBurText["num" + indexBur] = !this.moreBurText["num" + indexBur];
			this.$forceUpdate();
		},
		clickIconCorrelationEdit(row_cr) {
			this.$router.push("/contacts/" + row_cr.co_id.co_id + "/details");
		},
		clickIconCorrelation(row_cr) {
			// actionwinedit
			// console.log("row_cr", row_cr.co_id);
			this.contactSelected = row_cr.co_id;
			this.offersSelected = [this.row_of];
			this.$refs.actionwinedit.open(-1, "email");
		},
		openComment() {
			this.contactSelected = null;
			this.offersSelected = [this.row_of];
			this.$refs.actionwinedit.open(-1, "comment");
		},
		actionWinEditSaved(d) {
			// console.log("d", d);
			// this.getOffer(this.row_of.of_id);
		},
		// labelForCheckbox(row_cr) {
		//   let txt = this.$options.filters.formatContactName(row_cr.co_id);
		//   txt += '<icon class="icon-menu" name="paper-plane" small></icon>';
		//   return txt;
		// },
		showZoom() {
			this.$refs.offerswinzoom.open();
		},
		async actionHide() {
			for (let cs in this.contactsSelected) {
				if (!this.contactsSelected[cs]) return;
				let row_cr = this.$_.find(this.row_of.correlations, { cr_id: cs * 1 });
				await this.createAction(row_cr.co_id.co_id, "notinterested", "Non intéressé via une correlation");
			}
			await this.getOffer(this.row_of.of_id);
		},
		async actionPresent() {
			for (let cs in this.contactsSelected) {
				if (!this.contactsSelected[cs]) return;
				let row_cr = this.$_.find(this.row_of.correlations, { cr_id: cs * 1 });
				await this.createAction(row_cr.co_id.co_id, "present", "Présenté via une correlation");
			}
			await this.getOffer(this.row_of.of_id);
		},
		async actionEmail() {
			for (let cs in this.contactsSelected) {
				if (!this.contactsSelected[cs]) return;
				let row_cr = this.$_.find(this.row_of.correlations, { cr_id: cs * 1 });
				await this.createAction(row_cr.co_id.co_id, "email", "Email via une correlation");
			}

			await this.getOffer(this.row_of.of_id);
		},
		setcheck() {
			let contactsSelected = {};
			this.allChecked = !this.allChecked;
			for (let iCorr = 0; iCorr < this.row_of.correlations.length; iCorr++) {
				const row_cr = this.row_of.correlations[iCorr];
				contactsSelected[row_cr.cr_id] = this.allChecked;
			}
			this.contactsSelected = contactsSelected;
			// console.log("this.contactsSelected", this.contactsSelected);
		},
		downloadFile(file) {
			window.open(this.$config.server_url + "/api/1.0/files/" + file.fi_id + "/download?token=" + this.$store.state.accesstoken, "_blank");
		},
		async createAction(co_id, ac_type, ac_comment) {
			let ac_sendemail = false;
			if (ac_type == "email") ac_sendemail = true;
			await this.$axios.post(this.$config.server_url + "/api/1.0/actions", {
				ac_type,
				co_id_contact: co_id,
				co_id_user: this.$store.getters.get_user.co_id,
				co_id_user_todo: this.$store.getters.get_user.co_id,
				ac_date: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
				of_id: this.row_of.of_id,
				ac_offers: [this.row_of.of_id],
				ac_done: true,
				ac_sendemail: ac_sendemail,
				ac_comment
			});
			this.$store.commit("set_reload_actions");
		},
		toPrint(options) {
			this.offersPrintDialog = false;
			let selected = [this.row_of.of_id];
			if (options.typeFiche === "proprietaire") {
				this.toPrintOwner();
				return;
			}
			if (options.typeFiche === "negociateur") {
				this.toPrintAgent();
				return;
			}
			// console.log("this.$config.server_url", this.$config.server_url);
			window.open(
				this.$config.server_url +
					"/api/2.0/offers/prints?selected=" +
					JSON.stringify(selected) +
					"&type=" +
					options.typeFiche +
					"&duplicate1page=" +
					options.duplicate1page +
					"&lang=" +
					options.langue +
					"&wlogo=" +
					options.withLogo +
					"&wprice=" +
					options.withPrice +
					"&quality=" +
					options.quality +
					"&d=" +
					new Date().getTime(),
				"_blank"
			);
		},
		toPrintOwner() {
			this.menuPrint = false;
			window.open("/#/offers/" + this.row_of.of_id + "/printowner?d=3", "_blank");
		},
		toPrintAgent() {
			this.menuPrint = false;
			window.open("/#/offers/" + this.row_of.of_id + "/printagent?lang=" + this.langSelected + "&d=3", "_blank");
			//this.$store.commit("set_ficheAgent", true);
			//this.$router.push("/" + this.row_of.of_id + "/ficheagent");
			/* window.open(
        this.$config.server_url +
          "/api/1.0/offers/" +
          this.row_of.of_id +
          "/printagent",
        "_blank"
      ); */
		},
		showMenuPrint() {
			this.offersPrintDialog = true;
			//this.menuPrint = !this.menuPrint;
		},
		async onChangeSuivi(v) {
			if (
				parseInt(this.$store.state.preferences.shared.nbMaxSuiviOffer) === 0 ||
				this.followersoffers.length < parseInt(this.$store.state.preferences.shared.nbMaxSuiviOffer)
			) {
				// console.log("v", v);
				await this.$axios.post(
					this.$config.server_url + "/api/1.0/followers/offer/" + this.row_of.of_id + "/user/" + this.$store.getters.get_user.co_id,
					{ params: { suivi: v } }
				);
			} else {
				this.messageSuivi = true;
			}

			this.loadSuivi(this.row_of.of_id);
			this.$store.dispatch("load_nbclientsuivi");
		},
		async loadSuivi(of_id) {
			if (!of_id) return;
			// let user = this.$store.getters.get_user;
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/followers/offer/" + of_id);
			let ok = false;
			// let historySuiviOffer = [];
			for (let iAgents = 0; iAgents < response.data.followersoffers.length; iAgents++) {
				const row_co_agent = response.data.followersoffers[iAgents];
				if (row_co_agent.co_id_user && row_co_agent.co_id_user.co_id === this.$store.state.user.co_id) ok = true;
				// historySuiviOffer.push(row_co_agent);
			}
			// if (historySuiviOffer.length) {
			//   ok = historySuiviOffer[historySuiviOffer.length - 1].fo_state;
			// }
			this.offersuivi = ok;

			this.followersoffers = response.data.followersoffers;
			// this.followscontacts = response.data.followscontacts;
		},
		visitwineditSaved() {
			this.getOffer(this.row_of.of_id);
		},
		// sliderStart() {
		// 	this.sliderTimer = window.setInterval(() => {
		// 		this.$refs.myhooper.slideNext();
		// 	}, 2000);
		// },
		// sliderStop() {
		// 	window.clearInterval(this.sliderTimer);
		// },
		// sliderClick(indexImg) {
		// 	this.$refs.myhooper.slideTo(indexImg);
		// 	window.clearInterval(this.sliderTimer);
		// 	if (this.sliderTimerRestart) window.clearTimeout(this.sliderTimerRestart);
		// 	this.sliderTimerRestart = window.setTimeout(() => {
		// 		this.sliderStart();
		// 	}, 5000);
		// },
		// reloadOffer() {
		//   this.getOffer(this.row_of.of_id);
		// },
		async getOffer(of_id) {
			// this.sliderStop();
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers/" + of_id);
			this.contactsSelected = {};
			// if (response.data.data.images.length == 0) {
			//   response.data.data.images.push("default");
			// }
			this.row_of = response.data.data;
			if (this.row_of.of_immotools) this.$refs.parentDiv.style.backgroundColor = "#c8e5fb";
			else if (this.row_of.of_confidential) this.$refs.parentDiv.style.backgroundColor = "#cdebd1";
			else this.$refs.parentDiv.style.backgroundColor = "transparent";

			// console.log("this.row_of.images", this.row_of.images);
			this.vfImages = [];
			this.vfCaptions = [];
			let vfImages = [],
				vfCaptions = [];
			// console.log("this.row_of.images.length", this.row_of.images.length);
			for (let iImg = 0; iImg < this.row_of.images.length; iImg++) {
				const row_fi = this.row_of.images[iImg];
				vfImages.push(`${this.$config.server_url}/api/1.0/images/${row_fi.fi_id}/550/400/contain?d=${encodeURI(row_fi.updatedAt)}`);
				if (row_fi.fi_description == "undefined") row_fi.fi_description = "";
				vfCaptions.push(row_fi.fi_description);
			}
			if (this.row_of.images.length == 0) {
				vfImages.push("/img/principaute-de-monaco-2016.jpg");
				vfCaptions.push("");
			}
			window.setTimeout(() => {
				this.vfImages = vfImages;
				this.vfCaptions = vfCaptions;
				// console.log("this.vfImages.length", this.vfImages.length, this.vfCaptions.length);
			}, 200);

			if (this.offerCopied) {
				this.$refs.actionwinedit.open(-1, "present");
			}
			this.offerCopied = null;

			// if (!this.short && this.row_of && this.row_of.images && this.row_of.images.length > 1) this.sliderStart();
			// console.log("this.row_of", this.row_of);
			this.$emit("offersDetailsLoaded", this.row_of);
			// console.log("this.row_of", this.row_of);
		},
		editOffer() {
			this.$router.push("/offers/" + this.row_of.of_id + "/edit");
			// this.$refs.OfferWinEdit.open();
			// this.dialog = true;
		},
		offerWinEditDeleted(of_id) {
			this.$router.push("/offers");
		},
		async offerWinEditSaved(of_id) {
			// console.log("of_id", of_id);
			//of_slug,
			//let of_id = this.$route.params.of_id;
			// if (!this.row_of.of_id) {
			this.$router.push("/offers/" + of_id + "/details");
			await this.getOffer(of_id);
			// } else {
			// 	this.$router.push("/offers/" + this.row_of.of_id + "/details"); //of_slug_fr
			// 	await this.getOffer(this.row_of.of_id);
			// }
			this.$store.commit("set_reload_actions");
		},
		offerWinEditCanceled() {
			// let of_id = this.$route.params.of_id;
			if (!this.row_of.of_id) {
				this.$router.push("/offers");
			} else {
				// this.$router.push("/offers/" + this.row_of.of_slug_fr + "/details");
				this.$router.push("/offers/" + this.row_of.of_id + "/details");
				this.getOffer(this.row_of.of_id);
			}
		}
	}
};
</script>

<style lang="scss">
.hooperimage {
	background-size: contain;
	height: 300px;
	background-position: center center;
	background-repeat: no-repeat;
}
// .hooper-pagination {
//   // right: 15%;
// }
.image-description {
	background-color: #0000008f;
	color: #fff;
	padding-left: 5px;
	position: absolute;
	bottom: 0;
	width: 100%;
}
.v-application ul.hooper-track {
	padding-left: 0;
}
.menuOffersPrint {
	position: absolute;
	z-index: 10;
	top: 68px;
	right: 27px;
	border-radius: 5px;
	border: solid 1px #cacaca;
	box-shadow: #cacaca 2px 2px 6px;
	width: 300px;
}

.hooper-pagination {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 4px;
	ol {
		padding: 0;
	}
}
.carousel-contain .flux-image {
	background-position: center !important;
	background-size: contain !important;
	background-color: #2d2d2d;
}
// #fiche-agent {
//   background-color: gray;
//   font-family: "Helvetica Neue", "Open Sans", "Montserrat", sans-serif;
//   h1 {
//     color: red;
//   }
// }
.v-list-group--sub-group .v-list-group__items .v-list-item.print-langue {
	padding-left: 70px;
}
</style>
