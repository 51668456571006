import Vue from "vue";

import MForm from "../components/MForm";
Vue.component("m-form", MForm);
import MFormBase from "../components/MFormBase";
Vue.component("m-form-base", MFormBase);
import MFormCombobox from "../components/MFormCombobox";
Vue.component("m-form-combobox", MFormCombobox);
import MFormRadioGroup from "../components/MFormRadioGroup";
Vue.component("m-form-radio-group", MFormRadioGroup);
import MFormRadio from "../components/MFormRadio";
Vue.component("m-form-radio", MFormRadio);
import MFormCheckbox from "../components/MFormCheckbox";
Vue.component("m-form-checkbox", MFormCheckbox);
import MFormDate from "../components/MFormDate";
Vue.component("m-form-date", MFormDate);
import MHour from "../components/MHour";
Vue.component("m-hour", MHour);
import MFormDateHour from "../components/MFormDateHour";
Vue.component("m-form-date-hour", MFormDateHour);
import MConfirmDialog from "../components/MConfirmDialog";
Vue.component("m-confirm-dialog", MConfirmDialog);
import MMessageDialog from "../components/MMessageDialog";
Vue.component("m-message-dialog", MMessageDialog);
// import MComboboxOffer from "../components/MComboboxOffer";
// Vue.component("m-combobox-offer", MComboboxOffer);
// import MComboboxContact from "../components/MComboboxContact";
// Vue.component("m-combobox-contact", MComboboxContact);
// import MComboboxBuilding from "../components/MComboboxBuilding";
// Vue.component("m-combobox-building", MComboboxBuilding);
import MFormText from "../components/MFormText";
Vue.component("m-form-text", MFormText);
import MFormFiles from "../components/MFormFiles";
Vue.component("m-form-file", MFormFiles);
import MFormImages from "../components/MFormImages";
Vue.component("m-form-images", MFormImages);
import MFormSelect from "../components/MFormSelect";
Vue.component("m-form-select", MFormSelect);
import MList from "../components/MList";
Vue.component("m-list", MList);

import ResearchView from "../views/ResearchView";
Vue.component("research-view", ResearchView);
import OfferWinEdit from "../views/OfferWinEdit";
Vue.component("offer-win-edit", OfferWinEdit);
import OffersDetails from "../views/OffersDetails.vue";
Vue.component("offers-details", OffersDetails);
import CalendarWinEdit from "../views/CalendarWinEdit";
Vue.component("calendar-win-edit", CalendarWinEdit);
import OffersVisitWinEdit from "../views/OffersVisitWinEdit";
Vue.component("visit-win-edit", OffersVisitWinEdit);
import ContactDetails from "../views/ContactDetails";
Vue.component("contact-details", ContactDetails);
import ContactWinEdit from "../views/ContactWinEdit";
Vue.component("contact-win-edit", ContactWinEdit);
import CalendarDetails from "../views/CalendarDetails";
Vue.component("calendar-details", CalendarDetails);
import ActionWinEdit from "../views/ActionWinEdit";
Vue.component("action-win-edit", ActionWinEdit);
import Messages from "../views/Messages";
Vue.component("messages", Messages);

import CardFiles from "../views/CardFiles";
Vue.component("card-files", CardFiles);
import CardTableActions from "../views/CardTableActions";
Vue.component("card-table-actions", CardTableActions);
import CardTableActions2 from "../views/CardTableActions2";
Vue.component("card-table-actions2", CardTableActions2);
import MessagesCard from "../views/MessagesCard";
Vue.component("messages-card", MessagesCard);

import OffersReportChart from "../views/OffersReportChart";
Vue.component("offers-report-chart", OffersReportChart);
import OffersPrintDialog from "../views/OffersPrintDialog";
Vue.component("offers-print-dialog", OffersPrintDialog);
