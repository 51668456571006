<template>
	<div>
		<div v-if="$store.state.preferences.rights.message_create && !readonly">
			<m-form-text
				type="text"
				ref="inputTitle"
				:name="$Utils.randomstring()"
				v-model="title"
				:rules="[$Validation.mandatory]"
				label="Objet"
				label-position="top"
			></m-form-text>
			<m-form-text
				type="textarea"
				ref="inputMessage"
				:name="$Utils.randomstring()"
				v-model="message"
				:rules="rulesInputMessage"
				label="Message"
				label-position="top"
			></m-form-text>
			<div class="combobox-message">
				<m-form-combobox
					v-model="me_contact"
					v-if="showComboboxContact"
					label="Contact"
					label-position="top"
					prepend-icon="mdi-account"
					class="combobox-message-contact"
					:store-url="$config.server_url + '/api/1.0/contacts'"
					:store-params="{ gr_id: 'contacts' }"
					item-value="co_id"
					:item-text="$options.filters.formatContactName"
				></m-form-combobox>

				<m-form-combobox
					v-model="me_offer"
					v-if="showComboboxOffer"
					class="combobox-message-offer"
					label="Bien"
					label-position="top"
					:name="$Utils.randomstring('of_message')"
					:store-url="$config.server_url + '/api/1.0/offers'"
					item-value="of_id"
					:item-text="$options.filters.formatOfferName"
				></m-form-combobox>
			</div>
			<div class="d-flex flex-row container-btn-input-message mt-2">
				<!-- <div>
          <v-icon
            class="home-icon-message home-icon-offer"
            @click="onclickIconofferMessage"
            >mdi-home</v-icon
          >
          <v-icon
            class="home-icon-message home-icon-contact"
            @click="onclickIconContactMessage"
            >mdi-account</v-icon
          >
        </div> -->
				<v-btn id="btn-envoyer-message" color="#757575" small depressed @click="sendMessage()">{{
					messageToEdit.me_id ? "Modifer" : "Envoyer"
				}}</v-btn>
			</div>
		</div>

		<div v-for="row_me in messages" :key="row_me.me_id">
			<div
				:class="{
					messageContainer: true,
					left: row_me.me_from.co_id == $store.state.user.co_id,
					right: row_me.me_from.co_id != $store.state.user.co_id
				}"
			>
				<div class="message-who">
					{{ row_me.me_from | formatmessagewho($store.state.user) }}
					{{ row_me.me_date | formatmessagedate }}
				</div>
				<div class="clearfix"></div>
				<div class="messageIcon">
					<v-img
						:src="
							row_me.me_from.co_id
								? $config.server_url +
								  '/api/1.0/avatar/' +
								  row_me.me_from.co_id +
								  '/180/180?d=' +
								  row_me.me_from.updatedAt +
								  '&token=' +
								  $store.state.accesstoken
								: '/img/default-profile.jpg'
						"
						height="35px"
						width="35px"
						class="rounded-image"
					></v-img>
				</div>
				<div class="messageContent">
					<div class="messageArrow"></div>
					<div
						class="messageDelete"
						v-if="row_me.me_from.co_id == $store.state.user.co_id && !readonly"
						@click="
							me_id_to_delete = row_me.me_id;
							confirmDeleteMessageDialog = true;
						"
					>
						<v-icon>mdi-delete</v-icon>
					</div>
					<div
						class="messageDelete"
						v-if="(row_me.me_from.co_id == $store.state.user.co_id || $store.state.preferences.rights.message_edit_all) && !readonly"
						@click="editMessage(row_me)"
					>
						<v-icon>mdi-pencil</v-icon>
					</div>
					<div class="message-title">{{ row_me.me_title }}</div>
					<div v-html="$options.filters.nl2br(row_me.me_text)"></div>
					<div v-if="row_me.co_id" class="lien-message">
						Contact :
						<a @click="$router.push('/contacts/' + row_me.co_id.co_id + '/details')">{{
							$options.filters.formatContactName(row_me.co_id)
						}}</a>
					</div>
					<div v-if="row_me.of_id" class="lien-message">
						Bien :
						<a @click="$router.push('/offers/' + row_me.of_id.of_id + '/details')">{{
							$options.filters.formatOfferName(row_me.of_id)
						}}</a>
					</div>
				</div>
				<div class="clearfix"></div>
			</div>
			<div class="clearfix"></div>
		</div>
		<div class="clearfix"></div>
		<!-- <div style="text-align: right">
			<a @click="showAll = !showAll">Voir tous les messages</a>
		</div> -->
		<m-confirm-dialog
			v-model="confirmDeleteMessageDialog"
			text="Souhaitez-vous effacer ce message ?"
			title="Confirmation"
			@canceled="confirmDeleteMessageDialog = false"
			@confirmed="deleteMessage()"
		></m-confirm-dialog>
	</div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
export default {
	name: "messagescard",
	props: {
		limit: Number,
		users: Array,
		autoload: Boolean,
		filterfrom: null,
		filterto: null,
		readonly: Boolean,
		of_id: String
	},
	data() {
		return {
			showAll: false,
			showComboboxContact: true,
			showComboboxOffer: true,
			me_contact: {},
			me_offer: {},
			confirmDeleteMessageDialog: false,
			messages: [],
			message: "",
			title: "",
			messageToEdit: {},
			rulesInputMessage: [
				function(v) {
					if (v.length > 250) return "250 caractères maximum";
					return true;
				}
			]
		};
	},
	watch: {
		filterfrom(val) {
			this.loadMessages();
		},
		filterto(val) {
			this.loadMessages();
		},
		showAll(val) {
			this.loadMessages();
		},
		users(val) {
			this.loadMessages();
		}
	},
	sockets: {
		messages_created(data) {
			this.loadMessages();
		}
	},
	mounted() {
		if (this.autoload) this.loadMessages();
	},
	filters: {
		formatmessagedate: date => {
			let until = moment(date).format("DD/MM/YY");
			return until;
		},
		formatmessagewho(row_co, user) {
			if (row_co.co_id == user.co_id) return "Moi";
			return Vue.filter("formatContactName")(row_co);
		}
	},
	methods: {
		// onclickIconContactMessage() {
		//   this.showComboboxContact = !this.showComboboxContact;
		// },
		// onclickIconofferMessage() {
		//   this.showComboboxOffer = !this.showComboboxOffer;
		// },
		async loadMessages() {
			// console.log("je passe");
			// let users = [];
			// this.$_.each(this.users, (val, key) => {
			//   if (val) users.push(key);
			// });
			let params = {
				sort: "me_date DESC",
				// limit: this.limit,
				users: this.users
			};
			if (this.of_id) params.of_id = this.of_id;
			// if (this.showAll) delete params.limit;
			if (this.filterfrom) params.filterfrom = this.filterfrom;
			if (this.filterto) params.filterto = this.filterto;
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/messages", {
				params
			});
			this.messages = response.data.data;
			this.$emit("nbmessages", this.messages.length);
		},
		editMessage(row_me) {
			this.messageToEdit = row_me;
			// console.log("row_me", row_me);
			this.title = row_me.me_title;
			this.message = row_me.me_text;
			this.me_contact = row_me.co_id;
			this.me_offer = row_me.of_id;
		},
		async deleteMessage() {
			await this.$axios.delete(this.$config.server_url + "/api/1.0/messages/" + this.me_id_to_delete);
			this.confirmDeleteMessageDialog = false;
			await this.loadMessages();
			this.$socket.emit("messages_created", this.me_id_to_delete);
		},

		async sendMessage() {
			if (!this.$refs.inputTitle.validate() || !this.$refs.inputMessage.validate()) {
				return;
			}
			let response;
			if (this.messageToEdit && this.messageToEdit.me_id) {
				response = await this.$axios.put(this.$config.server_url + "/api/1.0/messages/" + this.messageToEdit.me_id, {
					me_title: this.title,
					me_text: this.message,
					of_id: this.me_offer ? this.me_offer.of_id : "",
					co_id: this.me_contact ? this.me_contact.co_id : ""
				});
			} else {
				response = await this.$axios.post(this.$config.server_url + "/api/1.0/messages", {
					me_title: this.title,
					me_text: this.message,
					of_id: this.me_offer ? this.me_offer.of_id : "",
					co_id: this.me_contact ? this.me_contact.co_id : ""
				});
			}
			this.messageToEdit = {};
			this.message = "";
			this.title = "";
			this.me_offer = null;
			this.me_contact = null;
			await this.loadMessages();
			this.$emit("newMessage", this.$store.state.user);
			this.$socket.emit("messages_created", response.data.data.me_id);
		}
	}
};
</script>
<style lang="scss" scoped>
.clearfix {
	clear: both;
}
.home-icon-message {
	cursor: pointer;
}
.lien-message {
	line-height: 0.8rem;
	font-size: 0.75rem;
	margin-bottom: 5px;
}
.message-title {
	font-size: 0.875rem;
	line-height: 1rem;
	font-weight: bold;
}
.messageContainer {
	.message-who {
		font-size: 11px;
		text-align: center;
	}
	.messageIcon {
		float: right;
	}
	.messageContent {
		float: left;
		width: calc(100% - 35px - 8px);
		padding: 10px;
		background-color: #eceff1;
		position: relative;
	}
	.messageArrow {
		width: 0;
		height: 0;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
	}
	.messageDelete {
		float: right;
		cursor: pointer;
		i {
			font-size: 18px;
		}
	}
	&.right {
		.messageIcon {
			float: right;
		}
		.messageContent {
			margin: 0 8px 10px 0;
		}
		.messageArrow {
			border-left: 8px solid #eceff1;
			position: absolute;
			right: -8px;
		}
	}
	&.left {
		.messageIcon {
			float: left;
		}
		.messageContent {
			margin: 0 0 10px 8px;
		}
		.messageArrow {
			border-right: 8px solid #eceff1;
			position: absolute;
			left: -8px;
		}
	}
}
</style>
