var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","mx-0":""}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-text',{staticClass:"fill-height d-flex flex-column"},[(_vm.$store.state.preferences.rights.calendar_showall)?_c('div',{staticClass:"flex-grow-0"},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md10":""}},[_c('v-combobox',{attrs:{"items":_vm.users,"name":_vm.$Utils.randomstring('users'),"label":"Sélectionner les agents pour afficher leur calendrier","multiple":"","small-chips":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"input-value":selected,"small":""}},'v-chip',attrs,false),[_c('v-avatar',{attrs:{"color":("" + (item.co_preferences && item.co_preferences.color ? item.co_preferences.color.hex : _vm.defaultColor)),"left":""},domProps:{"textContent":_vm._s(item.co_name.slice(0, 1).toUpperCase())}}),_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm._f("formatContactName")(item)))]),_c('span',{on:{"click":function($event){return _vm.cancelItem(item)}}},[_c('icon',{staticClass:"icon-cross withpointer",attrs:{"name":"times"}})],1)],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{staticClass:"ships-in-list",attrs:{"small":""}},[_c('v-avatar',{attrs:{"color":("" + (item.co_preferences && item.co_preferences.color ? item.co_preferences.color.hex : _vm.defaultColor)),"left":""},domProps:{"textContent":_vm._s(item.co_name.slice(0, 1).toUpperCase())}}),_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm._f("formatContactName")(item)))])],1)]}}],null,false,164415245),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1),(_vm.$store.state.preferences.shared.useAzure)?_c('v-flex',{attrs:{"md2":"","d-flex":"","justify-center":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',[_c('v-img',{staticClass:"mt-3",attrs:{"src":"/img/outlook.png","height":"40px","width":"40px"}})],1),_c('div',{staticClass:"ml-2 pt-5",staticStyle:{"line-height":"0.75rem"}},[(_vm.$store.state.preferences.personal.azure)?_c('div',_vm._g({},on),[_vm._v(" Compte Azure "),_c('br'),_c('b',[_vm._v(_vm._s(_vm.$store.state.preferences.personal.azure.name))])]):_c('div',[_c('a',{on:{"click":function($event){return _vm.$router.push('/preferences')}}},[_vm._v("Configurer un compte Azure")])])])]}}],null,false,2475468377)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.forceAzureSynchro}},[_c('v-list-item-title',[_vm._v("Forcer la synchronisation")])],1)],1)],1)],1):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"flex-grow-1 overflow-hidden"},[_c('calendar-details',{ref:"calendarDetails",attrs:{"users":_vm.selectedUsers},on:{"calendarWinEditOpen":_vm.calendarWinEditOpen,"calendarWinEditSaved":_vm.calendarWinEditSaved,"calendarWinEditCanceled":_vm.calendarWinEditCanceled,"calendarWinEditDeleted":_vm.calendarWinEditDeleted}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }