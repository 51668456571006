<template>
	<v-dialog v-model="dialog" persistent :transition="false" max-width="450px" ref="winOffer">
		<v-card>
			<v-card-title>
				<div>
					<h1>
						Edition d'un
						<span class="bis">élément</span>
					</h1>
				</div>
			</v-card-title>

			<v-card-text grid-list-xl :style="stylecontainer">
				<m-form-text
					type="text"
					:name="$Utils.randomstring('tr_key')"
					outline
					label="Clé (ne pas modifier)"
					label-position="top"
					:disabled="!!row_tr.tr_key"
					v-model="row_tr.tr_key"
				></m-form-text>
				<m-form-text
					type="text"
					:name="$Utils.randomstring('tr_fr')"
					outline
					label="Français"
					label-position="top"
					v-model="row_tr.tr_fr"
				></m-form-text>
				<m-form-text
					type="text"
					:name="$Utils.randomstring('tr_en')"
					outline
					label="Anglais"
					label-position="top"
					v-model="row_tr.tr_en"
				></m-form-text>
				<m-form-text
					type="text"
					:name="$Utils.randomstring('tr_ru')"
					outline
					label="Russe"
					label-position="top"
					v-model="row_tr.tr_ru"
				></m-form-text>
				<m-form-text
					type="text"
					:name="$Utils.randomstring('tr_it')"
					outline
					label="Italien"
					label-position="top"
					v-model="row_tr.tr_it"
				></m-form-text>
				<m-form-text
					type="text"
					:name="$Utils.randomstring('tr_de')"
					outline
					label="Allemand"
					label-position="top"
					v-model="row_tr.tr_de"
				></m-form-text>
				<m-form-text
					type="text"
					:name="$Utils.randomstring('tr_sp')"
					outline
					label="Espagnol"
					label-position="top"
					v-model="row_tr.tr_sp"
				></m-form-text>
			</v-card-text>

			<v-card-actions>
				<v-btn color="error2" @click="confirmDeleteDialog = true" :disabled="!isEditable">Effacer</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin()" :disabled="!isEditable">Enregistrer</v-btn>
			</v-card-actions>
		</v-card>
		<m-confirm-dialog
			v-model="confirmDeleteDialog"
			text="Souhaitez-vous effacer cet élément ?"
			title="Confirmation"
			@canceled="confirmDeleteDialog = false"
			@confirmed="deleteWin()"
		></m-confirm-dialog>
	</v-dialog>
</template>

<script>
export default {
	name: "TradWinEdit",
	data() {
		return {
			confirmDeleteDialog: false,
			dialog: false,
			row_tr: {},
			stylecontainer: ``
		};
	},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	computed: {
		isEditable() {
			return true;
		}
	},
	watch: {},
	props: ["offersSelected"],
	methods: {
		setContainerStyle() {
			if (!this.$refs.mycontainer) return;
			if (this.$refs.mycontainer.clientHeight > window.innerHeight - 300) this.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
			else this.stylecontainer = "";
		},
		open(tr_id) {
			this.dialog = true;
			this.getTrad(tr_id);
		},
		async getTrad(tr_id) {
			let params = {};

			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/trads/" + tr_id, { params });
			this.row_tr = response.data.data;

			setTimeout(() => {
				this.setContainerStyle();
			}, 10);
		},
		async saveWin() {
			let response;
			if (this.row_tr.tr_id) {
				response = await this.$axios.put(this.$config.server_url + "/api/1.0/trads/" + this.row_tr.tr_id, this.row_tr);
			} else {
				response = await this.$axios.post(this.$config.server_url + "/api/1.0/trads", this.row_tr);
			}

			this.$emit("tradWinEditSaved", response.data.data.tr_id);
			this.dialog = false;
		},
		cancelWin() {
			this.$emit("tradWinEditCanceled", this.row_tr.tr_id);
			this.dialog = false;
		},
		async deleteWin() {
			let tr_id = this.row_tr.tr_id;
			await this.$axios.delete(this.$config.server_url + "/api/1.0/trads/" + tr_id);
			this.confirmDeleteDialog = false;
			this.dialog = false;
			this.$emit("tradWinEditSaved", tr_id);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
