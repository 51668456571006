<template>
	<table class="tablestriped dense">
		<tbody>
			<tr v-for="item in items_frame_goods" :key="item.value">
				<td v-if="hasGoodValue(item, row_of)">{{ item.label ? item.label : item.label_fr | trad(lang) }}</td>
				<td v-if="false" v-html="Nada"></td>
				<td v-else-if="item.value == 'co_id_owner' && hasGoodValue(item, row_of)">
					<a @click="showprivate">{{ showprivatebt }}</a>
					<div v-if="showprivateok">
						<a
							@click="$router.push('/contacts/' + row_of.co_id_owner.co_id + '/details')"
							v-html="
								$options.filters[item.filter](
									row_of[item.value],
									item.filtermore && $store.state[item.filtermore] ? $store.state[item.filtermore] : item.filtermore
								)
							"
						></a>
					</div>
				</td>
				<td v-else-if="item.value == 'of_mandat' && hasGoodValue(item, row_of)">
					<a @click="showprivate">{{ showprivatebt }}</a>
					<div v-if="showprivateok">{{ row_of[item.value] }}</div>
				</td>
				<td v-else-if="item.value == 'of_notes_customer' && hasGoodValue(item, row_of)">
					<a @click="showprivate">{{ showprivatebt }}</a>
					<div v-if="showprivateok" v-html="$options.filters.nl2br(row_of[item.value])"></div>
				</td>
				<td v-else-if="item.value == 'of_notes_private' && hasGoodValue(item, row_of)">
					<a @click="showprivate">{{ showprivatebt }}</a>
					<div v-if="showprivateok" v-html="$options.filters.nl2br(row_of[item.value])"></div>
				</td>
				<td v-else-if="item.value == 'of_notes_private2' && hasGoodValue(item, row_of)">
					<a @click="showprivate">{{ showprivatebt }}</a>
					<div v-if="showprivateok" v-html="$options.filters.nl2br(row_of[item.value])"></div>
				</td>
				<td
					v-else-if="item.filter && hasGoodValue(item, row_of)"
					v-html="
						$options.filters[item.filter](
							row_of[item.value],
							item.filtermore && $store.state[item.filtermore] ? $store.state[item.filtermore] : item.filtermore
						)
					"
				></td>
				<td v-else-if="hasGoodValue(item, row_of)" v-html="row_of[item.value]"></td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: "offerframedetails",
	props: ["items_frame", "row_of", "forceshowprivate", "lang"], //, "keyload"
	data() {
		return {
			menu: false,
			showprivateok: false,
			showprivatebt: "Voir"
			// items_frame_goods: []
		};
	},
	mounted() {
		if (this.forceshowprivate) this.showprivateok = true;
		// this.load();
	},
	computed: {
		items_frame_goods() {
			let goods = this.items_frame.filter(item => {
				return this.hasGoodValue(item, this.row_of);
			});
			return goods;
		}
	},
	watch: {},
	components: {},
	methods: {
		// load() {
		// 	console.log("je passe");
		// 	this.items_frame_goods = this.items_frame.filter(item => {
		// 		return this.hasGoodValue(item, this.row_of);
		// 	});
		// },
		hasGoodValue(item, row_of) {
			if (!row_of) return false;
			let ok;
			if (Number.isInteger(row_of)) {
				ok = (window.BigInt(row_of) & window.BigInt(item.value)) === window.BigInt(item.value);
			} else {
				ok = row_of[item.value] && row_of[item.value] != "0000-00-00";
			}
			if (item.force) ok = true;
			return ok;
		},
		showprivate() {
			this.showprivateok = !this.showprivateok;
			if (this.showprivateok) this.showprivatebt = "Masquer";
			else this.showprivatebt = "Voir";
		}
	}
};
</script>

<style lang="scss"></style>
