<template>
	<v-card class="card3 mt-3">
		<v-card-title>
			<h1>
				<span class="bis">{{ cardtitle }}</span>
				<v-btn color="primary2" x-small style="margin-left: 30px" @click="addFiles = true" v-if="!addFiles">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
				<v-btn color="primary2" x-small style="margin-left: 30px" @click="addFiles = false" v-if="addFiles">
					<v-icon>mdi-minus</v-icon>
				</v-btn>
			</h1>
		</v-card-title>
		<v-card-text>
			<div v-if="addFiles">
				<v-file-input class="my-3" chips label="joindre un fichier" accept="file/*" v-model="inputFile" @change="changefile"></v-file-input>
				<m-form-text
					name="descriptionFile"
					label="Description du fichier"
					labelPosition="top"
					:label-width="9"
					v-model="descriptionFile"
				></m-form-text>
				<v-btn @click="ajouterFile" color="primary2 mt-2">{{ btntext }}</v-btn>
				<div class="d-flex flex-row flex-wrap">
					<div class="newFile-list" v-for="(file, index) in newFiles" :key="index">
						<span class="newfile-name">{{ cutName(file[0].name) }}</span>
						<span @click="cancelFile(file)">
							<icon class="icon-cross" name="times"></icon>
						</span>
					</div>
				</div>
			</div>
			<table class="tablestriped">
				<tbody>
					<tr v-for="file in files" :key="file.fi_id">
						<td style="width: 200px">
							<div>
								{{ file.fi_description ? file.fi_description : file.fi_file }}
							</div>
						</td>
						<td>
							<v-btn @click="downloadFile(file)" color="secondary2" small>Télécharger</v-btn>
						</td>
						<td>
							<img
								:src="
									$config.server_url +
										'/api/1.0/images/' +
										file.fi_id +
										`/35/50?token=${$store.state.accesstoken}&d=${file.updatedAt}`
								"
								alt="apperçu du fichier"
							/>
						</td>
						<td class="withpointer" @click="deleteFile(file)">
							<v-icon>mdi-trash-can</v-icon>
						</td>
					</tr>
				</tbody>
			</table>
			<m-confirm-dialog
				v-model="confirmDeleteDialog"
				text="Souhaitez-vous effacer ce fichier ?"
				title="Confirmation"
				@canceled="confirmDeleteDialog = false"
				@confirmed="deleteFileOk()"
			></m-confirm-dialog>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "cardfiles",
	props: ["value", "cardtitle", "btntext", "of_id"],
	data() {
		let files = this.value;
		return {
			files,
			descriptionFile: "",
			newFiles: [],
			inputFile: [],
			addFiles: false,
			confirmDeleteDialog: false,
			fileToDelete: []
		};
	},
	mounted() {},
	watch: {
		value(val) {
			this.files = val;
		},
		of_id(val) {
			this.saveFiles(val);
		}
	},
	methods: {
		changefile() {
			this.descriptionFile = this.inputFile.name;
		},
		downloadFile(file) {
			window.open(this.$config.server_url + "/api/1.0/files/" + file.fi_id + "/download?token=" + this.$store.state.accesstoken, "_blank");
		},
		ajouterFile() {
			// console.log("this.inputFile", this.inputFile);
			if (this.descriptionFile === "") this.descriptionFile = this.inputFile.name;
			// } else {
			this.newFiles.push([this.inputFile, this.descriptionFile]);
			this.descriptionFile = "";
			this.inputFile = [];
			// }
		},
		cutName(name) {
			let shortName = name;
			if (name.length > 13) {
				shortName = name.slice(0, 5);
				shortName += "...";
				shortName += name.slice(-8);
			}
			return shortName;
		},
		cancelFile(f) {
			let indexToCancel = 0;
			for (let iFile = 0; iFile < this.newFiles.length; iFile++) {
				const file = this.newFiles[iFile][0];
				if (file.name === f.name) {
					indexToCancel = iFile;
				}
			}
			this.newFiles.splice(indexToCancel, 1);
			// console.log("this.newFiles", this.newFiles);
		},
		async saveFiles(of_id) {
			// console.log("enregistrement du fichier en cours (saveFiles)");
			// console.log("this.files", this.files);
			for (let iFile = 0; iFile < this.newFiles.length; iFile++) {
				const file = this.newFiles[iFile];
				let formData = new FormData();
				formData.append("of_id", of_id);
				formData.append("fi_subtype", "");
				formData.append("fi_sort", this.files.length + (iFile + 1));
				formData.append("fi_from", "files");
				formData.append("fi_description", file[1]);
				formData.append("image", file[0]);
				// console.log("file", file, "formdata", formData);
				await this.$axios.post(this.$config.server_url + "/api/1.0/files", formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				});
			}
			this.newFiles = [];
			this.addFiles = false;
		},
		deleteFile(row_fi) {
			this.confirmDeleteDialog = true;
			this.fileToDelete = row_fi;
		},
		async deleteFileOk() {
			// console.log("this.fileToDelete", this.fileToDelete);
			await this.$axios.delete(this.$config.server_url + "/api/1.0/files/" + this.fileToDelete.fi_id);
			this.confirmDeleteDialog = false;
			this.fileToDelete = [];
			this.$emit("loadFiles");
		}
	}
};
</script>
<style scoped>
.tablestriped {
	background-color: #fff;
	border-top: solid 1px gray;
	border-bottom: solid 1px gray;
	margin-top: 20px;
}
.newFile-list {
	margin-top: 10px;
	margin-right: 3px;
	background-color: #a7a5a5;
	padding: 5px 15px;
	border-radius: 25px;
}
.icon-cross {
	cursor: pointer;
	width: 25px;
	height: 25px;
	padding-top: 5px;
	color: #2b2b2b;
}
.newfile-name {
	vertical-align: super;
}
</style>
