var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.state.preferences.rights.message_create && !_vm.readonly)?_c('div',[_c('m-form-text',{ref:"inputTitle",attrs:{"type":"text","name":_vm.$Utils.randomstring(),"rules":[_vm.$Validation.mandatory],"label":"Objet","label-position":"top"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('m-form-text',{ref:"inputMessage",attrs:{"type":"textarea","name":_vm.$Utils.randomstring(),"rules":_vm.rulesInputMessage,"label":"Message","label-position":"top"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('div',{staticClass:"combobox-message"},[(_vm.showComboboxContact)?_c('m-form-combobox',{staticClass:"combobox-message-contact",attrs:{"label":"Contact","label-position":"top","prepend-icon":"mdi-account","store-url":_vm.$config.server_url + '/api/1.0/contacts',"store-params":{ gr_id: 'contacts' },"item-value":"co_id","item-text":_vm.$options.filters.formatContactName},model:{value:(_vm.me_contact),callback:function ($$v) {_vm.me_contact=$$v},expression:"me_contact"}}):_vm._e(),(_vm.showComboboxOffer)?_c('m-form-combobox',{staticClass:"combobox-message-offer",attrs:{"label":"Bien","label-position":"top","name":_vm.$Utils.randomstring('of_message'),"store-url":_vm.$config.server_url + '/api/1.0/offers',"item-value":"of_id","item-text":_vm.$options.filters.formatOfferName},model:{value:(_vm.me_offer),callback:function ($$v) {_vm.me_offer=$$v},expression:"me_offer"}}):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row container-btn-input-message mt-2"},[_c('v-btn',{attrs:{"id":"btn-envoyer-message","color":"#757575","small":"","depressed":""},on:{"click":function($event){return _vm.sendMessage()}}},[_vm._v(_vm._s(_vm.messageToEdit.me_id ? "Modifer" : "Envoyer"))])],1)],1):_vm._e(),_vm._l((_vm.messages),function(row_me){return _c('div',{key:row_me.me_id},[_c('div',{class:{
					messageContainer: true,
					left: row_me.me_from.co_id == _vm.$store.state.user.co_id,
					right: row_me.me_from.co_id != _vm.$store.state.user.co_id
				}},[_c('div',{staticClass:"message-who"},[_vm._v(" "+_vm._s(_vm._f("formatmessagewho")(row_me.me_from,_vm.$store.state.user))+" "+_vm._s(_vm._f("formatmessagedate")(row_me.me_date))+" ")]),_c('div',{staticClass:"clearfix"}),_c('div',{staticClass:"messageIcon"},[_c('v-img',{staticClass:"rounded-image",attrs:{"src":row_me.me_from.co_id
								? _vm.$config.server_url +
								  '/api/1.0/avatar/' +
								  row_me.me_from.co_id +
								  '/180/180?d=' +
								  row_me.me_from.updatedAt +
								  '&token=' +
								  _vm.$store.state.accesstoken
								: '/img/default-profile.jpg',"height":"35px","width":"35px"}})],1),_c('div',{staticClass:"messageContent"},[_c('div',{staticClass:"messageArrow"}),(row_me.me_from.co_id == _vm.$store.state.user.co_id && !_vm.readonly)?_c('div',{staticClass:"messageDelete",on:{"click":function($event){_vm.me_id_to_delete = row_me.me_id;
							_vm.confirmDeleteMessageDialog = true;}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),((row_me.me_from.co_id == _vm.$store.state.user.co_id || _vm.$store.state.preferences.rights.message_edit_all) && !_vm.readonly)?_c('div',{staticClass:"messageDelete",on:{"click":function($event){return _vm.editMessage(row_me)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_c('div',{staticClass:"message-title"},[_vm._v(_vm._s(row_me.me_title))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.nl2br(row_me.me_text))}}),(row_me.co_id)?_c('div',{staticClass:"lien-message"},[_vm._v(" Contact : "),_c('a',{on:{"click":function($event){return _vm.$router.push('/contacts/' + row_me.co_id.co_id + '/details')}}},[_vm._v(_vm._s(_vm.$options.filters.formatContactName(row_me.co_id)))])]):_vm._e(),(row_me.of_id)?_c('div',{staticClass:"lien-message"},[_vm._v(" Bien : "),_c('a',{on:{"click":function($event){return _vm.$router.push('/offers/' + row_me.of_id.of_id + '/details')}}},[_vm._v(_vm._s(_vm.$options.filters.formatOfferName(row_me.of_id)))])]):_vm._e()]),_c('div',{staticClass:"clearfix"})]),_c('div',{staticClass:"clearfix"})])}),_c('div',{staticClass:"clearfix"}),_c('m-confirm-dialog',{attrs:{"text":"Souhaitez-vous effacer ce message ?","title":"Confirmation"},on:{"canceled":function($event){_vm.confirmDeleteMessageDialog = false},"confirmed":function($event){return _vm.deleteMessage()}},model:{value:(_vm.confirmDeleteMessageDialog),callback:function ($$v) {_vm.confirmDeleteMessageDialog=$$v},expression:"confirmDeleteMessageDialog"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }