<template>
	<m-form-base :id="id2" :label="label" :label-width="labelWidth" :label-position="labelPosition" :errormsg="errormsg2">
		<v-menu v-model="menu" :close-on-content-click="false" offset-y min-width="290px">
			<!-- transition="scale-transition" -->
			<template v-slot:activator="{ on }">
				<div v-on="on" class="m-form-select">
					<input
						type="text"
						ref="myinput"
						:readonly="readonly"
						:autocomplete="autocomplete"
						:disabled="disabled"
						:value="rawdate"
						@focus="onfocus"
						@input="changeInput($event.target.value)"
						:placeholder="placeholder"
						:id="id2"
					/>
					<div class="m-form-base__reset" @click="changeInput('')" v-if="resetbtn">
						<v-icon>mdi-close</v-icon>
					</div>
					<div class="m-form-base__picker">
						<v-icon>mdi-calendar</v-icon>
					</div>
				</div>
				<!-- <m-form-text
        v-model="date"
        :label="label"
        :label-width="labelWidth"
        :prefix="prefix"
        :prepend-icon="prependIcon"
        :readonly="readonly"
        v-on="on"
        ></m-form-text>-->
			</template>
			<v-date-picker :first-day-of-week="1" v-model="datepicker" no-title @input="changeDatePicker"></v-date-picker>
		</v-menu>
	</m-form-base>
</template>

<script>
import MFormBase from "./MFormBase";

export default {
	name: "mformdate",
	extends: MFormBase,
	props: {
		id: {
			default: "",
			type: String
		},
		value: String,
		prefix: String,
		autocomplete: {
			default: "new-password",
			type: String
		},
		readonly: {
			default: false,
			type: Boolean
		},
		disabled: {
			default: false,
			type: Boolean
		},
		resetbtn: {
			default: true,
			type: Boolean
		},
		rules: {
			default: function () {
				return [];
			},
			type: Array
		},
		placeholder: {
			default: "JJ/MM/AAAA",
			type: String
		}
	},
	mounted() {
		if (!this.id2) this.id2 = this.$Utils.randomstring();
		// if (this.value) this.setValue(this.value);
		// if (this.value=="0000-00-00" || !this.value) {
		//   this.rawdate = "";
		//   this.datepicker = "";
		// }
		// if (this.value){}
		this.setValue(this.value);
	},
	data() {
		// let date = this.$moment().format("YYYY-MM-DD");
		// let datepicker = this.$moment().format("YYYY-MM-DD");
		// if (this.value) {
		//   date = this.value;
		//   if (date!='0000-00-00') datepicker
		// }
		// if (date == "0000-00-00") date = "";
		// console.log("date", date);
		return {
			id2: this.id,
			errormsg2: "",
			rawdate: "",
			datepicker: "",
			dontwatch: false,
			// date: "0000-00-00",
			menu: false
		};
	},
	watch: {
		// date: function (val) {
		// 	this.$emit("input", val);
		// },
		value: function (val, oldval) {
			// console.log("watch-value", val, oldval, this.dontwatch);
			// if (this.dontwatch) return;
			this.setValue(val);
		}
		// datepicker: function (val, oldval) {
		// 	console.log("watch-datepicker", val, oldval, this.dontwatch);
		// 	if (this.dontwatch) return;
		// 	this.setValue(val);
		// },

		// rawdate: function (val, oldval) {
		// 	console.log("watch-rawdate", val, oldval, this.dontwatch);
		// 	if (this.dontwatch) return;
		// 	if (val == "") return this.setValue(val);
		// 	let v2 = this.$moment(val, "DD/MM/YYYY");
		// 	if (v2.isValid()) {
		// 		if (val.length == 10) {
		// 			this.setValue(v2.format("YYYY-MM-DD"));
		// 		}
		// 	}
		// }
	},
	components: {},
	methods: {
		changeInput(val) {
			// console.log("changeInput", val);
			if (val == "") return this.setValue("");
			let v2 = this.$moment(val, "DD/MM/YYYY");
			if (v2.isValid()) {
				if (val.length == 10) {
					this.setValue(v2.format("YYYY-MM-DD"));
				}
			}
		},
		changeDatePicker(val) {
			// console.log("changeDatePicker", val);
			this.menu = false;
			this.setValue(val);
		},
		setValue(val, ignoreRaw = false) {
			// this.dontwatch = true;
			this.menu = false;
			// console.log("setValue", val);
			if (val == "0000-00-00" || !val) {
				this.rawdate = "";
				// this.date = "0000-00-00";
				this.datepicker = "";
				// console.log("emit", "0000-00-00");
				this.$emit("input", "0000-00-00");
			} else {
				// if (!ignoreRaw)
				this.rawdate = this.$moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
				// this.date = this.$moment(val, "YYYY-MM-DD").format("YYYY-MM-DD");
				this.datepicker = this.$moment(val, "YYYY-MM-DD").format("YYYY-MM-DD");
				// console.log("emit", this.datepicker);
				this.$emit("input", this.datepicker);
			}
			// this.$nextTick(() => {
			// 	console.log("dontwatch");
			// 	this.dontwatch = false;
			// });
		},
		informValid() {
			this.errormsg2 = "";
		},
		informInvalid(txt) {
			this.errormsg2 = txt;
		},
		validate() {
			let oks = [];
			for (let iRule = 0; iRule < this.rules.length; iRule++) {
				const rule = this.rules[iRule];
				let ok = rule(this.date);
				if (this.$_.isString(ok)) oks.push(ok);
			}
			if (oks.length == 0) {
				this.informValid();
				return true;
			}
			this.informInvalid(oks.join(","));
			return false;
		},
		onfocus(evt) {
			this.$emit("focus", evt);
		}
	}
};
</script>
