<template>
	<div class="print-owner">
		<div v-for="row_of in rows_of" :key="row_of.of_id">
			<v-container class="container-fiche-agent" style="page-break-after:always;">
				<!-- <v-flex :class="{ md12: !short, md12: short }"> -->
				<v-flex>
					<v-layout>
						<v-flex md12 pt-0 class="col1">
							<v-card class="mt-3">
								<v-card-text>
									<div style="display:flex; justify-content:space-between;">
										<div><v-img :src="$store.state.preferences.shared.logoUrl" height="64px" width="147px" contain></v-img></div>
										<div>
											<h1>Compte rendu propriétaire</h1>
											<div>Le {{ $moment().format("DD/MM/YYYY") }}</div>
										</div>
									</div>
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-text>
									<h1>Pour le bien :</h1>
									<div>{{ row_of | formatOfferName }}</div>
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-text>
									<h1>Ce bien a été présenté {{ row_of.presents.length }} fois</h1>
									<div v-for="row_ac in row_of.presents" :key="row_ac.ac_id">
										<div>
											• Le {{ row_ac.ac_date | formatDateTime }} : {{ row_ac.co_id_contact | formatContactName }}
											<span v-if="row_ac.co_id_agence_confrere">
												| Confrère : {{ row_ac.co_id_agence_confrere | formatContactName }}</span
											>
											<span v-if="row_ac.ac_comment"> | Commentaire : <span v-html="row_ac.ac_comment"></span></span>
										</div>
									</div>
								</v-card-text>
							</v-card>
							<v-card class="mt-3">
								<v-card-text>
									<h1>Ce bien a été visité {{ row_of.reports.length }} fois</h1>
									<div v-for="row_ac in row_of.reports" :key="row_ac.ac_id" class="mt-4">
										<div>• Le {{ row_ac.ac_date | formatDateTime }} : {{ row_ac.co_id_contact | formatContactName }}</div>
										<div class="ml-5" v-if="row_ac.ac_comment"><b>Commentaire :</b> <span v-html="row_ac.ac_comment"></span></div>
										<div class="ml-5">
											<div v-if="row_ac.oks.length">
												<b>A aimé :</b>
												<div v-for="ok in row_ac.oks" :key="ok.text" class="ml-5">- {{ ok.text }} : {{ ok.tabtxt }}</div>
											</div>
											<div v-if="row_ac.kos.length">
												<b>A moins aimé :</b>
												<div v-for="ok in row_ac.kos" :key="ok.text" class="ml-5">- {{ ok.text }} : {{ ok.tabtxt }}</div>
											</div>
										</div>
									</div>
								</v-card-text>
							</v-card>

							<v-card class="mt-3" style="width: 500px; page-break-before: always;">
								<!-- <v-card-title> -->
								<h1>Avis des clients</h1>
								<!-- </v-card-title> -->
								<v-card-text>
									<offers-report-chart :row_of="row_of"></offers-report-chart>
								</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-container>
		</div>
	</div>
</template>

<script>
export default {
	name: "offersdetailsprintowner",
	components: {},
	data() {
		return {
			rows_of: [],
			comment: ["commentaire", "commentaires", "comment", 0],
			present: ["bien présenté", "biens présentés", "present", 0],
			visit: ["bien visité", "biens visités", "visit", 0],
			report: ["Compte-rendu", "Comptes-rendus", "report", 0],
			comment_loaded: false,
			present_loaded: false,
			visit_loaded: false,
			myChart: null
		};
	},
	props: {
		value: Object,
		short: Boolean
	},
	watch: {
		comment_loaded(val) {
			//&& navigator.userAgent.toLowerCase().indexOf("safari") < 0
			if (val) this.print();
		},
		present_loaded(val) {
			if (val) this.print();
		},
		visit_loaded(val) {
			if (val) this.print();
		}
	},
	computed: {},
	async mounted() {
		let of_id = this.$route.params.id;
		this.$root.$children[0].hideMenus();
		if (this.value && this.value.of_id) of_id = this.value.of_id;

		/* var labels = [];
		this.$_.each(this.$store.state.items_feedbackstypes, val => {
			labels.push(val.text);
		}); */

		await this.loadDatas(of_id);
	},
	methods: {
		dataLoaded(type) {
			this[type + "_loaded"] = true;
		},
		print() {
			//navigator.userAgent.toLowerCase().indexOf("safari") < 0
			if (this.rows_of.length && this.present_loaded && this.comment_loaded && this.visit_loaded) {
				window.print();
				window.close();
			}
		},
		async loadDatas(of_id) {
			function stripHtml(html) {
				let tmp = document.createElement("DIV");
				tmp.innerHTML = html;
				return tmp.textContent || tmp.innerText || "";
			}

			// this.sliderStop();
			let of_ids = of_id.split("|");
			let rows_of = [];
			for (let iOf = 0; iOf < of_ids.length; iOf++) {
				const of_id = of_ids[iOf];
				if (of_id == "nothing") continue;
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/offers/" + of_id);
				// this.row_of = response.data.data;
				/* response.data.data.imageLoaded = true;
				if (response.data.data.images && response.data.data.images.length) response.data.data.imageLoaded = false; */
				let row_of = response.data.data;

				let resPresents = await this.$axios.get(
					this.$config.server_url + "/api/1.0/actions/offer/" + of_id + "/present?limit=50&onlynotdone=false"
				);
				row_of.presents = resPresents.data.data;
				let resReports = await this.$axios.get(
					this.$config.server_url + "/api/1.0/actions/offer/" + of_id + "/report?limit=50&onlynotdone=false"
				);
				row_of.reports = resReports.data.data;
				for (let iReport = 0; iReport < row_of.reports.length; iReport++) {
					const report = row_of.reports[iReport];
					report.ac_comment = stripHtml(report.ac_comment);
					report.ac_comment = report.ac_comment.trim();
					// console.log("this.$store.state.items_feedbackstypes", this.$store.state.items_feedbackstypes);
					let oks = [],
						kos = [];
					for (let iFeedbacktypes = 0; iFeedbacktypes < this.$store.state.items_feedbackstypes.length; iFeedbacktypes++) {
						const feedbacktype = this.$store.state.items_feedbackstypes[iFeedbacktypes];
						let feeds = this.$store.state.items_feedbacks.filter(it => {
							return it.type == feedbacktype.value;
						});
						for (let iFeedbacks = 0; iFeedbacks < feeds.length; iFeedbacks++) {
							const feedback = feeds[iFeedbacks];
							// console.log("feedback", feedbacktype.text,feedback.text1, feedback.text2, report.ac_report["feedback" + feedback.value]);
							if (report.ac_report["feedback" + feedback.value] == -1) {
								let ft = this.$_.find(kos, { text: feedbacktype.text });
								if (!ft) {
									ft = { text: feedbacktype.text, tab: [] };
									kos.push(ft);
								}
								ft.tab.push(feedback.text2);
							}
							if (report.ac_report["feedback" + feedback.value] == 1) {
								let ft = this.$_.find(oks, { text: feedbacktype.text });
								if (!ft) {
									ft = { text: feedbacktype.text, tab: [] };
									oks.push(ft);
								}
								ft.tab.push(feedback.text2);
							}
						}
					}
					oks.map(item => {
						item.tabtxt = item.tab.join(", ");
					});
					kos.map(item => {
						item.tabtxt = item.tab.join(", ");
					});
					// console.log("oks", oks);
					report.oks = oks;
					report.kos = kos;
				}
				// console.log("row_of.reports", row_of.reports);

				rows_of.push(row_of);
			}

			/* 			if (this.isDayPrint) {
				let params = {
					from: this.currentDate.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
					to: this.currentDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
					// envoyer aussi un tableau avec le ou les agents
					users: [this.$store.state.user.co_id]
				};
				let response = await this.$axios.get(this.$config.server_url + "/api/1.0/events", { params });
				this.rows_ve = response.data.data;
				// console.log("response", response);
			} */

			this.rows_of = rows_of;
			this.print();
		}
	}
};
</script>

<style lang="scss" scoped>
.print-owner {
	background-color: #fff !important;
	width: calc(100% + 30px);
	margin: -15px;
}
// .v-card {
// 	background-color: $mygris !important;
// }
.v-card__text {
	padding: 0;
}
.v-application ul.hooper-track {
	padding-left: 0;
}
.container-fiche-agent {
	width: 100%;
	// height: 100%;
	page-break-after: always;
}
@media print {
	@page {
		margin: 0;
	}
	.v-card.theme--light.v-sheet {
		border: solid 0px gray;
		padding: 10px;
	}
	body {
		margin: 0;
	}
	.col1 {
		width: 510px;
	}
}
</style>
