<template>
	<v-dialog v-model="dialog" persistent max-width="950px" ref="winOffer">
		<v-card>
			<v-card-title>
				<div>
					<h1>Edition d'un événement</h1>
					<h2>Du {{ date_start | formatdate }} au {{ date_end | formatdate }}</h2>
				</div>
			</v-card-title>

			<v-card-text grid-list-xl :style="stylecontainer">
				<v-container fluid pa-0 grid-list-xl ref="mycontainer">
					<v-layout>
						<v-flex md5 style="border-right: 0px solid #e8e8e7">
							<v-card>
								<v-card-text>
									<div class="mt-3">
										<m-form-date-hour v-model="date_start" :usemoment="true" ref="date_start"></m-form-date-hour>
										<m-form-date-hour v-model="date_end" :usemoment="true" :hidedate="true" ref="date_end"></m-form-date-hour>
										<m-form-combobox
											:name="$Utils.randomstring('co_id_user')"
											:disabled="!$store.state.preferences.rights.calendar_edit_all"
											v-model="row_ve.co_id_user"
											label="Nom du négociateur"
											label-position="top"
											prepend-icon="mdi-account"
											:store-url="$config.server_url + '/api/1.0/contacts'"
											:store-params="{ gr_id: 'users', ignoremiglior: true }"
											item-value="co_id"
											:item-text="$options.filters.formatContactName"
										></m-form-combobox>
										<!-- <m-form-date-hour v-model="row_ve.ve_dstart"></m-form-date-hour> -->
									</div>

									<div class="mt-3">
										<label>
											Nom du contact / client / prospect
											<v-btn color="success" @click="$refs.contactwinedit.open(-1)" small>Nouveau</v-btn>
										</label>
										<m-form-combobox
											v-model="row_ve.co_id_contact"
											prepend-icon="mdi-account"
											:store-url="$config.server_url + '/api/1.0/contacts'"
											:store-params="{ gr_id: 'contacts' }"
											item-value="co_id"
											:item-text="$options.filters.formatContactName"
											@input="loadContact"
										></m-form-combobox>
									</div>

									<div v-if="true" class="mt-3">
										<label>
											Concerne le(s) bien(s) associé(s)
											<!-- <v-icon @click="row_ve.visits.push({ of_id: {} })"
                        >mdi-plus</v-icon
                      > -->
										</label>
										<m-form-combobox
											v-model="addOffer"
											:name="$Utils.randomstring('of_id')"
											:store-url="$config.server_url + '/api/1.0/offers'"
											item-value="of_id"
											:item-text="$options.filters.formatOfferName"
										></m-form-combobox>
										<!-- <table class="w-100">
                      <tr
                        v-for="(row_vi, indexVi) in row_ve.visits"
                        :key="indexVi"
                      >
                        <td>
                          <m-form-combobox
                            v-model="row_vi.of_id"
                            :name="$Utils.randomstring('of_id')"
                            :store-url="$config.server_url + '/api/1.0/offers'"
                            item-value="of_id"
                            :item-text="$options.filters.formatOfferName"
                          ></m-form-combobox>
                        </td>
                        <td style="width:40px;" class="text-right">
                          <v-icon @click="row_ve.visits.splice(indexVi, 1)"
                            >mdi-trash-can</v-icon
                          >
                        </td>
                      </tr>
                    </table> -->
										<table class="w-100">
											<tr v-for="(row_ac_temp, indexOf) in row_ve.visits" :key="indexOf">
												<td valign="top">
													<v-icon color="#2096f3" small>mdi-arrow-right-thick</v-icon>
													<!-- <span style='color:#2096f3;'>•</span> -->
												</td>
												<td v-html="$options.filters.formatOfferName(row_ac_temp.of_id, false, true)"></td>
												<td style="width: 40px" class="text-right" valign="top">
													<v-icon @click="row_ve.visits.splice(indexOf, 1)">mdi-trash-can</v-icon>
												</td>
											</tr>
										</table>
									</div>

									<div class="mt-3">
										<m-form-text
											type="text"
											:name="$Utils.randomstring('ve_summary')"
											outline
											label="Sujet et commentaire"
											label-position="top"
											v-model="row_ve.ve_summary"
										></m-form-text>
										<m-form-text
											v-show="showComment || row_ve.ve_description"
											type="textarea"
											:name="$Utils.randomstring('ve_description')"
											outline
											v-model="row_ve.ve_description"
										></m-form-text>
										<div>
											<a @click="showComment = !showComment">
												<span v-if="!showComment">Ajouter un commentaire</span>
												<span v-else>Masquer le commentaire</span>
											</a>
										</div>
									</div>
								</v-card-text>
							</v-card>
						</v-flex>
						<v-flex md7>
							<v-card class="mt-0" style="background-color: #e8e8e7">
								<v-card-text class="my-0">
									<v-tabs v-model="tabvisit" background-color="transparent" color="basil" grow @change="tabvisitchange">
										<v-tab key="contact">Contact</v-tab>
										<v-tab v-for="(item, index) in row_ve.visits" :key="`offer_${index}`"
											>Bien {{ item.of_id && item.of_id.of_ref ? item.of_id.of_ref : "..." }}</v-tab
										>
									</v-tabs>

									<v-tabs-items v-model="tabvisit">
										<v-tab-item key="contact" style="background-color: #e8e8e7">
											<div style="margin-top: 15px" v-if="row_co_details">
												<contact-details
													:short="true"
													:notclickable="true"
													v-model="row_co_details"
													@contactDetailsLoaded="setContainerStyle"
													@contactWinEditSaved="loadContact"
												></contact-details>
											</div>
										</v-tab-item>
										<v-tab-item v-for="(item, index) in row_ve.visits" :key="`offer_${index}`">
											<div style="margin-top: 15px" v-if="item.of_id && item.of_id.of_id">
												<offers-details
													:short="true"
													v-model="item.of_id"
													@offersDetailsLoaded="setContainerStyle"
												></offers-details>
											</div>
										</v-tab-item>
									</v-tabs-items>
								</v-card-text>
							</v-card>
						</v-flex>
					</v-layout>
				</v-container>
			</v-card-text>

			<!-- <v-card-text grid-list-xl :style="stylecontainer">
        <v-container fluid grid-list-xl pa-0 ref="mycontainer">
          <v-layout justify-space-between>
            <v-flex md6>
              <v-card class="card1">
                <v-card-text>
                  <m-form-text
                    type="textarea"
                    label="Commentaire"
                    label-position="top"
                    :name="$Utils.randomstring('ve_summary')"
                    v-model="row_ve.ve_summary"
                  ></m-form-text>
                  <m-form-combobox
                    v-model="row_ve.co_id_contact"
                    label="Contact"
                    prepend-icon="mdi-account"
                    :store-url="$config.server_url + '/api/1.0/contacts'"
                    :store-params="{gr_id:'contacts'}"
                    item-value="co_id"
                    :item-text="$options.filters.formatContactName"
                  ></m-form-combobox>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex md6>
              <v-card class="card4">
                <v-card-title>
                  <h2>
                    Biens
                    <span class="bis">à visiter</span>
                    <v-icon @click="addVisit({ of_id: {} })">mdi-plus</v-icon>
                  </h2>
                </v-card-title>
                <v-card-text>
                  <table class="tablewithoutstriped">
                    <tr v-for="(row_vi) in row_ve.visits" :key="row_vi.vi_id">
                      <td>
                        <m-form-combobox
                          v-model="row_vi.of_id"
                          :name="$Utils.randomstring('of_id')"
                          :store-url="$config.server_url + '/api/1.0/offers'"
                          item-value="of_id"
                          :item-text="$options.filters.formatOfferName"
                        ></m-form-combobox>
                      </td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
              <v-card class="mt-3">
                <v-card-title>
                  <h2>Infos événement</h2>
                </v-card-title>
                <v-card-text>
                  <m-form-combobox
                    v-model="row_ve.co_id_user"
                    label="Utilisateur"
                    prepend-icon="mdi-account"
                    :store-url="$config.server_url + '/api/1.0/contacts'"
                    :store-params="{gr_id:'users'}"
                    item-value="co_id"
                    :item-text="$options.filters.formatContactName"
                  ></m-form-combobox>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>-->
			<v-card-actions>
				<v-btn color="error2" @click="confirmDeleteDialog = true" :disabled="!isEditable">Effacer</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary2" text @click="cancelWin()">Annuler</v-btn>
				<v-btn color="primary2" @click="saveWin()" :disabled="!isEditable">Enregistrer</v-btn>
			</v-card-actions>
		</v-card>
		<m-confirm-dialog
			v-model="confirmDeleteDialog"
			text="Souhaitez-vous effacer cet événement ?"
			title="Confirmation"
			@canceled="confirmDeleteDialog = false"
			@confirmed="deleteWin()"
		></m-confirm-dialog>
		<contact-win-edit ref="contactwinedit" @contactWinEditSaved="contactWinEditSaved"></contact-win-edit>
	</v-dialog>
</template>

<script>
export default {
	name: "CalendarWinEdit",
	data() {
		let start = this.$moment();
		let end = this.$moment();
		return {
			addOffer: null,
			showComment: false,
			tabvisit: null,
			row_co_details: null,
			confirmDeleteDialog: false,
			dialog: false,
			row_ve: { ve_summary: "", start, end, row_co: {} },
			date_start: start,
			date_end: end,
			stylecontainer: ``
		};
	},
	filters: {
		formatdate(d) {
			return d.format("DD/MM/YYYY [à] HH[H]mm");
		}
	},
	mounted() {
		window.addEventListener("resize", this.setContainerStyle);
	},
	destroyed() {
		window.removeEventListener("resize", this.setContainerStyle);
	},
	computed: {
		isEditable() {
			if (this.$store.state.preferences.rights.calendar_edit_all) return true;
			if (
				this.$store.state.preferences.rights.calendar_edit_mine &&
				this.row_ve &&
				this.row_ve.co_id_user &&
				this.row_ve.co_id_user.co_id == this.$store.state.user.co_id
			)
				return true;

			return false;
		}
	},
	watch: {
		addOffer(v) {
			if (v && v.of_id) {
				this.row_ve.visits.push({ of_id: v });
				this.addOffer = {};
			}
		},
		date_start(v) {
			this.date_end.date(v.date());
			this.date_end.month(v.month());
			this.date_end.year(v.year());
			this.$refs.date_end.forceDate(this.date_end);
		},
		date_end(v) {
			this.date_start.date(v.date());
			this.date_start.month(v.month());
			this.date_start.year(v.year());
			this.$refs.date_start.forceDate(this.date_start);
		}
	},
	props: ["offersSelected", "contactSelected"],
	methods: {
		async contactWinEditSaved(co_id) {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/contacts/" + co_id);
			this.row_ve.co_id_contact = response.data.data;
		},
		setContainerStyle() {
			if (!this.$refs.mycontainer) return;
			if (this.$refs.mycontainer.clientHeight > window.innerHeight - 300) this.stylecontainer = `height: calc(100vh - 253px); overflow:auto;`;
			else this.stylecontainer = "";
		},
		loadContact(v) {
			let co_id = v;
			if (this.$_.isPlainObject(v)) co_id = v.co_id;
			this.row_co_details = co_id;
		},
		tabvisitchange(v) {
			// console.log("v", v);
			this.setContainerStyle();
			window.setTimeout(this.setContainerStyle, 200);
		},
		addVisit(visit) {
			this.row_ve.visits.push(visit);
			this.setContainerStyle();
		},
		open(ve_id, start, end, allday) {
			this.dialog = true;
			this.getEvent(ve_id, start, end, allday);
		},
		async getEvent(ve_id, start, end, allday) {
			let params = {};
			if (ve_id == -1) {
				params = {
					start: start.format("YYYY-MM-DD HH:mm:ss"),
					end: end.format("YYYY-MM-DD HH:mm:ss"),
					allday
				};
			}

			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/events/" + ve_id, { params });
			let row_ve = response.data.data;
			this.date_start = this.$moment(row_ve.ve_dtstart, "YYYY-MM-DD HH:mm:ss");
			this.date_end = this.$moment(row_ve.ve_dtend, "YYYY-MM-DD HH:mm:ss");
			// if (row_ve.co_id_contact) {
			//   this.co_id_items = [row_ve.co_id_contact];
			// }
			this.row_ve = row_ve;
			this.showComment = false;

			if (this.$_.isArray(this.offersSelected) && !row_ve.ve_id) {
				for (let iOffer = 0; iOffer < this.offersSelected.length; iOffer++) {
					const selected = this.offersSelected[iOffer];
					this.addVisit({ of_id: selected });
				}
			}
			if (this.contactSelected) {
				row_ve.co_id_contact = this.contactSelected;
			}

			setTimeout(() => {
				this.setContainerStyle();
			}, 10);
		},
		async saveWin() {
			// this.$socket.emit("go1", "toto");
			let response;
			if (this.date_start.isAfter(this.date_end)) return alert("Date de début après date de fin");
			this.row_ve.ve_dtstart = this.date_start.format("YYYY-MM-DD HH:mm:ss");
			this.row_ve.ve_dtend = this.date_end.format("YYYY-MM-DD HH:mm:ss");
			if (this.row_ve.ve_id) {
				response = await this.$axios.put(this.$config.server_url + "/api/1.0/events/" + this.row_ve.ve_id, this.row_ve);
			} else {
				response = await this.$axios.post(this.$config.server_url + "/api/1.0/events", this.row_ve);
			}

			this.$emit("calendarWinEditSaved", response.data.data.ve_id);
			this.$store.commit("set_reload_actions");
			this.$socket.emit("calendar_event", response.data.data.ve_id);
			this.dialog = false;
		},
		cancelWin() {
			this.$emit("calendarWinEditCanceled", this.row_ve.ve_id);
			this.dialog = false;
		},
		async deleteWin() {
			let ve_id = this.row_ve.ve_id;
			await this.$axios.delete(this.$config.server_url + "/api/1.0/events/" + ve_id);
			this.confirmDeleteDialog = false;
			this.dialog = false;
			this.$emit("calendarWinEditSaved", ve_id);
			this.$socket.emit("calendar_event", ve_id);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
