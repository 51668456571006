<template>
	<div class="container-research">
		<v-row class="page-preferences" column fill-height formlittle>
			<v-col class="py-0">
				<v-card class="mb-4 flex-grow-1 card card-personalpreference">
					<v-card-title>
						<h1>
							Résultats de votre
							<span class="bis">recherche</span> :
						</h1>
					</v-card-title>
					<v-card-text v-if="researchResponse">
						<h2>
							{{ researchResponse.total_co }} Contact(s) trouvés
							<span v-if="researchResponse.total_co > 100">(affichage limité à 100)</span>
						</h2>
						<div v-for="row_co in researchResponse.rows_co" :key="row_co.co_id">
							<a @click="goToResearch('contacts', row_co.co_id)">{{ $options.filters.formatContactName(row_co) }}</a>
							<br />
						</div>

						<hr style="margin-top: 20px" />
						<h2 style="margin-top: 20px">
							{{ researchResponse.total_of }} Bien(s) trouvés
							<span v-if="researchResponse.total_of > 100">(affichage limité à 100)</span>
						</h2>
						<div v-for="row_of in researchResponse.rows_of" :key="row_of.of_id">
							<a @click="goToResearch('offers', row_of.of_id)">{{ $options.filters.formatOfferName(row_of) }}</a>
						</div>

						<hr style="margin-top: 20px" />
						<h2 style="margin-top: 20px">
							{{ researchResponse.total_ac }} Action(s) trouvées
							<span v-if="researchResponse.total_ac > 100">(affichage limité à 100)</span>
						</h2>
						<div v-for="row_ac in researchResponse.rows_ac" :key="row_ac.ac_id">
							<a @click="goToResearch('home', row_ac.ac_id)">{{ $options.filters.formatActionName(row_ac) }}</a>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<action-win-edit ref="actionwinedit" @actionWinEditSaved="actionWinEditSaved"></action-win-edit>
	</div>
</template>

<script>
export default {
	name: "researchview",
	props: {
		researchVal: String
	},
	components: {},
	data() {
		return {
			researchResponse: {},
			tsSearch: null
		};
	},
	mounted() {
		// this.research();
	},
	watch: {
		researchVal: function(val) {
			if (this.tsSearch) window.clearTimeout(this.tsSearch);
			this.tsSearch = window.setTimeout(() => {
				this.research(val);
			}, 1000);
		}
	},
	methods: {
		actionWinEditSaved() {},
		async research() {
			let response = await this.$axios.get(this.$config.server_url + "/api/1.0/searches/search/" + this.researchVal);
			this.researchResponse = response.data;
		},
		goToResearch(route, id) {
			if (route === "home") {
				//this.$router.push({ name: "home", query: { action: id } });
				// alert("toto");
				this.$refs.actionwinedit.open(id);
			} else {
				this.$emit("deleteResearch");
				this.$router.push("/" + route + "/" + id + "/details");
			}
		}
	}
};
</script>
<style scoped>
.container-research {
	width: 100%;
	height: 100%;
}
</style>
