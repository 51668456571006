<template>
	<div>
		<v-card class="card1 card-building mt-3">
			<v-card-title>
				<h1>Immeuble</h1>
			</v-card-title>
			<v-card-text>
				<div>
					<div class="withpointer" @click="editName" v-if="canEditName" style="color: #2096f3">
						<icon name="edit"></icon> Éditer nom de l'immeuble
					</div>
					<m-form-combobox
						:disabled="newBuilding"
						@input="cbbuildingchange"
						v-model="building"
						label="Nom de l'immeuble existant <span style='color:red;font-size:18px;'>•</span>"
						:label-width="5"
						:name="$Utils.randomstring('bu_id')"
						:store-url="$config.server_url + '/api/1.0/buildings'"
						:store-params="{ addnone: true, addbuildingsreports: true, addimages: true }"
						item-value="bu_id"
						item-text="bu_name"
					></m-form-combobox>
				</div>
				<m-form-text
					:name="$Utils.randomstring('bu_name')"
					label="Nom du nouvel immeuble <span style='color:red;font-size:18px;'>•</span>"
					:label-width="5"
					v-model="bu_name_new"
					:disabled="existingBuilding && !editNameNew"
					@input="disabledInput"
				></m-form-text>
				<m-form-text
					:name="$Utils.randomstring('bu_address1')"
					label="Adresse 1"
					:label-width="5"
					v-model="building.bu_address1"
				></m-form-text>
				<m-form-text
					:name="$Utils.randomstring('bu_address2')"
					label="Adresse 2"
					:label-width="5"
					v-model="building.bu_address2"
				></m-form-text>
				<v-layout wrap>
					<v-flex md6>
						<m-form-text :name="$Utils.randomstring('bu_zip')" label="CP" :label-width="5" v-model="building.bu_zip"></m-form-text>
						<m-form-select
							:name="$Utils.randomstring('bu_district')"
							:items="$store.state.items_bu_district"
							v-model="building.bu_district"
							label="Quartier <span style='color:red;font-size:18px;'>•</span>"
							:label-width="5"
						></m-form-select>
						<m-form-text
							:name="$Utils.randomstring('bu_id_xml')"
							label="ID XML"
							:label-width="5"
							v-model="building.bu_id_xml"
						></m-form-text>

						<!-- <m-form-text
							:name="$Utils.randomstring('di_id')"
							v-model="building.bu_district_immotools"
							label="Quartier Immotools"
							:label-width="5"
						></m-form-text> -->
					</v-flex>
					<v-flex md6>
						<m-form-text
							:name="$Utils.randomstring('bu_city')"
							v-model="building.bu_city"
							label="Ville <span style='color:red;font-size:18px;'>•</span>"
							:label-width="5"
						></m-form-text>
						<m-form-text
							:name="$Utils.randomstring('bu_country')"
							v-model="building.bu_country"
							label="Pays <span style='color:red;font-size:18px;'>•</span>"
							:label-width="5"
						></m-form-text>
					</v-flex>
				</v-layout>
			</v-card-text>

			<v-card-title>
				<h1>
					Description
					<span class="bis">immeuble</span>
				</h1>
			</v-card-title>
			<v-card-text v-if="building">
				<div style="font-size: 12px" class="">
					<span v-for="item in $store.state.items_languages" :key="item.value">
						<a @click="changeLang(item)" :class="!building['bu_description_' + item.value] ? 'color-red' : ''">{{ item.text }}</a>
						/
					</span>
				</div>
				<div v-for="item in $store.state.items_languages" :key="item.value">
					<m-form-text
						type="textarea"
						:name="$Utils.randomstring(`bu_description_${item.value}`)"
						v-show="show_lang == item.value"
						:label="`Description en ${item.text}`"
						:label-width="3"
						v-model="building['bu_description_' + item.value]"
						labelPosition="top"
						@input="calculMaxLength"
					></m-form-text>
					<div v-if="show_lang == item.value && descriptionTooLong[show_lang]" class="text-red">
						Description de l'offre plus description de l'immeuble trop longue en {{ item.text }}
					</div>
				</div>
				<m-form-select
					:name="$Utils.randomstring('bu_standing')"
					v-model="building.bu_standing"
					:items="this.$store.state.items_bu_standing"
					label="Standing"
					:label-width="6"
				></m-form-select>
				<m-form-select
					v-if="!$store.state.preferences.shared.forMonaco"
					:name="$Utils.randomstring('bu_construction')"
					v-model="building.bu_construction"
					:items="this.$store.state.items_bu_construction"
					label="Construction"
					:label-width="6"
				></m-form-select>
				<m-form-select
					v-if="!$store.state.preferences.shared.forMonaco"
					label="Couverture"
					v-model="building.bu_roof"
					:items="this.$store.state.items_bu_roof"
					:label-width="6"
				></m-form-select>
				<m-form-select
					v-if="!$store.state.preferences.shared.forMonaco"
					:name="$Utils.randomstring('bu_facade')"
					v-model="building.bu_facade"
					:items="this.$store.state.items_bu_facade"
					label="Façade"
					:label-width="6"
				></m-form-select>
				<m-form-select
					:name="$Utils.randomstring('bu_state')"
					v-model="building.bu_state"
					:items="this.$store.state.items_bu_state"
					label="Etat de l'immeuble"
					:label-width="6"
				></m-form-select>
				<m-form-text
					:name="$Utils.randomstring('bu_construction_year')"
					v-model="building.bu_construction_year"
					label="Année de construction"
					:label-width="6"
					class="alignright"
				></m-form-text>
				<m-form-text
					:name="$Utils.randomstring('bu_floor')"
					v-model="building.bu_floor"
					label="Nb d'étages"
					:label-width="6"
					class="alignright"
				></m-form-text>
				<m-form-text
					:name="$Utils.randomstring('bu_digital_code')"
					label="Digicode"
					v-model="building.bu_digital_code"
					:label-width="6"
					class="alignright"
				></m-form-text>

				<m-form-combobox
					v-model="building.bu_syndic"
					label="Syndic"
					prepend-icon="mdi-account"
					:name="$Utils.randomstring('co_id_commercial2')"
					:store-url="$config.server_url + '/api/1.0/contacts'"
					:store-params="{ gr_id: 'syndics' }"
					item-value="co_id"
					:item-text="$options.filters.formatContactName"
				></m-form-combobox>
				<m-form-text
					:name="$Utils.randomstring('bu_plannedwork')"
					label="Travaux votés"
					v-model="building.bu_plannedwork"
					:label-width="6"
					class="alignright"
				></m-form-text>

				<v-layout wrap>
					<v-flex class="py-2">
						<v-radio-group row>
							<m-form-checkbox
								style="width: 180px"
								class="mr-2"
								label="Ascenseur"
								:name="$Utils.randomstring('bu_elevator')"
								v-model="building.bu_elevator"
							></m-form-checkbox>
							<m-form-checkbox
								style="width: 180px"
								class="mr-2"
								label="Accès handicapé"
								:name="$Utils.randomstring('bu_handicap_access')"
								v-model="building.bu_handicap_access"
							></m-form-checkbox>
							<m-form-checkbox
								style="width: 180px"
								label="Interphone"
								class="mr-2"
								:name="$Utils.randomstring('bu_intercom')"
								v-model="building.bu_intercom"
							></m-form-checkbox>
							<m-form-checkbox
								style="width: 180px"
								label="Concierge"
								class="mr-2"
								:name="$Utils.randomstring('bu_concierge')"
								v-model="building.bu_concierge"
							></m-form-checkbox>
							<!-- </v-flex>
            <v-flex class="pt-0 pb-2">-->
							<m-form-checkbox
								style="width: 180px"
								class="mr-2"
								:name="$Utils.randomstring('bu_swimming_pool')"
								v-model="building.bu_swimming_pool"
								label="Piscine"
							></m-form-checkbox>
							<!-- <m-form-checkbox
                style="width:180px;"
                class="mr-2"
                :name="$Utils.randomstring('bu_digital_code')"
                v-model="building.bu_digital_code"
                label="Digicode"
              ></m-form-checkbox> -->
							<m-form-checkbox
								style="width: 180px"
								v-if="!$store.state.preferences.shared.forMonaco"
								class="mr-2"
								:name="$Utils.randomstring('bu_guardian')"
								v-model="building.bu_guardian"
								label="Gardien"
							></m-form-checkbox>
						</v-radio-group>
					</v-flex>
				</v-layout>
			</v-card-text>

			<v-card-title class="mb-0 pb-0">
				<h1 class="mb-0 pb-0">Photo immeuble</h1>
			</v-card-title>
			<v-card-text>
				<div class="">
					<v-file-input accept="image/*" label="Photo de l'immeuble" ref="inputfilephoto" @change="handleChangeFile"></v-file-input>
				</div>
				<div v-for="row_fi in images2" :key="row_fi.fi_id" class="item_image">
					<v-img
						:src="
							row_fi.binary
								? row_fi.binary
								: `${$config.server_url}/api/1.0/images/${row_fi.fi_id}/75/75?token=${$store.state.accesstoken}&d=${row_fi.updatedAt}`
						"
						height="75px"
						width="75px"
						:ref="`littleimg${row_fi.fi_id}`"
					>
						<div class="container-icons">
							<v-icon dark @click="deleteImage(row_fi.fi_id)">mdi-delete</v-icon>
						</div>
					</v-img>
				</div>
				<div style="clear:both;"></div>
			</v-card-text>

			<v-card-title>
				<h1>
					Assemblée générale
					<v-btn color="primary2" x-small style="margin-left: 30px" @click="addBuildingReport">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</h1>
			</v-card-title>
			<v-card-text v-if="building">
				<div v-if="buildingreport_input">
					<m-form-date
						:name="$Utils.randomstring('buildingreport_date')"
						v-model="building.buildingreport_date"
						label="Date de l'assemblée"
						:label-width="7"
					/>
					<m-form-text
						type="textarea"
						ref="inputMessage"
						:name="$Utils.randomstring('buildingreport_txt')"
						v-model="building.buildingreport_txt"
						label="Ajouter un compte rendu"
						label-position="top"
					></m-form-text>
				</div>
				<table style="width: 100%">
					<tr v-for="(row_bur, indexBur) in building.buildingsreports" :key="row_bur.bur_id">
						<!-- <td style="border-bottom:1px solid gray;padding:5px 10px;">
              {{ row_bur.bur_date | formatDate }}
            </td> -->
						<td style="border-bottom: 1px solid gray; padding: 5px 10px">
							<div>
								<b>Le {{ row_bur.bur_date | formatDate }}</b>
							</div>
							<div
								style="cursor: pointer"
								v-if="!moreBurText['num' + indexBur]"
								v-html="$options.filters.limittext(row_bur.bur_text, 50, 'Voir plus...')"
								@click="clickMoreBurText(indexBur)"
							></div>
							<div
								v-if="moreBurText['num' + indexBur]"
								v-html="$options.filters.nl2br(row_bur.bur_text)"
								@click="clickMoreBurText(indexBur)"
							></div>
						</td>
						<td style="border-bottom: 1px solid gray; padding: 5px 10px; width: 40px" class="text-right" valign="top">
							<v-icon @click="deleteBuildingsReport(row_bur, indexBur)">mdi-trash-can</v-icon>
						</td>
					</tr>
				</table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
export default {
	name: "offercardbuilding",
	props: {
		value: {
			type: Object
		},
		canEditName: {
			default: false,
			type: Boolean
		},
		descriptionTooLong: Object
	},
	data() {
		let images2 = [];
		let building = this.value;
		if (!building) building = {};
		else images2 = building.images;
		if (!images2) images2 = [];
		// console.log("images2", images2);
		return {
			images2,
			buildingphoto: "",
			moreBurText: {},
			buildingreport_input: false,
			// buildingreport_txt: "",
			bu_name_new: "",
			building,
			newBuilding: false,
			existingBuilding: false,
			show_lang: "fr",
			editNameNew: false
		};
	},
	mounted() {
		if (this.building.value !== "0") {
			this.existingBuilding = true;
			// this.images2 =
		}
		if (!this.building.value) {
			this.existingBuilding = false;
		}
	},
	watch: {
		value(val) {
			// console.log("value", val);
			this.editNameNew = false;
			this.images2 = [];
			if (!val) val = {};
			this.building = val;
			this.images2 = this.building.images;
			if (!this.images2) this.images2 = [];
			// console.log("this.images2", this.images2);
			this.buildingreport_input = false;
		},
		bu_name_new(val) {
			this.building.bu_name_new = val;
			if (this.editNameNew) this.building.edit_bu_name = true;
			this.$emit("input", this.building);
		},
		building: {
			handler: function(val) {
				// console.log("building", val);
				if (val.bu_id != 0) {
					this.bu_name_new = val.bu_name;
				}
				this.$emit("input", val);
			},
			deep: true
		}
	},
	methods: {
		async deleteImage(fi_id) {
			if (window.confirm("Souhaitez-vous effacer cette image ?" + fi_id)) {
				await this.$axios.delete(this.$config.server_url + "/api/1.0/files/" + fi_id);
				this.$emit("reloadofferimages");
			}
		},
		changeLang(item) {
			this.show_lang = item.value;
			//this.calculMaxLength();
		},
		getImages() {
			return this.images2;
		},
		// dragleaveimages(e) {
		// 	this.$refs.frameDropImage.style.backgroundColor = "white";
		// },
		// dragoverimages(e) {
		// 	this.$refs.frameDropImage.style.backgroundColor = "green";
		// },
		// dropimages(e) {
		// 	this.$refs.frameDropImage.style.backgroundColor = "white";
		// 	let droppedFiles = e.dataTransfer.files;
		// 	if (!droppedFiles) return;
		// 	[...droppedFiles].forEach(f => {
		// 		// this.files.push(f);
		// 		this.createThumbnail(f);
		// 	});
		// 	// this.$emit("inputfile", this.files, "images");
		// },
		async handleChangeFile(f) {
			if (!f || !f.name) return;
			await this.createThumbnail(f, async () => {
				// alert("ici");
				await this.saveFiles();
				if (this.building && this.building.bu_id) {
					let response = await this.$axios.get(this.$config.server_url + "/api/1.0/buildings/" + this.building.bu_id);
					this.building = response.data.data;
				}

				this.$emit("reloadofferimages");
			});
		},
		setImages(images) {
			this.images2 = images;
			if (!this.images2) this.images2 = [];
			this.$refs.inputfilephoto.reset();
		},
		createThumbnail(f, cb) {
			if (f.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG)$/)) {
				var reader = new FileReader();
				var me = this;
				reader.onload = (function(theFile) {
					return function(e) {
						me.images2.push({
							file: f,
							binary: e.target.result
						});
						cb();
						// console.log("me.images2", me.images2);
					};
				})(f);
				reader.readAsDataURL(f);
			}
		},
		async saveFiles() {
			// console.log("this.images2", this.images2, of_id);
			for (let iImage = 0; iImage < this.images2.length; iImage++) {
				const row_fi = this.images2[iImage];
				if (!row_fi.file) continue;
				let formData = new FormData();
				formData.append("bu_id", this.building.bu_id);
				formData.append("of_id", "");
				formData.append("fi_from", "buildings");
				formData.append("fi_sort", iImage + 1);
				formData.append("fi_subtype", "building");
				formData.append("fi_description", "");
				formData.append("fi_online", true);
				formData.append("image", row_fi.file);
				await this.$axios.post(this.$config.server_url + "/api/1.0/files", formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				});
			}
			this.images2 = [];
		},
		editName() {
			this.editNameNew = true;
		},
		clickMoreBurText(indexBur) {
			if (!this.moreBurText["num" + indexBur]) this.moreBurText["num" + indexBur] = false;
			this.moreBurText["num" + indexBur] = !this.moreBurText["num" + indexBur];
			this.$forceUpdate();
		},
		async deleteBuildingsReport(row_bur, indexBur) {
			this.building.buildingsreports.splice(indexBur, 1);
			await this.$axios.delete(this.$config.server_url + "/api/1.0/buildingsreports/" + row_bur.bur_id);
		},
		addBuildingReport() {
			this.buildingreport_input = !this.buildingreport_input;
		},
		cbbuildingchange() {
			if (this.building.bu_id == 0) this.bu_name_new = "";
			this.disabledInput();
		},
		disabledInput() {
			if (this.building.bu_id && this.building.bu_id !== 0) {
				this.existingBuilding = true;
				this.newBuilding = false;
			}
			if (!this.building.bu_id || this.building.bu_id === 0) {
				this.existingBuilding = false;
				this.newBuilding = true;
			}
			if (!this.building.bu_id && !this.bu_name_new) {
				this.existingBuilding = false;
				this.newBuilding = false;
			}
		},
		calculMaxLength() {
			this.$emit("calculMaxLength");
		}
	}
};
</script>
<style scoped>
.card-build.m-form-base .m-form-base__container-input input[disabled] {
	background-color: #fff;
}
.color-red {
	color: red;
}
.item_image {
	padding: 2px;
	float: left;
}
.container-icons {
	text-align: right;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
	color: white;
	padding-bottom: 15px;
}
</style>
